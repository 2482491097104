import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flex: 1,
    backgroundColor: '#f3f4f7',
    height: '100%',
    overflow: 'auto',
  },
}));

export default useStyles;
