import React from 'react';
import { MenuContexProvider } from '../../contexts/AppMenuContext';
import { Menu } from './components';
import useStyles from './styles';

const AppLayout = ({ children }) => {
  const s = useStyles();

  return (
    <MenuContexProvider>
      <div className={s.root}>
        <Menu />
        {children}
      </div>
    </MenuContexProvider>
  );
};

export default AppLayout;
