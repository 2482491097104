import { Link } from 'react-router-dom';
import React from 'react';

import { BasicTextField } from 'components/common/Inputs/TextField/index';
import { Button } from 'components/common/Buttons';

const Section = ({
  title,
  desc,
  textBtn,
  placeholder,
  email = '',
  colorText,
  img,
  showInput = true,
  btnClass,
  type,
  showSignIn = true,
  onClick,
  loading,
  onChange,
  name,
  style,
  error,
  logo,
}) => {
  const text = colorText ? (
    <>
      {textBtn} <span className={style.span}>{colorText}</span>
    </>
  ) : (
    textBtn
  );

  return (
    <div className={style.rightSection}>
      {img && <img className={style.checkEmailIcon} src={img} alt="emailLogo" />}
      {logo && <img className={style.logoReset} src={logo} alt="emailLogo" />}

      <div className={style.top}>
        <h2 className={style.title}>{title}</h2>

        <div className={style.subTitle}>
          {desc}
          <span className={style.subTitleEmail}>{email}</span>
        </div>

        {showInput && (
          <BasicTextField
            name={name}
            onChange={onChange}
            type={type}
            customClass={style.input}
            placeholder={placeholder}
          />
        )}

        <div className={style.textError}>{error}</div>
        {textBtn && <Button text={text} loading={loading} customClass={btnClass} onClick={onClick} />}
      </div>

      {showSignIn && (
        <div className={style.forgotText}>
          Back to
          <Link className={style.reset} to="/login">
            Sign In
          </Link>
        </div>
      )}
    </div>
  );
};

export default Section;
