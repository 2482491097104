import React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import Router from './Router';
import { AuthContextProvider } from 'components/contexts/AuthContext';
import { SnackbarConsumer, SnackbarProvider } from 'components/contexts/SnackbarContext';
import { I18nProvider } from 'services/i18n';
import { darkTheme } from 'themes';

const App = () => (
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <I18nProvider>
      <SnackbarProvider>
        <AuthContextProvider>
          <Router />
          <SnackbarConsumer />
        </AuthContextProvider>
      </SnackbarProvider>
    </I18nProvider>
  </ThemeProvider>
);

export default App;
