import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import useStyles from './style';
import TemplatesList from './TemplatesList';
import AddNewTemplate from './AddNewTemplate';
import { useRpcApi } from '../../services/api';
import { useStoreTemplateBuilder } from '../../hooks/useStoreTemplateBuilder';
import TemplatesDetails from './TemplatesDetails';

const LIMIT = 10;
const [getTemplates, getCriteriaList] = useRpcApi('templates.getTemplatesList', 'criteria.getCriteriaList');

const Templates = () => {
  const classes = useStyles();
  const [templatesData, setTemplatesData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [nameSotr, setNameSort] = useState('createdAt');
  const [loadingList, setLoadingList] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(0);
  const [sort, setSort] = useState('desc');
  const [createTemplateStore, createTemplateActions] = useStoreTemplateBuilder(null);
  const [visible, setVisible] = useState(false);
  const [textPlatoon, setTextPlatoon] = useState('');
  const { pathname } = useLocation();

  const getData = useCallback(async () => {
    setLoadingList(true);
    const title = createTemplateStore.search?.length ? { $like: `%${createTemplateStore.search.trim()}%` } : {};

    const response = await getTemplates({
      filter: Object.entries({ parentId: { $eq: null }, title }),
      order: Object.entries({ [nameSotr]: sort }),
      limit: LIMIT,
      offset: offset * LIMIT,
    });

    setTemplatesData(response.items);
    setLimit(response?.total);
    setLoadingList(false);
  }, [createTemplateStore.search, nameSotr, offset, sort]);
  useEffect(() => {
    if (createTemplateStore.search?.length) {
      getData();
    }
  }, [createTemplateStore.search.length, getData]);

  useEffect(() => {
    if (pathname === '/templates') {
      getData();
    }
  }, [getData, pathname]);

  useEffect(() => {
    getCriteriaList({
      order: Object.entries({ name: 'asc' }),
    })
      .then((res) => setCategoryData(res?.items))
      .catch(console.error);
  }, []);

  const attr = {
    onChangeOffset: (num) => setOffset(num),
    templatesData,
    limit,
    value: createTemplateStore.search,
    setValue: (e) => createTemplateActions.changeSearch(e),
  };

  return (
    <div className={classes.container}>
      <Switch>
        <Route exact path="/templates">
          <TemplatesList
            visible={visible}
            setVisible={setVisible}
            setNameSort={setNameSort}
            setSort={setSort}
            textPlatoon={textPlatoon}
            {...attr}
            loadingList={loadingList}
            getData={getData}
          />
        </Route>
        <Route exact path="/templates/new-template">
          <AddNewTemplate setTextPlatoon={setTextPlatoon} setVisible={setVisible} categoryData={categoryData} />
        </Route>
        <Route exact path="/templates/edit-template/:id">
          <TemplatesDetails setTextPlatoon={setTextPlatoon} setVisible={setVisible} />
        </Route>
        <Route exact path="/templates/details/:id">
          <TemplatesDetails categoryData={categoryData} setTextPlatoon={setTextPlatoon} setVisible={setVisible} />
        </Route>
        <Redirect exact path="/*" to={'/templates'} />
      </Switch>
    </div>
  );
};
export default Templates;
