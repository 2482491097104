import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'components/assets/images/logo.png';
import clsx from 'clsx';
import useStyles from './style';

const AuthSidebar = ({ customClass }) => {
  const s = useStyles();
  return (
    <section className={clsx(s.leftSection, customClass)}>
      <img className={s.logo} src={Logo} alt="Logo" />

      <div className={s.links}>
        <p>Copyright © 2019 Ten-Eight</p>
        <Link to="/terms" className={s.terms}>
          Terms and conditions
        </Link>
      </div>
    </section>
  );
};

export default AuthSidebar;
