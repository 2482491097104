import { Avatar, Grid, Radio, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from '../../../style';

const Item = ({ user, sectionTitle, onChangeRecruits, checked }) => {
  const classes = useStyles();

  return (
    <>
      {sectionTitle && (
        <Typography variant="h4" color="textSecondary" className={classes.char}>
          {sectionTitle?.toUpperCase()}
        </Typography>
      )}
      <Grid container spacing={1} alignItems="stretch" wrap="nowrap" className={classes.item}>
        <Grid item>
          <Radio checked={checked} onClick={onChangeRecruits} color="primary" />
        </Grid>
        <Grid item>
          <Avatar className={classes.avatarText} src={user.avatar} alt="Avatar">
            {user?.firstName?.charAt(0) + user?.lastName?.charAt(0)}
          </Avatar>
        </Grid>
        <Grid container className={classes.textBox} justify="space-between" alignItems="center">
          <Typography variant="body1">{`${user.firstName} ${user.lastName}`}</Typography>
          <Typography color="textSecondary" variant="body1">
            {user.test}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Item;
