import React, { useContext, useEffect, useState } from 'react';
import translation from './translations/en.json';

function replaceValues(source, values) {
  if (values) {
    const arr = source.split(' ');
    const formattedMessage = arr.map((word) => {
      const key = word.substring(1, word.length - 1);
      if (word.startsWith('{') && values[key]) {
        return values[key];
      } else {
        return word;
      }
    });
    return formattedMessage.join(' ');
  }
  return source;
}

export const i18nContextInitialValue = {
  currentLanguage: 'en',
  translation,
  changeLanguage: () => {},
  trans: () => {},
};
const I18nContext = React.createContext(i18nContextInitialValue);

export function useTrans() {
  return useContext(I18nContext);
}

export function I18nProvider({ children }) {
  const [state, setState] = useState(i18nContextInitialValue);

  useEffect(() => {
    const changeLanguage = (currentLanguage) => {
      import(`./translations/${currentLanguage}.json`)
        .then((translation) => {
          setState((state) => ({ ...state, translation, currentLanguage }));
        })
        .catch((e) => console.log(e));
    };
    const trans = (key, values) => {
      const text = state.translation[key] || key;
      return values ? replaceValues(text, values) : text;
    };
    setState((state) => ({ ...state, changeLanguage, trans }));
  }, [state.translation]);

  return <I18nContext.Provider value={state}>{children}</I18nContext.Provider>;
}
