import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getCurrentMonth } from '../../utils.ts';
import { useMonth } from '../../hooks/index.ts';

import React from 'react';

const CustomHead = (props) => {
  const { activeMonth, onMonthChange } = props;
  const { prevMonth, nextMonth, monthName, year } = useMonth(activeMonth);

  const PickerEvents = {
    toPrev: () => {
      onMonthChange(prevMonth);
    },
    toNext: onMonthChange.bind(this, nextMonth),
  };
  return (
    <Grid style={{ minWidth: 295 }} container spacing={2} alignItems="center">
      <Grid item>
        <Button variant="outlined" onClick={() => onMonthChange(getCurrentMonth())}>
          Today
        </Button>
      </Grid>
      <Grid item>
        <IconButton size="small" onClick={PickerEvents.toPrev}>
          <ChevronLeftIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton size="small" onClick={PickerEvents.toNext}>
          <ChevronRightIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography>{`${monthName} ${year}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default CustomHead;
