import React, { useCallback, useEffect, useRef, useState } from 'react';
import ScenarioDetails from './DetailsPage/index';
import EditScheduleDetails from './EditScheduleDetails';
import { useRpcApi } from 'services/api';
import { useLocation } from 'react-router-dom';
import { Route, Switch, useParams } from 'react-router';
import useStyles from './style';
import { useEventCreationStore } from '../../hooks/useEventCreationStore';
import MultiAssessment from './MultiAssesment';

const [
  getInstructorsInSchedule,
  getSchedule,
  getScheduleInRecruit,
  getEvaluationListByScheduleId,
  getScenarioList,
  getPlatoonList,
  getInstructorsList,
  getCriteriaList,
  getRecuitersByScheduleId,
] = useRpcApi(
  'instructorsInSchedule.getInstructorsInSchedule',
  'schedule.getSchedule',
  'scheduleInRecruit.getScheduleInRecruit',
  'evaluation.getEvaluationListByScheduleId',
  'scenario.getScenarioList',
  'platoon.getPlatoonList',
  'user.getInstructorsList',
  'criteria.getCriteriaList',
  'scheduleInRecruit.getRecuitersByScheduleId'
);

const ScheduleDetails = () => {
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();
  const [scheduleInstructor, setScheduleInstructor] = useState([]);
  const [dataSchedule, setDataSchedule] = useState([]);
  const [dataRecruits, setDataRecruits] = useState([]);
  const [dataShedules, setDataShedules] = useState([]);
  const [newDataSchedules, setNewDataSchedule] = useState([]);
  const [dataEvaluation, setDataEvaluation] = useState([]);
  const [scenarioList, setScenarioList] = useState([]);
  const [platoonList, setPlatoonList] = useState([]);
  const [instructorList, setInstructorList] = useState([]);
  const [rubricList, setRubricList] = useState([]);
  const [store, actions] = useEventCreationStore({});

  const classes = useStyles();
  const { id } = useParams();
  const topRef = useRef(null);

  useEffect(() => {
    if (dataRecruits?.length) {
      setNewDataSchedule(dataRecruits);

      if (dataShedules) {
        const newDataRecruits = dataRecruits.map((i) => {
          const shedule = dataShedules?.find((j) => j?.recruitId === i?.recruitId);
          const fullAssessment = dataEvaluation?.find((e) => e?.recruitId === i?.recruitId);
          return { ...i, checked: false, shedule, fullAssessment };
        });
        return setNewDataSchedule(newDataRecruits);
      }
    }
  }, [dataShedules, dataRecruits, dataEvaluation]);

  const getData = useCallback(() => {
    getInstructorsInSchedule({ scheduleId: id })
      .then((res) => setScheduleInstructor(res))
      .catch(console.error);
  }, [id]);

  const getDataSchedule = useCallback(() => {
    getSchedule({ id })
      // .then((res) => setDataSchedule(res))
      .then((res) => {
        return setDataSchedule(res);
      })
      .catch(console.error);
  }, [id]);

  const getListRecruits = useCallback(() => {
    getRecuitersByScheduleId({ scheduleId: id })
      .then((res) => {
        return setDataRecruits(res);
      })
      .catch(console.error);

    getScheduleInRecruit({ scheduleId: id })
      .then((res) => setDataShedules(res))
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSchedule.platoonId]);

  const getevaluationByScheduleId = useCallback(() => {
    getEvaluationListByScheduleId({ scheduleId: id })
      .then((res) => setDataEvaluation(res))
      .catch(console.error);
  }, [id]);

  const getScenariosList = useCallback(() => {
    return getScenarioList({
      order: Object.entries({ name: 'asc' }),
    })
      .then((res) => setScenarioList(res?.items))
      .catch(console.error);
  }, []);

  const getPlatoonsList = useCallback(() => {
    return getPlatoonList({
      order: Object.entries({ name: 'asc' }),
    })
      .then((res) => setPlatoonList(res?.items))
      .catch(console.error);
  }, []);

  const getInsructorsList = useCallback(() => {
    return getInstructorsList({
      order: Object.entries({ firstName: 'asc' }),
    })
      .then((res) => setInstructorList(res?.items))
      .catch(console.error);
  }, []);

  const getCategoriesList = useCallback(() => {
    return getCriteriaList({
      order: Object.entries({ name: 'asc' }),
    })
      .then((res) => setRubricList(res?.items))
      .catch(console.error);
  }, []);

  useEffect(() => {
    dataSchedule.platoonId && getListRecruits();
  }, [dataSchedule.platoonId, getListRecruits]);

  useEffect(() => {
    if (!topRef.current) return;
    topRef.current.scrollTo({ top: 0 });
  }, [pathname]);

  useEffect(() => {
    getScenariosList();
    getPlatoonsList();
    getInsructorsList();
    getCategoriesList();
    getData();
    getevaluationByScheduleId();
    getDataSchedule();
  }, [
    getScenariosList,
    getPlatoonsList,
    getInsructorsList,
    getCategoriesList,
    getData,
    getevaluationByScheduleId,
    getDataSchedule,
  ]);

  useEffect(() => {
    if (dataSchedule) {
      actions.setEvent({
        ...store,
        scenarioId: dataSchedule?.scenarioData?.id,
        platoonId: dataSchedule?.platoonData?.id,
        criterioId: dataSchedule?.criterioId,
        beginningAt: dataSchedule?.beginningAt,
        endAt: dataSchedule?.endAt,
        recurrence: dataSchedule?.recurrence,
        duration: dataSchedule?.duration,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSchedule]);

  return (
    <div ref={topRef} className={classes.container}>
      <Switch>
        <Route exact path={`/event-details/:id`}>
          <ScenarioDetails
            id={id}
            getevaluationByScheduleId={getevaluationByScheduleId}
            setNewDataSchedule={setNewDataSchedule}
            dataDetails={scheduleInstructor}
            dataSchedule={dataSchedule}
            dataRecruits={dataRecruits}
            newDataSchedules={newDataSchedules}
            getListRecruits={getListRecruits}
            getData={getData}
            getDataSchedule={getDataSchedule}
            visible={visible}
            setVisible={setVisible}
          />
        </Route>
        <Route exact path={`/event-edit/:id`}>
          <EditScheduleDetails
            store={store}
            actions={actions}
            platoonsData={platoonList}
            instructorsData={instructorList}
            scenariosData={scenarioList}
            scheduleData={dataSchedule}
            shedleInstructors={scheduleInstructor}
            categoryData={rubricList}
            visible={visible}
            setVisible={setVisible}
          />
        </Route>
        <Route exact path={`/multi-assessment/:id`}>
          <MultiAssessment
            getevaluationByScheduleId={getevaluationByScheduleId}
            dataDetails={scheduleInstructor}
            dataSchedule={dataSchedule}
            id={id}
            newDataSchedules={newDataSchedules}
            visible={visible}
            setVisible={setVisible}
            getListRecruits={getListRecruits}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default ScheduleDetails;
