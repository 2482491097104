import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconSortArrow = (props) => {
  const { up = false, width = 8, height = 8 } = props;

  return up ? (
    <SvgIcon width={width} height={height} viewBox="0 0 213.333 213.333">
      <g>
        <polygon points="106.667,53.333 0,160 213.333,160" fill="#a4a6aa" />
      </g>
    </SvgIcon>
  ) : (
    <SvgIcon width={width} height={height} viewBox="0 0 213.333 213.333">
      <g>
        <polygon points="0,53.333 106.667,160 213.333,53.333" fill="#a4a6aa" />
      </g>
    </SvgIcon>
  );
};

IconSortArrow.propTypes = simpleIconPropsTypes;
