export { default as ActionBlockDetails } from './ActionBlockDetails';
export { default as AssessmentPanel } from './AssessmentPanel';
export { default as TextAreaBlock } from './TextAreaBlock';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const defaultText =
  'A score of 1 = does not meet standard. A score of 2 = needs improvement. A score of 3 = meets standard. A score of 4 = exceeds standards.';

export const roles = [
  { id: 0, name: 'None' },
  { id: 1, name: 'Assistant Team Leader' },
  { id: 2, name: 'Contact' },
  { id: 3, name: 'Cover' },
  { id: 4, name: 'Point' },
  { id: 5, name: 'Rear Guard' },
  { id: 6, name: 'Team Leader' },
];

export const behaviours = [
  { id: 0, name: 'None' },
  { id: 1, name: 'Active' },
  { id: 2, name: 'Assaultive' },
  { id: 3, name: 'Bodily Harm' },
  { id: 4, name: 'Cooperative' },
  { id: 5, name: 'Passive' },
];
