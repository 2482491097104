import React, { useCallback, useEffect, useState } from 'react';
import { TopBar, Evaluation } from 'components/common';
import { useRpcApi } from '../../../services/api';
import { getEventStatus } from '../../../utils/helpers';
import BigAvatar from './BigAvatar';
import { useHistory } from 'react-router-dom';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import { monthNames, roles, behaviours, getTodayFilterDiapazon } from '../constants';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button, Divider, Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import CustomButton from '../../../components/common/Buttons/Button';
import useStyles from './style';
import useBooleanHandlers from '../../../hooks/useBooleanHandlers';
import GenerateReportDialog from './GenerateReportDialog';
import { useLabels } from '../../../components/contexts/AuthContext';

const [getScheduleListByRecruitId, getPlatoon, getScheduleListInRecruitId, getPlatoonByRecruitId] = useRpcApi(
  'schedule.getScheduleListByRecruitId',
  'platoon.getPlatoon',
  'scheduleInRecruit.getScheduleListInRecruitId',
  'platoonInRecruit.getPlatoonByRecruitId'
);

const RecruitDetails = ({ currentUser, visible, setVisible, getCurentRecruit, loading }) => {
  const classes = useStyles();
  const history = useHistory();
  const [openInfo, setOpenInfo] = useState(false);
  const [platoon, setPlatoon] = useState({});
  const [platoonId, setPlatoonId] = useState(null);
  const [scheduleRoleBehavior, setScheduleRoleBehavior] = useState([]);
  const [finishScenariosList, setFinishScenariosList] = useState([]);
  const [nextScenariosList, setNextScenariosList] = useState([]);
  const [finishScenarios, setFinishScenarios] = useState([]);
  const [nextScenarios, setNextScenarios] = useState([]);
  const [loadingEventFinish, setLoadingEventFinish] = useState(true);
  const [loadingEventNext, setLoadingEventNext] = useState(true);
  const [open, onOpen, onClose] = useBooleanHandlers();
  const labels = useLabels();
  const userId = currentUser && currentUser.id ? currentUser.id : null;

  const getScheduleList = useCallback(
    async (diapazon, attr, limit = 0) => {
      if (!userId) return [];

      const [eventList, evaluationList] = await getScheduleListByRecruitId({
        recruitId: userId,
        order: Object.entries({ beginningAt: 'asc' }),
        withEvaluation: true,
        filter: Object.entries({
          beginningAt: { [attr]: diapazon },
          parentId: { $ne: null },
        }),
        limit,
      }).catch((e) => console.error(e));
      const list = eventList.map((event, index) => ({ ...event, evaluationList: evaluationList[index] }));
      return list;
    },
    [userId]
  );

  const getCurrentDay = (from) => {
    let d = new Date(from);
    return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  };

  const filterFinishScenarios = (list) => list.filter((item) => getEventStatus(item) === 'COMPLETED');

  const filterNextScenarios = (list) => list.filter((item) => !(getEventStatus(item) === 'COMPLETED'));

  useEffect(() => {
    getScheduleList(getTodayFilterDiapazon()[1], '$lt', 5)
      .then((res) => setFinishScenariosList(filterFinishScenarios(res)))
      .catch((e) => console.error(e))
      .finally(() => setLoadingEventFinish(false));
    getScheduleList(getTodayFilterDiapazon()[0], '$gt')
      .then((res) => setNextScenariosList(filterNextScenarios(res)))
      .catch((e) => console.error(e))
      .finally(() => setLoadingEventNext(false));
  }, [getScheduleList]);

  useEffect(() => {
    getCurentRecruit();
    setVisible(false);
  }, [getCurentRecruit, setVisible]);

  useEffect(() => {
    if (userId) {
      getPlatoonByRecruitId({ recruitId: userId })
        .then((res) => res && res.platoonId && setPlatoonId(res.platoonId))
        .catch((e) => console.error(e));
    }
  }, [userId]);

  const getCurrentPlatoon = useCallback(() => {
    if (!platoonId) return;

    getPlatoon({ id: platoonId })
      .then((res) => setPlatoon(res))
      .catch(console.error);
  }, [platoonId]);

  const getScheduleRoleBehaviorList = useCallback(() => {
    getScheduleListInRecruitId({ recruitId: userId })
      .then((res) => setScheduleRoleBehavior(res))
      .catch(console.error);
  }, [userId]);

  useEffect(() => {
    if (finishScenariosList?.length) {
      setFinishScenarios(finishScenariosList);

      const newDataRecruits = finishScenariosList.map((i) => {
        const roleBehaviour = scheduleRoleBehavior?.find(
          (j) => j?.scheduleId === i?.id && i?.recruitId === j?.recruitId
        );
        return { ...i, roleBehaviour };
      });

      return setFinishScenarios(newDataRecruits.reverse());
    }
  }, [finishScenariosList, scheduleRoleBehavior]);

  useEffect(() => {
    if (nextScenariosList?.length) {
      setNextScenarios(nextScenariosList);

      const newDataRecruits = nextScenariosList?.map((i) => {
        const roleBehaviour = scheduleRoleBehavior?.find(
          (j) => j?.scheduleId === i?.id && i?.recruitId === j?.recruitId
        );
        return { ...i, roleBehaviour };
      });

      return setNextScenarios(newDataRecruits);
    }
  }, [nextScenariosList, scheduleRoleBehavior]);

  useEffect(() => {
    getCurrentPlatoon();
    getScheduleRoleBehaviorList();
  }, [getCurrentPlatoon, getScheduleRoleBehaviorList]);

  return (
    <>
      <SuccessNotification open={visible} setOpen={setVisible} text={labels.recruit} />
      <TopBar
        maxWidth={744}
        title={`${labels.recruit} Details`}
        hasBackButton
        goToEdit={() => history.push(`/recruits/edit-recruit/${userId}`)}
      />
      <div className={classes.container}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Paper>
              <div className={classes.helperGrid}>
                <div>
                  <BigAvatar
                    src={currentUser?.avatar}
                    subSrc={platoon?.avatar}
                    alt={currentUser?.firstName}
                    subAlt={platoon?.name}
                    user={currentUser}
                    platoon={platoon}
                  />
                </div>
                <div>
                  <Typography variant="h3">{`${currentUser?.firstName} ${currentUser?.lastName}`}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {` ${currentUser.organizationInfo?.name || ''}`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentUser?.badgeNumber && ` Badge # ${currentUser?.badgeNumber}`}
                  </Typography>
                  <Divider className={classes.dividerMargin} />
                  {openInfo && (
                    <>
                      <Typography variant="body1">Personal information</Typography>
                      <Typography className={classes.personalInfo} variant="body2" color="textSecondary">
                        Email <span> {currentUser?.email || '-'} </span>
                      </Typography>
                      <Typography className={classes.personalInfo} variant="body2" color="textSecondary">
                        Phone <span> {currentUser?.phoneNumber || '-'} </span>
                      </Typography>
                      <Typography className={classes.personalInfo} variant="body2" color="textSecondary">
                        Emergency contact <span> {currentUser?.emergencyContactPhone || '-'} </span>
                      </Typography>
                      <Typography className={classes.personalInfo} variant="body2" color="textSecondary">
                        Date of birth{' '}
                        <span> {currentUser?.dateOfBirth ? getCurrentDay(currentUser?.dateOfBirth) : '-'} </span>
                      </Typography>
                      <Typography className={classes.personalInfo} variant="body2" color="textSecondary">
                        Address <span> {currentUser?.address || '-'} </span>
                      </Typography>

                      <Divider className={classes.dividerMargin} />
                    </>
                  )}
                  <Button className={classes.center} onClick={() => setOpenInfo(!openInfo)} color="primary">
                    {openInfo ? 'HIDE ' : 'SHOW '}
                    PERSONAL INFO
                  </Button>
                </div>
              </div>
            </Paper>

            <Paper>
              {loadingEventFinish ? (
                <Skeleton />
              ) : (
                <Grid container direction="column">
                  <Grid className={classes.titleTable} container item alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="h6">Completed scenarios</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="h6">Role</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h6">Behaviour</Typography>
                    </Grid>
                    <Grid item style={{ width: 120 }}>
                      <Typography variant="h6">Date</Typography>
                    </Grid>
                    <Grid item container xs justify="flex-end">
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => history.push(`/recruits/completed-scenarios/${currentUser.id}`)}
                      >
                        VIEW ALL
                      </Button>
                    </Grid>
                  </Grid>
                  {finishScenarios?.map((item, index) => (
                    <Grid key={index} className={classes.itemScenarios} container item alignItems="center">
                      <Grid item xs={3}>
                        <Button onClick={() => history.push(`/event-details/${item?.id}`)} color="primary">
                          {item?.name}
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {+item?.roleBehaviour?.roles ? roles[+item?.roleBehaviour?.roles] : '------'}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          {+item?.roleBehaviour?.behaviours ? behaviours[+item?.roleBehaviour?.behaviours] : '------'}
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: 120 }}>
                        <Typography variant="body2">{getCurrentDay(item?.beginningAt)}</Typography>
                      </Grid>
                      <Grid item container xs justify="flex-end">
                        <Typography variant="body2" component="div">
                          <Evaluation evaluation={item?.evaluationList[0]?.evaluation} />
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Paper>

            <Paper>
              {loadingEventNext ? (
                <Skeleton />
              ) : (
                <Grid container direction="column">
                  <Grid className={classes.titleTable} container item alignItems="center">
                    <Grid item container xs={3}>
                      <Typography className={classes.scenariosTitle} variant="h4">
                        Next scenarios <span>{nextScenarios?.length}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="h6">Role</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h6">Behaviour</Typography>
                    </Grid>
                    <Grid item style={{ width: 120 }}>
                      <Typography variant="h6">Date</Typography>
                    </Grid>
                    <Grid item xs="auto"></Grid>
                  </Grid>
                  {nextScenarios?.map((item, index) => (
                    <Grid key={index} className={classes.itemScenarios} container item alignItems="center">
                      <Grid item xs={3}>
                        <Button onClick={() => history.push(`/event-details/${item?.id}`)} color="primary">
                          {item?.name}
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {item?.roleBehaviour?.roles ? roles[+item?.roleBehaviour?.roles] : '------'}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          {item?.roleBehaviour?.behaviours ? behaviours[+item?.roleBehaviour?.behaviours] : '------'}
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: 120 }}>
                        <Typography variant="body2">{getCurrentDay(item?.beginningAt)}</Typography>
                      </Grid>
                      <Grid item container xs justify="flex-end">
                        <Typography variant="body2" component="div">
                          <Evaluation evaluation={item?.evaluationList[0]?.evaluation} />
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Paper>

            <div className="center">
              <CustomButton onClick={onOpen}>Generate Report</CustomButton>
              <GenerateReportDialog open={open} onClose={onClose} recruitId={userId} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RecruitDetails;
