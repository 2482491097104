import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Button, SimpleSnackbar } from '../../../components/common';
import SuccessNotification from '../../../components/common/Dialogs/SuccessNotification';
import InputBox from '../../../components/common/Inputs/InputBox/InputBox';
import { useAuth } from '../../../components/contexts/AuthContext';
import useFormFields from '../../../hooks/useFormFields';
import { useRpcApi } from '../../../services/api';

const [saveOrganizationSettings] = useRpcApi('organizations.saveOrganizationSettings');

export default function CustomLabels() {
  const { labels, updateLabels } = useAuth();

  const [error, setError] = React.useState('');
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const { values, onChange } = useFormFields(labels);

  const onSave = (e) => {
    e.preventDefault();
    setPending(true);

    saveOrganizationSettings({ labels: values })
      .then(() => {
        updateLabels(values);
        setOpenSuccess(true);
      })
      .catch((error) => setError((error && error.data && error.data.message) || 'Server Error'))
      .finally(() => setPending(false));
  };

  return (
    <div>
      {error && <SimpleSnackbar message={error} onClose={() => setError('')} />}
      <SuccessNotification open={openSuccess} textPlatoon="Saved" setOpen={setOpenSuccess} />

      <Grid container item spacing={3} component="form" onSubmit={onSave} action="#">
        <Grid item xs={12}>
          <Typography variant="h4">Labels</Typography>
          <Typography>Set your own labels for common terms</Typography>
        </Grid>

        <Grid item xs={6}>
          <InputBox onInput={onChange} name="recruit" value={values.recruit} title="Recruit" required />
        </Grid>
        <Grid item xs={6}>
          <InputBox onInput={onChange} name="recruits" value={values.recruits} title="Recruits" required />
        </Grid>
        <Grid item xs={6}>
          <InputBox onInput={onChange} name="platoon" value={values.platoon} title="Platoon" required />
        </Grid>
        <Grid item xs={6}>
          <InputBox onInput={onChange} name="platoons" value={values.platoons} title="Platoons" required />
        </Grid>
        <Grid item xs={6}>
          <InputBox onInput={onChange} name="instructor" value={values.instructor} title="Trainer" required />
        </Grid>
        <Grid item xs={6}>
          <InputBox onInput={onChange} name="instructors" value={values.instructors} title="Trainers" required />
        </Grid>

        <Grid item xs={12}>
          <Button
            loading={pending}
            size="large"
            color="secondary"
            variant="contained"
            text="Save Changes"
            type="submit"
          />
        </Grid>
      </Grid>
    </div>
  );
}
