/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRpcApi } from 'services/api';
import { useCreateScenarioStore } from 'hooks/useStoreCreateScenario';
import { setCorrectObject } from '../../../utils/helpers';
import { getCorrectObject } from '../../Calendar/utils.ts';
import Select from 'components/common/Inputs/Select';
import DataPicker from 'components/common/Inputs/DataTimePicker';
import ButtonsGroup from 'components/common/Buttons/ButtonsGroup';
import { MultilineTextField } from 'components/common/Inputs';
import MapForm from 'components/common/MapBox/index';
import { FormContainer, InputBox, TopBar, MultiAutocomplete, Segments, InputTime } from 'components/common';
import { ReccurrenceList, DurationList, ReccurrenceData, apis, defaultViewport } from './constants';
import useStyles from './style';
import moment from 'moment';
import { useLabels } from '../../../components/contexts/AuthContext';

const [getSchedule, editScheduleDetails, updateSchedule] = useRpcApi(...apis);

const EditScheduleDetails = ({
  scenariosData,
  platoonsData,
  instructorsData,
  categoryData,
  scheduleData,
  shedleInstructors,
  store,
  actions,
  visible,
  setVisible,
}) => {
  const [error, setError] = useState({
    name: '',
    scenarioId: false,
    startDate: false,
    startTime: false,
    errorTime: '',
    errorDate: '',
  });
  const [loading, setLoading] = useState(false);
  const defaultInstructor = useRef();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [activeReccurence, selectReccurence] = useState(ReccurrenceList[0]);
  const [reccurence, setReccurence] = useState(ReccurrenceData[0]);
  const history = useHistory();
  const classes = useStyles();
  const [viewport, setViewport] = useState(defaultViewport);
  const labels = useLabels();

  const [createScenarioStore, createScenarioActions] = useCreateScenarioStore(null);

  const id = window.location.pathname.split('/')[2];

  const goTo = () => history.push(`/event-details/${id}`);

  const getDataSchedule = () => {
    getSchedule({ id })
      .then((res) => {
        const obj = setCorrectObject(res);
        setName(obj?.name);
        setViewport({ ...viewport, latitude: +obj?.latitude || 50.3, longitude: +obj?.longitude || 28.7 });
        if (!obj.address) {
          obj.latitude = '';
          obj.longitude = '';
        }
        createScenarioActions.setScenario(obj);

        actions.setEvent({
          ...store,
          startTime: { h: moment(res?.beginningAt).utc().format('HH'), m: moment(res?.beginningAt).utc().format('mm') },
          startDate: {
            d: moment(res?.beginningAt).utc().format('D'),
            m: moment(res?.beginningAt).utc().format('M'),
            y: moment(res?.beginningAt).utc().format('YYYY'),
          },
          endDate: {
            d: moment(res?.endAt).utc().format('D'),
            m: moment(res?.endAt).utc().format('M'),
            y: moment(res?.endAt).utc().format('YYYY'),
          },
          criterioId: res?.criterioId,
          beginningAt: res?.beginningAt,
          endAt: res?.endAt,
          platoonId: res?.platoonId,
          scenarioId: res?.scenarioId,
        });
      })
      .catch(console.error);
  };

  useEffect(() => {
    const ids = [];
    // eslint-disable-next-line array-callback-return
    instructorsData.map((i) => {
      const inst = shedleInstructors.find((j) => j?.instructor?.id === i?.id);
      if (inst) {
        ids.push(inst?.instructor);
      }
    });

    actions.selectInstructors(ids.map((i) => i.id));
    defaultInstructor.current = ids;
  }, [instructorsData, defaultInstructor, shedleInstructors]);

  const onSubmit = () => {
    setLoading(true);
    if (!store?.scenarioId || !store?.startDate?.d || !store?.startTime?.m || error?.errorTime || error?.errorDate) {
      setError({
        ...error,
        scenarioId: !store?.scenarioId && true,
        startDate: !store?.startDate?.d && true,
        startTime: !store?.startTime?.m && true,
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoading(false);
    } else {
      let correctObject = getCorrectObject(store);
      let obj = setCorrectObject({ ...createScenarioStore });
      const inputAddress = document.querySelector('.mapboxgl-ctrl-geocoder--input')?.value;
      const coords = {
        latitude: viewport?.latitude?.toString() || '',
        longitude: viewport.longitude?.toString() || '',
        address: inputAddress,
      };

      if (!obj?.name?.trim()?.length) {
        setError({ ...error, name: 'Field "Scenario Name" is required' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
        return;
      }

      editScheduleDetails({ ...obj, ...coords, id })
        .then(() => {
          updateSchedule({ id, ...correctObject }).catch((e) => console.log(e?.message));
        })
        .catch((e) => console.log(e?.message))
        .finally(() => {
          goTo();
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setLoading(false);
          setVisible(true);
        });
    }
  };
  const handleAddFieldValue = (event, index) => {
    const { value, name } = event.target;
    createScenarioActions.completeCustomTextFields(value, name, index);
  };
  useEffect(() => {
    getDataSchedule();
  }, []);

  const handlerObj = () => setVisible(!!Object.keys(scheduleData).length);

  useEffect(() => {
    handlerObj();
  }, [scheduleData]);

  return (
    <div className={classes.container}>
      <TopBar maxWidth={744} subtitle={id && name} title="Edit Scheduled Scenario" hasBackButton backText="Cancel" />
      <FormContainer titleBtn="Save" onClick={onSubmit} maxWidth={744} loading={loading}>
        <Grid container direction="column" spacing={6}>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">Schedule Scenario Information</Typography>
            </Grid>
            <Grid item xs={6}>
              <InputBox
                onInput={(e) => createScenarioActions.changeName(e.target.value)}
                title={`Scenario Name (visible for ${labels.recruits})`}
                value={createScenarioStore?.name}
                error={error?.name}
                star
              />
            </Grid>
            <Grid item xs={6}>
              <InputBox
                onInput={(e) => createScenarioActions.changeShortName(e.target.value)}
                title={`Short Code (visible for ${labels.recruits})`}
                value={createScenarioStore?.shortName}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                star
                title="Scenario"
                name="name"
                onChange={(e, indx) => actions.selectScenario(indx?.id)}
                value={store?.scenarioId}
                options={scenariosData}
                nameIndex="id"
              />
              {error?.scenarioId && <p className={classes.error}>*Scenario must be selected</p>}
            </Grid>
            <Grid item xs={6}>
              <Select
                title={labels.platoon}
                name="name"
                onChange={(e, indx) => actions.selectPlatoon(indx?.id)}
                value={store?.platoonId}
                options={platoonsData}
                nameIndex="id"
              />
            </Grid>
            <Grid item xs={12}>
              <MultiAutocomplete
                name="scenarioId"
                title={labels.instructors}
                labelKey="firstName"
                lastName="lastName"
                options={instructorsData}
                onChange={(e) => actions.selectInstructors(e)}
                defaultValue={defaultInstructor.current}
              />
            </Grid>
            <Grid container item xs={6} direction="column">
              <DataPicker
                star
                defaultDate={store?.beginningAt}
                title={store?.recurrence ? 'Start date' : 'Date'}
                onChange={(e) => actions.changeStartDate(e)}
                setError={setError}
                error={error}
              />
              {error?.startDate && <p className={classes.error}>*Date must be filled</p>}
            </Grid>
            <Grid container item xs={6} direction="column">
              <InputTime
                star
                defaultTime={store?.beginningAt}
                onChange={(e) => actions.changeStartTime(e)}
                setError={setError}
                error={error}
              />
              {error?.startTime && <p className={classes.error}>*Time must be filled</p>}
            </Grid>
            {store?.recurrence && (
              <Grid container item xs={12}>
                <DataPicker
                  star
                  defaultDate={store?.endAt}
                  title="End date"
                  onChange={(e) => actions.changeEndDate(e)}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Select
                title="Duration"
                name="label"
                onChange={(e, indx) => actions.changeDuration(indx?.value)}
                value={store?.duration}
                options={DurationList}
                timeValue
                nameIndex="value"
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                title="Criteria"
                name="name"
                onChange={(e, indx) => actions.selectCategories(indx?.id)}
                value={store?.criterioId}
                options={categoryData}
                nameIndex="id"
              />
            </Grid>
            <Grid item xs={12}>
              <Segments
                selectReccurence={setReccurence}
                value={store?.recurrence || reccurence || null}
                onValueChange={actions.changeRecurrence}
                listItems={ReccurrenceData}
              />
            </Grid>
            {createScenarioStore.customFields && createScenarioStore.customFields.length > 0 ? (
              createScenarioStore.customFields.map((el, index) => (
                <Grid item xs={12}>
                  <MultilineTextField
                    onInput={(event) => handleAddFieldValue(event, index)}
                    title={el.name}
                    value={el.value}
                  />
                </Grid>
              ))
            ) : (
              <>
                <Grid item xs={12}>
                  <MultilineTextField
                    onInput={createScenarioActions.onChangeValue}
                    name="objective"
                    title="Objective"
                    value={createScenarioStore?.objective}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultilineTextField
                    onInput={createScenarioActions.onChangeValue}
                    name="summary"
                    title="Summary"
                    value={createScenarioStore?.summary}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultilineTextField
                    onInput={createScenarioActions.onChangeValue}
                    name="instructorBriefing"
                    title={`${labels.instructor} Briefing`}
                    value={createScenarioStore?.instructorBriefing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultilineTextField
                    name="recruitBriefing"
                    title={`${labels.recruit} Briefing (visible for ${labels.recruits})`}
                    onInput={createScenarioActions.onChangeValue}
                    value={createScenarioStore?.recruitBriefing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultilineTextField
                    title="Dispatch Information"
                    name="dispatchInformation"
                    onInput={createScenarioActions.onChangeValue}
                    value={createScenarioStore?.dispatchInformation}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultilineTextField
                    title="Debriefing Notes"
                    name="debriefingNotes"
                    onInput={createScenarioActions.onChangeValue}
                    value={createScenarioStore?.debriefingNotes}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultilineTextField
                    title={`Assessment (visible for ${labels.recruits})`}
                    name="assessment"
                    onInput={createScenarioActions.onChangeValue}
                    value={createScenarioStore?.assessment}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="h5">Evaluation</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <ButtonsGroup
                  star
                  title="Scoring type"
                  value={createScenarioStore?.scoringType || activeReccurence || null}
                  selectReccurence={selectReccurence}
                  onValueChange={createScenarioActions.changeScoringType}
                  listItems={ReccurrenceList}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MapForm
                viewport={viewport}
                setViewport={setViewport}
                address={address}
                setAddress={setAddress}
                createScenarioStore={createScenarioStore}
                createScenarioActions={createScenarioActions}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormContainer>
    </div>
  );
};

export default EditScheduleDetails;
