import { ROUTE_DASHBOARD } from './dashboard';
import {
  ROUTE_DATABASE_MANAGEMENT,
  ROUTE_MANUFACTURERS,
  ROUTE_SUPPLIERS,
  ROUTE_MARKETS,
  ROUTE_CATEGORIES,
  ROUTE_DOCUMENTS,
  ROUTE_LABOR_COST,
  ROUTE_BUILDING_MATERIALS,
} from './database';
import { ROUTE_USERS_MANAGEMENT, ROUTE_USERS_ROLES } from './users';
import { ROUTE_ASSEMBLIES } from './assemblies';
import { ROUTE_PROJECTS } from './projects';
import { ROUTE_PAYROLL_MANAGEMENT } from './payroll';

export * from './dashboard';
export * from './database';
export * from './users';
export * from './assemblies';
export * from './projects';
export * from './payroll';

export const ROUTE_LOGIN = { key: 'login', path: '/login', i18nKey: 'Log In', icon: 'IconArrow' };
export const ROUTE_RESET_PASSWORD = { key: 'reset', path: '/reset-password', i18nKey: 'reset', icon: 'IconArrow' };
export const ROUTE_NEW_PASSWORD = {
  key: 'new-password',
  path: '/new-password/:id',
  i18nKey: 'new-password',
  icon: 'IconArrow',
};
export const ROUTE_CHANGE_PASSWORD = {
  key: 'change-password',
  path: '/change-password/',
  i18nKey: 'change-password',
  icon: 'IconArrow',
};
export const ROUTE_CHANGE_PASSWORD_SUCCESS = {
  key: 'change-password-success',
  path: '/change-password-success',
  i18nKey: 'change-password-success',
  icon: 'IconArrow',
};
export const ROUTE_TERMS_CONDITIONS = { key: 'terms', path: '/terms', i18nKey: 'terms', icon: 'IconArrow' };
export const ROUTE_NEW_CHECK_EMAIL = {
  key: 'check-email',
  path: '/check-email',
  i18nKey: 'check-email',
  icon: 'IconArrow',
};
export const ROUTE_NEW_PASSWORD_CHANGED = {
  key: 'new-password-changed',
  path: '/password-changed',
  i18nKey: 'new-password-changed',
  icon: 'IconArrow',
};

export const ROUTE_NEW_CHECK_EMAIL_SUCCESS_PASSWORD = {
  key: 'check-email-success',
  path: '/check-email-success',
  i18nKey: 'check-email-success',
  icon: 'IconArrow',
};

export const ROUTE_RECRUITS = { key: 'Recruits', path: '/recruits', i18nKey: 'Recruits', icon: 'IconArrow' };
export const ROUTE_SCENARIOS = { key: 'Scenarios', path: '/scenarios', i18nKey: 'Scenarios', icon: 'IconArrow' };
export const ROUTE_PLATOONS = { key: 'Platoon', path: '/platoon', i18nKey: 'Platoon', icon: 'IconArrow' };
export const ROUTE_TEMPLATES = { key: 'Templates', path: '/templates', i18nKey: 'Templates', icon: 'IconArrow' };
export const ROUTE_ADD_TEMPLATE = {
  key: 'Templates',
  path: 'templates/new-template',
  i18nKey: 'NewTemplate',
  icon: 'IconArrow',
};
export const ROUTE_EDIT_TEMPLATE = {
  key: 'EditTemplate',
  path: 'templates/edit-template/:id',
  i18nKey: 'editTemplate',
  icon: 'IconArrow',
};

export const ROUTE_TEMPLATE_DETAILS = {
  key: 'DetailsTemplate',
  path: '/templates/details/:id',
  i18nKey: 'detailsTemplate',
  icon: 'IconArrow',
};
export const ROUTE_INSTRUCTORS = {
  key: 'Instructors',
  path: '/instructors',
  i18nKey: 'Instructors',
  icon: 'IconArrow',
};
export const ROUTE_CALENDAR = { key: 'Calendar', path: '/calendar', i18nKey: 'Calendar', icon: 'IconArrow' };
export const ROUTE_EVENT_DETAILS = { key: 'Event', path: '/event-details/:id', i18nKey: 'Event', icon: 'IconArrow' };
export const ROUTE_EVENT_EDIT = { key: 'Event', path: '/event-edit/:id', i18nKey: 'Event', icon: 'IconArrow' };
//
export const ROUTE_MULTI_ASSESSMENT = {
  key: 'Multi',
  path: '/multi-assessment/:id',
  i18nKey: 'Multi',
  icon: 'IconArrow',
};

export const ROUTE_SETTINGS = { key: 'settings', path: '/settings', i18nKey: 'settings', icon: 'IconArrow' };
export const ROUTE_CREATE_NEW_INSTRUCTOR = {
  key: 'create-new-instructor',
  path: '/instructors/new-instructor',
  i18nKey: 'create-new-instructor',
  icon: 'IconArrow',
};
export const ROUTE_EDIT_INSTRUCTORS = {
  key: 'edit-instructor',
  path: '/instructors/edit-instructor/:id',
  i18nKey: 'Instructors',
  icon: 'IconArrow',
};

export const ROUTE_ADMINS = {
  key: 'Admins',
  path: '/admins',
  i18nKey: 'Admins',
  icon: 'IconArrow',
};
export const ROUTE_CREATE_NEW_ADMINS = {
  key: 'create-new-admin',
  path: '/admins/new-admin',
  i18nKey: 'create-new-admin',
  icon: 'IconArrow',
};
export const ROUTE_EDIT_ADMINS = {
  key: 'edit-admin',
  path: '/admins/edit-admin/:id',
  i18nKey: 'Admins',
  icon: 'IconArrow',
};

export const ROUTE_ORGANIZATIONS = {
  key: 'Organizations',
  path: '/organizations',
  i18nKey: 'Organizations',
  icon: 'IconArrow',
};
export const ROUTE_CREATE_NEW_ORGANIZATIONS = {
  key: 'create-new-organization',
  path: '/organizations/new-organization',
  i18nKey: 'create-new-organization',
  icon: 'IconArrow',
};
export const ROUTE_EDIT_ORGANIZATIONS = {
  key: 'edit-organizations',
  path: '/organizations/edit-organization/:id',
  i18nKey: 'Organizations',
  icon: 'IconArrow',
};

export const ROUTE_EDIT_SCENARIOS = {
  key: 'edit-scenarios',
  path: '/scenarios/edit-scenarios/:id',
  i18nKey: 'Scenarios',
  icon: 'IconArrow',
};

export const APPLICATION_MENU = {
  rootMenu: [
    ROUTE_DASHBOARD,
    ROUTE_PROJECTS,
    ROUTE_DATABASE_MANAGEMENT,
    ROUTE_ASSEMBLIES,
    ROUTE_USERS_MANAGEMENT,
    ROUTE_PAYROLL_MANAGEMENT,
  ],
  sidebarMenu: {
    [ROUTE_USERS_MANAGEMENT.path]: [{ ...ROUTE_USERS_MANAGEMENT, i18nKey: 'Users' }, ROUTE_USERS_ROLES],
    [ROUTE_DATABASE_MANAGEMENT.path]: [
      ROUTE_DOCUMENTS,
      ROUTE_MANUFACTURERS,
      ROUTE_SUPPLIERS,
      ROUTE_MARKETS,
      ROUTE_CATEGORIES,
      ROUTE_BUILDING_MATERIALS,
      ROUTE_LABOR_COST,
    ],
  },
};
