import { Typography } from '@material-ui/core';
import React from 'react';
import { CriterioAssmentContainer } from './CriterioAssmentContainer';

const CriterioListAssessment = ({ criterioList, handleAddCriterio }) => {
  return (
    <div style={{ paddingBottom: 30 }}>
      <Typography variant="h5">Criteria Assessment</Typography>
      <div>
        {criterioList ? (
          criterioList.map((criterio) => (
            <CriterioAssmentContainer key={criterio.name} handleAddCriterio={handleAddCriterio} criterio={criterio} />
          ))
        ) : (
          <Typography>No data</Typography>
        )}
      </div>
    </div>
  );
};

export default CriterioListAssessment;
