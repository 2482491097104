import React from 'react';
import useStyles from '../style';
import { CheckBox } from 'components/common';
import GetAppIcon from '@material-ui/icons/GetApp';

const PhotoComponent = ({ idToDeletePhoto, addIdToDelete, id, url, addPhotosToModal }) => {
  const classes = useStyles();
  return (
    <>
      <CheckBox
        style={{
          backgroundColor: '#ffff',
        }}
        className={classes.checkBox}
        checked={idToDeletePhoto.includes(id)}
        onClick={() => addIdToDelete(id)}
      />
      <div className={classes.iconDownloadImg}>
        <a href={url} download style={{ color: '#00c853' }}>
          <GetAppIcon style={{ width: 18, height: 18 }} />
        </a>
      </div>
      <img key={id} src={url} alt="img" className={classes.imageShow} onClick={() => addPhotosToModal(url)} />
    </>
  );
};

export default PhotoComponent;
