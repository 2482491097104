export const ROUTE_DATABASE_MANAGEMENT = {
  key: 'database',
  path: '/database',
  i18nKey: 'Database Management',
  icon: 'IconDataBase',
};

export const ROUTE_CATEGORIES = {
  key: 'categories',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/categories`,
  i18nKey: 'Categories',
  icon: 'IconCategories',
  totalApi: 'category.getCategoryTotal',
};

export const ROUTE_MANUFACTURERS = {
  key: 'manufacturers',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/manufacturers`,
  i18nKey: 'Manufacturers',
  icon: 'IconManufacturers',
  totalApi: 'manufacturer.getTotal',
};

export const ROUTE_SUPPLIERS = {
  key: 'suppliers',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/suppliers`,
  i18nKey: 'Suppliers',
  icon: 'IconSuppliers',
  totalApi: 'supplier.getTotal',
};

export const ROUTE_MARKETS = {
  key: 'markets',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/markets`,
  i18nKey: 'Markets',
  icon: 'IconMarkets',
  totalApi: 'market.getTotal',
};

export const ROUTE_DOCUMENTS = {
  key: 'documents',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/documents`,
  i18nKey: 'Documents',
  icon: 'IconDocuments',
  totalApi: 'document.getDocumentTotal',
};

export const ROUTE_LABOR_COST = {
  key: 'labor-cost',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/labor-cost`,
  i18nKey: 'Labor Cost',
  icon: 'IconLaborCost',
  totalApi: 'laborCost.getTotal',
};

export const ROUTE_LABOR_COST_HOURLY_RATE = {
  key: 'hourly-rate',
  path: `${ROUTE_LABOR_COST.path}/hourly-rate`,
  i18nKey: 'hourly',
  icon: 'IconLaborCost',
};
export const ROUTE_LABOR_COST_PIECE_WORK = {
  key: 'piece-work',
  path: `${ROUTE_LABOR_COST.path}/piece-work`,
  i18nKey: 'piecework',
  icon: 'IconLaborCost',
};

export const ROUTE_BUILDING_MATERIALS = {
  key: 'building-materials',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/building-materials`,
  i18nKey: 'Building Materials',
  icon: 'IconBuildingMaterials',
  totalApi: '',
};
