import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  buttonPrev: {
    position: 'absolute',
    bottom: '50%',
    left: 0,
    '&:hover': {
      opacity: 0.7,
    },
  },
  buttonNext: {
    position: 'absolute',
    bottom: '50%',
    right: 0,
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

export default useStyles;
