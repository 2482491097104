import React, { useState, useEffect, useCallback } from 'react';
import { useRpcApi } from 'services/api';
import { useHistory } from 'react-router-dom';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { ActionButtons, GridTable, TopBar } from 'components/common';
import { DialogContainer, RemoveModal } from 'components/common/Dialogs';
import SimpleSnackbar from 'components/common/Snackbar';
import Item from './components/Item/Item';
import { columns, apiUser, apiPlatoon } from './constants';
import { sortByName } from 'utils/helpers';
import useStyles from '../style';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLabels } from '../../../components/contexts/AuthContext';

const [getFreeRecruitsList, getPoliceServicesList] = useRpcApi(...apiUser);
const [updatePlatoon, getPlatoon, getPlatoonInRecruit, deletePlatoonInRecruit] = useRpcApi(...apiPlatoon);

const PlatoonRecruits = () => {
  const [selectAllTitle, setSelectAllTitle] = useState('Select All');
  const history = useHistory();
  const [policeServices, setPoliceServices] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [freeRecruits, setFreeRecruits] = useState([]);
  const [ids, setIds] = useState([]);
  const [recruit, setRecruit] = useState({});
  const [platoon, setPlatoon] = useState({});
  const [isDel, setIsDel] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [valueRecruit, setValueRecruit] = useState('');
  const [valueFreeRecruit, setValueFreeRecruit] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const classes = useStyles();
  const labels = useLabels();

  const id = window.location.pathname.split('/')[3];

  const newQuantityRecruit = +platoon.quantityRecruit + ids?.length;

  const getPoliceServices = useCallback(() => {
    getPoliceServicesList()
      .then((res) => setPoliceServices(res))
      .catch(console.error);
  }, []);

  const getListRecruitsInPlatoon = useCallback(() => {
    setLoadingList(true);
    getPlatoonInRecruit({ platoonId: id })
      .then((res) => setDataSource(res))
      .catch(console.error)
      .finally(() => setLoadingList(false));
  }, [id]);

  const getRecruitsFreeList = useCallback(() => {
    getFreeRecruitsList()
      .then((res) => setFreeRecruits(res?.items))
      .catch(console.error);
  }, []);

  const getCurrentPlatoon = useCallback(() => {
    getPlatoon({ id })
      .then((res) => setPlatoon(res))
      .catch(() => history.push('/platoon'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getRecruitsFreeList();
    getCurrentPlatoon();
    getListRecruitsInPlatoon();
    getPoliceServices();
  }, [getCurrentPlatoon, getListRecruitsInPlatoon, getPoliceServices, getRecruitsFreeList]);

  const onChangeSearch = ({ target: { value } }) => setValueRecruit(value);
  const onChangeFreeRecruit = ({ target: { value } }) => setValueFreeRecruit(value);
  const onChangeDel = () => setIsDel((prev) => !prev);
  const onChangeNew = () => setIsNew((prev) => !prev);
  const onChangeIds = (idRecruit) => {
    if (ids.find((i) => i === +idRecruit)) {
      const newCurrentRecruit = ids.filter((i) => i !== +idRecruit);
      setIds(newCurrentRecruit);
    } else {
      setIds([...ids, +idRecruit]);
    }
  };

  const getService = (id) => policeServices.find((i) => id === i.id);

  const onClickSort = (isReverse, col) => {
    const arr = [...dataSource];
    let newData = [...arr];

    const flag = isReverse === 'asc' ? true : false;

    if (col === 'name') {
      newData = newData.sort((a, b) => {
        const nameA = a.recruit.firstName + a.recruit.lastName;
        const nameB = b.recruit.firstName + b.recruit.lastName;

        return sortByName(nameA, nameB, flag);
      });
    } else if (col === 'badge') {
      newData = arr.sort((a, b) => {
        const numA = a?.recruit?.badgeNumber;
        const numB = b?.recruit?.badgeNumber;

        return flag ? numB - numA : numA - numB;
      });
    } else if (col === 'service') {
      newData = arr.sort((a, b) => {
        const serviceA = getService(a?.recruit?.policeServiceId)?.title || '';
        const serviceB = getService(b?.recruit?.policeServiceId)?.title || '';

        return sortByName(serviceA, serviceB, flag);
      });
    }

    setDataSource(newData);
  };

  const getDataSource = (items) => {
    const [firstValue, secondValue] = valueFreeRecruit.toLowerCase().trim().split(' ');
    let data = items;
    const obj = {};

    if (firstValue && !secondValue)
      data = items.filter(
        (i) => i?.firstName.toLowerCase().includes(firstValue) || i?.lastName.toLowerCase().includes(firstValue)
      );

    if (firstValue && secondValue)
      data = items.filter(
        (i) => i?.firstName.toLowerCase().includes(firstValue) && i?.lastName.toLowerCase().includes(secondValue)
      );

    for (let i of data) {
      const firstChar = i.firstName.charAt(0);
      obj[firstChar] = [...(obj[firstChar] || []), i];
    }

    return obj;
  };

  const addAllId = () => {
    if (ids?.length !== freeRecruits.length) {
      let newIds = [];
      Object.entries(getDataSource(freeRecruits)).forEach((section) => {
        section[1].forEach((item) => {
          newIds = [...newIds, item.id];
        });
      });
      setIds(newIds);
    } else {
      setIds([]);
    }
  };

  useEffect(() => {
    if (ids?.length !== freeRecruits.length || freeRecruits.length === 0) {
      setSelectAllTitle('Select All');
    } else {
      setSelectAllTitle('Unselect All');
    }
  }, [ids, freeRecruits]);

  const applyRecruits = () => {
    if (ids?.length) {
      const obj = { id: +id, name: platoon?.name, recruits: ids, quantityRecruit: newQuantityRecruit?.toString() };
      onUpdatePlatoon(obj);
      addAllId();
      setIds([]);
      onChangeNew();
    } else {
      setErrorMessage(`Select ${labels.recruit} from the list`);
    }
  };

  const handlerSearch = (data, value) => {
    const [firstValue, secondValue] = value.toLowerCase().trim().split(' ');

    if (firstValue && !secondValue)
      data = data.filter(
        (i) =>
          i?.recruit.firstName.toLowerCase().includes(firstValue) ||
          i?.recruit.lastName.toLowerCase().includes(firstValue)
      );

    if (firstValue && secondValue)
      data = data.filter(
        (i) =>
          i?.recruit.firstName.toLowerCase().includes(firstValue) &&
          i?.recruit.lastName.toLowerCase().includes(secondValue)
      );

    return data;
  };

  const onDeleteRecruitinPlatoon = () => {
    const { quantityRecruit, name } = platoon;
    const platoonRecruit = +quantityRecruit ? +quantityRecruit - 1 : null;

    const obj = { id, name, quantityRecruit: platoonRecruit?.toString() };

    deletePlatoonInRecruit({ id: +recruit.id })
      .then(() => onUpdatePlatoon(obj))
      .catch(console.error)
      .finally(() => {
        onChangeDel();
        getCurrentPlatoon();
      });

    onUpdatePlatoon(obj);
  };

  const onUpdatePlatoon = (data) => {
    updatePlatoon(data)
      .then(() => {
        getRecruitsFreeList();
        getListRecruitsInPlatoon();
        getCurrentPlatoon();
      })
      .catch(console.error);
  };

  const rows = handlerSearch(dataSource, valueRecruit).map((row) => [
    <>
      <Avatar className={classes.avatarText} src={(row && row.recruit && row.recruit.avatar) || undefined} alt="Avatar">
        {row?.recruit?.firstName?.charAt(0) + row?.recruit?.lastName?.charAt(0)}
      </Avatar>
      <Typography variant="body2" align={row.align}>
        {`${row?.recruit?.firstName} ${row?.recruit?.lastName}`}
      </Typography>
    </>,
    <Typography variant="body2" align={row.align}>
      {row?.recruit?.badgeNumber}
    </Typography>,
    <Typography variant="body2" align={row.align}>
      {row.recruit.organizationInfo?.name}
    </Typography>,
    <ActionButtons
      onEdit={() => history.push(`/recruits/edit-recruit/${row?.recruit?.id}`)}
      actions={['edit', 'remove']}
      onRemove={() => {
        setRecruit(row);
        onChangeDel();
      }}
    />,
  ]);

  return (
    <>
      <TopBar
        onChangeSearch={onChangeSearch}
        title={`${labels.platoon} ${labels.recruits}`}
        subtitle={platoon?.name}
        hasSearch
        hasBackButton
        hasButton
        btnTitle={`Add ${labels.recruits}`}
        onButton={onChangeNew}
      />
      {errorMessage && <SimpleSnackbar message={errorMessage} onClose={() => setErrorMessage('')} />}

      {loadingList ? (
        <div className={classes.widthNotRecruit}>
          <CircularProgress />
        </div>
      ) : rows?.length ? (
        <GridTable onSort={onClickSort} columns={columns} rows={rows} />
      ) : (
        <div className={classes.widthNotRecruit}>
          <p>
            There are no {labels.recruits} in this {labels.platoon} yet.{' '}
            <span onClick={onChangeNew} className={classes.titleAdd}>
              Add New {labels.recruit}!
            </span>
          </p>
        </div>
      )}

      <DialogContainer
        onCancel
        value={valueFreeRecruit}
        onChange={onChangeFreeRecruit}
        open={isNew}
        onAbort={() => {
          setValueFreeRecruit('');
          onChangeNew();
          setIds([]);
        }}
        title={`Add ${labels.recruits}`}
        subTitle={platoon?.name}
        hasBackButton
        hasSearch
        searchPlaceholder={
          freeRecruits.length
            ? 'Search recruits…'
            : `All recruits are currently assigned to ${labels.platoons}. To add a ${labels.recruit} to this platoon please remove them first`
        }
        headBtnTitle="Apply"
        headOnClick={applyRecruits}
        selectAll
        selectAllTitle={selectAllTitle}
        addAllId={addAllId}
      >
        <Grid container direction="column">
          {Object.entries(getDataSource(freeRecruits))
            ?.sort((a, b) => a[0].localeCompare(b[0]))
            ?.map((section) =>
              section?.[1]?.map((item, ind) => (
                <Item
                  key={ind}
                  user={item}
                  onChangeRecruits={() => onChangeIds(item.id)}
                  sectionTitle={!ind && section[0]}
                  checked={ids.includes(item.id)}
                />
              ))
            )}
        </Grid>
      </DialogContainer>

      <RemoveModal
        open={isDel}
        onClose={onChangeDel}
        onClick={onDeleteRecruitinPlatoon}
        title="Remove from platoon?"
        name={`${recruit?.recruit?.firstName} ${recruit?.recruit?.lastName}`}
        subText={platoon?.name}
        isRemove
        textBtn="Remove"
      />
    </>
  );
};

export default PlatoonRecruits;
