import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: 'black',
    overflow: 'hidden',
    height: '100vh',
  },
}));

export default useStyles;
