import { EventEmitter } from 'events';
let _instance = null;
class AppEventEmitter extends EventEmitter {
  static get instance() {
    if (_instance === null) {
      _instance = new AppEventEmitter();
    }
    return _instance;
  }
}
export default AppEventEmitter;
