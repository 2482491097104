import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: '0 !important',
    padding: '0 !important',
    maxWidth: '100% !important',
  },
  btn: {
    color: '#0e4bad',
    width: '420px',
    height: '50px',
    border: '1px dashed rgba(14, 75, 173, 0.4)',
    marginTop: '45px',
    marginBottom: '30px',
  },
  input: {
    width: '420px',
  },
  inputBlock: {
    width: '100%',
  },
  footer: {
    borderTop: '1px solid #e3e4e8',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    marginTop: '0',
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
    overflow: 'auto',
    flex: 1,
  },
  footerBtn: {
    height: '50px',
    marginTop: '30px',
  },
  criterios: {
    display: 'flex',
    alignItems: 'center',
    width: '465px',
    marginTop: '18px',
  },
  iconBtn: {
    marginTop: '23px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
}));

export default useStyles;
