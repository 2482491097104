import { FormControl, NativeSelect } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  inputTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
  star: {
    color: 'red',
  },
  root: {
    border: `1px solid ${theme.palette.grey['A400']}`,
    backgroundColor: '#fff',
    borderRadius: 3,
    width: '100%',
    height: 52,
    '& .MuiInputBase-input': {
      color: '#000000',
      height: 38,
      paddingLeft: 12,
      paddingRight: 40,
    },
    '& .MuiInputBase-root': {
      color: 'inherit',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiNativeSelect-icon': {
      marginRight: 20,
    },
  },
  textDisabled: {
    color: `${theme.palette.grey[400]} !important`,
  },
  textBlack: {
    color: '#000',
  },
  selectMenu: {
    '& .MuiNativeSelect-selectMenu': {
      backgroundColor: '#f66',
    },
  },
}));

const Autocomplete = (props) => {
  const classes = useStyles();
  const {
    name = '',
    title = '',
    star = false,
    value = '',
    findKey = 'title',
    onChange,
    options = [],
    timeValue,
    id,
  } = props;

  return (
    <>
      <h4 className={classes.inputTitle}>
        {title}
        {star && <span className={classes.star}>*</span>}
      </h4>
      <FormControl className={classes.root}>
        <NativeSelect style={{ color: '#000000' }} name={name} value={value} onChange={onChange}>
          {!id && <option>None</option>}
          {options &&
            options.map((value, index) => (
              <option key={index} value={timeValue ? value.value : value.id}>
                {value[findKey]}
              </option>
            ))}
        </NativeSelect>
      </FormControl>
    </>
  );
};

export default Autocomplete;
