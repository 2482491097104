/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Paper, Typography, Avatar, Container, Divider } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { TopBar, Evaluation, CheckBox } from 'components/common';
import TimeIcon from '@material-ui/icons/AccessTimeOutlined';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import PeopleIcon from '@material-ui/icons/PeopleAltOutlined';
import PlaceIcon from '@material-ui/icons/PlaceOutlined';
import { ActionBlockDetails, monthNames, defaultText, roles, behaviours, AssessmentPanel } from './components';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import clsx from 'clsx';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import Select from 'components/common/Inputs/Select';
import moment from 'moment';

import { useRpcApi } from 'services/api';
import useTabs from '../../../hooks/useTabs';
import ChangeLog from './ChangeLog';
import useStyles from './style';
import { useLabels } from '../../../components/contexts/AuthContext';

const [addScheduleInRecruit, addEvaluationToRecruits] = useRpcApi(
  'scheduleInRecruit.addScheduleInRecruit',
  'evaluation.addEvaluationToRecruits'
);

const ScenarioDetails = ({
  getevaluationByScheduleId,
  dataDetails,
  dataSchedule,
  dataRecruits,
  newDataSchedules,
  getListRecruits,
  id,
  getData,
  getDataSchedule,
  visible,
  setVisible,
  setNewDataSchedule,
}) => {
  const labels = useLabels();
  const [textMassage, setTextMassage] = useState('Schedule Event');

  const [occurrence, setOccurrence] = useState({
    customClass: false,
    text: '',
  });
  const [tab, onChangeTab] = useTabs(0);

  const history = useHistory();
  const classes = useStyles();

  const getCurrentDay = (from) => {
    let d = new Date(from);
    return `${monthNames[d.getMonth()]} ${d.getUTCDate()}, ${d.getFullYear()}`;
  };

  useEffect(() => {
    getData();
    getDataSchedule();
  }, []);

  const OccurrenceLabel = () => {
    const occurrenceDate = new Date(dataSchedule?.beginningAt);
    let offset = occurrenceDate.getTimezoneOffset() / 60;
    let occStartTime = occurrenceDate.getTime() - offset * -1 * 3600000;
    let occEndTime = occStartTime + dataSchedule?.duration;
    let currTime = new Date().getTime();

    if (currTime < occStartTime) {
      return setOccurrence({
        customClass: true,
        text: 'Upcoming',
      });
    } else if (currTime > occEndTime) {
      return setOccurrence({
        customClass: false,
        text: 'Completed',
      });
    } else {
      return setOccurrence({
        customClass: true,
        text: 'Current',
      });
    }
  };

  useEffect(() => {
    if (dataSchedule?.beginningAt) {
      OccurrenceLabel();
    }
  }, [dataSchedule]);

  const onChangeRole = (role, recruit) => {
    const obj = {
      platoonId: +dataSchedule?.platoonId,
      recruitId: +recruit?.recruitId,
      scheduleId: +dataSchedule?.id,
      roles: +role?.id >= 0 ? +role?.id : 0,
    };

    addScheduleInRecruit(obj)
      .then(() => {
        setVisible(true);
        setTextMassage(`${labels.recruit} roles`);
        getListRecruits();
      })
      .catch((e) => console.error(e));
  };
  const onChangeBehaviour = (behaviour, recruit) => {
    const obj = {
      platoonId: +dataSchedule?.platoonId,
      recruitId: +recruit?.recruitId,
      scheduleId: +dataSchedule?.id,
      behaviours: +behaviour?.id >= 0 ? +behaviour?.id : 0,
    };

    addScheduleInRecruit(obj)
      .then(() => {
        setVisible(true);
        setTextMassage(`${labels.recruit} behaviours`);
        getListRecruits();
      })
      .catch((e) => console.error(e));
  };

  const addMask = (number) => {
    if (!number) return;
    if (number.includes('(')) return number;
    number = number.replace('+', '');
    return [' (', number.slice(0, 3), ') ', number.slice(3, 6), ' - ', number.slice(6, 10)].join('');
  };

  const goToEdit = () => history.push(`/event-edit/${id}`);

  const addIdtoList = (idFromList) => {
    setNewDataSchedule((prevState) => {
      const el = newDataSchedules.map((el) => {
        if (el.id === idFromList)
          return {
            ...el,
            checked: !el.checked,
          };
        return el;
      });
      return el;
    });
  };

  const selectAll = () => {
    if (newDataSchedules.find((el) => el.checked === true)) {
      setNewDataSchedule(newDataSchedules.map((el) => ({ ...el, checked: false })));
      return;
    }
    setNewDataSchedule(newDataSchedules.map((el) => ({ ...el, checked: !el.checked })));
  };

  const goToMultiAssessment = async () => {
    let selectedRecruits = newDataSchedules.filter((el) => el.checked === true).map((el) => el.recruitId);
    history.push({
      pathname: `/multi-assessment/${id}`,
      search: `ids=${selectedRecruits.join(',')}`,
    });
  };

  const goToSinglAssessment = async (idSelected) => {
    let selectedRecruits = newDataSchedules.filter((el) => el.id === idSelected).map((el) => el.recruitId);
    history.push({
      pathname: `/multi-assessment/${id}`,
      search: `ids=${selectedRecruits.join(',')}`,
    });
  };

  const onChangeScore = (score, recruits) => {
    const obj = {
      evaluation: +score.id,
      scheduleId: +dataSchedule?.id,
      recruitIds: recruits.map((el) => el.recruitId),
      isReleased: false,
    };
    addEvaluationToRecruits(obj)
      .then(() => {
        setVisible(true);
        setTextMassage(`${labels.recruit} scores`);
        getevaluationByScheduleId({ scheduleId: id });
      })
      .catch((error) => console.log(error));
  };
  const releaseGrading = () => {
    const recruitsWithGrading = newDataSchedules.filter((el) => el.fullAssessment);

    const object = {
      scheduleId: +dataSchedule?.id,
      recruitIds: recruitsWithGrading.map((el) => el.recruitId),
      isReleased: true,
    };
    addEvaluationToRecruits(object)
      .then(() => {
        setVisible(true);
        setTextMassage('Grading');
        getevaluationByScheduleId({ scheduleId: id });
      })
      .catch((error) => console.log(error));
  };
  const type = useMemo(() => {
    if (dataSchedule && dataSchedule.id) {
      const occurrenceDate = new Date(dataSchedule.beginningAt);
      let offset = occurrenceDate.getTimezoneOffset() / 60;
      let occStartTime = occurrenceDate.getTime() - offset * -1 * 3600000;
      let occEndTime = occStartTime + dataSchedule.duration;
      let currTime = new Date().getTime();
      if (currTime < occStartTime) return 'future';
      else if (currTime > occEndTime) return 'past';
      else return 'current';
    } else return null;
  }, [dataSchedule]);
  const checkedCheckBoxAll = useMemo(() => newDataSchedules.some((el) => el.checked === true), [newDataSchedules]);

  return (
    <>
      <SuccessNotification open={visible} text={textMassage} setOpen={setVisible} />
      <TopBar
        maxWidth="lg"
        title="Scenario Details"
        hasBackButton
        btnTitle="Release Grading"
        onButton={releaseGrading}
        goToEdit={type === 'future' || type === 'current' ? goToEdit : null}
      />
      {newDataSchedules.some((el) => el.checked === true) && (
        <AssessmentPanel
          onChangeScore={onChangeScore}
          dataSchedule={dataSchedule}
          goToMultiAssessment={goToMultiAssessment}
          selectedRecruits={newDataSchedules.filter((el) => el.checked === true)}
          selectAll={selectAll}
        />
      )}

      <Container maxWidth="lg" className={classes.container}>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h3">{dataSchedule?.name}</Typography>
          <Grid item>
            <Grid container alignItems="center" spacing={4}>
              <div className={clsx(classes.rootBtn, occurrence?.customClass && classes.customClass)}>
                {occurrence?.text}
              </div>
              <Grid item>
                <ToggleButtonGroup color="primary" value={tab} exclusive onChange={onChangeTab}>
                  <ToggleButton value={0}>Details</ToggleButton>
                  <Divider orientation="vertical" flexItem />
                  <ToggleButton value={1}>Change Log</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {tab === 1 ? (
          <ChangeLog scheduleId={dataSchedule && dataSchedule.id} />
        ) : (
          <>
            <Paper>
              <Grid>
                <div className={classes.blockTableLeft}>
                  <div className={classes.containerTable}>
                    <div className={classes.boxTable}>
                      <CalendarIcon className={classes.tableIcon} />
                      <span className={classes.titleIcon}>Date</span>
                    </div>
                    <span>{getCurrentDay(dataSchedule?.beginningAt) || '-'}</span>
                  </div>
                  <div className={classes.containerTableRight}>
                    <div className={classes.boxTable}>
                      <TimeIcon className={classes.timeIcon} />
                      <span className={classes.titleIcon}>Time</span>
                    </div>
                    <span>{moment(new Date(dataSchedule.beginningAt)).format('H:mm')}</span>
                  </div>
                </div>
                <div className={classes.blockTableRight}>
                  <div className={classes.containerTable}>
                    <div className={classes.boxTable}>
                      <PlaceIcon className={classes.tableIcon} />
                      <span className={classes.titleIcon}>Place</span>
                    </div>
                    <span>{dataSchedule?.locationName ? dataSchedule?.locationName : '--'}</span>
                  </div>
                  <div className={classes.containerTableRight}>
                    <div className={classes.boxTable}>
                      <PeopleIcon className={classes.timeIcon} />
                      <span className={classes.titleIcon}>{labels.platoon}</span>
                    </div>
                    <div className={classes.platoonBox}>
                      <Typography variant="subtitle2">{dataSchedule?.platoonData?.name}</Typography>
                      <Avatar
                        className={`${classes.platoonAvatar} ml`}
                        src={dataSchedule?.platoonData?.avatar}
                        alt="avatar"
                      >
                        {dataSchedule?.platoonData?.name?.charAt(0)}
                      </Avatar>
                    </div>
                  </div>
                </div>
              </Grid>
            </Paper>

            <Paper className={classes.rootTablePaper}>
              <Grid container justify="space-between" direction="row">
                <div style={{ display: 'flex' }}>
                  <Typography variant="h5">{labels.instructors}</Typography>
                  {!dataDetails[0]?.instructor ? <Typography style={{ marginLeft: 5 }}>(0)</Typography> : null}
                </div>
                <Typography variant="subtitle2">{dataDetails[0]?.instructor && 'Phone'}</Typography>
              </Grid>
              {dataDetails.map((i, ind) => (
                <Grid key={ind} className={classes.rowInstructor} container justify="space-between" alignItems="center">
                  <div>
                    <div className={classes.instructorBlock}>
                      <Avatar className={classes.instructorAvatar} src={i?.instructor?.avatar} alt="Avatar">
                        {i?.instructor?.firstName?.charAt(0) + i?.instructor?.lastName?.charAt(0)}
                      </Avatar>
                      <Typography variant="subtitle2">
                        {`${i?.instructor?.firstName} ${i?.instructor?.lastName}`}
                      </Typography>
                    </div>
                  </div>
                  <div>{i?.instructor?.phoneNumber ? addMask(i?.instructor?.phoneNumber) : '--'}</div>
                </Grid>
              ))}
            </Paper>

            <ActionBlockDetails title="Objective" text="No data" data={dataSchedule?.objective} />

            <ActionBlockDetails title="Assessment" text="No data" data={dataSchedule?.assessment} />

            <ActionBlockDetails title="Debriefing notes" text="No data" data={dataSchedule?.debriefingNotes} />
            <ActionBlockDetails title="Summary" text="No data" data={dataSchedule?.summary} />
            <ActionBlockDetails
              title={`${labels.instructor} Briefing`}
              text="No data"
              data={dataSchedule?.instructorBriefing}
            />
            <ActionBlockDetails
              title={`${labels.recruit} Briefing`}
              text="No data"
              data={dataSchedule?.recruitBriefing}
            />
            <ActionBlockDetails title="Dispatch information" text="No data" data={dataSchedule?.dispatchInformation} />
            {dataSchedule.customFields && dataSchedule.customFields.length > 0
              ? dataSchedule.customFields.map((el) => (
                  <ActionBlockDetails title={el.name} text="noData" data={el.value} />
                ))
              : null}
            <ActionBlockDetails title="Score" data={defaultText} />
            <Paper className={classes.rootTablePaper}>
              <Grid container justify="space-between">
                <Typography className={classes.quantityRecruit} variant="h5">
                  <CheckBox
                    indeterminate={checkedCheckBoxAll}
                    checked={checkedCheckBoxAll}
                    className={classes.checkbox}
                    onClick={selectAll}
                  />
                  {dataRecruits[0]?.recruit ? labels.recruits : `${labels.recruits} is empty`}
                </Typography>
                <Typography className={classes.select} variant="h5" style={{ width: 190 }}>
                  {dataRecruits[0]?.recruit && 'Role'}
                </Typography>
                <Typography className={classes.select} variant="h5" style={{ width: 190 }}>
                  {dataRecruits[0]?.recruit && 'Behaviour'}
                </Typography>
                <Typography className={classes.assessmentText} variant="h5">
                  {dataRecruits[0]?.recruit && 'Assessment'}
                </Typography>
                <Typography className={classes.recruitButtonHeader} variant="h5">
                  {''}
                </Typography>
              </Grid>
              {newDataSchedules
                .sort((a, b) => a.id - b.id)
                .map((i, ind) => (
                  <Grid
                    key={ind}
                    className={classes.rowInstructor}
                    container
                    justify="space-between"
                    alignItems="center"
                  >
                    <div className={classes.quantityRecruit}>
                      <CheckBox
                        checked={i.checked}
                        className={classes.checkbox}
                        type="checkbox"
                        onClick={() => addIdtoList(i.id)}
                      />
                      <Button className={classes.avatarBtn} onClick={() => goToSinglAssessment(i.id)}>
                        <Avatar className={classes.recruitAvatar} src={i?.recruit?.avatar} alt="Avatar">
                          {i?.recruit?.firstName?.charAt(0) + i?.recruit?.lastName?.charAt(0)}
                        </Avatar>
                        <Typography
                          variant="subtitle2"
                          style={{ textAlign: 'start' }}
                        >{`${i?.recruit?.firstName} ${i?.recruit?.lastName}`}</Typography>
                      </Button>
                    </div>
                    <div style={{ width: 'auto' }} className={classes.select}>
                      <Select
                        style={{ width: 190 }}
                        customClass={classes.root}
                        name="name"
                        onChange={(_, role) => onChangeRole(role, i)}
                        value={Number(i?.shedule?.roles)}
                        options={roles}
                        nameIndex="id"
                        disableClearable
                      />
                    </div>
                    <div style={{ width: 'auto' }} className={classes.select}>
                      <Select
                        style={{ width: 190 }}
                        customClass={classes.root}
                        name="name"
                        onChange={(_, behaviour) => onChangeBehaviour(behaviour, i)}
                        value={Number(i?.shedule?.behaviours)}
                        options={behaviours}
                        nameIndex="id"
                        disableClearable
                      />
                    </div>
                    <div onClick={() => goToSinglAssessment(i.id)} className={classes.assessmentBox}>
                      <Evaluation evaluation={i?.fullAssessment?.evaluation} />
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => history.push(`/recruits/recruit-details/${i?.recruit?.id}`)}
                      >
                        View Profile
                      </Button>
                    </div>
                  </Grid>
                ))}
              <Grid container item xs={12} justify="center" style={{ margin: '20px 0 20px 0' }}>
                {dataRecruits[0]?.recruit && (
                  <Button variant="contained" color="secondary" size="medium" onClick={releaseGrading}>
                    Release Grading
                  </Button>
                )}
              </Grid>
            </Paper>
          </>
        )}
      </Container>
    </>
  );
};

export default ScenarioDetails;
