export const templateFields = (labels) => [
  { id: 0, name: 'Objective' },
  { id: 1, name: 'Summary' },
  { id: 2, name: `${labels.instructor} Briefing` },
  { id: 3, name: `${labels.recruit} Briefing (visible for ${labels.recruits})` },
  { id: 4, name: 'Dispatch Information' },
  { id: 5, name: 'six' },
  { id: 6, name: 'seventh' },
];

export const defaultValue = {
  name: '',
  shortName: '',
  objective: '',
  summary: '',
  instructorBriefing: '',
  recruitBriefing: '',
  dispatchInformation: '',
  debriefingNotes: '',
  assessment: '',
  locationName: '',
  address: '',
  latitude: '',
  longitude: '',
  search: '',
  customFields: [],
  scoringType: 'four-numeric-stage',
  organization: null,
};

export const defaultViewport = {
  altitude: 1.5,
  bearing: 0,
  height: 255,
  latitude: 40.764628570117544,
  longitude: -73.9807219839746,
  maxPitch: 60,
  maxZoom: 24,
  minPitch: 0,
  minZoom: 16,
  pitch: 0,
  transitionDuration: 0,
  transitionInterruption: 1,
  width: 300,
  zoom: 16,
};

export const SUCCESS_MODE = 'SUCCESS_MODE';

export const ReccurrenceList = ['Pass/Fail', '1 to 4'];
