import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 744,
    margin: '40px auto',
    '& > div': {
      padding: 30,
      marginBottom: 20,
    },
  },
  helperGrid: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  center: {
    display: 'block',
    margin: '0 auto',
  },
  scenariosTitle: {
    fontSize: '17px',
    '& > span': {
      padding: '3px 10px',
      backgroundColor: theme.palette.grey['A100'],
      borderRadius: '50%',
      fontSize: '0.875rem',
    },
  },
  personalInfo: {
    marginTop: 20,
    '& > span': {
      float: 'right',
      color: theme.palette.grey[700],
    },
  },
  dividerMargin: {
    margin: '25px 0',
  },
  itemScenarios: {
    borderTop: `1px solid ${theme.palette.grey['A100']}`,
    color: theme.palette.grey[700],
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
  },
  titleTable: {
    marginBottom: '10px',
  },
  loadingContainer: {
    backgroundColor: '#f0f1f4',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
