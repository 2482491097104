import Button from '../../Buttons/Button/index';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { UploadButton } from '../..';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '40px auto',
    padding: 60,
    paddingBottom: 0,
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  content: {
    display: 'flex',
  },
  avatarBox: {
    height: '100%',
    marginRight: 40,
  },
  container: {
    width: '100%',
  },
  action: {
    padding: '30px 0',
    margin: '56px -60px',
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'center',
  },
  loadingStyle: {
    position: 'relative',
  },
  newLoading: {
    color: 'rgba(0, 0, 0, 0.12) !important',
  },
}));

const FormContainer = (props) => {
  const {
    hasImg = false,
    children,
    titleBtn = '',
    onClick = () => {},
    file,
    setFile,
    maxWidth = 944,
    avatar,
    alt,
    errorPhoto,
    loading,
    setErrorPhoto,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ maxWidth: maxWidth }}>
      <div className={classes.content}>
        {hasImg && (
          <div className={classes.avatarBox}>
            <UploadButton
              setErrorPhoto={setErrorPhoto}
              errorPhoto={errorPhoto}
              file={file}
              setFile={setFile}
              avatar={avatar}
              alt={alt}
            />
          </div>
        )}
        <div className={classes.container}>{children}</div>
      </div>

      <div className={classes.action}>
        <Button disabled={loading} text={titleBtn} loading={loading} onClick={onClick} />
      </div>
    </div>
  );
};

FormContainer.propTypes = {
  hasImg: PropTypes.bool,
  titleBtn: PropTypes.string,
  onClick: PropTypes.func,
  maxWidth: PropTypes.number,
  avater: PropTypes.string,
  isSave: PropTypes.bool,
  errorPhoto: PropTypes.bool,
  loading: PropTypes.bool,
  setErrorPhoto: PropTypes.func,
};

export default FormContainer;
