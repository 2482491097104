import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const StyledSwitch = withStyles((theme) => ({
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.success.main,
      opacity: 1,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '0.875rem',
  },
}));

export const BasicSwitch = (props) => {
  const { onChange, checked, label } = props;
  const classes = useStyles();
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="start"
          control={<StyledSwitch checked={checked} onChange={onChange} name="checked" />}
          label={label}
          labelPlacement="start"
          classes={{
            label: classes.label,
          }}
        />
      </FormGroup>
    </FormControl>
  );
};
