import { makeStyles } from '@material-ui/core/styles';
import BackgroundLogin from 'assets/images/backgroundLogin.png';

const useStyles = makeStyles((theme) => ({
  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    backgroundImage: `url(${BackgroundLogin})`,
    backgroundSize: 'cover',
    position: 'relative',
    maxWidth: '940px',
    fontSize: '14px',
    '@media (max-width: 650px)': {
      display: 'none',
    },
  },
  logo: {
    width: '60%',
    maxWidth: '270px',
    margin: '0 auto',
  },
  links: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    bottom: '0',
    padding: '0 30px 24px 30px',
    flexWrap: 'wrap',
  },
  terms: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#ffffff',
  },
}));

export default useStyles;
