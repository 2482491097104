import { Avatar, Button, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ActionButtons, GridTable, SimpleSnackbar, TopBar, UploadButton } from 'components/common';
import { DialogContainer, RemoveModal } from 'components/common/Dialogs';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { uploadFileToS3, useRpcApi } from 'services/api';
import { useLabels } from '../../../components/contexts/AuthContext';
import { apis, columnsFactory, defaultValue } from './constants';
import useStyles from './styles';

const [getPlatoonsList, createPlatoon, getAvatarUploadUrl, updatePlatoon, removePlatoon] = useRpcApi(...apis);

const PlatoonsTable = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isDel, setIsDel] = useState(false);
  const [errorPhoto, setErrorPhoto] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [platoon, setPlatoon] = useState({});
  const [values, setValues] = useState(defaultValue);
  const [allPlatoonsData, setAllPlatoonsData] = useState([]);
  const [platoonsData, setPlatoonsData] = useState([]);
  const [file, setFile] = useState();
  const [nameSotr, setNameSort] = useState('name');
  const [sort, setSort] = useState('asc');
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const classes = useStyles();
  const labels = useLabels();
  const columns = columnsFactory(labels);

  const messageTitle = platoon?.id
    ? `${labels.platoon} successfully updated`
    : `${labels.platoon} successfully created`;

  const getPlatoonList = useCallback(() => {
    setLoadingList(true);
    return getPlatoonsList({
      filter: Object.entries({
        name: { $like: `%${searchValue.trim()}%` },
      }),
      order: Object.entries({ [nameSotr]: sort }),
    })
      .then((res) => {
        setAllPlatoonsData(res?.items);
        setPlatoonsData(res?.items);
      })
      .catch(console.error)
      .finally(() => setLoadingList(false));
  }, [nameSotr, searchValue, sort]);

  useEffect(() => {
    getPlatoonList();
  }, [getPlatoonList]);

  const onChangeSearch = ({ target: { value } }) => setSearchValue(value);
  const onChange = (name) => ({ target: { value } }) => {
    setErrorName(false);
    setValues({ ...values, [name]: value });
  };
  const onChangeDelete = () => setIsDel((prev) => !prev);
  const onChangeEdit = () => setIsEdit((prev) => !prev);
  const onChangePlatoon = (id) => {
    const user = allPlatoonsData.find((item) => +item.id === +id) || {};

    setPlatoon(user);
    setValues({ name: user?.name, avatar: user?.avatar, quantityRecruit: '' });
  };

  const goTo = (path) => () => history.push(path);

  const onClickSort = (flag, name) => {
    setSort(flag);
    setNameSort(name);
  };

  const onCloseModals = useCallback((isDelete) => {
    setPlatoon({});
    setValues(defaultValue);
    setFile();

    if (isDelete) {
      onChangeDelete();
    } else {
      onChangeEdit();
    }
  }, []);

  const onDelete = () => {
    removePlatoon({ id: platoon?.id }).then(() => {
      getPlatoonList().then(() => onCloseModals(true));
    });
  };

  const uploadPhoto = useCallback(
    (value) => {
      if (file) {
        return getAvatarUploadUrl({ id: value.id, alias: 'avatarsPlatoon' })
          .then(async (value) => {
            setLoading(true);
            await uploadFileToS3(value.url.url, file, value.url.fields).then((e) => {
              getPlatoonList();
              setLoading(false);
            });
          })
          .catch(console.error);
      }
    },
    [file, getPlatoonList]
  );

  const onSubmit = useCallback(() => {
    const { name, avatar } = values;
    const { id } = platoon;
    setLoading(true);

    values.name = values.name?.trim();

    if (!values.name) {
      setErrorName(true);
      setLoading(false);
    }
    if (!values.name) return;

    const attr = { avatar: file || avatar ? 1 : null, id };

    let func = createPlatoon;
    let obj = { ...values, ...attr };

    if (id) {
      func = updatePlatoon;
      obj = { name, ...attr };
    }

    func(obj)
      .then((val) => {
        uploadPhoto(val);
        setVisible(true);
      })
      .catch((error) => {
        if (error.message === 'value too long for type character varying(255)') {
          setErrorMessage('Value exceeds max length 255 characters');
        } else {
          setErrorMessage(error?.message);
        }
      })
      .finally(() => {
        onChangeEdit();
        setLoading(false);
        setValues(defaultValue);
        getPlatoonList();
        setErrorPhoto(false);
        setErrorName(false);
        setFile(null);
      });
  }, [file, getPlatoonList, platoon, uploadPhoto, values]);

  const rows = platoonsData.map((row) => [
    <Button onClick={goTo(`/platoon/recruits/${row.id}`)}>
      <Avatar className={classes.avatarText} src={row.avatar || undefined} alt="Avatar">
        {row?.name?.charAt(0)}
      </Avatar>
      <Typography variant="subtitle2" align={row.align}>
        {row.name}
      </Typography>
    </Button>,
    <Button variant="outlined" size="small" color="primary" onClick={goTo(`/platoon/recruits/${row.id}`)}>
      {+row.quantityRecruit ? row.quantityRecruit : 'Add'}
    </Button>,
    <ActionButtons
      actions={['edit', 'delete']}
      onDelete={() => {
        onChangePlatoon(row.id);
        onChangeDelete();
      }}
      onEdit={() => {
        onChangePlatoon(row.id);
        onChangeEdit();
      }}
    />,
  ]);

  return (
    <>
      {errorMessage && <SimpleSnackbar message={errorMessage} onClose={() => setErrorMessage('')} />}
      <SuccessNotification open={visible} setOpen={setVisible} textPlatoon={messageTitle} />
      <TopBar
        title={labels.platoons}
        hasSearch
        hasButton
        btnTitle={!visible && `Add New ${labels.platoon}`}
        onButton={onChangeEdit}
        onChangeSearch={onChangeSearch}
      />

      {loadingList ? (
        <div className={classes.widthNotRecruit}>
          <CircularProgress />
        </div>
      ) : rows?.length ? (
        <GridTable columns={columns} rows={rows} onSort={onClickSort} />
      ) : (
        <div className={classes.widthNotRecruit}>
          <p>
            There are no {labels.platoons} in the table.{' '}
            <span onClick={() => setIsEdit(true)} className={classes.titleAdd}>
              Add new platoon!
            </span>
          </p>
        </div>
      )}

      <RemoveModal
        onClick={onDelete}
        open={isDel}
        onClose={() => onCloseModals(true)}
        title="Delete this platoon?"
        name={platoon?.name}
        subText={platoon?.quantityRecruit ? `${platoon?.quantityRecruit}  ${labels.recruits}` : `0 ${labels.recruits}`}
        urlAvatar={platoon?.avatar}
        avatar
      />

      <DialogContainer
        open={isEdit}
        onAbort={() => {
          onCloseModals(false);
          setErrorPhoto(false);
          setErrorName(false);
        }}
        title={platoon?.id ? `Edit ${labels.platoon}` : `New ${labels.platoon}`}
        btnTitle={platoon?.id ? 'Save changes' : `Create ${labels.platoon}`}
        onClick={onSubmit}
        loading={loading}
      >
        <UploadButton
          setErrorPhoto={setErrorPhoto}
          errorPhoto={errorPhoto}
          file={file}
          setFile={setFile}
          avatar={values?.avatar}
        />
        <TextField
          id="platoon-name"
          className={classes.input}
          placeholder={`${labels.platoon} Name`}
          variant="outlined"
          value={values?.name}
          onChange={onChange('name')}
          error={errorName}
        />
      </DialogContainer>
    </>
  );
};

export default PlatoonsTable;
