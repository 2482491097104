import React, { useEffect, useState } from 'react';
import { TopBar } from 'components/common';
import { useRpcApi } from '../../../services/api';
import FormAdmin from './Form';

const [getOrganization] = useRpcApi('organizations.getOrganization');

const NewInstructor = ({ getOrganizations, visible, setVisible }) => {
  const [currentOrganization, setCurrentOrganization] = useState();

  const id = window.location.pathname.split('/')[3];

  useEffect(() => {
    if (id) getOrganization({ id }).then((res) => setCurrentOrganization(res));
  }, [id]);

  return (
    <>
      <TopBar
        title={id ? 'Edit Organization' : 'Create New Organization'}
        hasBackButton
        backText="Cancel"
        maxWidth={944}
      />

      <FormAdmin
        id={id}
        getOrganizations={getOrganizations}
        currentOrganization={currentOrganization}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  );
};

export default NewInstructor;
