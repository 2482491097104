import axios from 'axios';
import ApiClient from './apiClient';
import HttpAdapter from './httpAdapter';
import randomHex from './randomHex';

const api = new ApiClient(
  new HttpAdapter(
    axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api`,
    })
  )
);

api.jsonrpc = (method, params) => {
  return {
    jsonrpc: '2.0',
    method,
    params,
    id: randomHex(8),
  };
};

export const useApi = () => {
  return {
    call: (method, params) => api.jsonRpc(api.jsonrpc(method, params)),
    batchCall: (obj) => api.jsonRpc(Object.entries(obj).map(([method, params]) => api.jsonrpc(method, params))),
  };
};

const apiStore = {};

export const useRpcApi = (...methods) => {
  return methods.map((method) => {
    if (!apiStore[method]) {
      apiStore[method] = (...params) => api.jsonRpc(api.jsonrpc(method, params.length > 1 ? params : params[0]));
    }
    return apiStore[method];
  });
};

/**
 * Upload photo to S3 by server creds
 * @param url
 * @param file
 * @param data
 */
export const uploadFileToS3 = (url, file, data) => {
  try {
    data.file = file;
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    console.log('*** uploadPhoto -> getAvatarUploadUrl -> uploadFileToS3***');
    const res = axios({
      method: 'post',
      url: url,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        console.log('*** uploadPhoto -> getAvatarUploadUrl -> uploadFileToS3 DONE ***');
        //handle success
        return true;
      })
      .catch(function (response) {
        //handle error
        console.log('error', response);
        return false;
      });

    return res;
  } catch (e) {
    console.log('222 error', e);
  }
  // const formData = new FormData();
};

export default api;
