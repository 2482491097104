import React from 'react';
import Button from '@material-ui/core/Button';
import UploadIcon from 'components/assets/images/ico-upload-image.svg';
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
    backgroundColor: '#ffffff',
    border: 'none',
  },
  uploadText: {
    display: 'flex',
    flex: 'none',
  },
  uploadImgRadius: {
    width: '160px',
    height: '160px',
    borderRadius: '100%',
    backgroundColor: '#f7f8fa',
    border: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: 'center',
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  wrapperUpload: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatarUser: {
    width: '100%',
    height: '100%',
    margin: 0,
    borderRadius: '100%',
    objectFit: 'cover',
  },
  noAvatar: {
    borderRadius: 0,
    margin: 0,
  },
  errorText: {
    color: 'red',
    fontSize: '11px',
    width: '205px',
    textAlign: 'center',
  },
}));

const UploadButton = ({ file, setFile, avatar, errorPhoto, setErrorPhoto, alt = 'pic' }) => {
  const classes = useStyles();

  const getImage = (e) => {
    if (+e.target.files[0].size < 10000000) {
      setFile(e.target.files[0]);
      setErrorPhoto(false);
    } else {
      setErrorPhoto(true);
    }
  };

  return (
    <div className={classes.wrapperUpload}>
      <div className={classes.uploadImgRadius}>
        {avatar && !file ? (
          <Avatar className={classes.avatarUser} src={avatar} alt={alt} />
        ) : (
          <Avatar
            className={file ? classes.avatarUser : classes.noAvatar}
            src={file ? URL.createObjectURL(file) : UploadIcon}
            alt={alt}
          />
        )}
      </div>
      <div className={classes.root}>
        <input
          className={classes.input}
          accept="image/*,.heic"
          id="contained-button-file"
          multiple
          type="file"
          onChange={getImage}
        />
        <label htmlFor="contained-button-file">
          <Button color="primary" component="span">
            <div className={classes.uploadText}>Upload Photo</div>
          </Button>
        </label>
      </div>
      {errorPhoto && <div className={classes.errorText}>* Photo size should be less than 10MB</div>}
    </div>
  );
};

export default UploadButton;
