import { Avatar, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useLabels } from '../../../../components/contexts/AuthContext';
import { addMask } from '../helpers';
import useStyles from '../style';

const InstructorBlock = ({ dataDetails }) => {
  const classes = useStyles();
  const labels = useLabels();

  return (
    <Paper>
      <Grid container justify="space-between">
        <Typography variant="h5">
          {dataDetails[0]?.instructor ? labels.instructors : `${labels.instructors} (0)`}
        </Typography>
        <Typography variant="subtitle2">{dataDetails[0]?.instructor && 'Phone'}</Typography>
      </Grid>
      {dataDetails.map((i, ind) => (
        <Grid key={ind} className={classes.rowInstructor} container justify="space-between" alignItems="center">
          <div>
            <div className={classes.instructorBlock}>
              <Avatar className={classes.instructorAvatar} src={i?.instructor?.avatar} alt="Avatar">
                {i?.instructor?.firstName?.charAt(0) + i?.instructor?.lastName?.charAt(0)}
              </Avatar>
              <Typography variant="subtitle2">{`${i?.instructor?.firstName} ${i?.instructor?.lastName}`}</Typography>
            </div>
          </div>
          <div>{i?.instructor?.phoneNumber ? addMask(i?.instructor?.phoneNumber) : '--'}</div>
        </Grid>
      ))}
    </Paper>
  );
};

export default InstructorBlock;
