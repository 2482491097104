export const defultImg = 'https://cryptor.net/sites/default/files/pictures/picture-425-1516178707.png';

export const columns = [
  { id: 'name', title: 'Name', align: 'left', xs: 4, isSort: true },
  { id: 'badge', title: 'Badge', align: 'left', xs: 3, isSort: true },
  { id: 'organization', title: 'Organization', align: 'left', xs: 3, isSort: true },
  { id: 'actions', title: 'Actions', align: 'right', xs: 2 },
];

export const apiUser = ['user.getFreeRecruitsList', 'policeService.getPoliceServicesList'];

export const apiPlatoon = [
  'platoon.updatePlatoon',
  'platoon.getPlatoon',
  'platoonInRecruit.getPlatoonInRecruit',
  'platoonInRecruit.delPlatoonInRecruit',
];
