import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';

export default function DatePicker({ value, onChange, error, label = '', ...rest }) {
  const classes = useStyles();

  const handleDateChange = React.useCallback(
    (date) => {
      onChange && onChange(date);
    },
    [onChange]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <>
        {label && <h4 className={classes.inputTitle}>{label}</h4>}
        <KeyboardDatePicker
          className={classes.root}
          helperText={false}
          maxDateMessage="Date should not be after current date"
          placeholder="MM/DD/YYYY"
          inputVariant="outlined"
          margin="normal"
          format="MM/dd/yyyy"
          value={value}
          onChange={handleDateChange}
          {...rest}
        />
        {error && <div className={classes.error}>*{error}</div>}
      </>
    </MuiPickersUtilsProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    borderColor: '#f66',
    width: '100%',
  },
  inputTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
}));
