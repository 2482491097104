export const apis = [
  'platoon.getPlatoonList',
  'user.getInstructorsList',
  'scenario.getScenarioList',
  'schedule.createSchedule',
  'schedule.getScheduleList',
  'schedule.updateSchedule',
  'criteria.getCriteriaList',
  'evaluation.updateEvaluation',
  'evaluation.addEvaluationToRecruits',
];

export const deleteEvent = [
  'schedule.deleteSchedule',
  'instructorsInSchedule.getInstructorsInSchedule',
  'schedule.deleteScheduleChain',
];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
