/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRpcApi } from 'services/api';
import { MAPBOX_TOKEN } from 'components/common/MapBox/apiMap';
import { IconButton } from '@material-ui/core';
import { jsonParse, setCorrectObject } from '../../utils/helpers';
import useStyles from './style';
import MapGL, { Marker } from 'react-map-gl';
import marker from 'components/assets/images/marker.png';
import downArrow from 'components/assets/images/down-arrow.svg';
import upArrow from 'components/assets/images/up-arrow.svg';
import { Button } from '@material-ui/core';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { TopBar } from 'components/common';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import { ActionBlockDetails } from '../ScheduleDetails/DetailsPage/components';
import { useLabels } from '../../components/contexts/AuthContext';

const [getScenario] = useRpcApi('scenario.getScenario');

const ScenarioDetails = ({ visible, setVisible }) => {
  const [scenarioData, setScenarioData] = useState([]);
  const [zoom, setZoom] = useState(16);
  const [viewport, setViewport] = useState({
    altitude: 1.5,
    latitude: 40.764628570117544,
    longitude: -73.9807219839746,
    maxPitch: 60,
    maxZoom: 24,
  });

  const [visibleMap, setVisibleMap] = useState(false);
  const [openScoring, setOpenScoring] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const mapRef = useRef();
  const classes = useStyles();
  const labels = useLabels();

  const getScenarioById = useCallback(async () => {
    if (id) {
      const res = await getScenario({ id });
      setViewport({ ...viewport, latitude: +res?.latitude || 50.3, longitude: +res?.longitude || 28.7 });
      setScenarioData(setCorrectObject(res));
    }
  }, []);

  useEffect(() => {
    getScenarioById();
  }, [getScenarioById]);

  const onDragEnd = (lngLat) => setViewport({ longitude: lngLat.lng, latitude: lngLat.lat });

  const handleViewportChange = useCallback(
    (newViewport) =>
      setViewport({
        latitude: newViewport?.latitude,
        longitude: newViewport?.longitude,
      }),
    []
  );

  const onChangeOpenScoring = () => setOpenScoring(!openScoring);
  const onChangeMap = () => setVisibleMap(!visibleMap);

  useEffect(() => {
    scenarioData.locationName && onChangeMap();
    scenarioData?.scoringType && onChangeOpenScoring();
  }, [scenarioData]);

  const goToEdit = () => {
    if (scenarioData.customFields && scenarioData.customFields.length > 0) {
      history.push(`/scenarios/edit-customScenario/${id}`);
    } else {
      history.push(`/scenarios/edit-scenarios/${id}`);
    }
  };

  const plusZoom = () => setZoom(zoom + 0.5);

  const minusZoom = () => setZoom(zoom - 0.5);

  const coordinate = () => {
    setViewport({
      ...viewport,
      latitude: +scenarioData?.latitude || 50.3,
      longitude: +scenarioData?.longitude || 28.7,
    });
    setZoom(13);
  };

  const criterias = useMemo(() => {
    if (scenarioData?.criterioId && scenarioData?.criterioData) {
      if (scenarioData.criterioData.criterios) {
        return jsonParse(scenarioData.criterioData.criterios);
      } else return [];
    } else return null;
  }, [scenarioData]);

  return (
    <div className={classes.wrapper}>
      <SuccessNotification open={visible} setOpen={setVisible} text="Scenario" />
      <TopBar
        maxWidth={744}
        goToEdit={goToEdit}
        custom={classes.widthHeader}
        edit
        hasBackButton
        title="Scenario Details"
      />
      <div className={classes.container}>
        <h2 className={classes.titleDetails}>{scenarioData?.name}</h2>
        <ActionBlockDetails title="Objective" text="No data" data={scenarioData?.objective} />
        <ActionBlockDetails title="Debriefing Notes" text="No data" data={scenarioData?.debriefingNotes} />
        {scenarioData.customFields && scenarioData.customFields.length > 0
          ? scenarioData.customFields.map((el) => (
              <ActionBlockDetails key={el.name} title={el.name} text="no data" data={el.value} />
            ))
          : null}
        <ActionBlockDetails
          title={`Assessment (visible for ${labels.recruits})`}
          text="No data"
          data={scenarioData?.assessment}
        />
        <ActionBlockDetails
          title={`Scoring type (visible for ${labels.recruits})`}
          text="No data"
          data={scenarioData?.scoringType === 'boolean' ? 'Pass/Fail' : 'From 1 to 4'}
        />
        {criterias && (
          <ActionBlockDetails
            title="Criteria"
            text="No data"
            data={
              criterias.length
                ? criterias.map(({ name }, index) => `Criteria #${index + 1} - ${name}`).join(' \n')
                : null
            }
          />
        )}
        <ActionBlockDetails title="Summary" text="No data" data={scenarioData?.summary} />
        <ActionBlockDetails
          title={`${labels.instructor} Briefing`}
          text="No data"
          data={scenarioData?.instructorBriefing}
        />
        <ActionBlockDetails
          title={`${labels.recruit} Briefing (visible for ${labels.recruits})`}
          text="No data"
          data={scenarioData?.recruitBriefing}
        />
        <ActionBlockDetails title="Dispatch Information" text="No data" data={scenarioData?.dispatchInformation} />
        <div className={classes.textAreaBox}>
          {visibleMap ? (
            <div className={classes.visibleMap}>
              <h4 className={classes.textTitle}>
                {scenarioData.locationName ? scenarioData.locationName : 'Location is empty'}
              </h4>
              <IconButton color="primary" onClick={onChangeMap}>
                <img src={upArrow} alt="upArrow" className={classes.downArrow} />
              </IconButton>
            </div>
          ) : (
            <div className={classes.visibleMap}>
              <h4 className={classes.textLocation}>Location</h4>
              <IconButton color="primary" onClick={onChangeMap}>
                <img src={downArrow} alt="downArrow" className={classes.downArrow} />
              </IconButton>
            </div>
          )}
          {visibleMap && (
            <>
              <p className={classes.descText}>{scenarioData.address ? scenarioData.address : 'Address is empty'}</p>
              <div className={classes.map}>
                <MapGL
                  mapStyle="mapbox://styles/mapbox/streets-v11"
                  ref={mapRef}
                  {...viewport}
                  zoom={zoom}
                  width="100%"
                  height="340px"
                  onViewportChange={handleViewportChange}
                  mapboxApiAccessToken={MAPBOX_TOKEN}
                  marker={true}
                >
                  <Marker
                    longitude={+scenarioData?.longitude}
                    latitude={+scenarioData?.latitude}
                    onDragEnd={onDragEnd}
                    draggable
                  >
                    <img src={marker} style={{ width: '65px', height: '70px' }} alt="marker" />
                  </Marker>
                  <Button onClick={coordinate} className={classes.ovalBtn}>
                    <LocationSearchingIcon className={classes.oval} />
                  </Button>
                  <div className={classes.btnCroup}>
                    <Button
                      onClick={plusZoom}
                      className={classes.btnPlus}
                      size="medium"
                      color="secondary"
                      variant="contained"
                    >
                      +
                    </Button>
                    <Button
                      onClick={minusZoom}
                      className={classes.btnMinus}
                      size="medium"
                      color="secondary"
                      variant="contained"
                    >
                      -
                    </Button>
                  </div>
                </MapGL>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScenarioDetails;
