import { Paper, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Axios from 'axios';
import { Button, TopBar } from 'components/common';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import Select from 'components/common/Inputs/Select';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRpcApi } from 'services/api';
import GetAppIcon from '@material-ui/icons/GetApp';
import SimpleDialog from '../../../components/common/Dialogs/SimpleDialog';
import { MultilineTextField } from '../../../components/common/Inputs';
import { InputFile } from '../../../components/common/Inputs/InputFile';
import { Loader } from '../../../components/common/Loader';
import { IconUpload } from '../../../components/svg/forms/IconUpload';
import { behaviours, roles, TextAreaBlock } from '../DetailsPage/components';
import { AssessmentChoose } from '../DetailsPage/components/AssessmentChoose';
import { AssessmentSelect } from '../DetailsPage/components/AssessmentSelect';
import {
  CriterioListAssessment,
  InstructorBlock,
  LabelInsideInput,
  ModalPhoto,
  NoUploadedBlock,
  PhotoComponent,
  RecruitsList,
  VideoComponentDb,
  VideoContainer,
} from './components';
import useStyles from './style';
import { downloadPhotos, MAX_SIZE_PHOTO, MAX_SIZE_VIDEO } from './helpers';
import { useQuery } from '../../../hooks/useQuery';
import { jsonParse } from '../../../utils/helpers';
const [
  addEvaluationToRecruits,
  addScheduleInRecruit,
  getPhotoUploadUrl,
  markPhotoAsExist,
  getPhotoList,
  getVideoList,
  getVideoUploadUrl,
  removePhoto,
  removeVideo,
  markVideoAsExist,
] = useRpcApi(
  'evaluation.addEvaluationToRecruits',
  'scheduleInRecruit.addScheduleInRecruit',
  'evaluation.getPhotoUploadUrl',
  'evaluationPhoto.markAsExist',
  'evaluationPhoto.getPhotoList',
  'evaluationVideo.getVideoList',
  'evaluation.getVideoUploadUrl',
  'evaluationPhoto.removePhoto',
  'evaluationVideo.removeVideo',
  'evaluationVideo.markAsExist'
);

const MultiAssessment = ({
  getevaluationByScheduleId,
  dataDetails,
  dataSchedule,
  visible,
  setVisible,
  getListRecruits,
  newDataSchedules,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComment] = useState('');
  const [score, setScore] = useState(0);
  const [behavior, setBehavior] = useState({ id: 0, name: 'None' });
  const [rolesel, setRoles] = useState({ id: 0, name: 'None' });
  const [photo, setPhoto] = useState([]);
  const [criterioList, setCriterioList] = useState([]);
  const [textMassage, setTextMassage] = useState('Schedule Event');
  const [videos, setVideos] = useState([]);
  const [urlToWatchVideo, setUrlToWatchVideo] = useState();
  const [openVideoBox, setOpenVideoBox] = useState(false);
  const [photoGallery, setPhotoGallery] = useState({
    photos: [],
    open: false,
    index: null,
  });
  const [choosenRecruts, setChoosenRecruts] = useState([]);
  const [videoForUpload, setVideoForUpload] = useState({ videoUpload: [], videoShow: [] });
  const [photoForUpload, setPhotoForUpload] = useState({ photoUpload: [], photoShow: [] });
  const [idToDeletePhoto, setIdToDeletePhoto] = useState({ idFromDB: [], idLocal: [] });
  const [idToDeleteVideo, setIdToDeleteVideo] = useState({ idFromDB: [], idLocal: [] });
  const [allNewScores, setAllNewScores] = useState({ score: null, criterio: null });
  const [haveScores, setHaveScores] = useState(null);
  const [toBigForUpload, setToBigForUpload] = useState({ video: [], photo: [] });

  const { query } = useQuery();
  const { id } = useParams();
  const downloadPhotoVideoFromDb = useCallback(() => {
    const obj = {
      filter: Object.entries({
        scheduleId: +dataSchedule.id,
        recruitId: choosenRecruts[0].recruitId,
        isExist: true,
      }),
    };
    getPhotoList(obj)
      .then((res) => Array.isArray(res) && setPhoto(res))
      .catch(console.error);

    getVideoList(obj).then((res) => Array.isArray(res) && setVideos(res));
  }, [choosenRecruts, dataSchedule.id]);
  useEffect(() => {
    if (choosenRecruts.length === 1 && choosenRecruts[0].fullAssessment) {
      setComment(choosenRecruts[0].fullAssessment.comment ? choosenRecruts[0].fullAssessment.comment : '');
    }
    if (choosenRecruts.length === 1 && choosenRecruts[0].fullAssessment) {
      if (choosenRecruts[0].fullAssessment.evaluation) {
        setHaveScores(choosenRecruts[0].fullAssessment.evaluation);
      }
    }
    if (choosenRecruts.length === 1 && choosenRecruts[0].shedule) {
      setBehavior({ id: choosenRecruts[0].shedule.behaviours });
    }
    if (choosenRecruts.length === 1 && choosenRecruts[0].shedule) {
      setRoles({ id: choosenRecruts[0].shedule.roles });
    }
    if (choosenRecruts.length === 1) {
      downloadPhotoVideoFromDb();
    }

    let parseCriterio = null;
    if (dataSchedule.criterioData) {
      parseCriterio = jsonParse(dataSchedule.criterioData.criterios);
      parseCriterio = parseCriterio.map((el) => ({
        name: el.name,
        evaluation: null,
      }));
    }
    if (choosenRecruts.length === 1 && choosenRecruts[0].fullAssessment) {
      if (choosenRecruts[0].fullAssessment.criterias && dataSchedule.criterioData) {
        const recrut = jsonParse(choosenRecruts[0].fullAssessment.criterias);
        for (let i = 0; i < recrut.length; i++) {
          parseCriterio = parseCriterio.map((el) => (el.name === recrut[i].name ? recrut[i] : el));
        }
      }
    }
    setCriterioList(parseCriterio);
  }, [choosenRecruts, dataSchedule.criterioData, downloadPhotoVideoFromDb]);

  useEffect(() => {
    if (!query.ids) {
      history.push(`/event-details/${id}`);
    }
    const listID = query.ids ? query.ids.split(',') : [];
    const recruits = newDataSchedules.filter((el) => listID.includes(String(el.recruitId)));
    setChoosenRecruts(recruits);
  }, [history, id, newDataSchedules, query.ids]);

  const AddScores = (score, selectedRecruits) => {
    setScore(score);

    if (!score) {
      return;
    }
    setAllNewScores((prev) => ({ ...prev, score: true }));
  };

  const updateEvaluation = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      let gradingForSend = {
        scheduleId: +dataSchedule.id,
        recruitIds: choosenRecruts.map((el) => el.recruitId),
        isReleased: false,
      };
      if (photoForUpload.photoUpload.length > 0) {
        const photoFiles = photoForUpload.photoUpload.map((el) => el.file);
        await handleSavePhoto(photoFiles);
      }
      if (videoForUpload.videoUpload.length > 0) {
        const videoFiles = videoForUpload.videoUpload.map((el) => el.file);
        await handleSaveVideo(videoFiles);
      }
      if (comments.length > 0) {
        Object.assign(gradingForSend, { comment: comments });
      }
      if (allNewScores.score && score !== 0) {
        Object.assign(gradingForSend, { evaluation: +score.id });
      }
      if (allNewScores.criterio) {
        Object.assign(gradingForSend, { criterias: criterioList });
      }
      if (choosenRecruts.length === 1) {
        const obj = {
          platoonId: +dataSchedule?.platoonId,
          recruitId: +choosenRecruts[0]?.recruitId,
          scheduleId: +dataSchedule?.id,
          // behaviours: +behavior?.id > 1 ? +behavior?.id : 1,
          // roles: +rolesel?.id > 1 ? +rolesel?.id : 1,
          roles: +rolesel?.id >= 0 ? +rolesel?.id : 0,
          behaviours: +behavior?.id >= 0 ? +behavior?.id : 0,
        };
        await addScheduleInRecruit(obj);
      }
      await addEvaluationToRecruits(gradingForSend);
      setVisible(true);
      setTextMassage('Update');
      await getListRecruits();
      await getevaluationByScheduleId({ scheduleId: id });
      setIsLoading(false);
      history.push(`/event-details/${id}`);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleAddCriterio = useCallback(({ name, evaluation, comment }) => {
    if (!name) return;

    setCriterioList((prev) => {
      const list = (prev || []).slice();
      const existIndex = list.findIndex((el) => el.name === name);
      if (existIndex < 0) return list;

      if (evaluation) list[existIndex].evaluation = evaluation;
      if (typeof comment === 'string') list[existIndex].comment = comment;

      return list;
    });

    setAllNewScores((prev) => ({ ...prev, criterio: true }));
  }, []);

  const handleEditComment = (event) => {
    const { value } = event.target;
    setComment(value);
  };

  const handleAddPhoto = useCallback((event) => {
    const files = event.target.files;
    let arrOfUrlToShowLocal = [];
    let filesWithId = [];
    let namesNoUploadPhotos = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].size >= MAX_SIZE_PHOTO) {
        namesNoUploadPhotos.push(files[i].name);
        continue;
      }
      const objectURL = URL.createObjectURL(files[i]);
      arrOfUrlToShowLocal.push({ url: objectURL });
      filesWithId.push({ file: files[i], id: objectURL });
    }
    setToBigForUpload((prev) => ({
      ...prev,
      photo: [...namesNoUploadPhotos],
    }));
    setPhotoForUpload((prev) => ({
      photoUpload: [...prev.photoUpload, ...filesWithId],
      photoShow: [...prev.photoShow, ...arrOfUrlToShowLocal],
    }));
  }, []);

  const handleAddVideo = useCallback((files) => {
    let arrOfUrlToShowLocal = [];
    let filesWithId = [];
    let namesNoUploadVideos = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].size >= MAX_SIZE_VIDEO) {
        namesNoUploadVideos.push(files[i].name);
        continue;
      }
      const objectURL = URL.createObjectURL(files[i]);
      arrOfUrlToShowLocal.push({ url: objectURL });
      filesWithId.push({ file: files[i], id: objectURL });
    }
    setToBigForUpload((prev) => ({
      ...prev,
      video: [...namesNoUploadVideos],
    }));
    setVideoForUpload((prev) => ({
      videoUpload: [...prev.videoUpload, ...filesWithId],
      videoShow: [...prev.videoShow, ...arrOfUrlToShowLocal],
    }));
  }, []);

  const addIdToDeletePhotoLocal = useCallback(
    (id) => {
      if (idToDeletePhoto.idLocal.includes(id)) {
        setIdToDeletePhoto((prev) => ({
          ...prev,
          idLocal: prev.idLocal.filter((el) => el !== id),
        }));
        return;
      }
      setIdToDeletePhoto((prev) => ({
        ...prev,
        idLocal: [...prev.idLocal, id],
      }));
    },
    [idToDeletePhoto.idLocal]
  );
  const addIdToDeleteFromDb = useCallback(
    (id) => {
      if (idToDeletePhoto.idFromDB.includes(id)) {
        setIdToDeletePhoto((prev) => ({
          ...prev,
          idFromDB: prev.idFromDB.filter((el) => el !== id),
        }));
        return;
      }
      setIdToDeletePhoto((prev) => ({
        ...prev,
        idFromDB: [...prev.idFromDB, id],
      }));
    },
    [idToDeletePhoto.idFromDB]
  );
  const addIdToDeleteVideoLocal = useCallback(
    (id) => {
      if (idToDeleteVideo.idLocal.includes(id)) {
        setIdToDeleteVideo((prev) => ({
          ...prev,
          idLocal: prev.idLocal.filter((el) => el !== id),
        }));
        return;
      }
      setIdToDeleteVideo((prev) => ({
        ...prev,
        idLocal: [...prev.idLocal, id],
      }));
    },
    [idToDeleteVideo.idLocal]
  );

  const addIdToDeleteVideoDb = useCallback(
    (id) => {
      if (idToDeleteVideo.idFromDB.includes(id)) {
        setIdToDeleteVideo((prev) => ({
          ...prev,
          idFromDB: prev.idFromDB.filter((el) => el !== id),
        }));
        return;
      }
      setIdToDeleteVideo((prev) => ({
        ...prev,
        idFromDB: [...prev.idFromDB, id],
      }));
    },
    [idToDeleteVideo.idFromDB]
  );

  const handleSaveVideo = async (files) => {
    let uploadedVideoIds = [];
    const recruitIds = choosenRecruts.map((el) => el.recruitId);
    const scheduleId = +dataSchedule.id;
    if (!recruitIds.length) return;

    for (let i = 0; i < files.length; i++) {
      let extension = files[i].name.split('.');
      extension = extension[extension.length - 1];

      try {
        const result = await getVideoUploadUrl({ recruitIds, scheduleId, extension });

        const data = { ...result.url.fields, file: files[i] };
        const formData = new FormData();
        for (const key in data) {
          formData.append(key, data[key]);
        }

        const res = await Axios.post(result.url.url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (res.status < 300 && Array.isArray(result.ids)) {
          uploadedVideoIds = uploadedVideoIds.concat(result.ids);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (!uploadedVideoIds.length) return;

    try {
      await markVideoAsExist({ ids: uploadedVideoIds, scheduleId, recruitIds });
    } catch (error) {
      //
    }
  };

  const handleSavePhoto = async (files) => {
    let uploadedPhotoIds = [];
    const recruitIds = choosenRecruts.map((el) => el.recruitId);
    const scheduleId = +dataSchedule.id;
    if (!recruitIds.length) return;

    for (let i = 0; i < files.length; i++) {
      let extension = files[i].name.split('.');
      extension = extension[extension.length - 1];

      try {
        const result = await getPhotoUploadUrl({ recruitIds, scheduleId, extension });

        const data = { ...result.url.fields, file: files[i] };
        const formData = new FormData();
        for (const key in data) {
          formData.append(key, data[key]);
        }

        const res = await Axios.post(result.url.url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (res.status < 300 && Array.isArray(result.ids)) {
          uploadedPhotoIds = uploadedPhotoIds.concat(result.ids);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (!uploadedPhotoIds.length) return;

    try {
      await markPhotoAsExist({ ids: uploadedPhotoIds, scheduleId, recruitIds });
    } catch (error) {
      //
    }
  };

  const handleDeletePhoto = async () => {
    if (idToDeletePhoto.idFromDB.length > 0) {
      for (let i = 0; i < idToDeletePhoto.idFromDB.length; i++) {
        await removePhoto({ id: +idToDeletePhoto.idFromDB[i] });
      }
      setPhoto(
        photo.filter((el) => {
          if (idToDeletePhoto.idFromDB.includes(el.id)) return null;
          return el;
        })
      );
      setIdToDeletePhoto((prev) => ({
        ...prev,
        idFromDB: [],
      }));
    }
    if (idToDeletePhoto.idLocal.length > 0) {
      let photosUp = photoForUpload.photoUpload.filter((el) => !idToDeletePhoto.idLocal.includes(el.id));
      let photoWith = photoForUpload.photoShow.filter((el) => !idToDeletePhoto.idLocal.includes(el.url));
      setIdToDeletePhoto((prev) => ({
        ...prev,
        idLocal: [],
      }));
      setPhotoForUpload({
        photoUpload: photosUp,
        photoShow: photoWith,
      });
    }
  };

  const handleDeleteVideo = async () => {
    if (idToDeleteVideo.idFromDB.length > 0) {
      for (let i = 0; i < idToDeleteVideo.idFromDB.length; i++) {
        await removeVideo({ id: +idToDeleteVideo.idFromDB[i] });
      }
      setVideos(
        videos.filter((el) => {
          if (idToDeleteVideo.idFromDB.includes(el.id)) return null;
          return el;
        })
      );
      setIdToDeleteVideo((prev) => ({
        ...prev,
        idFromDB: [],
      }));
    }
    if (idToDeleteVideo.idLocal.length > 0) {
      let videoUp = videoForUpload.videoUpload.filter((el) => !idToDeleteVideo.idLocal.includes(el.id));
      let videoWith = videoForUpload.videoShow.filter((el) => !idToDeleteVideo.idLocal.includes(el.url));

      setIdToDeleteVideo((prev) => ({
        ...prev,
        idLocal: [],
      }));
      setVideoForUpload({ videoUpload: videoUp, videoShow: videoWith });
    }
  };

  const handleOpenVideoBox = async (url) => {
    setUrlToWatchVideo(url);
    setOpenVideoBox(true);
  };
  const addPhotosToModal = (url) => {
    let photoToUpload = [];
    if (photoForUpload.photoShow.length > 0) {
      photoToUpload = photoForUpload.photoShow.map((el) => el.url);
    }

    let photoFromDataBase = [];
    if (photo.length > 0) {
      photoFromDataBase = photo.map((el) => el.photoUrl);
    }

    for (let i = 0; i < photoToUpload.length; i++) {
      photoFromDataBase.push(photoToUpload[i]);
    }

    let ind = photoFromDataBase.findIndex((el) => el === url);
    setPhotoGallery({
      photos: photoFromDataBase,
      open: true,
      index: ind,
    });
  };
  const multipleDownloadPhoto = () => {
    if (idToDeletePhoto.idFromDB.length > 0) {
      let filteredArray = photo.filter((el) => idToDeletePhoto.idFromDB.includes(el.id));
      const arrayOfUrlAndKey = filteredArray.map((el) => ({
        photoUrl: el.photoUrl,
        key: el.key,
      }));
      downloadPhotos(arrayOfUrlAndKey);
    }
    if (idToDeletePhoto.idLocal.length > 0) {
      const filteredPhoto = photoForUpload.photoUpload.filter((el) => idToDeletePhoto.idLocal.includes(el.id));
      const prepearedForUpload = filteredPhoto.map((el) => {
        return {
          photoUrl: el.id,
          key: el.file.name,
        };
      });
      downloadPhotos(prepearedForUpload);
    }
    setIdToDeletePhoto({
      idFromDB: [],
      idLocal: [],
    });
  };
  return (
    <>
      <SuccessNotification open={visible} text={textMassage} setOpen={setVisible} />
      <TopBar maxWidth={744} title="Evaluation" hasBackButton />
      <div className={classes.container}>
        <RecruitsList choosenRecruts={choosenRecruts} />
        <InstructorBlock dataDetails={dataDetails} />
        <Paper className={classes.rootTablePaper}>
          <div>
            <TextAreaBlock title="Debriefing notes" text={dataSchedule?.debriefingNotes} />
          </div>
        </Paper>
        <Paper>
          <form onSubmit={updateEvaluation}>
            <Typography variant="h5" className={classes.commentHeading}>
              Comment
            </Typography>
            <MultilineTextField onChange={handleEditComment} customClass={classes.inputComment} value={comments} />
            {choosenRecruts.length === 1 && (
              <>
                <Typography variant="h5" className={classes.roleHeading}>
                  Role and Behavior
                </Typography>
                <div className={classes.roleAndBehavior}>
                  <div style={{ width: 320 }}>
                    <Select
                      customClass={classes.root}
                      name="name"
                      onChange={(_, role) => setRoles(role)}
                      value={rolesel.id}
                      options={roles}
                      nameIndex="id"
                      disableClearable
                    />
                  </div>
                  <div style={{ width: 320 }}>
                    <Select
                      customClass={classes.root}
                      name="name"
                      onChange={(_, behaviour) => setBehavior(behaviour)}
                      value={behavior.id}
                      options={behaviours}
                      nameIndex="id"
                      disableClearable
                    />
                  </div>
                </div>
              </>
            )}
            <div className={classes.scoring}>
              <Typography variant="h5" className={classes.roleHeading}>
                Assessment
              </Typography>
              {dataSchedule.scoringType === 'four-numeric-stage' ? (
                <AssessmentSelect
                  scoresFromDb={haveScores}
                  newScores={score}
                  onChangeScore={AddScores}
                  selectedRecruits={choosenRecruts}
                />
              ) : (
                <AssessmentChoose onChangeScore={AddScores} selectedRecruits={choosenRecruts} score={score} />
              )}
            </div>
            <CriterioListAssessment criterioList={criterioList} handleAddCriterio={handleAddCriterio} />
            <div className={classes.photoBlock}>
              <Typography variant="h5">Photos</Typography>
              <label className={classes.buttonAddPhotoVideo}>
                <IconUpload width={20} height={20} />
                <InputFile id="photo" multiple accept="image/*" onChange={handleAddPhoto} />
              </label>
              {idToDeletePhoto.idLocal.length > 0 || idToDeletePhoto.idFromDB.length > 0 ? (
                <button type="button" className={classes.assessmentButtonFail} onClick={handleDeletePhoto}>
                  <ClearIcon className={classes.failIcon} />
                </button>
              ) : null}
              {idToDeletePhoto.idLocal.length > 0 || idToDeletePhoto.idFromDB.length > 0 ? (
                <button
                  type="button"
                  style={{ marginLeft: 15 }}
                  className={classes.buttonDownloadImage}
                  onClick={multipleDownloadPhoto}
                >
                  <GetAppIcon className={classes.failIcon} />
                </button>
              ) : null}
            </div>
            {toBigForUpload.photo.length > 0 && (
              <NoUploadedBlock contant={toBigForUpload.photo} typeOfContant="photos" size={10} />
            )}
            <div className={classes.photoContainer} style={{ position: 'relative' }}>
              {photo.length === 0 && photoForUpload.photoShow.length === 0 && (
                <LabelInsideInput text="Upload photos here" label="photo" />
              )}
              {photoGallery.open && (
                <SimpleDialog
                  open={photoGallery.open}
                  onClose={() =>
                    setPhotoGallery((prev) => ({
                      ...prev,
                      open: false,
                    }))
                  }
                  maxWidth={false}
                >
                  <ModalPhoto photoGallery={photoGallery} />
                </SimpleDialog>
              )}
              {photo &&
                photo.map((el) => (
                  <div className={classes.imgChecboxContainer} key={el.id}>
                    <PhotoComponent
                      idToDeletePhoto={idToDeletePhoto.idFromDB}
                      addIdToDelete={addIdToDeleteFromDb}
                      addPhotosToModal={addPhotosToModal}
                      id={el.id}
                      url={el.photoUrl}
                    />
                  </div>
                ))}
              {photoForUpload.photoShow &&
                photoForUpload.photoShow.map((el) => (
                  <div className={classes.imgChecboxContainer} key={el.url}>
                    <PhotoComponent
                      idToDeletePhoto={idToDeletePhoto.idLocal}
                      addIdToDelete={addIdToDeletePhotoLocal}
                      addPhotosToModal={addPhotosToModal}
                      id={el.url}
                      url={el.url}
                    />
                  </div>
                ))}
            </div>
            <div style={{ display: 'flex', width: '100%', paddingBottom: 15, paddingTop: 15, alignItems: 'center' }}>
              <Typography variant="h5">Videos</Typography>
              <label className={classes.buttonAddPhotoVideo}>
                <IconUpload width={20} height={20} color="black" />
                <InputFile
                  id="video"
                  multiple
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => handleAddVideo(e.target.files)}
                />
              </label>
              {idToDeleteVideo.idLocal.length > 0 || idToDeleteVideo.idFromDB.length > 0 ? (
                <button type="button" className={classes.assessmentButtonFail} onClick={handleDeleteVideo}>
                  <ClearIcon className={classes.failIcon} />
                </button>
              ) : null}
            </div>
            {toBigForUpload.video.length > 0 && (
              <NoUploadedBlock contant={toBigForUpload.video} typeOfContant="videos" size={830} />
            )}
            <div className={classes.photoContainer} style={{ position: 'relative' }}>
              {videos.length === 0 && videoForUpload.videoShow.length === 0 && (
                <LabelInsideInput text="Upload videos here" label="video" />
              )}

              {openVideoBox && (
                <SimpleDialog open={openVideoBox} onClose={() => setOpenVideoBox(false)} maxWidth={false}>
                  <VideoContainer url={urlToWatchVideo} setOpen={setOpenVideoBox} />
                </SimpleDialog>
              )}
              {videos &&
                videos.map((el) => (
                  <div className={classes.imgChecboxContainer} key={el.id}>
                    <VideoComponentDb
                      idToDeleteVideo={idToDeleteVideo.idFromDB}
                      addIdToDeleteVideoDb={addIdToDeleteVideoDb}
                      handleOpenVideoBox={handleOpenVideoBox}
                      id={el.id}
                      url={el.videoUrl}
                    />
                  </div>
                ))}
              {videoForUpload &&
                videoForUpload.videoShow.map((el) => (
                  <div key={el.url} className={classes.imgChecboxContainer}>
                    <VideoComponentDb
                      idToDeleteVideo={idToDeleteVideo.idLocal}
                      addIdToDeleteVideoDb={addIdToDeleteVideoLocal}
                      handleOpenVideoBox={handleOpenVideoBox}
                      id={el.url}
                      url={el.url}
                    />
                  </div>
                ))}
            </div>
            <Button customClass={classes.submitButton} type="submit" text="Update" disabled={isLoading} />
          </form>
          <Loader open={isLoading} fixed={true} />
        </Paper>
      </div>
    </>
  );
};

export default MultiAssessment;
