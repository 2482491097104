import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 32,
    padding: '45px 60px 60px',
  },
  root: {
    display: 'flex',
  },
  icon: {
    flex: '0 0',
    marginRight: 50,
  },
  container: {
    flex: '1 0',
  },
  title: {
    fontSize: 33,
  },
  content: {
    margin: '30px 0 35px',
    backgroundColor: theme.palette.table.tHeadBg,
    borderRadius: 16,
    maxWidth: 380,
    padding: 10,
    '& p': {
      ...theme.mixins.ellipsis,
    },
  },
  actions: {
    '& button': {
      textTransform: 'none',
      '&:not(:last-child)': {
        marginRight: 20,
      },
    },
  },
}));

export default useStyles;
