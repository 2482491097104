import { useState } from 'react';

export const useCreateScenarioStore = () => {
  const [state, dispatch] = useState({
    name: '',
    shortName: '',
    objective: '',
    summary: '',
    instructorBriefing: '',
    recruitBriefing: '',
    dispatchInformation: '',
    debriefingNotes: '',
    assessment: '',
    locationName: '',
    address: '',
    latitude: '',
    organization: null,
    longitude: '',
    search: '',
    customFields: [],
    scoringType: 'four-numeric-stage',
  });

  const actions = {
    changeName: (name) => name.split(' ').join('')?.length < 120 && dispatch({ ...state, name }),
    changeOrganization: (organization) => dispatch({ ...state, organization }),
    changeLocationName: (locationName) => dispatch({ ...state, locationName }),
    changeAddress: (address) => dispatch({ ...state, address }),
    changeCoords: (coords) => dispatch({ ...state, latitude: coords?.lat, longitude: coords?.lng }),
    changeScoringType: (scoringType) => dispatch({ ...state, scoringType }),
    changeSearch: (search) => dispatch({ ...state, search }),
    setScenario: dispatch,
    completeCustomTextFields: (value, name, index) => {
      const arr = state.customFields;
      arr[index].value = value;
      dispatch({ ...state, customFields: arr });
    },
    onChangeValue: (e) => {
      const { name, value } = e.target;
      dispatch((prev) => ({ ...prev, [name]: value }));
    },
  };

  return [state, actions, null];
};
