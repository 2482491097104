export const sortByName = (a, b, isReverse) => {
  const nameA = a?.toLowerCase();
  const nameB = b?.toLowerCase();

  if (nameA < nameB || nameB === undefined) return isReverse ? -1 : 1;
  if (nameA > nameB || nameB === undefined) return isReverse ? 1 : -1;

  return 0;
};

export const uniqBy = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const setPoliceServicesToArray = (array, servicesList) => {
  return array.map((i) => ({
    ...i,
    policeService: servicesList.find((item) => item.id === i.policeServiceId)?.title,
  }));
};

export const setCorrectObject = (scenario) => {
  const {
    name,
    shortName,
    objective,
    summary,
    instructorBriefing,
    recruitBriefing,
    dispatchInformation,
    locationName,
    address,
    latitude,
    longitude,
    organization,
    debriefingNotes,
    assessment,
    scoringType,
    criterioId,
    customFields,
    criterioData,
    beginningAt,
  } = scenario;

  return {
    name: name || '',
    shortName: shortName || '',
    objective: objective || '',
    summary: summary || '',
    instructorBriefing: instructorBriefing || '',
    recruitBriefing: recruitBriefing || '',
    dispatchInformation: dispatchInformation || '',
    locationName: locationName || '',
    address: address || '',
    organization: organization || null,
    latitude: latitude?.toString(),
    longitude: longitude?.toString(),
    debriefingNotes: debriefingNotes || '',
    assessment: assessment || '',
    scoringType: scoringType || 'four-numeric-stage',
    beginningAt: beginningAt || '',
    customFields: customFields || [],
    criterioId,
    criterioData,
  };
};

export const getEventStatus = (eventData) => {
  const occurrenceDate = new Date(eventData?.beginningAt);
  let offset = occurrenceDate.getTimezoneOffset() / 60;
  let occStartTime = occurrenceDate.getTime() - offset * -1 * 3600000;
  let occEndTime = occStartTime + eventData?.duration;
  let currTime = new Date().getTime();

  if (currTime < occStartTime) return 'UPCOMING';
  else if (currTime > occEndTime) return 'COMPLETED';
  else return 'CURRENT';
};

export function jsonParse(string, defaultValue = null) {
  const errorResult = defaultValue || string;
  if (typeof string !== 'string') return errorResult;

  try {
    return JSON.parse(string);
  } catch (error) {
    return errorResult;
  }
}
