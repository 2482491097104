export const defaultValue = {
  name: '',
  shortName: '',
  objective: '',
  summary: '',
  instructorBriefing: '',
  recruitBriefing: '',
  dispatchInformation: '',
  debriefingNotes: '',
  assessment: '',
  locationName: '',
  address: '',
  latitude: '',
  longitude: '',
  search: '',
  scoringType: 'four-numeric-stage',
};

export const apis = ['schedule.getSchedule', 'schedule.editScheduleDetails', 'schedule.updateSchedule'];

export const SUCCESS_MODE = 'SUCCESS_MODE';

export const ReccurrenceList = ['Pass/Fail', '1 to 4'];

export const ReccurrenceData = ['No', 'Daily', 'Weekly'];

const getDuration = (hours, minutes = 0) => {
  return `${(hours * 60 + minutes) * 60000}`;
};

export const defaultViewport = {
  altitude: 1.5,
  bearing: 0,
  height: 255,
  latitude: 40.764628570117544,
  longitude: -73.9807219839746,
  maxPitch: 60,
  maxZoom: 24,
  minPitch: 0,
  minZoom: 16,
  pitch: 0,
  transitionDuration: 0,
  transitionInterruption: 1,
  width: 300,
  zoom: 16,
};

export const DurationList = [
  {
    label: '0',
    value: getDuration(0, 0),
  },
  {
    label: '15m',
    value: getDuration(0, 15),
  },
  {
    label: '30m',
    value: getDuration(0, 30),
  },
  {
    label: '45m',
    value: getDuration(0, 45),
  },
  {
    label: '1h',
    value: getDuration(1),
  },
  {
    label: '1.5h',
    value: getDuration(1, 30),
  },
  {
    label: '2h',
    value: getDuration(2),
  },
  {
    label: '2.5h',
    value: getDuration(2, 30),
  },
  {
    label: '3h',
    value: getDuration(3),
  },
  {
    label: '3.5h',
    value: getDuration(3, 30),
  },
  {
    label: '4h',
    value: getDuration(4),
  },
  {
    label: '4.5h',
    value: getDuration(4, 30),
  },
  {
    label: '5h',
    value: getDuration(5),
  },
  {
    label: '5.5h',
    value: getDuration(5, 30),
  },
  {
    label: '6h',
    value: getDuration(6),
  },
  {
    label: '6.5h',
    value: getDuration(6, 30),
  },
  {
    label: '7h',
    value: getDuration(7),
  },
  {
    label: '7.5h',
    value: getDuration(7, 30),
  },
  {
    label: '8h',
    value: getDuration(8),
  },
  {
    label: '8.5h',
    value: getDuration(8, 30),
  },
  {
    label: '9h',
    value: getDuration(9),
  },
  {
    label: '9.5h',
    value: getDuration(9, 30),
  },
  {
    label: '10h',
    value: getDuration(10),
  },
  {
    label: '10.5h',
    value: getDuration(10, 30),
  },
  {
    label: '11h',
    value: getDuration(11),
  },
  {
    label: '11.5h',
    value: getDuration(11, 30),
  },
  {
    label: '12h',
    value: getDuration(12),
  },
  {
    label: '12.5h',
    value: getDuration(12, 30),
  },
  {
    label: '13h',
    value: getDuration(13),
  },
  {
    label: '13.5h',
    value: getDuration(13, 30),
  },
  {
    label: '14h',
    value: getDuration(14),
  },
  {
    label: '14.5h',
    value: getDuration(14, 30),
  },
  {
    label: '15h',
    value: getDuration(15),
  },
  {
    label: '15.5h',
    value: getDuration(15, 30),
  },
  {
    label: '16h',
    value: getDuration(16),
  },
  {
    label: '16.5h',
    value: getDuration(16, 30),
  },
  {
    label: '17h',
    value: getDuration(17),
  },
  {
    label: '17.5h',
    value: getDuration(17, 30),
  },
  {
    label: '18h',
    value: getDuration(18),
  },
  {
    label: '18.5h',
    value: getDuration(18, 30),
  },
  {
    label: '19h',
    value: getDuration(19),
  },
  {
    label: '19.5h',
    value: getDuration(19, 30),
  },
  {
    label: '20h',
    value: getDuration(20),
  },
  {
    label: '20.5h',
    value: getDuration(20, 30),
  },
  {
    label: '21h',
    value: getDuration(21),
  },
  {
    label: '21.5h',
    value: getDuration(21, 30),
  },
  {
    label: '22h',
    value: getDuration(22),
  },
  {
    label: '22.5h',
    value: getDuration(22, 30),
  },
  {
    label: '23h',
    value: getDuration(23),
  },
  {
    label: '23.5h',
    value: getDuration(23, 30),
  },
];
