/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Divider } from '@material-ui/core';
import SuccessModal from '../../components/common/Dialogs/SuccessModal';
import MapForm from '../../components/common/MapBox/index';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRpcApi } from '../../services/api';
import { FormContainer, InputBox, TopBar } from '../../components/common';
import ButtonsGroup from '../../components/common/Buttons/ButtonsGroup';
import { MultilineTextField } from '../../components/common/Inputs';
import { useCreateScenarioStore } from '../../hooks/useStoreCreateScenario';
import { setCorrectObject } from '../../utils/helpers';
import useStyles from './style';
import { ROLE_SUPER_ADMIN } from '../../constants';
import Select from 'components/common/Inputs/Select';
import SelectAutocomplete from '../../components/common/Inputs/SelectAutocomplete';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import useTabs from '../../hooks/useTabs';
import CustomScenarioBuilder from './CustomScenarioBuilder';
import FromTemplate from './FromTemplate';
import { defaultValue, defaultViewport, ReccurrenceList, SUCCESS_MODE } from '../../utils/constants';
import { useLabels } from '../../components/contexts/AuthContext';

const [createScenario, editScenario, getScenario, getOrganizationsList, getCriteriaList] = useRpcApi(
  'scenario.createScenario',
  'scenario.updateScenario',
  'scenario.getScenario',
  'organizations.getOrganizationsList',
  'criteria.getCriteriaList'
);

const AddScenarios = ({ visible, setVisible, activeUser }) => {
  const [error, setError] = useState(null);
  const [errorScenarioISExist, setErrorScenarioISExist] = useState(null);
  const [errorOrganization, setErrorOrganization] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [activeReccurence, selectReccurence] = useState(ReccurrenceList[0]);
  const [categoryData, setCategoryData] = useState([]);
  const [activeCategory, setActiveCategory] = useState();
  const labels = useLabels();

  const [tab, onChangeTab] = useTabs(0);

  const [Organizations, setOrganizations] = useState([]);

  useEffect(() => {
    if (activeUser.roleId === ROLE_SUPER_ADMIN) {
      getOrganizationsList().then((res) => {
        const organizationsList = [];
        // eslint-disable-next-line array-callback-return
        res.items.map((val) => {
          organizationsList.push({ id: val.id, title: val.name });
        });
        setOrganizations(organizationsList);
      });
    }
    getCriteriaList({
      order: Object.entries({ name: 'asc' }),
    })
      .then((res) => setCategoryData(res?.items))
      .catch(console.error);
  }, [getOrganizationsList]);

  const history = useHistory();
  const classes = useStyles();
  const [viewport, setViewport] = useState(defaultViewport);

  const [createScenarioStore, createScenarioActions] = useCreateScenarioStore(null);

  const id = window.location.pathname.split('/')[3];

  const onChangeOpen = () => setOpen(!open);

  const goTo = () => history.push('/scenarios');

  const goToDetail = () => history.push(`/scenarios/details/${id}`);

  useEffect(() => {
    const getScenarioById = async () => {
      const res = await getScenario({ id }).catch((e) => console.log(e));
      const obj = setCorrectObject(res);
      setName(obj?.name);
      setViewport({ ...viewport, latitude: +obj?.latitude || 50.3, longitude: +obj?.longitude || 28.7 });
      if (!obj.address) {
        obj.latitude = '';
        obj.longitude = '';
      }
      createScenarioActions.setScenario(obj);
      setActiveCategory(obj.criterioId);
    };

    if (id) {
      getScenarioById();
    }
  }, []);

  useEffect(() => {
    if (createScenarioStore?.name?.trim()?.length && error) {
      setError(null);
    }
  }, [createScenarioStore, error]);

  const createOneMore = () => {
    setOpen(false);
    setViewport(defaultViewport);
    createScenarioActions.setScenario({ ...defaultValue });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isValide = () => {
    let result = true;
    if (activeUser.roleId === ROLE_SUPER_ADMIN && !createScenarioStore.organization) {
      setErrorOrganization('Organization is required');
      result = false;
    }

    if (!createScenarioStore.name) {
      setError('Name is required');
      result = false;
    }

    return result;
  };

  useEffect(() => {
    if (open) document.querySelector('.mapboxgl-ctrl-geocoder--input').value = '';
  }, [open]);

  const onSubmit = async () => {
    setErrorScenarioISExist('');
    if (!isValide()) {
      return;
    }

    setLoading(true);
    try {
      const obj = {
        ...setCorrectObject({ ...createScenarioStore }),
        ...(activeCategory ? { criterioId: activeCategory } : {}),
      };

      if (!obj?.name?.trim()?.length) {
        setError('Field "Scenario Name" is required');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
        return;
      }

      const inputAddress = document.querySelector('.mapboxgl-ctrl-geocoder--input')?.value;

      const coords = {
        latitude: viewport?.latitude?.toString() || '',
        longitude: viewport.longitude?.toString() || '',
        address: inputAddress,
      };
      (await id)
        ? editScenario({ ...obj, ...coords, id })
            .then(() => {
              setVisible(!visible);
              goToDetail();
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setLoading(false);
            })
            .catch((e) => {
              if ('scenario_exists' === e.message) {
                setError('A Scenario with this name exists');
              }
              console.log(e.message);
              setLoading(false);
            })
        : createScenario({ ...obj, ...coords })
            .then(() => {
              onChangeOpen();
              setLoading(false);
            })
            .catch((e) => {
              if ('scenario_exists' === e.message) {
                setErrorScenarioISExist('A Scenario with this name exists');
              }
              setLoading(false);
            });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={classes.container}>
      <TopBar
        maxWidth={744}
        subtitle={id && name}
        title={id ? 'Edit Scenario' : 'Create New Scenario'}
        hasBackButton
        backText="Cancel"
      />
      {!id && (
        <div
          style={{
            maxWidth: 744,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            margin: '20px auto 0',
          }}
        >
          <Grid item>
            <ToggleButtonGroup color="primary" value={tab} exclusive onChange={onChangeTab}>
              <ToggleButton value={0}>Common</ToggleButton>
              <Divider orientation="vertical" flexItem />
              <ToggleButton value={1}>Custom</ToggleButton>
              <Divider orientation="vertical" flexItem />
              <ToggleButton value={2}>From template</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </div>
      )}
      {tab === 1 && <CustomScenarioBuilder activeUser={activeUser} categoryData={categoryData} />}
      {tab === 2 && <FromTemplate activeUser={activeUser} categoryData={categoryData} />}
      {tab === 0 && (
        <FormContainer
          titleBtn={id ? 'Save changes' : 'Create Scenario'}
          onClick={onSubmit}
          maxWidth={744}
          loading={loading}
        >
          <Grid container direction="column" spacing={6}>
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">Scenario Information</Typography>
              </Grid>
              <Grid item xs={6}>
                <InputBox
                  name="name"
                  onInput={(e) => createScenarioActions.changeName(e.target.value)}
                  title={`Scenario Name (visible for ${labels.recruits})`}
                  value={createScenarioStore?.name}
                  error={error || errorScenarioISExist}
                  star
                />
              </Grid>
              <Grid item xs={6}>
                <InputBox
                  name="shortName"
                  onInput={createScenarioActions.onChangeValue}
                  title={`Short Code (visible for ${labels.recruits})`}
                  value={createScenarioStore?.shortName}
                />
              </Grid>
              <Grid item xs={12}>
                <MultilineTextField
                  onInput={createScenarioActions.onChangeValue}
                  name="objective"
                  title="Objective"
                  value={createScenarioStore?.objective}
                />
              </Grid>
              {activeUser.roleId === ROLE_SUPER_ADMIN ? (
                <Grid item xs={12}>
                  <SelectAutocomplete
                    onChange={(e, id) => createScenarioActions.changeOrganization(id?.id)}
                    options={Organizations}
                    name="organization"
                    star
                    title="Organization"
                    defaulValue={createScenarioStore}
                    id={createScenarioStore?.organization}
                    error={errorOrganization}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <MultilineTextField
                  onInput={createScenarioActions.onChangeValue}
                  name="summary"
                  title="Summary"
                  value={createScenarioStore?.summary}
                />
              </Grid>
              <Grid item xs={12}>
                <MultilineTextField
                  onInput={createScenarioActions.onChangeValue}
                  name="instructorBriefing"
                  title={`${labels.instructor} Briefing`}
                  value={createScenarioStore?.instructorBriefing}
                />
              </Grid>
              <Grid item xs={12}>
                <MultilineTextField
                  title={`${labels.recruit} Briefing (visible for ${labels.recruits})`}
                  name="recruitBriefing"
                  onInput={createScenarioActions.onChangeValue}
                  value={createScenarioStore?.recruitBriefing}
                />
              </Grid>
              <Grid item xs={12}>
                <MultilineTextField
                  title="Dispatch Information"
                  name="dispatchInformation"
                  onInput={createScenarioActions.onChangeValue}
                  value={createScenarioStore?.dispatchInformation}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">Evaluation</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <ButtonsGroup
                    star
                    title="Scoring type"
                    value={createScenarioStore?.scoringType || activeReccurence || null}
                    selectReccurence={selectReccurence}
                    onValueChange={createScenarioActions.changeScoringType}
                    listItems={ReccurrenceList}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MultilineTextField
                  title="Debriefing Notes"
                  name="debriefingNotes"
                  onInput={createScenarioActions.onChangeValue}
                  value={createScenarioStore?.debriefingNotes}
                />
              </Grid>
              <Grid item xs={12}>
                <MultilineTextField
                  title={`Assessment (visible for ${labels.recruits})`}
                  name="assessment"
                  onInput={createScenarioActions.onChangeValue}
                  value={createScenarioStore?.assessment}
                />
              </Grid>
              {activeUser.roleId !== ROLE_SUPER_ADMIN && (
                <Grid item xs={12}>
                  <Select
                    title="Criteria"
                    name="name"
                    onChange={(e, indx) => setActiveCategory(indx?.id)}
                    value={activeCategory}
                    options={categoryData}
                    nameIndex="id"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <MapForm
                  open={open}
                  viewport={viewport}
                  setViewport={setViewport}
                  address={address}
                  setAddress={setAddress}
                  createScenarioStore={createScenarioStore}
                  createScenarioActions={createScenarioActions}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormContainer>
      )}

      <SuccessModal
        onSubmit={createOneMore}
        textBtn="success"
        open={open}
        onClose={goTo}
        title={'Scenario successfully created'}
        name={createScenarioStore?.name}
        mode={SUCCESS_MODE}
      />
    </div>
  );
};

export default AddScenarios;
