import { Button, Container, Grid, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormContainer, InputBox, TopBar } from '../../../components/common';
import ButtonsGroup from '../../../components/common/Buttons/ButtonsGroup';
import { MultilineTextField } from '../../../components/common/Inputs';
import Select from '../../../components/common/Inputs/Select';
import MapForm from '../../../components/common/MapBox';
import { useLabels } from '../../../components/contexts/AuthContext';
import { useCustomScenarioStore } from '../../../hooks/useStoreCustomScenarioBuilder';
import { useRpcApi } from '../../../services/api';
import { changePlace } from '../../../utils/changeFieldsPlace';
import { defaultViewport, ReccurrenceList, templateFields } from '../../../utils/constants';
import useStyles from './style';

const [createTemplate] = useRpcApi('templates.saveTemplate');

const AddNewTemplate = ({ categoryData, setVisible, setTextPlatoon }) => {
  const classes = useStyles();
  const labels = useLabels();
  const [createScenarioStore, createScenarioActions] = useCustomScenarioStore(null);
  const [choosenField, setChoosenField] = useState(null);
  const [address, setAddress] = useState('');
  const [viewport, setViewport] = useState(defaultViewport);
  const [usedFileds, setUsedFields] = useState([]);
  const [customFields, setCustomFields] = useState(templateFields(labels));
  const [activeReccurence, selectReccurence] = useState(ReccurrenceList[0]);
  const [activeCategory, setActiveCategory] = useState();
  const [error, setError] = useState({
    errorNoFields: '',
    errorNoName: '',
  });
  const [templateName, setTemplateName] = useState('');

  const history = useHistory();

  const handleAddField = () => {
    if (choosenField && choosenField.name) {
      createScenarioActions.addField(choosenField.name);
      setCustomFields((prev) => [...prev.filter((el) => el.name !== choosenField.name)]);
      setUsedFields((prev) => [...prev, choosenField]);
      setChoosenField(null);
      return;
    }
    return;
  };
  const changePlaceFields = (nameField, direction) => {
    let resultArray = changePlace(createScenarioStore.customFields, nameField, direction);
    if (!resultArray) {
      return null;
    }
    createScenarioActions.addFieldsAfteJuggling(resultArray);
  };
  const handleDeleteField = (name) => {
    const deletedField = usedFileds.find((el) => el.name === name);
    if (deletedField) {
      setCustomFields((prev) => [...prev, deletedField]);
    }
    createScenarioActions.deleteField(name);
  };
  const onSubmit = async () => {
    const { customFields } = createScenarioStore;
    if (!templateName.trim()?.length) {
      setError((prev) => ({
        ...prev,
        errorNoName: 'Enter template name',
      }));
      return;
    } else {
      setError((prev) => ({
        ...prev,
        errorNoName: '',
      }));
    }
    if (customFields.length <= 0) {
      setError((prev) => ({ ...prev, errorNoFields: 'need to add fields' }));
      return;
    }

    const result = await createTemplate({
      title: templateName,
      customFields: customFields,
    });
    if (result) {
      setTextPlatoon('Template created');
      setVisible(true);
      history.goBack();
    }
  };

  return (
    <div>
      <TopBar maxWidth={744} title="Add new template" hasBackButton />
      <Container maxWidth="lg" className={classes.container}>
        <FormContainer titleBtn={'Create Template'} onClick={onSubmit} maxWidth={744}>
          <Grid container direction="column" spacing={6}>
            <Grid item xs={12}>
              <InputBox
                name="templateName"
                onInput={(e) => setTemplateName(e.target.value)}
                title="Template name"
                value={templateName}
                error={error.errorNoName}
                star
              />
            </Grid>
            <Grid container item direction="row" spacing={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Grid item xs={6}>
                <InputBox
                  name="name"
                  disabled={true}
                  placeholder={' '}
                  title={`Scenario Name (visible for ${labels.recruits})`}
                />
              </Grid>
              <Grid item xs={6}>
                <InputBox
                  name="shortCode"
                  disabled={true}
                  placeholder={' '}
                  title={`Short Code (visible for ${labels.recruits})`}
                />
              </Grid>
            </Grid>
            {createScenarioStore.customFields.length > 0 &&
              createScenarioStore.customFields.map((el) => (
                <Grid container direction="column" item xs={12} key={el.name}>
                  <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <Typography variant="h5">{el.name}</Typography>
                    <IconButton className={classes.assessmentButtonFail} onClick={() => handleDeleteField(el.name)}>
                      <ClearIcon className={classes.failIcon} />
                    </IconButton>
                    <IconButton onClick={() => changePlaceFields(el.name, 2)}>
                      <ArrowUp />
                    </IconButton>
                    <IconButton onClick={() => changePlaceFields(el.name, 1)}>
                      <ArrowDown />
                    </IconButton>
                  </div>
                  <MultilineTextField disabled name={el.name} style={{ width: '100%' }} />
                </Grid>
              ))}
            <Grid item xs={12}>
              <Typography variant="h5" style={{ paddingBottom: 15 }}>
                Select a field
              </Typography>
              <Grid container item direction="row" spacing={3}>
                <Grid item xs={9}>
                  <Select
                    name="name"
                    options={customFields}
                    value={choosenField}
                    onChange={(_, template) => {
                      setChoosenField(template);
                    }}
                    disableClearable
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button variant="contained" color="secondary" size="medium" onClick={handleAddField}>
                    Add field
                  </Button>
                </Grid>
              </Grid>
              {error.errorNoFields && <Typography style={{ color: 'red' }}>Need to add fields!!!</Typography>}
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">Evaluation</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <ButtonsGroup
                    title="Scoring type"
                    value={createScenarioStore.scoringType || activeReccurence || null}
                    selectReccurence={selectReccurence}
                    onValueChange={createScenarioActions.changeScoringType}
                    listItems={ReccurrenceList}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Select
                title="Criteria"
                name="name"
                onChange={(e, indx) => setActiveCategory(indx?.id)}
                value={activeCategory}
                options={categoryData}
                nameIndex="id"
              />
            </Grid>
            <Grid item xs={12}>
              <MapForm
                viewport={viewport}
                setViewport={setViewport}
                address={address}
                setAddress={setAddress}
                createScenarioStore={createScenarioStore}
                createScenarioActions={createScenarioActions}
              />
            </Grid>
          </Grid>
        </FormContainer>
      </Container>
    </div>
  );
};
export default AddNewTemplate;
