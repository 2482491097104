import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ROLE_ADMIN, ROLE_INSTRUCTOR } from '../../constants';
import AddCriteria from './AddCriteria';
import { useCreateCriterioStore } from './AddCriteria/components/index';
import ChangePassword from './ChangePassword';
import Criteria from './Criteria';
import CustomLabels from './CustomLabels';
import PersonalInformation from './PersonalInformation';
import useStyles from './style';

function getPathNameID(pathName = '') {
  if (pathName.includes('change-password')) return 2;
  if (pathName.includes('criteria')) return 3;
  if (pathName.includes('labels')) return 4;
  return 1;
}

const Settings = ({ user }) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const [criterioStore, criterioActions] = useCreateCriterioStore(null);
  const [visible, setVisible] = useState(false);

  const [activeId, setActiveId] = useState(1);
  useEffect(() => {
    setActiveId(getPathNameID(pathname));
  }, [pathname]);

  return (
    <div className={classes.wrapperWidth}>
      <div className={classes.nav}>
        <Typography variant="h4">Settings</Typography>

        <Button
          className={clsx(classes.btn, activeId === 1 && classes.activeBtn)}
          component={Link}
          to="/settings/personal-information"
        >
          Personal Information
        </Button>
        <Button
          className={clsx(classes.btn, activeId === 2 && classes.activeBtn)}
          component={Link}
          to="/settings/change-password"
        >
          Change Password
        </Button>
        {[ROLE_ADMIN, ROLE_INSTRUCTOR].includes(user.roleId) && (
          <Button
            className={clsx(classes.btn, activeId === 3 && classes.activeBtn)}
            component={Link}
            to="/settings/criteria"
          >
            Rubric Set Up
          </Button>
        )}
        {user.roleId === ROLE_ADMIN && (
          <Button
            className={clsx(classes.btn, activeId === 4 && classes.activeBtn)}
            component={Link}
            to="/settings/labels"
          >
            Custom Labels
          </Button>
        )}
      </div>

      <div className={classes.content}>
        <Switch>
          <Route exact path="/settings/personal-information">
            <PersonalInformation />
          </Route>
          <Route exact path="/settings/change-password">
            <ChangePassword />
          </Route>
          {[ROLE_ADMIN, ROLE_INSTRUCTOR].includes(user.roleId) && (
            <>
              <Route exact path="/settings/add-criteria">
                <AddCriteria store={criterioStore} actions={criterioActions} setVisible={setVisible} />
              </Route>
              <Route exact path="/settings/edit-criteria/:id">
                <AddCriteria store={criterioStore} actions={criterioActions} setVisible={setVisible} />
              </Route>
              <Route exact path="/settings/criteria">
                <Criteria visible={visible} setVisible={setVisible} store={criterioStore} actions={criterioActions} />
              </Route>
              {user.roleId === ROLE_ADMIN && (
                <Route exact path="/settings/labels">
                  <CustomLabels />
                </Route>
              )}
            </>
          )}
          <Redirect exact path="/*" to="/settings/personal-information" />
        </Switch>
      </div>
    </div>
  );
};

export default Settings;

//comment is only change to test dev version redeployment, test 7
