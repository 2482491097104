import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IconSortArrow } from '../../svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 700,
    overflowX: 'auto',
    overflowY: 'hidden',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerBg: {
    width: '100%',
    padding: '0 20px',
  },
  container: {
    padding: '0 18px',
    margin: '20px auto',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    '& > div': {
      padding: '12px 0',
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  head: {
    minHeight: 40,
    padding: '0 20px',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.08)',
    '& > div': {
      padding: '0 18px',
    },
  },
  cell: {
    display: 'flex',
    padding: '0 4px',
    '& h6': {
      textAlign: 'start',
    },
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyLeft: {
    justifyContent: 'flex-start',
  },
  justifyRight: {
    justifyContent: 'flex-end',
  },

  aroowWidth: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '7px',
    '& > button': {
      padding: 0,
    },
  },

  titleBox: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const GridTable = (props) => {
  const classes = useStyles();
  const [sort, setSort] = useState('asc');
  const { maxWidth, columns, rows, onSort = () => true, role } = props;

  const handleSort = (id, isSort) => {
    if (isSort) {
      onSort(sort, id);
      if (sort === 'asc') setSort('desc');
      else setSort('asc');
    }
  };

  return (
    <div className={classes.root}>
      <Grid container justify="center" className={classes.head}>
        <Grid container alignItems="center" style={{ maxWidth: maxWidth }}>
          {columns.map((col) => (
            <Grid
              key={col.id}
              container
              item
              xs={col.xs}
              className={clsx(classes.cell, {
                [classes.justifyCenter]: col.align === 'center',
                [classes.justifyLeft]: col.align === 'left',
                [classes.justifyRight]: col.align === 'right',
              })}
            >
              <div
                onClick={() => handleSort(col.id, col.isSort || col.sortRole?.includes(role))}
                className={classes.titleBox}
                style={col?.isSort || col.sortRole?.includes(role) ? { cursor: 'pointer' } : null}
              >
                <Typography variant="subtitle2" align={col.align}>
                  {col.title}
                </Typography>
                {(col.isSort || col?.sortRole) &&
                  (!col?.sortRole || (col?.sortRole && col?.sortRole?.includes(role)) ? (
                    <div className={classes.aroowWidth}>
                      <IconButton onClick={() => handleSort(col.id)} size="small">
                        <IconSortArrow up />
                      </IconButton>
                      <IconButton onClick={() => handleSort(col.id)} size="small">
                        <IconSortArrow />
                      </IconButton>
                    </div>
                  ) : null)}
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <div className={classes.containerBg}>
        <div className={classes.container} style={{ maxWidth: maxWidth }}>
          {rows.map((row, index) => (
            <Grid key={`row-${index}`} container alignItems="center" spacing={1}>
              {row.map((column, index) => (
                <Grid
                  key={`col-${index}`}
                  item
                  wrap="nowrap"
                  xs={columns[index].xs}
                  align={columns[index].align}
                  alignItems="center"
                  container
                  className={clsx(classes.cell, {
                    [classes.justifyCenter]: columns[index].align === 'center',
                    [classes.justifyLeft]: columns[index].align === 'left',
                    [classes.justifyRight]: columns[index].align === 'right',
                  })}
                >
                  {column}
                </Grid>
              ))}
            </Grid>
          ))}
        </div>
      </div>
    </div>
  );
};

GridTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      align: PropTypes.oneOf(['left', 'center', 'right']),
      xs: PropTypes.number,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.element)).isRequired,
  maxWidth: PropTypes.number,
};

export default GridTable;
