import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRpcApi } from 'services/api';
import { Typography } from '@material-ui/core';
import { GridTable, ActionButtons } from 'components/common';
import RemoveModal from 'components/common/Dialogs/RemoveModal';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import useStyles from './style';
import { TopBar } from 'components/common';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

const columns = [
  { id: 'name', title: 'Name', filterField: 'Name', align: 'left', xs: 2, isSort: true },
  { id: 'Address', title: 'Address', filterField: 'Address', align: 'left', xs: 2 },
  { id: 'createdAt', title: 'Date', filterField: 'createdAt', align: 'left', xs: 3, isSort: true },
  { id: 'visibleScenario', title: 'Status', align: 'left', xs: 2, isSort: true },
  { id: 'Actions', title: 'Actions', align: 'right', xs: 2 },
];

const [removeScenario, updateVisibleScenario] = useRpcApi(
  'scenario.deleteScenario',
  'scenario.updateVisibleScenario',
  'scenario.getScenario'
);

const BodyScenarios = ({
  scenariosData,
  getData,
  limit,
  onChangeOffset,
  setValue,
  value,
  setSort,
  setNameSort,
  loadingList,
}) => {
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(false);
  const [selectUser, setSelectUser] = useState();
  const [dataSource, setDataSource] = useState([]);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (scenariosData?.length) {
      setDataSource(scenariosData);
    }
  }, [scenariosData]);

  const onChangeSearch = (e) => setValue(e.target.value);

  const onChange = (row) => {
    updateVisibleScenario({ id: row.id, visibleScenario: !row.visibleScenario }).then(() => getData());
  };

  const onChangeOpen = (id) => {
    setSelectUser(scenariosData.find((item) => item.id === id));
    setUserId(id);
    setOpen(!open);
  };

  const onDelete = () => {
    removeScenario({ id: userId })
      .then(() => getData())
      .catch((e) => console.error(e))
      .finally(() => onChangeOpen());
  };

  const onClickSort = (flag, name) => {
    setSort(flag);
    setNameSort(name);
  };

  const getRows = () =>
    dataSource?.map((row) => [
      <Typography
        style={{ width: 152 }}
        onClick={() => history.push(`/scenarios/details/${row?.id}`)}
        className={classes.typography}
        variant="body2"
        key={row?.id}
      >
        {row.name}
      </Typography>,
      <Typography style={{ width: 152 }} variant="body2" key={row?.id}>
        <div className={classes.location}>
          {<RoomOutlinedIcon />} {row.locationName ? row.locationName : '--------'}
        </div>
      </Typography>,
      <Typography style={{ width: 228 }} variant="body2" key={row?.id}>
        {moment(row.createdAt).format('YYYY-MM-DD')}
      </Typography>,
      <Typography style={{ width: 152 }} variant="body2" key={row?.id + ' Active'}>
        <div className={classes.status}>
          <div className={row.visibleScenario ? classes.disable : classes.active} />
          {row.visibleScenario ? 'Active' : 'Retired'}
        </div>
      </Typography>,
      <ActionButtons
        style={{ width: 152 }}
        visible={row.visibleScenario}
        visibleIcon={() => onChange(row)}
        actions={['visible', 'edit', 'delete']}
        onEdit={() => history.push(`/scenarios/edit-scenarios/${row?.id}`)}
        onDelete={() => onChangeOpen(row.id)}
        key={row?.id + ' Buttons'}
      />,
    ]);

  return (
    <div className={classes.container}>
      <RemoveModal
        onClick={onDelete}
        open={open}
        onClose={onChangeOpen}
        title="Delete this scenario?"
        name={selectUser?.name}
        subText={selectUser?.locationName}
      />
      <TopBar
        onChangeSearch={onChangeSearch}
        title="Scenarios"
        templatesTitle="Scenario Templates"
        goToTemplates={() => history.push('/templates')}
        btnTitle="Add New Scenario"
        onButton={() => history.push('/scenarios/new-scenarios')}
        count={limit}
        onChangePage={onChangeOffset}
        value={value}
      />

      {loadingList ? (
        <div className={classes.widthNotRecruit}>
          <CircularProgress />
        </div>
      ) : getRows().length ? (
        <GridTable onSort={onClickSort} columns={columns} rows={getRows()} sortTab />
      ) : (
        <div className={classes.widthNotRecruit}>
          <p>
            There are no scenarios in the table.{' '}
            <span onClick={() => history.push('/scenarios/new-scenarios')} className={classes.titleAdd}>
              Add new scenario!
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default BodyScenarios;
