/* eslint-disable no-use-before-define */
import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inputTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
  star: {
    color: 'red',
  },
  root: {
    backgroundColor: '#fff',
    height: 52,
    '& .MuiFormControl-root': {
      height: '52px',
    },
    '& .MuiInputBase-root': {
      height: 52,
      border: null,
    },
    '& .MuiFormHelperText-contained': {
      color: 'red',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#c6d0e0',
    },
  },
}));

const Link = ({ children }) => <Paper style={{ width: '100%' }}>{children}</Paper>;

const SelectAutocomplete = ({
  options,
  setValue,
  name,
  defaulValue,
  id,
  title,
  onChange,
  error,
  star = false,
  labelKey = 'title',
  ...rest
}) => {
  const classes = useStyles();

  const items = options?.sort((a, b) => (a[labelKey]?.toLowerCase() > b[labelKey]?.toLowerCase() ? 1 : -1));

  const getItemById = () => {
    for (const i in items) {
      if (items[i].id === id) return items[i];
    }
    return star ? '' : 'None';
  };

  return (
    <>
      <h4 className={classes.inputTitle}>
        {title ? title : 'Police Service'} {star ? <span className={classes.star}>*</span> : null}
      </h4>
      <Autocomplete
        id={name}
        value={getItemById()}
        onChange={onChange ? onChange : (i, id) => setValue({ ...defaulValue, [name]: id?.id })}
        className={classes.root}
        options={items}
        PaperComponent={Link}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option[labelKey])}
        {...rest}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ margin: 0, padding: 0 }}
            variant="outlined"
            className={classes.root}
            helperText={error ? `* ${error}` : null}
          />
        )}
      />
    </>
  );
};

export default SelectAutocomplete;
