import { Avatar, Chip, Paper } from '@material-ui/core';
import React from 'react';
import useStyles from '../style';
import { useHistory } from 'react-router-dom';

const RecruitsList = ({ choosenRecruts }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Paper>
      <div className={classes.recruitsList}>
        {choosenRecruts.map((el) => (
          <Chip
            key={el.id}
            className={classes.recrutListItem}
            onClick={() => history.push(`/recruits/recruit-details/${el?.recruit?.id}`)}
            avatar={
              el?.recruit?.avatar ? (
                <Avatar src={el?.recruit?.avatar} />
              ) : (
                <Avatar>{el?.recruit?.firstName?.charAt(0) + el?.recruit?.lastName?.charAt(0)}</Avatar>
              )
            }
            label={`${el?.recruit?.firstName} ${el?.recruit?.lastName}`}
          />
        ))}
      </div>
    </Paper>
  );
};

export default RecruitsList;
