import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapperWidth: {
    flex: 1,
    overflow: 'auto',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'grid',
    height: '100%',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridAutoRows: 'minmax(100px, auto)',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
}));

export default useStyles;
