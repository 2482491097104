import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import SuccessModal from 'components/common/Dialogs/SuccessModal';
import InputBox from 'components/common/Inputs/InputBox/InputBox';
import { IconTrash } from 'components/svg/Icons/IconTrash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRpcApi } from 'services/api';
import { defaulValue, setCorrectObject } from './components/index';
import useStyles from './style';

const [createCriterio, getCriterio, updateCriterio] = useRpcApi(
  'criteria.createCriterio',
  'criteria.getCriterio',
  'criteria.updateCriterio'
);

const AddCriteria = ({ store, actions, setVisible }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { criterios = [] } = store;
  const { id } = useParams();

  const goTo = () => history.push('/settings/criteria');

  const getCriterioById = () => {
    getCriterio({ id })
      .then((res) => actions.setCriterio(setCorrectObject(res)))
      .catch((e) => console.error(e));
  };

  const onSubmit = () => {
    setLoading(true);
    const correctObject = setCorrectObject({ ...store, criterios: JSON.stringify(store?.criterios) });
    if (!correctObject?.name?.trim()?.length) {
      setError(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoading(false);
      return;
    }
    if (id) {
      updateCriterio({ ...correctObject, id })
        .then(() => {
          setVisible(true);
          goTo();
          setLoading(false);
          actions.setCriterio(defaulValue);
        })
        .catch((e) => console.log(e?.message));
    } else {
      createCriterio(correctObject)
        .then(() => {
          setOpen(true);
          setLoading(false);
        })
        .catch((e) => console.log(e?.message));
    }
  };

  const createOneMore = () => {
    setOpen((prev) => !prev);
    actions.setCriterio(defaulValue);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onClose = () => {
    goTo();
    actions.setCriterio(defaulValue);
  };

  useEffect(() => {
    if (id) getCriterioById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container direction="column" className={classes.wrapper}>
      <Grid container item spacing={3} className={classes.container}>
        <div className={classes.inputBlock}>
          <Grid item xs={12}>
            <Typography variant="h5" align="left">
              Rubric Title
            </Typography>
            <InputBox
              onInput={(e) => {
                setError(false);
                actions.changeName(e.target.value);
              }}
              value={store?.name}
            />
            {error && <span className={classes.error}>*Rubric title is required</span>}
          </Grid>
          {criterios.map(({ number, name }, ind) => (
            <Grid key={ind} item xs={7}>
              <div className={classes.criterios}>
                <InputBox
                  title={`Criteria ${++ind}`}
                  className={classes.input}
                  onInput={(e) => actions.writeCriteria(number, e?.target?.value)}
                  value={name}
                />
                {ind > 1 && (
                  <IconButton
                    color="primary"
                    className={classes.iconBtn}
                    onClick={() => actions.removeCriteria(number)}
                  >
                    <IconTrash />
                  </IconButton>
                )}
              </div>
            </Grid>
          ))}
          <Grid item xs={7}>
            <Button onClick={actions.addCriteria} className={classes.btn}>
              Add Criteria
            </Button>
          </Grid>
        </div>
        <div className={classes.footer}>
          <Button
            disabled={loading}
            onClick={onSubmit}
            size="large"
            color="secondary"
            variant="contained"
            className={classes.footerBtn}
          >
            {id ? 'Save' : 'Create Rubric'}
          </Button>
        </div>
      </Grid>
      <SuccessModal
        onSubmit={createOneMore}
        textBtn="success"
        open={open}
        onClose={onClose}
        title={'Rubric successfully created'}
        name={store?.name}
        mode={'SUCCESS_MODE'}
      />
    </Grid>
  );
};

export default AddCriteria;
