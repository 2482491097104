import { useTheme } from '@material-ui/styles';
import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconAlertSuccess = (props) => {
  const theme = useTheme();
  const { color = theme.palette.icons.primary, width = 32, height = 32 } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <path
            d="M40 20c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-8c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h8zm0 2h-8c-5.43 0-9.848 4.327-9.996 9.72L22 32v8c0 5.43 4.327 9.848 9.72 9.996L32 50h8c5.43 0 9.848-4.327 9.996-9.72L50 40v-8c0-5.43-4.327-9.848-9.72-9.996L40 22zm2.718 9.304c.355.366.374.933.063 1.321l-.085.093-8.25 8c-.358.347-.91.374-1.299.08l-.093-.08-3.75-3.636c-.397-.385-.406-1.018-.022-1.415.355-.365.922-.402 1.319-.103l.095.082 3.054 2.961 7.554-7.325c.396-.384 1.03-.375 1.414.022z"
            transform="translate(-508 -50) translate(488 30)"
          />
        </g>
      </g>
    </svg>
  );
};

IconAlertSuccess.propTypes = simpleIconPropsTypes;
