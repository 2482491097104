import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5px 5px 0',
    border: `1px solid ${theme.palette.grey['A100']}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    overflow: 'hidden',
  },
  closeBtn: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  dayText: {
    marginTop: 5,
    marginBottom: 6,
    color: theme.palette.grey[700],
  },
  cellInactiveContainer: {
    backgroundColor: theme.palette.grey[50],
    '& > div > p': {
      color: theme.palette.grey['A200'],
    },
  },
  cellActiveDay: {
    width: 24,
    height: 24,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  moreBox: {
    '& button': {
      padding: '0 5px',
    },
    '& p': {
      fontSize: '0.75rem',
    },
  },
  viewMore: {
    minWidth: 200,
    padding: '20px 24px',
    '& > h4': {
      marginBottom: 10,
    },
  },
}));

export default useStyles;
