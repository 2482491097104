import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapperWidth: {
    flex: 1,
    overflow: 'auto',
  },
  widthNotRecruit: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '60px',
  },
  titleAdd: {
    color: 'blue',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  char: {
    margin: '0 0 10px 53px',
  },
  item: {
    margin: '0 -15px',
    paddingBottom: 20,
  },
  textBox: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    paddingBottom: 10,
  },
  avatarText: {
    backgroundColor: '#3482ff',
    fontSize: '17px',
  },
}));

export default useStyles;
