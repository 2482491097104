import { Avatar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ActionButtons, GridTable, TopBar } from '../../../components/common';
import SecurityIcon from '@material-ui/icons/Security';
import RemoveModal from 'components/common/Dialogs/RemoveModal';
import { ROLE_SUPER_ADMIN } from '../../../constants';
import { useRpcApi } from '../../../services/api';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import { columns } from '../constants';
import useStyles from '../style';
import CircularProgress from '@material-ui/core/CircularProgress';

const [deleteUser] = useRpcApi('admin.deleteAdmin');

const Table = ({
  limit,
  getUsers,
  users: data,
  onChangeOffset,
  setValueSearch,
  setSort,
  setNameSort,
  visible,
  setVisible,
  loadingList,
  activeUser,
}) => {
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState('');
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    setUsers(data);
  }, [data]);

  useEffect(() => {
    setValueSearch(value);
  }, [setValueSearch, value]);

  const onChangeSearch = (e) => setValue(e.target.value);

  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(false);
  const [selectUser, setSelectUser] = useState();
  const onChangeOpen = (id) => {
    setSelectUser(users.find((item) => item.id === id));
    setUserId(id);
    setOpen(!open);
  };
  const onDelete = () => {
    deleteUser({ id: userId }).then(() => {
      getUsers().then(() => onChangeOpen());
    });
  };

  const addMask = (number) => {
    if (!number) return;
    if (number.includes('(')) return number;
    number = number.replace('+', '');
    return [' (', number.slice(0, 3), ') ', number.slice(3, 6), ' - ', number.slice(6, 10)].join('');
  };

  const onClickSort = (flag, name) => {
    setSort(flag);
    setNameSort(name);
  };

  const rows = users?.map((row) => [
    <>
      {<Avatar src={row?.avatar} alt="Avatar" />}
      <Typography variant="subtitle2" align={row.align}>
        {`${row.firstName} ${row.lastName}`}
      </Typography>
    </>,
    <Typography variant="subtitle2" align={row.align}>
      {row.organizationInfo?.name}
    </Typography>,
    <Typography variant="subtitle2" align={row.align} style={{ wordBreak: 'break-all' }}>
      {row.email}
    </Typography>,
    <Typography variant="subtitle2" align={row.align}>
      {addMask(row.phoneNumber)}
    </Typography>,
    <Typography variant="subtitle2" align={row.align}>
      {row.roleId === ROLE_SUPER_ADMIN ? <SecurityIcon /> : null}
    </Typography>,
    <ActionButtons
      actions={['edit', 'delete']}
      onEdit={() => history.push(`/admins/edit-admin/${row.id}`)}
      onDelete={() => onChangeOpen(row.id)}
    />,
  ]);

  return (
    <>
      <SuccessNotification open={visible} text="Admin" setOpen={setVisible} />

      <TopBar
        title="Admins"
        onChangeSearch={onChangeSearch}
        count={limit}
        btnTitle={!visible && 'Add New Admin'}
        onButton={() => history.push('/admins/new-admin')}
        onChangePage={onChangeOffset}
      />

      {loadingList ? (
        <div className={classes.widthNotRecruit}>
          <CircularProgress />
        </div>
      ) : rows.length ? (
        <GridTable onSort={onClickSort} columns={columns} rows={rows} role={activeUser.roleId} />
      ) : (
        <div className={classes.widthNotRecruit}>
          <p>
            There are no admins in the table.{' '}
            <span onClick={() => history.push('/admins/new-admin')} className={classes.titleAdd}>
              Add New Admin!
            </span>
          </p>
        </div>
      )}

      <RemoveModal
        open={open}
        onClose={onChangeOpen}
        title="Delete this admin?"
        name={`${selectUser?.firstName} ${selectUser?.lastName}`}
        onClick={onDelete}
        subText={selectUser?.email}
      />
    </>
  );
};

export default Table;
