import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRpcApi } from 'services/api';
import { IconTrash } from 'components/svg/Icons/IconTrash';
import { IconEdit } from 'components/svg/Icons/IconEdit';
import { Button, Typography, IconButton, Paper } from '@material-ui/core';
import { RemoveModal } from 'components/common/Dialogs';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import useStyles from './style';
import { defaulValue } from '../AddCriteria/components';
import { jsonParse } from '../../../utils/helpers';

const [getCriteriaList, deleteCriterio] = useRpcApi('criteria.getCriteriaList', 'criteria.deleteCriterio');

const Criteria = ({ visible, setVisible, actions }) => {
  const [categoryData, setCategoryData] = useState();
  const [criterios, setCriterios] = useState([]);
  const [upDownArrow, setUpDownArrow] = useState([]);
  const [openRemove, setOpenRemove] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  const goTo = (id) => history.push(`/settings/edit-criteria/${id}`);

  const openRemoveModal = (i) => {
    setCategoryData(i);
    setOpenRemove((prev) => !prev);
  };

  const getCriteriosList = useCallback(() => {
    return getCriteriaList({
      order: Object.entries({ updatedAt: 'desc' }),
    })
      .then((res) => setCriterios(res?.items))
      .catch((e) => console.error(e));
  }, []);

  const onDelete = () => {
    deleteCriterio({ id: categoryData?.id })
      .then(() => getCriteriosList())
      .catch((e) => console.error(e))
      .finally(() => {
        setCategoryData({});
        setOpenRemove((prev) => !prev);
      });
  };

  const onClose = () => {
    setOpenRemove((prev) => !prev);
    setCategoryData({});
  };

  useEffect(() => {
    actions.setCriterio(defaulValue);
    getCriteriosList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCriteriosList]);

  return (
    <div className={classes.wrapper}>
      <SuccessNotification open={visible} setOpen={setVisible} text="Rubric" />
      <RemoveModal
        onClick={onDelete}
        open={openRemove}
        onClose={onClose}
        title="Delete this Rubric?"
        name={categoryData?.name}
        noAvatar
      />
      <div className={classes.header}>
        <Typography variant="h4">Rubric</Typography>
        <Button
          onClick={() => history.push('/settings/add-criteria')}
          className={classes.btn}
          size="small"
          color="secondary"
          variant="contained"
        >
          Add Rubric
        </Button>
      </div>
      <div className={classes.table}>
        {criterios?.map((i, ind) => (
          <Paper key={ind} className={classes.rowTable}>
            <div className={classes.titleCategory}>
              <Typography>
                {++ind}. {i?.name}
              </Typography>
              <div className={classes.iconTable}>
                {upDownArrow.includes(i?.id) ? (
                  <IconButton onClick={() => setUpDownArrow(upDownArrow.filter((j) => j !== i?.id))} color="primary">
                    <ArrowUp />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setUpDownArrow([...upDownArrow, i?.id])} color="primary">
                    <ArrowDown />
                  </IconButton>
                )}
                <IconButton onClick={() => goTo(i?.id)} color="primary">
                  <IconEdit />
                </IconButton>
                <IconButton onClick={() => openRemoveModal(i)} color="primary">
                  <IconTrash />
                </IconButton>
              </div>
            </div>
            {upDownArrow.includes(i?.id) &&
              jsonParse(i?.criterios, [])?.map((j, ind) => (
                <div className={classes.childCategory} key={ind}>
                  <p className={classes.textChild}>
                    {j?.name || <span className={classes.emptyText}>Сriteria is empty</span>}
                  </p>
                </div>
              ))}
          </Paper>
        ))}
      </div>
    </div>
  );
};

export default Criteria;
