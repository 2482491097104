import React, { useState } from 'react';
import { InputAdornment, IconButton, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';
import useStyles from './style';

export const BasicTextField = ({ customClass, type, ...props }) => {
  const [visible, setVisible] = useState(false);
  const s = useStyles();

  const handleClickShowPassword = () => setVisible(!visible);

  const handleMouseDownPassword = (e) => e.preventDefault();

  return (
    <OutlinedInput
      {...props}
      id="outlined-adornment-password"
      className={clsx(s.root, customClass)}
      variant="outlined"
      type={type ? (visible ? 'text' : 'password') : 'text'}
      endAdornment={
        type === 'password' && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};
