import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Table from './Table';
import NewOrganization from './NewOrganization';
import { useRpcApi } from '../../services/api';
import useStyles from './style';
import debounce from '../../utils/debounce';

const LIMIT = 10;

const [getOrganizationsList] = useRpcApi('organizations.getOrganizationsList');

const Organizations = () => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(0);
  const [offset, setOffset] = useState(0);
  const [visible, setVisible] = useState(false);
  const [nameSotr, setNameSort] = useState('name');
  const [sort, setSort] = useState('asc');
  const [loadingList, setLoadingList] = useState(true);
  const [valueSearch, setValueSearch] = useState('');
  const { pathname } = useLocation();
  const classes = useStyles();

  const getOrganizations = useCallback(async () => {
    setLoadingList(true);
    const response = await getOrganizationsList({
      limit: LIMIT,
      offset: offset * LIMIT,
      order: Object.entries({ [nameSotr]: sort }),
      orFilter: Object.entries({
        name: { $like: `%${valueSearch.trim()}%` },
        address: { $like: `%${valueSearch.trim()}%` },
      }),
    });
    setUsers(
      response.items.map((i) => ({
        ...i,
      }))
    );
    setLimit(response?.total);
    setLoadingList(false);
  }, [nameSotr, offset, sort, valueSearch]);

  const forceSearch = useCallback(
    debounce(() => {
      getOrganizations();
    }, 1000),
    [getOrganizations, valueSearch, offset, sort, nameSotr]
  );

  useEffect(() => {
    forceSearch();
  }, [forceSearch, valueSearch, offset, sort, nameSotr]);

  const id = window.location.pathname.split('/')[3];

  const attr = {
    onChangeOffset: (num) => setOffset(num),
    getOrganizations,
    limit,
    users,
    setValueSearch,
    visible,
    setVisible,
  };

  const obj = {
    '/organizations': () => (
      <Table
        setSort={setSort}
        loadingList={loadingList}
        getOrganizations={getOrganizations}
        setNameSort={setNameSort}
        {...attr}
      />
    ),
    '/organizations/new-organization': () => <NewOrganization getOrganizations={getOrganizations} />,
    [`/organizations/edit-organization/${id}`]: () => (
      <NewOrganization getOrganizations={getOrganizations} visible={visible} setVisible={setVisible} />
    ),
  };

  const content = obj[pathname] || obj['/organizations'];

  return <div className={classes.wrapperWidth}>{content()}</div>;
};

export default Organizations;
