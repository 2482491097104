import colors from '../common/colors';
import MuiCssBaseline from './MuiCssBaseline';

const overrides = {
  MuiCssBaseline: MuiCssBaseline,
  MuiButton: {
    root: {
      padding: '10px 20px 10px',
      borderRadius: 4,
      boxShadow: 'unset !important',
    },
    outlined: {
      padding: '10px 15px 10px',
    },
    outlinedSizeSmall: {
      minWidth: 0,
    },
    textSizeLarge: {
      fontSize: '1rem',
    },
    outlinedSizeLarge: {
      padding: '15px 29px 15px !important',
    },
    sizeLarge: {
      padding: '16px 30px 16px',
    },
  },
  MuiAvatar: {
    root: {
      width: 44,
      height: 44,
      marginRight: 12,
    },
  },
  MuiCheckbox: {
    root: {
      color: null,
    },
  },
  MuiBreadcrumbs: {
    root: {
      fontSize: '0.875rem',
      color: colors.dark.text.notActive,
    },
    separator: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  MuiIconButton: {
    colorPrimary: {
      color: colors.dark.text.secondary,
    },
  },
  MuiInput: {
    root: {
      height: '100%',
    },
  },
  MuiFilledInput: {
    root: {
      backgroundColor: colors.dark.background.default,
      color: colors.dark.grey[500],
      borderRadius: 4,
      borderTopRightRadius: 4,
      borderTopLeftRadius: 4,
    },
    input: {
      padding: '10px 15px 8px',
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 4,
      backgroundColor: colors.white,
    },
    notchedOutline: {
      borderColor: colors.dark.grey['A400'],
      '&: hover': {
        borderColor: colors.dark.grey[600],
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: 4,
      backgroundColor: colors.dark.grey['A100'],
    },
    label: {
      fontSize: '0.9375rem',
    },
  },
  MuiAutocomplete: {
    root: {
      borderRadius: 4,
    },
    popper: {
      '& > div': {
        width: '50%',
        float: 'right',
      },
    },
    input: {
      padding: '17px 14px',
    },
  },

  MuiPaper: {
    root: {
      '&.gray': {
        backgroundColor: colors.dark.grey[100],
      },
    },
  },

  MuiToggleButtonGroup: {
    root: {
      backgroundColor: colors.white,
      borderRadius: 4,
      border: `1px solid ${colors.dark.grey.A400}`,
      '& > hr': {
        backgroundColor: colors.dark.grey.A400,
      },
    },
    groupedHorizontal: {
      '&:not(:last-child)': {
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
      },
      '&:not(:first-child)': {
        border: 'none',
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
        marginLeft: undefined,
      },
    },
  },
  MuiToggleButton: {
    root: {
      margin: 5,
      border: 'none',
      borderRadius: 2,
      color: colors.primary.main,
      padding: 10,
      '&$selected': {
        backgroundColor: colors.primary.light,
        color: colors.white,
        '&:hover': { backgroundColor: colors.primary.light },
      },
    },
    sizeSmall: {
      padding: 3,
      borderRadius: 4,
    },
    sizeLarge: {
      padding: 14,
      minWidth: 52,
    },
  },
};

export default overrides;
