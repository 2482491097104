export const ROUTE_USERS_MANAGEMENT = {
  key: 'users',
  path: '/users',
  i18nKey: 'User Management',
  icon: 'IconUser',
  totalApi: 'user.getTotalUsers',
};

export const ROUTE_USERS_ROLES = {
  key: 'roles',
  path: `${ROUTE_USERS_MANAGEMENT.path}/roles`,
  i18nKey: 'Roles',
  icon: 'IconRoles',
  totalApi: 'user.getTotalRoles',
};
export const USER_STATUS_ENABLED = 'ENABLED';
export const USER_STATUS_DISABLED = 'DISABLED';
export const USER_STATUSES = [USER_STATUS_ENABLED, USER_STATUS_DISABLED];
