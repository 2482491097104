import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formTitle: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#000000',
    marginBottom: '20px',
  },
  root: {
    margin: '0',
    height: '52px',
    width: '298px',
    borderRadius: '3px',
    border: 'solid 1px #c6d0e0',
    backgroundColor: '#ffffff',
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
    '& .MuiOutlinedInput-input': {
      padding: '17px 0 17px 20px',
    },
    '& .MuiInputBase-formControl input': {
      border: 0,
      backgroundColor: 'white',
      margin: 0,
      padding: '17px 0 17px 20px',
      fontSize: '14px',
      color: '#333333',
    },
  },
  mapBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputTitle: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#7080a1',
    margin: '25px 0 10px 0 ',
  },
  customTextArea: {
    backgroundColor: 'red',
  },
  customBtn: {
    backgroundColor: 'red',
  },
  locationWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '50px 0',
  },
  map: {
    marginTop: '78px',
    maxWidth: '300px',
    height: '255px',
    flex: 1,
  },
  custom: {
    margin: '0',
    width: '140px',
    borderRadius: '3px',
    border: 'solid 1px #c6d0e0',
    backgroundColor: '#ffffff',
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
    '& .makeStyles-root-39 input': {
      border: 0,
      backgroundColor: 'white',
      margin: 0,
      padding: '17px 0 17px 20px',
    },
  },
  mapContainer: {
    width: '100%',
    height: '100%',
  },
  searchGeocoder: {
    height: '52px',
    width: '298px',
    margin: '10px 0 20px 0',
    '& .mapboxgl-ctrl-geocoder--input': {
      paddingLeft: '20px',
      fontSize: '16px',
      borderRadius: '3px',
      border: 'solid 1px #c6d0e0',
      backgroundColor: '#ffffff',
      height: '52px',
      color: '#000',
      '&:hover': {
        borderColor: '#000',
      },
      '&:focus': {
        border: `2px solid ${theme.palette.primary.main}`,
        outline: 'none',
      },
    },
    '& .mapboxgl-ctrl-geocoder--icon': {
      display: 'none',
    },
    '& .mapboxgl-ctrl-geocoder': {
      width: '100%',
    },
    '& .mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions': {
      boxShadow: 'none',
    },
    '& .suggestions': {
      overflow: 'auto',
      height: '150px',
      borderRadius: '3px',
      border: 'solid 1px #c6d0e0',
    },
  },
}));

export default useStyles;
