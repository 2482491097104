import { Typography } from '@material-ui/core';
import React from 'react';
import { IconUpload } from '../../../../components/svg/forms/IconUpload';
import useStyles from '../style';

const LabelInsideInput = ({ text, label }) => {
  const classes = useStyles();
  return (
    <div className={classes.lableDownload}>
      <div className={classes.iconWrapper}>
        <IconUpload width={60} height={60} color="grey" />
      </div>
      <Typography style={{ color: '#000', paddingTop: 5 }}>{text}</Typography>
      <label htmlFor={label} className={classes.buttonInput}>
        <Typography style={{ color: '#000' }}>Select files</Typography>
      </label>
    </div>
  );
};

export default LabelInsideInput;
