import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 0,
    overflow: 'hidden',
    borderRadius: 16,
    paddingLeft: 0,
    '&$focused': {
      borderColor: theme.palette.primary.main,
      '& input': {
        border: '2px solid',
        borderColor: theme.palette.background.input,
      },
    },
    '& .MuiIconButton-edgeEnd': {
      position: 'absolute',
      right: 20,
    },
    '& .MuiIconButton-edgeStart': {
      position: 'absolute',
      left: 20,
    },
    '& input': {
      padding: '26px 50px 12px 12px',
      paddingLeft: 28,
      borderRadius: 16,
      height: 23,
      border: '2px solid',
      color: theme.palette.primary.inputText,
      backgroundColor: theme.palette.background.input,
      borderColor: theme.palette.background.input,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
      '&:active': {
        borderColor: theme.palette.primary.main,
      },
      '&$focused': {
        borderColor: theme.palette.primary.main,
      },
      '&$focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  margin: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
    '& .MuiInputAdornment-positionStart': {
      position: 'absolute',
      left: 5,
      width: 24,
      height: 24,
      top: '50%',
      transform: 'translate(0, -50%)',
      marginTop: '0 !important',
    },
    '& .Mui-focused': {
      '& input': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.error.main,
      maxWidth: 320,
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
      transform: 'translate(28px, 21px) scale(0.75)',
    },
    '& label.MuiInputLabel-shrink': {
      color: theme.palette.primary.inputLabelFilled,
      transform: 'translate(30px, 14px) scale(0.75)',
    },
    '& label': {
      color: theme.palette.primary.inputLabel,
      transform: 'translate(28px, 23px) scale(1)',
    },
    '& .MuiInput-underline:after': {
      color: theme.palette.primary.main,
    },
  },
}));

export const TextFieldWithIcon = (props) => {
  const classes = useStyles();
  const { InputProps, errorText, helperText, error, label, ...rest } = props;
  return (
    <TextField
      InputProps={{ classes: { root: classes.root }, shrink: 'false', disableUnderline: true, ...InputProps }}
      className={classes.margin}
      variant="filled"
      error={!!errorText || !!error}
      label={errorText || label || ''}
      InputLabelProps={!!errorText ? { shrink: true, color: 'secondary' } : { label: null }}
      {...rest}
      helperText={helperText}
      value={rest.value || ''}
    />
  );
};
