export const defaultValue = {
  name: '',
  address: '',
  type: 'Service',
  phone: '',
  image: null,
};

export const errors = {
  name: '',
  address: '',
  type: '',
  phone: '',
  image: '',
};

export const columns = [
  { id: 'name', title: 'Name', align: 'left', xs: 4, isSort: true },
  { id: 'address', title: 'Address', align: 'left', xs: 4, isSort: true },
  { id: 'type', title: 'Type', align: 'left', xs: 3, isSort: true },
  { id: 'actions', title: 'Actions', align: 'right', xs: 1 },
];
