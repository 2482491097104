import { Button, Container, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconEdit } from 'components/svg/Icons/IconEdit';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SearchBar } from '..';
import Pagination from '../Pagination/';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: '0 12px',
      backgroundColor: theme.palette.common.white,
      minHeight: 60,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.14)',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
  };
});

const TopBar = (props) => {
  const history = useHistory();

  const classes = useStyles(props);
  const {
    hasBackButton,
    maxWidth,
    backText,
    title,
    value,
    subtitle,
    btnTitle,
    onButton,
    onChangeSearch,
    onChangePage,
    count,
    goToEdit,
    startSlot,
    templatesTitle,
    goToTemplates,
    endSlot: EndSlot,
  } = props;

  const containerProps = {
    maxWidth: typeof maxWidth === 'string' ? maxWidth : 'lg',
    style: typeof maxWidth === 'number' ? { maxWidth } : undefined,
  };

  return (
    <div className={classes.root}>
      <Container {...containerProps}>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid
            item
            container
            xs="auto"
            justify="flex-start"
            alignItems="center"
            style={hasBackButton ? { minWidth: 280 } : templatesTitle ? { minWidth: 400 } : undefined}
          >
            {startSlot && startSlot}
            {hasBackButton && (
              <IconButton color="primary" onClick={() => history.goBack()} edge="start">
                <ArrowBackIcon />
              </IconButton>
            )}
            {templatesTitle && (
              <Button
                variant="contained"
                style={{ marginRight: 20 }}
                color="secondary"
                size="medium"
                onClick={goToTemplates}
              >
                {templatesTitle}
              </Button>
            )}
            {backText && (
              <Typography variant="subtitle1" color="primary" style={{ marginLeft: 5 }}>
                {backText}
              </Typography>
            )}
            {onChangeSearch && (
              <>
                {hasBackButton && <Divider style={{ margin: '0 10px' }} orientation="vertical" />}
                <SearchBar onChangeSearch={onChangeSearch} value={value} />
              </>
            )}
          </Grid>

          <Grid item container xs="auto" direction="column" alignItems="center" justify="center">
            <Typography variant="h4">{title}</Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {subtitle}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs="auto"
            justify="flex-end"
            alignItems="center"
            style={onChangePage && { minWidth: 390 }}
          >
            {onChangePage && <Pagination onChangePage={onChangePage} count={count} />}
            {goToEdit && (
              <IconButton color="primary" onClick={goToEdit}>
                <IconEdit />
              </IconButton>
            )}

            {btnTitle && (
              <>
                <Button variant="contained" color="secondary" size="medium" onClick={onButton}>
                  {btnTitle}
                </Button>
              </>
            )}
            {EndSlot && <EndSlot />}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

TopBar.propTypes = {
  value: PropTypes.string,
  onSearch: PropTypes.func,
  hasBackButton: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backText: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  btnTitle: PropTypes.string,
  templatesTitle: PropTypes.string,
  onButton: PropTypes.func,
  goToEdit: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  goToTemplates: PropTypes.func,
  endSlot: PropTypes.bool,
};

export default TopBar;
