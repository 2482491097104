import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { AuthSidebar } from 'components/common';
import Section from 'components/common/Section';
import useStyles from './style';
import Auth from '@aws-amplify/auth';
import { isMobile } from 'react-device-detect';

const ERROR_INVALID_PASSWORD_EXCEPTION =
  'Please enter a valid password that is at least 8 characters with one uppercase, one lowercase, one numeric and one special symbol';

const ChangePassword = () => {
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [userC, setUserC] = useState();
  const [code, setCode] = useState();
  const [type, setType] = useState('reset');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const s = useStyles();
  const goTo = useCallback((path) => () => history.push(path), [history]);

  const onChange = (e) => setPassword(e.target.value);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (!parsed || !parsed.code) {
      goTo('/login')();
      return;
    }

    setCode(parsed.code);
    if (parsed.type) {
      setType(parsed.type);
      setUserC(parsed.user);
    } else {
      setEmail(parsed.email);
    }
  }, [goTo]);

  const actionNewPassword = useCallback(async () => {
    if (!password) return setError('Field cannot be empty.');
    setLoading(true);

    if (type === 'new') {
      const oldPWD = code;
      if (!userC || !oldPWD) {
        goTo('/login')();
      }
      Auth.signIn(userC, oldPWD)
        .then((user) => {
          Auth.completeNewPassword(user, password)
            .then((re) => {
              setError('');
              if (isMobile) {
                goTo('/login')();
              } else {
                goTo('/change-password-success')();
              }
            })
            .catch((error) => {
              console.log('******************', error);
              if (error.code === 'InvalidPasswordException' || error.code === 'InvalidParameterException') {
                setError(ERROR_INVALID_PASSWORD_EXCEPTION);
              } else {
                setError(error?.message || '');
              }
            })
            .finally(() => setLoading(false));
        })
        .catch((error) => {
          setError(error?.message || '');
        })
        .finally(() => setLoading(false));
    } else {
      Auth.forgotPasswordSubmit(email, code, password)
        .then(() => {
          setError('');
          goTo('/check-email-success')();
        })
        .catch((error) => {
          if (error.code === 'InvalidPasswordException' || error.code === 'InvalidParameterException') {
            setError(ERROR_INVALID_PASSWORD_EXCEPTION);
          } else {
            setError(error?.message || '');
          }
        })
        .finally(() => setLoading(false));
    }
  }, [email, code, password, userC, type, goTo]);

  return (
    <section className={s.container}>
      <AuthSidebar />
      <Section
        title="Set New Password"
        desc="Password should contain at least 8 characters, at least one uppercase letter, number and one special symbol"
        placeholder="New Password"
        textBtn="Change Password"
        type="password"
        name="newPassword"
        loading={loading}
        error={error}
        style={s}
        onChange={onChange}
        onClick={actionNewPassword}
      />
    </section>
  );
};

export default ChangePassword;
