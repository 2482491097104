import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconCheckBox = (props) => {
  const { checked = false, minus = false, width = 20, height = 20 } = props;

  return (
    <SvgIcon viewBox="0 0 20 20" width={width} height={height}>
      {checked ? (
        <path
          d="M14 0c3.314 0 6 2.686 6 6v8c0 3.314-2.686 6-6 6H6c-3.314 0-6-2.686-6-6V6c0-3.314 2.686-6 6-6h8zm0 2H6C3.858 2 2.109 3.684 2.005 5.8L2 6v8c0 2.142 1.684 3.891 3.8 3.995L6 18h8c2.142 0 3.891-1.684 3.995-3.8L18 14V6c0-2.142-1.684-3.891-3.8-3.995L14 2zm.676 4.263c.376.344.428.91.14 1.315l-.079.098-5.5 6c-.328.358-.86.424-1.264.174l-.098-.07-2.5-2c-.431-.344-.5-.973-.156-1.405.319-.398.88-.488 1.303-.228l.103.072 1.769 1.416 4.869-5.31c.373-.408 1.006-.435 1.413-.062z"
          transform="translate(-330 -361) translate(280 61) translate(0 108) translate(0 70) translate(0 102) translate(50 20)"
        />
      ) : minus ? (
        <path
          d="M14 0c3.314 0 6 2.686 6 6v8c0 3.314-2.686 6-6 6H6c-3.314 0-6-2.686-6-6V6c0-3.314 2.686-6 6-6h8zm0 2H6C3.858 2 2.109 3.684 2.005 5.8L2 6v8c0 2.142 1.684 3.891 3.8 3.995L6 18h8c2.142 0 3.891-1.684 3.995-3.8L18 14V6c0-2.142-1.684-3.891-3.8-3.995L14 2zm-1 7c.552 0 1 .448 1 1s-.448 1-1 1H7c-.552 0-1-.448-1-1s.448-1 1-1h6z"
          transform="translate(-330 -184) translate(280 61) translate(0 108) translate(50 15)"
        />
      ) : (
        <path
          d="M14 0c3.314 0 6 2.686 6 6v8c0 3.314-2.686 6-6 6H6c-3.314 0-6-2.686-6-6V6c0-3.314 2.686-6 6-6h8zm0 2H6C3.858 2 2.109 3.684 2.005 5.8L2 6v8c0 2.142 1.684 3.891 3.8 3.995L6 18h8c2.142 0 3.891-1.684 3.995-3.8L18 14V6c0-2.142-1.684-3.891-3.8-3.995L14 2z"
          transform="translate(-330 -239) translate(280 61) translate(0 108) translate(0 70) translate(50)"
        />
      )}
    </SvgIcon>
  );
};

IconCheckBox.propTypes = simpleIconPropsTypes;
