import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    backgroundColor: '#F0F1F4',
    height: '100%',
  },
  titleDetails: {
    fontSize: '26px',
    fontWeight: 'normal',
    marginTop: '30px',
  },
  container: {
    width: '745px',
    margin: '20px auto 0 auto',
    paddingBottom: '40px',
    '&>*:not(:last-child)': {
      marginBottom: 30,
    },
  },
  textAreaBox: {
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.14)',
    backgroundColor: '#ffffff',
    padding: '15px 30px',
  },
  tablePlutton: {
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.14)',
    backgroundColor: '#ffffff',
    padding: '15px 30px',
    margin: '30px 0 0 0',
  },
  textTitle: {
    marginBottom: '10px',
  },
  descText: {
    fontSize: '15px',
    color: '#333333',
    whiteSpace: 'break-spaces',
    fontFamily: 'sans-serif',
  },
  map: {
    marginTop: '25px',
    position: 'relative',
    marginBottom: '5px',
  },
  downArrow: {
    width: '15px',
    height: '15px',
    color: '#000000',
    cursor: 'pointer',
  },
  visibleMap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  criteriosBlock: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  criteriaTitle: {
    fontSize: '15px',
    color: '#7080a1',
    marginRight: '170px',
  },
  criteria: {
    fontSize: '15px',
    color: '#515e74',
  },
  widthHeader: {
    width: '800px',
    backgroundColor: '#ffffff',
    margin: '0 auto',
  },
  wrapperHeader: {
    width: '100%',
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
  },
  textCriteriaBox: {
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.14)',
    backgroundColor: '#ffffff',
    padding: '15px 30px 15px 30px',
    marginTop: '30px',
  },
  btnCroup: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'absolute',
    right: 20,
    bottom: 25,
  },
  btnPlus: {
    width: '36px',
    height: '50px',
    backgroundColor: '#ffffff',
    color: '#0e4bad',
    fontSize: '25px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  btnMinus: {
    marginTop: '10px',
    width: '36px',
    height: '50px',
    backgroundColor: '#ffffff',
    color: '#0e4bad',
    fontSize: '25px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  oval: {
    color: '#0e4bad',
  },
  ovalBtn: {
    position: 'absolute',
    left: 20,
    bottom: 25,
    width: '50px',
    height: '50px',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
}));

export default useStyles;
