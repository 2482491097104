import { Avatar, Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Loader } from '../../../../components/common/Loader';
import { useLabels } from '../../../../components/contexts/AuthContext';
import { useRpcApi } from '../../../../services/api';
import { emptyArray } from '../../../../utils/arrayUtils';
import { formatPrettyDateTime } from '../../../../utils/dateUtils';
import useStyles from '../style';
import ChangeLogHeader from './ChangeLogHeader';
import ChangeLogRow from './ChangeLogRow';

const [getHistoryList] = useRpcApi('evaluationHistory.getList');
const order = Object.entries({ createdAt: 'desc' });
const limit = 20;

export default function ChangeLog({ scheduleId }) {
  const classes = useStyles();
  const [pending, setPending] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [history, setHistory] = React.useState(null);
  const labels = useLabels();

  const isHistory = history && !emptyArray(history.items);
  const total = (history && Number(history.total)) || 0;

  React.useEffect(() => {
    setPending(true);
    getHistoryList({ scheduleId, order, limit, offset: (page - 1) * limit })
      .then((res) =>
        res && Array.isArray(res.items)
          ? setHistory((prevState) => (!prevState ? res : { ...prevState, items: prevState.items.concat(res.items) }))
          : null
      )
      .catch(() => null)
      .finally(() => setPending(false));
  }, [scheduleId, page]);

  const onIncreasePage = React.useCallback(() => setPage((prevPage) => prevPage + 1), []);

  return (
    <div>
      {isHistory && <ChangeLogHeader classes={classes} />}

      {isHistory ? (
        history.items.map((point, key) => (
          <Paper key={key} elevation={0} className={classes.sectionBlock}>
            <div className={classes.chLogCardBlock}>
              <Grid container alignItems="center" spacing={1} className={classes.sectionBlock}>
                <Grid item>
                  <Avatar className={classes.platoonAvatar}>
                    {point.userCreatedBy?.firstName[0]}
                    {point.userCreatedBy?.lastName[0]}
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography>{point.userCreatedBy?.fullName}</Typography>
                </Grid>
                <Grid item>
                  <Typography color="textSecondary">
                    - {formatPrettyDateTime(point.createdAt)}, for {labels.recruit} {point.recruit.fullName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                {point.fieldsLog &&
                  Object.keys(point.fieldsLog || {}).map((field, ind) => (
                    <ChangeLogRow field={field} key={ind} fieldData={point.fieldsLog[field]} classes={classes} />
                  ))}
              </Grid>
            </div>
          </Paper>
        ))
      ) : !pending ? (
        <Paper elevation={0} className={classes.sectionBlock}>
          <div className={classes.chLogCardBlock}>
            <Typography variant="h3" className="center">
              No history yet
            </Typography>
          </div>
        </Paper>
      ) : null}

      {isHistory && (total > limit * page || pending) ? (
        <div className="center">
          <Button
            variant="text"
            color="primary"
            onClick={onIncreasePage}
            size="large"
            className="bold-600"
            disabled={pending}
          >
            {pending ? <CircularProgress size={24} /> : 'Load more'}
          </Button>
        </div>
      ) : pending ? (
        <Loader open fixed />
      ) : null}

      <br />
      <br />
    </div>
  );
}
