import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { InputBox } from '../../../../components/common/Inputs';

const CRITERIO_LIST = ['M', 'NM', 'NI', 'NA'];
const styles = { container: { padding: '10px 0', borderBottom: '1px solid #dce2ea' } };

export const CriterioAssmentContainer = ({ criterio, handleAddCriterio }) => {
  const onChangeCriterio = ({ evaluation, comment }) =>
    criterio && handleAddCriterio && handleAddCriterio({ name: criterio.name, evaluation, comment });

  const onChangeComment = (event) => onChangeCriterio({ comment: event.target.value });

  return !criterio ? null : (
    <Grid container alignItems="center" justify="space-between" style={styles.container} spacing={2}>
      <Grid item component={Typography} variant="h6">
        {criterio.name}
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justify="space-around" spacing={1}>
          {CRITERIO_LIST.map((evaluation) => (
            <Grid item key={evaluation}>
              <Button
                type="button"
                size="small"
                color="secondary"
                variant={criterio.evaluation === evaluation ? 'contained' : 'outlined'}
                onClick={onChangeCriterio.bind(null, { evaluation })}
              >
                {evaluation}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InputBox
          onInput={onChangeComment}
          name="comment"
          value={criterio.comment || ''}
          placeholder="Criteria Comment"
          multiline
          rows={2}
        />
      </Grid>
    </Grid>
  );
};

export default CriterioAssmentContainer;
