import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRpcApi } from 'services/api';
import { ActionButtons, GridTable, TopBar } from 'components/common';
import RemoveModal from 'components/common/Dialogs/RemoveModal';
import { Button, Typography, CircularProgress, Avatar } from '@material-ui/core';
import useStyles from '../style';
import { ROLE_SUPER_ADMIN } from '../../../constants';
import { useLabels } from '../../../components/contexts/AuthContext';

const LIMIT = 10;

const [getRecruitsList, deleteUser] = useRpcApi('user.getRecruitsList', 'user.deleteUser');

const RecruitsTable = ({ activeUser }) => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState('');
  const [userValue, setUserValue] = useState('');
  const [openRemove, setOpenRemove] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [selectUser, setSelectUser] = useState();
  const [limit, setLimit] = useState(0);
  const [offset, setOffset] = useState(0);
  const [nameSotr, setNameSort] = useState('createdAt');
  const [sort, setSort] = useState('desc');
  const classes = useStyles();
  const labels = useLabels();

  const onClickSort = (flag, name) => {
    setSort(flag);
    setNameSort(name);
  };

  const onDelete = () =>
    deleteUser({ id: selectUser?.id }).then(() => {
      getUsers().then(onChangeRemove);
    });

  const onChangeUser = (id) => {
    const user = users.find((item) => +item?.id === +id) || {};
    setSelectUser(user);
    setUserValue({
      name: `${user?.firstName}  ${user?.lastName}`,
      email: user?.email,
      avatar: user?.avatar,
    });
  };

  const getUsers = useCallback(async () => {
    const [firstValue, secondValue] = value.trim().split(' ');

    let filter = [];
    let orFilter = [];

    const Op = '$ilike';

    if (firstValue && !secondValue) {
      orFilter = Object.entries({
        firstName: {
          [Op]: `%${firstValue}%`,
        },
        lastName: {
          [Op]: `%${firstValue}%`,
        },
        badgeNumber: {
          [Op]: `%${firstValue}%`,
        },
      });
    }
    if (firstValue && secondValue) {
      filter = Object.entries({
        firstName: {
          [Op]: `%${firstValue}%`,
        },
        lastName: {
          [Op]: `%${secondValue}%`,
        },
      });
    }

    const response = await getRecruitsList({
      limit: LIMIT,
      offset: offset * LIMIT,
      order: Object.entries({ [nameSotr]: sort }),
      orFilter,
      filter,
    });
    setUsers(
      response?.items?.map((i) => ({
        ...i,
        platoonAvatar: response?.platoon?.find((item) => item?.recruitId === i.id)?.platoon.avatar,
        platoon: response?.platoon?.find((item) => item?.recruitId === i.id)?.platoon.name,
      }))
    );
    setLimit(response?.total);
  }, [value, offset, nameSotr, sort]);

  useEffect(() => {
    getUsers()
      .then(() => setLoadingList(true))
      .finally(() => setLoadingList(false));
  }, [getUsers]);

  const onChangeRemove = () => setOpenRemove(!openRemove);
  const goTo = (path) => () => history.push(path);
  const onChangeSearch = (e) => setValue(e.target.value);

  const rows = users?.map((row) => [
    <Button onClick={() => history.push(`/recruits/recruit-details/${row?.id}`)}>
      <Avatar className={classes.avatarRecruit} src={row?.avatar} alt="Avatar">
        {row?.firstName?.charAt(0) + row?.lastName?.charAt(0)}
      </Avatar>
      <Typography variant="subtitle2">{`${row?.firstName} ${row?.lastName}`}</Typography>
    </Button>,
    <Typography variant="subtitle2">{row?.badgeNumber}</Typography>,
    <Typography variant="subtitle2">{row.organizationInfo?.name}</Typography>,
    activeUser.roleId !== ROLE_SUPER_ADMIN && (
      <>
        <Avatar className={classes.avatarRecruit} src={row?.platoonAvatar} alt="Avatar">
          {row?.platoon?.charAt(0)}
        </Avatar>
        <Typography variant="subtitle2">{row?.platoon}</Typography>
      </>
    ),
    <ActionButtons
      actions={['edit', 'delete']}
      onEdit={() => history.push(`/recruits/edit-recruit/${row?.id}`)}
      onDelete={() => {
        onChangeUser(row?.id);
        onChangeRemove();
      }}
    />,
  ]);

  let columnsTable = [];
  if (activeUser.roleId !== ROLE_SUPER_ADMIN) {
    columnsTable = [
      { id: 'firstName', title: 'Full Name', align: 'left', xs: 3, isSort: true },
      { id: 'badge', title: 'Badge', align: 'left', xs: 2 },
      { id: 'organization', title: 'Organization', align: 'left', xs: 3 },
      { id: 'Platoon', title: labels.platoon, align: 'left', xs: 3 },
      { id: 'actions', title: 'Actions', align: 'right', xs: 1 },
    ];
  } else {
    columnsTable = [
      { id: 'firstName', title: 'Full Name', align: 'left', xs: 4, isSort: true },
      { id: 'badge', title: 'Badge', align: 'left', xs: 3 },
      { id: 'organization', title: 'Organization', align: 'left', xs: 3, isSort: true },
      { id: '', title: '', xs: 1 },
      { id: 'actions', title: 'Actions', align: 'right', xs: 1 },
    ];
  }

  return (
    <>
      <TopBar
        title={labels.recruits}
        btnTitle={`Add New ${labels.recruit}`}
        onChangeSearch={onChangeSearch}
        onButton={goTo('/recruits/new-recruit')}
        count={limit}
        onChangePage={(num) => setOffset(num)}
      />

      {loadingList ? (
        <div className={classes.widthNotRecruit}>
          <CircularProgress />
        </div>
      ) : rows?.length ? (
        <GridTable columns={columnsTable} rows={rows} onSort={onClickSort} role={activeUser.roleId} />
      ) : (
        <div className={classes.widthNotRecruit}>
          <p>
            There are no {labels.recruits} in the table.{' '}
            <span onClick={goTo('/recruits/new-recruit')} className={classes.titleAdd}>
              Add New {labels.recruit}!
            </span>
          </p>
        </div>
      )}

      <RemoveModal
        header={`Delete ${userValue?.name}?`}
        open={openRemove}
        onClose={onChangeRemove}
        title={`Proceeding will delete their record including scenarios and scores. This cannot be undone.`}
        name={userValue?.name}
        subText={userValue?.email}
        urlAvatar={userValue?.avatar}
        onClick={onDelete}
        avatar
      />
    </>
  );
};

export default RecruitsTable;
