import { Button, Grid, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  assessment: {
    backgroundColor: '#e3e7ed',
    color: '#fff',
    width: 30,
    height: 30,
    borderRadius: 25,
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  fail: {
    backgroundColor: '#ff7373',
  },
  pass: {
    backgroundColor: '#69d873',
  },
  failIcon: {
    color: '#fff',
  },
}));

const Evaluation = (props) => {
  const classes = useStyles();
  const { evaluation } = props;

  return (
    <>
      {evaluation ? (
        evaluation < 0 ? (
          <Grid container alignItems="center">
            {evaluation === -2 ? 'Failed' : 'Passed'}
            <div className={clsx(classes.assessment, evaluation === -2 ? classes.fail : classes.pass)}>
              {evaluation === -2 ? (
                <ClearIcon className={classes.failIcon} />
              ) : (
                <CheckIcon className={classes.passIcon} />
              )}
            </div>
          </Grid>
        ) : (
          <Button variant="outlined" size="medium">
            {evaluation}
          </Button>
        )
      ) : (
        <div style={{ cursor: 'pointer' }}>------</div>
      )}
    </>
  );
};

Evaluation.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      align: PropTypes.oneOf(['left', 'center', 'right']),
      xs: PropTypes.number,
    })
  ),
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.element)),
  maxWidth: PropTypes.number,
};

export default Evaluation;
