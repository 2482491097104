import { Checkbox, makeStyles } from '@material-ui/core';
import React from 'react';
import { IconCheckBox } from '../../../svg';

const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    '&$checked': {
      color: theme.palette.text.primary,
    },
  },
  checked: {},
}));

export const CheckBox = ({ ...rest }) => {
  const classes = useStyles();

  return (
    <Checkbox
      disableRipple
      classes={classes}
      icon={<IconCheckBox />}
      checkedIcon={<IconCheckBox checked />}
      {...rest}
    />
  );
};
