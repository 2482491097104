import { makeStyles } from '@material-ui/core/styles';

const flex = {
  display: 'flex',
  justifyContent: 'space-between',
};

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
};

const useStyles = makeStyles((theme) => ({
  container: {
    ...flex,
    backgroundColor: 'white',
    height: '100%',
  },
  rightSection: {
    ...flexColumn,
    alignItems: 'center',
    color: 'white',
    fontSize: '14px',
    width: '80%',
    margin: 'auto',
  },
  textError: {
    color: 'red',
    marginBottom: '20px',
    height: '20px',
  },
  btnSubmit: {
    marginTop: '20px',
  },
  checkEmailIcon: {
    width: '72px',
    height: '70px',
    marginBottom: '20px',
  },
  rightSectionWidth: {
    width: '500px',
    margin: 'auto',
  },
  top: {
    ...flexColumn,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '25px',
  },
  title: {
    color: '#000000',
    fontSize: '42px',
    fontWeight: 'normal',
  },
  subTitle: {
    textAlign: 'center',
    color: '#7080a1',
    fontSize: '16px',
    fontWeight: 'normal',
    marginTop: '5px',
    margin: '0 10px',
  },
  subTitleEmail: {
    color: '#00c853',
  },
  inputs: {
    ...flexColumn,
    width: '380px',
    boxShadow: '0 0 10px 0 rgba(0, 46, 120, 0.1)',
    marginTop: '40px',
    '@media (max-width: 400px)': {
      width: '100%',
    },
  },
  inputFirst: {
    borderRadius: '4px 4px 0 0',
    borderBottomWidth: '0',
  },
  inputLast: {
    borderRadius: '0 0 4px 4px',
  },
  checkBtn: {
    marginTop: '20px',
    background: 'white',
    border: 'solid 1px rgba(115, 115, 139, 0.3)',
    color: '#73738b',
    boxShadow: 'none',
    fontSize: '15px',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  span: {
    color: '#0e4bad',
    marginLeft: '7px',
  },
  checkBtnChanged: {
    color: '#0e4bad',
    border: 'solid 1px rgba(14, 75, 173, 0.4)',
  },
  forgotText: {
    marginBottom: '25px',
    color: '#7080a1',
    fontSize: '14px',
    position: 'absolute',
    bottom: '0',
  },
  reset: {
    color: '#0e4bad',
    textDecoration: 'underline',
    marginLeft: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  input: {
    width: '400px',
    margin: '30px 0 40px 0',
    boxShadow: '0 0 10px 0 rgba(0, 46, 120, 0.1)',
  },
}));

export default useStyles;
