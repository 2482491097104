import { FormControlLabel, makeStyles } from '@material-ui/core';
import React from 'react';
import { CheckBox } from '../CheckBox';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px 0',
    width: '100%',
    minHeight: 64,
    backgroundColor: theme.palette.background.paperSecondary,
    borderRadius: theme.shape.borderRadius,
    padding: '0 15px',
    ...theme.mixins.unselectable,
  },
}));

export const CheckBoxWithLabel = ({ label, checked, labelProps = {}, ...rest }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={classes}
      control={<CheckBox {...rest} checked={!!checked} />}
      label={label}
      {...labelProps}
    />
  );
};
