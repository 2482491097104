const colors = {
  white: '#FFF',
  black: '#000',
  primary: { main: '#0e4bad', dark: '#002e78', light: '#3482ff', contrastText: '#fff' },
  secondary: { main: '#00c853', contrastText: '#fff' },
  success: { main: '#08dea1' },
  error: { main: '#ff7373' },
  info: { main: '#0d5ebd' },
  warning: { main: '#ffc500' },
  dark: {
    text: {
      primary: '#000',
      secondary: '#7080a1',
      disabled: '#CCC',
      hint: '#AAA',
    },
    background: {
      default: '#f0f1f4',
      paper: '#fff',
    },
    grey: {
      50: '#f3f4f7',
      100: '#f7f8fa',
      200: '#eeeeee',
      300: '#e3e5e9',
      400: '#b7bfcd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#515e74',
      800: '#424242',
      900: '#212121',
      A100: '#dce2ea',
      A200: '#a0b0ca',
      A400: '#c6d0e0',
      A700: '#616161',
    },
    divider: '#e3e4e8',
    dividerSecondary: '#3a383e',
    icons: {
      primary: '#FFF',
      secondary: '#7080A1',
      disabled: '#88868e',
    },
    action: {
      activatedOpacity: 0.12,
      disabledOpacity: 0.38,
      focusOpacity: 0.12,
      hoverOpacity: 0.04,
      selectedOpacity: 0.08,
    },
    custom: {
      support: '#535B68',
      links: '#A5AEBD',
      sidebar: '#191C1E',
      sidebarLight: '#1E2123',
    },
  },
};

export default colors;
