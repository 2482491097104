import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRpcApi } from 'services/api';
import { Redirect, Route, Switch } from 'react-router';
import { useCreateScenarioStore } from 'hooks/useStoreCreateScenario';
import AddScenarios from '../AddNewScenarios';
import ScenarioDetails from '../ScenarioDetails';
import BodyScenarios from './ScenariosBody';
import useStyles from './style';
import EditCustomScenario from './EditCustomScenario';

const LIMIT = 10;
const [getScenarios] = useRpcApi('scenario.getScenarioList');

const Scenarios = ({ user }) => {
  const [scenariosData, setScenariosData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(0);
  const [nameSotr, setNameSort] = useState('createdAt');
  const [sort, setSort] = useState('desc');
  const { pathname } = useLocation();
  const [createScenarioStore, createScenarioActions] = useCreateScenarioStore(null);
  const classes = useStyles();

  const getData = useCallback(async () => {
    setLoadingList(true);
    const name = createScenarioStore.search?.length ? { $like: `%${createScenarioStore.search.trim()}%` } : {};

    const response = await getScenarios({
      filter: Object.entries({ parentId: { $eq: null }, name }),
      order: Object.entries({ [nameSotr]: sort }),
      limit: LIMIT,
      offset: offset * LIMIT,
    });

    setScenariosData(response.items);
    setLimit(response?.total);
    setLoadingList(false);
  }, [createScenarioStore.search, nameSotr, sort, offset]);

  useEffect(() => {
    if (createScenarioStore.search?.length) {
      getData();
    }
  }, [createScenarioStore.search, getData]);

  useEffect(() => {
    if (pathname === '/scenarios') {
      getData();
    }
  }, [pathname, offset, getData]);
  const attr = {
    onChangeOffset: (num) => setOffset(num),
    scenariosData,
    limit,
    value: createScenarioStore.search,
    setValue: (e) => createScenarioActions.changeSearch(e),
  };

  return (
    <div className={classes.container}>
      <Switch>
        <Route exact path={'/scenarios'}>
          <BodyScenarios
            setNameSort={setNameSort}
            setSort={setSort}
            {...attr}
            loadingList={loadingList}
            getData={getData}
          />
        </Route>
        <Route exact path={'/scenarios/new-scenarios'}>
          <AddScenarios activeUser={user} />
        </Route>
        <Route exact path={'/scenarios/edit-scenarios/:id'}>
          <AddScenarios activeUser={user} setVisible={setVisible} />
        </Route>
        <Route exact path={'/scenarios/edit-eventScenario/:id'}>
          <AddScenarios activeUser={user} visible={visible} setVisible={setVisible} />
        </Route>
        <Route exact path={'/scenarios/edit-customScenario/:id'}>
          <EditCustomScenario activeUser={user} />
        </Route>
        <Route exact path={'/scenarios/details/:id'}>
          <ScenarioDetails visible={visible} setVisible={setVisible} />
        </Route>
        <Redirect exact path="/*" to={'/scenarios'} />
      </Switch>
    </div>
  );
};

export default Scenarios;
