import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapperWidth: {
    flex: 1,
    display: 'flex',
    overflow: 'auto',
  },
  nav: {
    minWidth: 280,
    padding: '30px 25px',
    backgroundColor: theme.palette.grey[100],
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    '& > h4': {
      margin: '0 0 6px 15px',
    },
  },
  content: {
    width: '100%',
    padding: 40,
    //backgroundColor: '#fff',
    backgroundColor: '#F3F4F7',
    display: 'flex',
    '& > div': {
      maxWidth: 744,
    },
    '& > div:first-child': {
      maxWidth: 610,
      marginRight: 40,
    },
  },
  btn: {
    width: '100%',
    height: 42,
    marginTop: 10,
    justifyContent: 'start',
  },
  activeBtn: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export default useStyles;
