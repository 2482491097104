import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#f3f4f7',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  },
  typography: {
    // marginLeft: '12px',
    color: 'blue',
    cursor: 'pointer',
  },
  active: {
    width: '15px',
    height: '15px',
    backgroundColor: 'red',
    borderRadius: '50%',
    marginRight: '10px',
  },
  disable: {
    width: '15px',
    height: '15px',
    backgroundColor: '#69d873',
    borderRadius: '50%',
    marginRight: '10px',
  },
  status: {
    width: '152px',
    display: 'flex',
    alignItems: 'center',
  },
  location: {
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
  },
  widthNotRecruit: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '60px',
  },
  titleAdd: {
    color: 'blue',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
