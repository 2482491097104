import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';

const ChangeLogHeader = ({ classes }) => (
  <Paper className={`${classes.chLogCardBlock} ${classes.sectionBlock} head sticky`} elevation={1}>
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={4}>
        <Typography color="textSecondary" variant="subtitle2" className="bold-600">
          Field
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography color="textSecondary" variant="subtitle2" className="bold-600">
          Original Value
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography color="textSecondary" variant="subtitle2" className="bold-600">
          New Value
        </Typography>
      </Grid>
    </Grid>
  </Paper>
);

export default ChangeLogHeader;
