const alias = 'platoon';

export const apis = [
  `${alias}.getPlatoonList`,
  `${alias}.createPlatoon`,
  'user.getAvatarUploadUrl',
  `${alias}.updatePlatoon`,
  `${alias}.deletePlatoon`,
];

export const columnsFactory = (labels) => [
  { id: 'name', title: 'Name', align: 'left', xs: 4, isSort: true },
  { id: 'quantityRecruit', title: labels.recruits, align: 'center', xs: 4, isSort: true },
  { id: 'actions', title: 'Actions', align: 'right', xs: 4 },
];

export const defaultValue = { name: '', avatar: '', quantityRecruit: '' };
