import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';
import { SvgIcon } from '../../common/SvgIcon';

export const IconTrash = (props) => {
  const { width = 17, height = 17 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 17 17">
      <defs>
        <path id="aqrxh2lija" d="M0 0H18V18H0z" />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="currentColor">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-1372 -153) translate(240) translate(20 122) translate(18 18) translate(1094 13)">
                  <mask id="1evayhe7eb" fill="#fff">
                    <use xlinkHref="#aqrxh2lija" />
                  </mask>
                  <path
                    strokeWidth="1.2"
                    d="M14.4 4.6V15c0 .387-.157.737-.41.99s-.603.41-.99.41h0-8c-.387 0-.737-.157-.99-.41s-.41-.603-.41-.99h0V4.6h10.8z"
                    mask="url(#1evayhe7eb)"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                    d="M1.5 4.5h15M7.5 8.5L7.5 12.5M10.5 8.5L10.5 12.5"
                    mask="url(#1evayhe7eb)"
                  />
                  <path
                    strokeWidth="1.2"
                    d="M5.5 4v-.5c0-1.105.895-2 2-2h3c1.105 0 2 .895 2 2V4h0"
                    mask="url(#1evayhe7eb)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

IconTrash.propTypes = simpleIconPropsTypes;
