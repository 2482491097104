import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rowInstructor: {
    padding: '15px 0',
    borderBottom: '1px solid #dce2ea',
  },
  root: {
    marginRight: 20,
  },
  roleHeading: {
    marginTop: 20,
    paddingBottom: 10,
  },
  commentHeading: {
    paddingBottom: 10,
  },

  recruitsList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  recruitAvatar: {
    margin: '0 10px 0 0',
    width: '50px',
    height: '50px',
    backgroundColor: '#3482ff',
    fontSize: '17px',
  },
  instructorBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  instructorAvatar: {
    margin: '0 10px 0 0',
    width: '50px',
    height: '50px',
    backgroundColor: '#3482ff',
    fontSize: '17px',
  },
  submitButton: {
    margin: '20px auto 20px',
  },
  inputComment: {
    width: '100%',
  },
  roleAndBehavior: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  scoring: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingTop: '0',
    paddingBottom: '40px',
  },
  recrutListItem: {
    margin: '0 5px 10px 0',
  },
  photoBlock: {
    display: 'flex',
    width: '100%',
    paddingBottom: 15,
    alignItems: 'center',
  },
  photoContainer: {
    width: '100%',
    minHeight: '200px',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
    justifyContent: 'flex-start',
    backgroundColor: '#F0F1F4',
    borderRadius: 10,
  },
  checkBox: {
    position: 'absolute',
    left: 15,
    top: 15,
    width: 5,
    height: 5,
    marginRight: 5,
  },
  iconDownloadImg: {
    position: 'absolute',
    right: 15,
    top: 15,
    cursor: 'pointer',
    width: 20,
    height: 20,
    backgroundColor: '#ffff',
    borderRadius: '50%',
    border: '1px solid #00c853',
    '&:hover': {
      opacity: 0.7,
    },
  },

  container: {
    maxWidth: 744,
    margin: '40px auto',
    '& > div': {
      padding: 30,
      marginBottom: 35,
    },
  },
  assessmentButtonFail: {
    backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none',
    padding: 2.5,
    width: 20,
    height: 20,
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },

  buttonDownloadImage: {
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none',
    padding: 2.5,
    width: 20,
    height: 20,
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  failIcon: {
    color: '#fff',
    width: 15,
    height: 15,
    borderRadius: 25,
  },
  spanText: {
    marginRight: 10,
    color: '#ffff',
  },
  imgChecboxContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    padding: 10,
    height: 120,
    width: 120,
  },
  imageShow: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    border: '1px solid #ffff',
    objectFit: 'cover',
  },
  lableDownload: {
    position: 'absolute',
    top: 'calc(50% - 73px)',
    left: 'calc(50% - 70px)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },

  iconDownload: {
    width: 100,
    height: 100,
    color: '#0E4BAD',
    '& .MuiPaper-root': {
      color: '#808080',
    },
  },

  buttonInput: {
    padding: '5px 10px',
    backgroundColor: 'transparent',
    border: '1px solid grey',
    color: '#000',
    borderRadius: 10,
    cursor: 'pointer',
    marginTop: 10,
    '&:hover': {
      opacity: 0.7,
    },
  },
  iconWrapper: {
    borderRadius: '50%',
    backgroundColor: '#d7d7d7',
    padding: 10,
    width: 80,
    height: 80,
  },

  buttonAddPhotoVideo: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    marginRight: 15,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

export default useStyles;
