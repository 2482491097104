import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import useStyles from '../style';
import Button from '../../../../components/common/Buttons/CustomButton';
import { Grid } from '@material-ui/core';

const PASSED = { id: -1 };
const FAILED = { id: -2 };

export const AssessmentChoose = ({ onChangeScore, selectedRecruits, score, actionButtons = false }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Button
          onClick={() => onChangeScore(PASSED, selectedRecruits)}
          endIcon={<CheckIcon />}
          color="success"
          variant={(score && score.id === PASSED.id) || actionButtons ? 'contained' : 'outlined'}
          size="large"
          type="button"
          className={classes.assessmentButton}
        >
          Passed
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => onChangeScore(FAILED, selectedRecruits)}
          endIcon={<ClearIcon />}
          color="error"
          variant={(score && score.id === FAILED.id) || actionButtons ? 'contained' : 'outlined'}
          size="large"
          type="button"
          className={classes.assessmentButton}
        >
          Failed
        </Button>
      </Grid>
    </Grid>
  );
};
