import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import SimpleDialog from '../../../../components/common/Dialogs/SimpleDialog';
import { Loader } from '../../../../components/common/Loader';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import { useChangeLogContext } from './context';

const Photo = ({ fileId, classes }) => {
  const { photosDict, onGetPhoho } = useChangeLogContext();
  const src = photosDict && photosDict[fileId];

  React.useEffect(() => {
    if (src) return;
    onGetPhoho(fileId);
  }, [onGetPhoho, fileId, src]);

  return !src ? null : <img className={`${classes.contain} ${classes.fit}`} src={src} alt="Loading ..." />;
};

const Video = ({ fileId, classes }) => {
  const { videosDict, onGetVideo } = useChangeLogContext();
  const src = videosDict && videosDict[fileId];

  React.useEffect(() => {
    if (src) return;
    onGetVideo(fileId);
  }, [onGetVideo, fileId, src]);

  return !src ? null : <video controls="controls" title="video" className={classes.contain} src={src} />;
};

export default function MediaView({ fileId, type = 'image' }) {
  const classes = useStyles();
  const [open, onOpen, onClose] = useBooleanHandlers();
  const { pending } = useChangeLogContext();

  return (
    <React.Fragment>
      <div>
        <Button variant="text" color="primary" onClick={onOpen}>
          Uploaded new {type}
        </Button>
      </div>

      {open && (
        <SimpleDialog open={open} onClose={onClose} maxWidth={false}>
          <div className={classes.content}>
            <Loader open={pending} invisible />
            {type === 'image' && <Photo fileId={fileId} classes={classes} />}
            {type === 'video' && <Video fileId={fileId} classes={classes} />}
          </div>
        </SimpleDialog>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles({
  content: {
    position: 'relative',
    width: '60vw',
    height: '65vh',
  },
  contain: {
    width: '100%',
    height: '100%',
  },
  fit: {
    objectFit: 'contain',
  },
});
