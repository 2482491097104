import { useState } from 'react';

export const useEventCreationStore = () => {
  const [state, dispatch] = useState({
    scenarioId: '',
    platoonId: '',
    criterioId: '',
    recurrence: null,
    activeReccurence: null,
    startTime: { h: '', m: '', format: '' },
    startDate: { d: '', m: '', y: '' },
    endDate: { d: '', m: '', y: '' },
    instructors: [],
    duration: 0,
  });

  const actions = {
    selectScenario: (scenarioId) => dispatch({ ...state, scenarioId }),
    changeRecurrence: (recurrence) => dispatch({ ...state, recurrence }),
    selectPlatoon: (platoonId) => dispatch({ ...state, platoonId }),
    selectInstructors: (instructors) => dispatch({ ...state, instructors }),
    selectCategories: (criterioId) => dispatch({ ...state, criterioId }),
    selectReccurence: (activeReccurence) => dispatch({ ...state, activeReccurence }),
    changeStartDate: (startDate) => dispatch({ ...state, startDate }),
    changeEndDate: (endDate) => dispatch({ ...state, endDate }),
    changeStartTime: (startTime) => dispatch({ ...state, startTime }),
    changeDuration: (duration) => dispatch({ ...state, duration }),
    setEvent: dispatch,
  };

  return [state, actions, null];
};
