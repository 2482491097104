import Select from 'components/common/Inputs/Select';
import React, { useEffect, useState } from 'react';
import useStyles from '../style';

const defaultState = {
  id: 0,
  name: 'None',
};
export const AssessmentSelect = ({ scoresFromDb = null, onChangeScore, selectedRecruits }) => {
  const classes = useStyles();
  const [score, setScore] = useState(defaultState);
  useEffect(() => {
    if (scoresFromDb) {
      setScore(scores.find((el) => +el.id === +scoresFromDb));
      return;
    }
    return;
  }, [scoresFromDb]);

  return (
    <div style={{ width: 320 }} className={classes.select}>
      <Select
        customClass={classes.root}
        name="name"
        onChange={(_, scor) => {
          onChangeScore(scor, selectedRecruits);
          setScore(scor);
        }}
        value={score ? score.id : defaultState.id}
        options={scores}
        nameIndex="id"
        disableClearable
      />
    </div>
  );
};

export const scores = [
  { id: 0, name: 'None' },
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
];
