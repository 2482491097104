import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconUpload = (props) => {
  const { width = 16, height = 16, color = 'black' } = props;

  return (
    <SvgIcon viewBox="0 0 16 16" width={width} height={height}>
      <g fill={color}>
        <path
          fillRule="nonzero"
          d="M14 9c.513 0 .936.386.993.883L15 10v2c0 1.545-.97 2.896-2.34 2.994L12.5 15h-9c-1.4 0-2.422-1.294-2.496-2.823L1 12v-2c0-.552.448-1 1-1 .513 0 .936.386.993.883L3 10v2c0 .554.25.933.447.992L3.5 13h9c.186 0 .451-.332.494-.853L13 12v-2c0-.552.448-1 1-1zM8.613 1.21l.094.083 3 3c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083L9 4.415V10c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L7 10V4.415L5.707 5.707c-.36.36-.928.388-1.32.083l-.094-.083c-.36-.36-.388-.928-.083-1.32l.083-.094 3-3c.36-.36.928-.388 1.32-.083z"
        />
      </g>
    </SvgIcon>
  );
};

IconUpload.propTypes = simpleIconPropsTypes;
