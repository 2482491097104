import React, { useEffect, useState } from 'react';
import { TopBar } from 'components/common';
import { useRpcApi } from '../../../services/api';
import FormAdmin from './Form';

const [getUser] = useRpcApi('getUser');

const NewAdmin = ({ getUsers, visible, setVisible, activeUser }) => {
  const [currentUser, setCurrentUser] = useState();

  const id = window.location.pathname.split('/')[3];

  useEffect(() => {
    if (id)
      getUser({ id })
        .then((res) => setCurrentUser(res))
        .catch((e) => console.error(e));
  }, [id]);

  return (
    <>
      <TopBar title={id ? 'Edit Admin' : 'Create New Admin'} hasBackButton backText="Cancel" maxWidth={944} />

      <FormAdmin
        getUsers={getUsers}
        activeUser={activeUser}
        currentUser={currentUser}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  );
};

export default NewAdmin;
