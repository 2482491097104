import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: '100%',
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiFormLabel-root': {
      color: '#7080a1',
      fontSize: '17px',
      fontWeight: 'bold',
    },
    '& .MuiInputBase-root': {
      border: '1px solid #dce2ea',
      height: '56px',
      borderRadius: '4px',
      backgroundColor: '#ffffff',
      paddingLeft: '10px',
      width: '100%',
    },
  },
  inputTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
  star: {
    color: 'red',
    marginLeft: '3px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
}));

export const InputTime = ({ title = 'Time', defaultTime, star, onChange }) => {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (e) => {
    if (e.target.value) {
      let time = e.target.value.split(':');
      if (time[0] > 23) time[0] = '23';
      if (time[1] > 59) time[1] = '59';
      let utcTime = moment().hours(time[0]).minutes(time[1]).utc();

      setSelectedDate(`${time[0]}:${time[1]}`);
      if (time[1].indexOf('_') === -1) {
        onChange({ h: utcTime.format('HH'), m: utcTime.format('mm') });
      } else {
        onChange({});
      }
    } else {
      setSelectedDate('');
    }
  };

  useEffect(() => {
    if (defaultTime) {
      setSelectedDate(moment(new Date(defaultTime)).format('HH:mm'));
    }
  }, [defaultTime]);

  return (
    <div>
      <h4 className={classes.inputTitle}>
        {title}
        {star && <span className={classes.star}>*</span>}
      </h4>
      <InputMask
        fullWidth
        mask="99:99"
        placeholder="HH:MM"
        onChange={handleDateChange}
        value={selectedDate || ''}
        variant={'outlined'}
      >
        <TextField />
      </InputMask>
    </div>
  );
};
