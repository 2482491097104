import moment from 'moment';
import { useRpcApi } from '../../../services/api';

const [getReportAboutRecruit] = useRpcApi('user.getReportAboutRecruit');

export function isValidReportParams({ recruitId, dateFrom, dateTo }) {
  if (!(recruitId && dateFrom && dateTo)) return false;
  return moment(dateFrom).isSameOrBefore(dateTo);
}

export async function generateRecruitsReport({ recruitId, dateFrom, dateTo }) {
  const reportData = await getReportAboutRecruit({ recruitId, dateFrom, dateTo });
  if (!reportData) throw new Error('No info for report');

  return sharePdf({ ...reportData, dateFrom, dateTo });
}

function sharePdf({ recruit, base64Pdf, dateFrom, dateTo }) {
  const dateRange = `${moment(dateFrom).format('MM-DD-YYYY')}_${moment(dateTo).format('MM-DD-YYYY')}`;
  const recruitsName = recruit && recruit.fullName ? recruit.fullName.replace(/[^\w\d_]/gi, '').slice(0, 30) : 'UN';

  // Report_Monica_Geller_10-06-2021_21-06-2021
  const fileName = `Report_${recruitsName || 'UN'}_${dateRange}.pdf`;

  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: `data:application/pdf;base64,${base64Pdf}`,
    download: fileName,
  }).click();

  return { fileName };
}
