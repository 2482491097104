import { Typography } from '@material-ui/core';
import React from 'react';
import useStyles from '../style';

const TextAreaBlock = ({ title, text }) => {
  const classes = useStyles();

  return (
    <div className={classes.textAreaBox}>
      <Typography variant="h5" className={classes.textTitle}>
        {title}
      </Typography>
      <Typography className={classes.descText}>{text ? text : 'No data'}</Typography>
    </div>
  );
};

export default TextAreaBlock;
