import { TextField } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect, useState } from 'react';
import { IconX } from '../../../svg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    '& .MuiAutocomplete-popper': {
      width: '50%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.grey['A400']}`,
    },
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
  star: {
    color: 'red',
  },
}));

export const MultiAutocomplete = (props) => {
  const { title, star, name, options, labelKey = 'name', lastName, onChange, defaultValue, ...rest } = props;
  const classes = useStyles();
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (defaultValue?.length) {
      const arr = [];
      // eslint-disable-next-line array-callback-return
      defaultValue.map((i) => {
        const obj = options.find((j) => j.id === i.id);
        if (obj) arr.push(obj);
      });
      setValue(arr);
    }
  }, [defaultValue, options]);

  const handleChange = useCallback(
    (e, value) => {
      onChange(value.map((i) => i.id));
      setValue(value);
    },
    [onChange]
  );

  return (
    <>
      <h4 className={classes.inputTitle}>
        {title}
        {star && <span className={classes.star}>*</span>}
      </h4>
      <Autocomplete
        onChange={handleChange}
        value={value}
        className={classes.root}
        id={name}
        options={options}
        getOptionLabel={(option) => `${option[labelKey]} ${option[lastName]}`}
        multiple
        filterSelectedOptions
        popupIcon={<AddBoxOutlinedIcon color="primary" />}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              deleteIcon={
                <div className={classes.iconBox}>
                  <IconX />
                </div>
              }
              label={option[labelKey] + option[lastName]}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        {...rest}
      />
    </>
  );
};
