import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    width: 400,
    padding: 60,
    borderRadius: '20px',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 40px 0 rgba(0, 0, 0, 0.2)',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    '&:focus': {
      outline: 'none',
    },
  },
  widthIconSuccess: {
    marginBottom: 20,
    color: theme.palette.error.main,
  },
  informationInstructor: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-all',
    borderTop: '1px solid rgba(115, 115, 139, 0.2)',
    borderBottom: '1px solid rgba(115, 115, 139, 0.2)',
    marginTop: '25px',
    padding: '10px 0',
  },
  modallButoon: {
    backgroundColor: '#ff7373',
    color: '#fff',
    marginTop: 30,
    marginBottom: 25,
    '&:hover': {
      backgroundColor: '#cf5d5d',
    },
  },
  closModallIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: '20px 20px 0 0',
  },
  textChild: {
    color: '#333333',
    padding: '5px 0 5px 0',
  },
}));
