import { useState } from 'react';

export const useStoreTemplateBuilder = () => {
  const [state, dispatch] = useState({
    templateName: '',
    name: '',
    shortName: '',
    assessment: '',
    locationName: '',
    address: '',
    latitude: '',
    organization: null,
    longitude: '',
    search: '',
    customFields: {},
    scoringType: 'four-numeric-stage',
  });

  const actions = {
    addFieldsAfteJuggling: (fields) => {
      dispatch({
        ...state,
        customFields: fields,
      });
    },
    addField: (fieldName) => {
      if (state.customFields.hasOwnProperty(fieldName)) return;
      dispatch({
        ...state,
        customFields: {
          ...state.customFields,
          [fieldName]: '',
        },
      });
    },
    addFieldsFromDB: (fields) => dispatch({ ...state, customFields: fields }),
    completeCustomTextFields: (value, name) =>
      dispatch({ ...state, customFields: { ...state.customFields, [name]: value } }),
    deleteField: (name) => {
      let b = {};
      for (let key in state.customFields) {
        if (key === name) continue;
        b[key] = state.customFields[key];
      }
      dispatch({ ...state, customFields: b });
    },
    changeTemplateName: (templateName) =>
      templateName.split(' ').join('')?.length < 120 && dispatch({ ...state, templateName }),
    changeName: (name) => name.split(' ').join('')?.length < 120 && dispatch({ ...state, name }),
    changeShortName: (shortName) => shortName.split(' ').join('')?.length < 120 && dispatch({ ...state, shortName }),
    changeLocationName: (locationName) => dispatch({ ...state, locationName }),
    changeAddress: (address) => dispatch({ ...state, address }),
    changeAssessment: (assessment) => dispatch({ ...state, assessment }),
    changeCoords: (coords) => dispatch({ ...state, latitude: coords?.lat, longitude: coords?.lng }),
    changeScoringType: (scoringType) => dispatch({ ...state, scoringType }),
    changeSearch: (search) => dispatch({ ...state, search }),
    setTemplate: dispatch,
  };

  return [state, actions, null];
};
