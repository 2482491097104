import React, { useState, useEffect } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 5px',
    '& .MuiTablePagination-toolbar': {
      padding: '0',
    },
    '& span': {
      fontSize: '0.875em',
    },
    '& .MuiTablePagination-select, .MuiTablePagination-selectRoot': {
      display: 'none',
    },
    '& .MuiTypography-colorInherit': {
      color: '#7080a1',
      fontSize: '14px',
    },
  },
}));

const Pagination = (props) => {
  const { onChangePage = () => {}, count = 100 } = props;
  const classes = useStyles(props);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    onChangePage(page);
  }, [onChangePage, page]);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
      labelRowsPerPage=""
      onChangeRowsPerPage={handleChangeRowsPerPage}
      className={classes.root}
    />
  );
};

export default Pagination;
