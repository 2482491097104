/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@material-ui/core';
import SuccessModal from 'components/common/Dialogs/SuccessModal';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormContainer, InputBox } from '../../../../components/common';
import { CheckBoxWithLabel } from '../../../../components/common/Inputs';
import SimpleSnackbar from 'components/common/Snackbar';
import { SUCCESS_MODE, ROLE_SUPER_ADMIN } from '../../../../constants';
import { uploadFileToS3, useRpcApi } from '../../../../services/api';
import DataPicker from 'components/common/Inputs/DataTimePicker';
import { defaultValue, errors, DEFAULT_PASSWORD } from '../../constants';
import SelectAutocomplete from '../../../../components/common/Inputs/SelectAutocomplete';

const [createUser, updateUser, getAvatarUploadUrl, getOrganizationsList] = useRpcApi(
  'admin.createAdmin',
  'admin.updateAdmin',
  'user.getAvatarUploadUrl',
  'organizations.getOrganizationsList'
);

const FormAdmin = ({ currentUser, getUsers, visible, setVisible, activeUser }) => {
  const [open, setOpen] = useState(false);
  const [errorPhoto, setErrorPhoto] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(errors);
  const [isSuperAdmin, setSuperAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const [file, setFile] = useState();
  const [Organizations, setOrganizations] = useState([]);

  useEffect(() => {
    if (activeUser.roleId === ROLE_SUPER_ADMIN) {
      getOrganizationsList().then((res) => {
        const organizationsList = [];
        // eslint-disable-next-line array-callback-return
        res.items.map((val) => {
          organizationsList.push({ id: val.id, title: val.name });
        });
        setOrganizations(organizationsList);
      });
    }
  }, [getOrganizationsList, activeUser]);

  const id = window.location.pathname.split('/')[3];

  useEffect(() => {
    if (currentUser) {
      setValue({
        ...currentUser,
        day: moment(currentUser?.dateOfBirth).format('DD') || '',
        month: moment(currentUser?.dateOfBirth).format('MM') || '',
        year: moment(currentUser?.dateOfBirth).format('YYYY') || '',
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setSuperAdmin(currentUser.roleId === ROLE_SUPER_ADMIN);
    }
  }, [currentUser]);

  const onChangeOpen = () => setOpen((prev) => !prev);

  const goTolist = () => {
    history.push('/admins');
    getUsers();
  };

  const onChange = (e) => {
    let newValye = e.target.value;

    setError(errors);

    if ((newValye && 'lastName' === e.target.name) || (newValye && 'firstName' === e.target.name)) {
      if (!/^[A-Za-z ]+$/.test(newValye)) return;
    }

    if (['phoneNumber', 'emergencyContactPhone'].includes(e.target.name)) {
      newValye = newValye && String(newValye).replace(/[^+\d]/g, '');
    }

    if (newValye && 'badgeNumber' === e.target.name && !/^[\d]+[-]?[\d]*?$/g.test(newValye)) {
      return;
    }

    if (e.target.name === 'd' && newValye && (newValye > 31 || newValye < 1)) {
      newValye = 31;
    }

    if (e.target.name === 'y' && newValye && newValye < 1) {
      newValye = 2020;
    }

    if (e.target.name === 'm' && newValye && (newValye > 12 || newValye < 1)) {
      newValye = 12;
    }

    setValue({ ...value, [e.target.name]: newValye });
  };

  const createOneMore = () => {
    setDate('');
    setValue(defaultValue);
    if (currentUser) {
      history.push('/admins/new-admin');
    }
    onChangeOpen();
  };

  const uploadPhoto = useCallback(
    (value) => {
      if (file) {
        setLoading(true);
        return getAvatarUploadUrl({
          id: value.id,
        })
          .then(async (value) => {
            await uploadFileToS3(value.url.url, file, value.url.fields).then(() => setLoading(false));
          })
          .catch((error) => console.log(error));
      }
    },
    [file, getAvatarUploadUrl, open]
  );

  const getValidDate = (year, month, day) => {
    if (!year && !month && !day) {
      return null;
    }
    try {
      return new Date(year, month - 1, day).toISOString();
    } catch (e) {
      console.log('error getValidDate', e);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isValide = (value) => {
    let result = true;
    let newError = error;
    for (const key in value) {
      if (value.hasOwnProperty(key) && typeof value[key] === 'string') {
        value[key] = value[key]?.trim();
      }
    }
    if (!value.firstName) {
      newError = { ...newError, firstName: 'First Name is required' };
      result = false;
    }

    if (!value.lastName) {
      newError = { ...newError, lastName: 'Last  Name is required' };
      result = false;
    }

    if (!isSuperAdmin && !value.organization && activeUser.roleId === ROLE_SUPER_ADMIN) {
      newError = { ...newError, organization: 'Organization is required' };
      result = false;
    }

    if (!value.email) {
      newError = { ...newError, email: 'Email is required' };
      result = false;
    }

    if (value.phoneNumber) {
      let parsedPhone = value.phoneNumber.replace(/\D/g, '');
      if (parsedPhone.length !== 10) {
        newError = { ...newError, phoneNumber: 'Invalid phone format' };
        result = false;
      }
    }

    if (value.emergencyContactPhone) {
      let parsedPhone = value.emergencyContactPhone.replace(/\D/g, '');
      if (parsedPhone.length !== 10) {
        newError = { ...newError, emergencyContactPhone: 'Invalid phone format' };
        result = false;
      }
    }

    if (value.day || value.month || value.year) {
      // eslint-disable-next-line array-callback-return
      ['day', 'month', 'year'].map((key) => {
        if (!value[key]) {
          newError = { ...newError, [key]: `${key} is required` };
          result = false;
        }
      });
    }

    if (!result) {
      setError(newError);
    }
    return result;
  };

  const onSubmit = useCallback(() => {
    if (!isValide(value)) {
      return;
    }

    const { firstName, lastName, d, m, y } = value;
    setLoading(true);
    const filterObj = (obj) => {
      let result = {};
      for (var key in obj) {
        obj[key] ? (result[key] = obj[key]) : (result[key] = null);
      }
      return result;
    };

    if (id) {
      updateUser(
        filterObj({
          ...value,
          familyName: lastName,
          name: firstName,
          avatar: file ? 1 : value.avatar ? 1 : null,
          super: isSuperAdmin,
          dateOfBirth: getValidDate(y, m, d),
          id,
        })
      )
        .then((value) => uploadPhoto(value))
        .then(() => {
          setVisible(!visible);
          goTolist();
        })
        .catch((error) => setErrorMessage(error?.message))
        .finally(() => {
          setLoading(false);
          setOpen(!open);
          setErrorPhoto(false);
        });
    } else {
      createUser(
        filterObj({
          ...value,
          familyName: lastName,
          name: firstName,
          super: isSuperAdmin,
          avatar: file ? 1 : '',
          password: DEFAULT_PASSWORD,
          dateOfBirth: getValidDate(y, m, d),
        })
      )
        .then((value) => {
          uploadPhoto(value);
          setOpen(!open);
        })
        .catch((error) => setErrorMessage(error?.message))
        .finally(() => {
          setLoading(false);
          setErrorPhoto(false);
          setFile(null);
        });
    }
  }, [value, updateUser, id, createUser, file, uploadPhoto, isValide]);

  return (
    <FormContainer
      hasImg
      titleBtn={id ? 'Save changes' : 'Create Admin'}
      onClick={onSubmit}
      file={file}
      setFile={setFile}
      avatar={value?.avatar}
      errorPhoto={errorPhoto}
      loading={loading}
      setErrorPhoto={setErrorPhoto}
    >
      <Grid container direction="column" spacing={6}>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Personal Information</Typography>
          </Grid>

          <Grid item xs={6}>
            {errorMessage && <SimpleSnackbar message={errorMessage} onClose={() => setErrorMessage('')} />}
            <InputBox
              onInput={onChange}
              name="firstName"
              title="First Name"
              value={value?.firstName || ''}
              error={error.firstName}
              star
            />
          </Grid>
          <Grid item xs={6}>
            <InputBox
              onInput={onChange}
              name="lastName"
              title="Last Name"
              value={value?.lastName || ''}
              error={error.lastName}
              star
            />
          </Grid>
          <Grid item xs={6}>
            <DataPicker
              defaultDate={currentUser?.dateOfBirth || date}
              title="Date of birth"
              onChange={setValue}
              values={value}
              maxDate={new Date()}
            />
          </Grid>
          {activeUser.roleId === ROLE_SUPER_ADMIN ? (
            <Grid item xs={6}>
              <CheckBoxWithLabel
                onInput={() => {
                  setSuperAdmin(!isSuperAdmin);
                }}
                name="super"
                label={'Super Admin'}
                checked={isSuperAdmin}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Service Information</Typography>
          </Grid>
          <Grid item xs={6}>
            <InputBox onInput={onChange} name="badgeNumber" title="Badge Number" value={value?.badgeNumber || ''} />
          </Grid>
          {activeUser.roleId === ROLE_SUPER_ADMIN ? (
            isSuperAdmin ? null : (
              <Grid item xs={6}>
                <SelectAutocomplete
                  setValue={setValue}
                  options={Organizations}
                  name="organization"
                  title="Organization"
                  defaulValue={value}
                  id={value?.organization}
                  error={error.organization}
                  star
                />
              </Grid>
            )
          ) : null}
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Contact Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <InputBox
              onInput={onChange}
              name="email"
              title="Email"
              value={value?.email || ''}
              error={error.email}
              star
            />
          </Grid>
          <Grid item xs={12}>
            <InputBox
              onInput={onChange}
              name="phoneNumber"
              title="Phone Number"
              value={value?.phoneNumber || null}
              inputMask="(999) 999 - 9999"
              error={error.phoneNumber}
            />
          </Grid>

          <Grid item xs={6}>
            <InputBox
              onInput={onChange}
              name="emergencyContactName"
              title="Emergency Contact Name"
              value={value?.emergencyContactName || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <InputBox
              onInput={onChange}
              name="emergencyContactPhone"
              title="Emergency Contact Phone"
              value={value?.emergencyContactPhone || null}
              inputMask="(999) 999 - 9999"
              error={error.emergencyContactPhone}
            />
          </Grid>
          <Grid item xs={12}>
            <InputBox onInput={onChange} name="address" title="Address" value={value?.address || ''} />
          </Grid>
        </Grid>
      </Grid>

      <SuccessModal
        open={open}
        onSubmit={createOneMore}
        onClose={goTolist}
        title={id ? 'Admin successfully updated' : 'Admin successfully created'}
        name={`${value.firstName}  ${value.lastName}`}
        mode={SUCCESS_MODE}
      />
    </FormContainer>
  );
};

export default FormAdmin;
