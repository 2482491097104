import { CellData } from './static';
import {
  CanGetPrevMonth,
  CompareMonth,
  GetCalendarDatesPage,
  GetClearMilisecondsDate,
  GetCurrentMonth,
  GetDaysInMonth,
  GetGroupedData,
  GetGroupedObject,
  GetNextMonth,
  GetPreviousMonth,
  GetRelevantSheduleList,
  GroupedData,
  IsToday,
} from './utils.types';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const monthShortnames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const weekdayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const weekdayShortnames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export const DATE_VARS = {
  millisecond: 1,
  get second() {
    return this.milisecond * 1000;
  },
  get minute() {
    return this.second * 60;
  },
  get hour() {
    return this.minute * 60;
  },
  get day() {
    return this.hour * 24;
  },
  get week() {
    return this.day * 7;
  },
  get year() {
    return this.day * 365;
  },
};

export const getDaysInMonth: GetDaysInMonth = (from) => {
  let date = new Date(from.getFullYear(), from.getMonth(), 1);
  let days = [];
  while (date.getMonth() === from.getMonth()) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const getPrevMonth: GetPreviousMonth = (from) => {
  return new Date(from.getFullYear(), from.getMonth(), 0);
};

export const getNextMonth: GetNextMonth = (from) => {
  const [formYear, fromMonth] = [from.getFullYear(), from.getMonth()];
  return fromMonth === 11 ? new Date(formYear + 1, 0, 1) : new Date(formYear, fromMonth + 1, 1);
};

export const getCurrentMonth: GetCurrentMonth = () => new Date();

export const canGetPrevMonth: CanGetPrevMonth = (from, boundary = getCurrentMonth()) => {
  const [fromYear, boundaryYear] = [from.getFullYear(), boundary.getFullYear()];
  const [fromMonth, boundaryMonth] = [from.getMonth(), boundary.getMonth()];

  if (fromYear === boundaryYear) {
    return fromMonth <= boundaryMonth ? false : true;
  } else if (fromYear < boundaryYear) return false;
  else if (fromYear > boundaryYear) return true;
  else return false;
};

export const isToday: IsToday = (from, today = getCurrentMonth()) => {
  if (
    today.getFullYear() === from.getFullYear() &&
    today.getMonth() === from.getMonth() &&
    today.getDate() === from.getDate()
  )
    return true;
  else return false;
};

export const getClearMilisecondsDate: GetClearMilisecondsDate = (date, isUTC = false) => {
  return isUTC
    ? new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()).getTime()
    : new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
};

export const getClearH_MilisecondsDate: GetClearMilisecondsDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours()).getTime();
};

export const getClearM_MilisecondsDate: GetClearMilisecondsDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()).getTime();
};

export const getRelevantSheduleList: GetRelevantSheduleList = (items, activeMonth) => {
  const cellsData: Array<CellData> = [];
  const dates = getCalendarDatesPage(getDaysInMonth(activeMonth));
  const groupedItems = {};

  for (let index = 0; index < items.length; index++) {
    const item = items[index];
    const clearTime = getClearMilisecondsDate(new Date(item.beginningAt), true);

    if (Array.isArray(groupedItems[clearTime])) {
      groupedItems[clearTime].push(item);
    } else {
      groupedItems[clearTime] = [item];
    }
  }

  for (let index = 0; index < dates.length; index++) {
    const date = dates[index];
    const clearTime = getClearMilisecondsDate(date);

    cellsData.push({ date, clearTime, items: groupedItems[clearTime] || [] });
  }

  return cellsData;
};

export const compareMonth: CompareMonth = (one, two) => {
  return one.getMonth() === two.getMonth();
};

export const getCalendarDatesPage: GetCalendarDatesPage = (monthDates) => {
  const [firstDay, lastDay] = [monthDates[0].getDay(), monthDates[monthDates.length - 1].getDay()];
  let [startBoundary, endBoundary] = [[], []] as Date[][];

  if (firstDay > 0) {
    // добавляем в начало массива недостающие дни недели прошлого месяца
    startBoundary = getDaysInMonth(getPrevMonth(monthDates[0])).slice(-firstDay);
  }

  if (lastDay < 6) {
    // добавляем в конец массива недостающие дни недели следующего месяца
    endBoundary = getDaysInMonth(getNextMonth(monthDates[0])).slice(0, 7 - lastDay - 1);
  }

  // соединяем прошлый, текущий и следующий месяцы
  return startBoundary.concat(monthDates.slice().concat(endBoundary));
};

const getGroupedObject: GetGroupedObject = (item) => ({
  startDay: item.date.getDate(),
  endDay: item.date.getDate(),
  dailyData: [
    {
      data: item.items,
      day: item.date.getDate(),
      weekday: weekdayNames[item.date.getDay()],
      date: item.date,
    },
  ],
});

export const getGroupedData: GetGroupedData = (data, activeMonth) => {
  let filteredData = data.filter(({ date, items }) => date.getMonth() === activeMonth.getMonth() && items.length);
  let resultData: Array<GroupedData> = [];
  filteredData.forEach((item) => {
    if (resultData.length) {
      let existElementIndex = resultData.findIndex(({ endDay }) => endDay + 1 === item.date.getDate());
      if (existElementIndex + 1) {
        resultData[existElementIndex].endDay = item.date.getDate();
        resultData[existElementIndex].dailyData = resultData[existElementIndex].dailyData.concat({
          data: item.items,
          day: item.date.getDate(),
          weekday: weekdayNames[item.date.getDay()],
          date: item.date,
        });
      } else resultData.push(getGroupedObject(item));
    } else resultData.push(getGroupedObject(item));
  });
  return resultData;
};

export const getFilterDiapazon = (month) => {
  let monthDays = getDaysInMonth(month);
  let allDays = getCalendarDatesPage(monthDays);
  return [allDays[0], allDays[allDays.length - 1]];
};

export enum ETimeFormats {
  AM = 'AM',
  PM = 'PM',
}

export const getTimeFromFormatAMPM = (hours, minutes, format) => {
  let m = +minutes;
  let h = hours === '12' ? 0 : +hours;
  if (format === 'pm' && h !== 12) {
    h = parseInt(hours, 10) + 12;
  }
  if (format === 'am' && h === 12) h = 24;
  return [h, m];
};

export const getCorrectEditObject = ({
  scenario,
  platoon,
  startTime,
  startDate,
  selectedInstructors,
  duration,
  criterioId,
}) => {
  let [startHours, startMinutes] = getTimeFromFormatAMPM(startTime.h, startTime.m, startTime.format);
  let startDateTime = new Date(+startDate.y, +startDate.m - 1, +startDate.d, startHours, startMinutes);
  let _startDateTime = new Date(
    `${startDateTime.getDate()} ${
      monthNames[startDateTime.getMonth()]
    } ${startDateTime.getFullYear()} ${startDateTime.getHours()}:${startDateTime.getMinutes()} UTC`
  );

  return {
    duration: duration || 0,
    scenarioId: +scenario,
    platoonId: platoon ? +platoon : null,
    criterioId: criterioId ? +criterioId : null,
    instructors: selectedInstructors?.length ? selectedInstructors.map((item) => item.key) : null,
    beginningAt: _startDateTime.toISOString(),
  };
};

export const getTimeInFormatAMPM = (date) => {
  let hours = date.getUTCHours();
  let minutes: string = date.getUTCMinutes() + '';
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = +minutes < 10 ? String(minutes).padStart(2, '0') : minutes;
  return `${hours}:${minutes} ${ampm}`;
};

export const getCorrectObject = ({
  scenarioId,
  startDate,
  startTime,
  endDate,
  platoonId,
  criterioId,
  instructors,
  activeReccurence,
  duration,
  recurrence,
}) => {
  let startDateTime = new Date(+startDate.y, +startDate.m - 1, +startDate.d, startTime.h, startTime.m);
  let endDateTime: null | Date = null;

  if (endDate?.y && endDate?.m && endDate?.y)
    endDateTime = new Date(+endDate.y, +endDate.m - 1, +endDate.d, startTime.h, startTime.m);

  let _startDateTime = new Date(
    `${startDateTime.getDate()} ${
      monthNames[startDateTime.getMonth()]
    } ${startDateTime.getFullYear()} ${startDateTime.getHours()}:${startDateTime.getMinutes()} UTC`
  );
  let _endDateTime = new Date(
    `${(endDateTime || startDateTime).getDate()} ${monthNames[(endDateTime || startDateTime).getMonth()]} ${(
      endDateTime || startDateTime
    ).getFullYear()} ${(endDateTime || startDateTime).getHours()}:${(endDateTime || startDateTime).getMinutes()} UTC`
  );

  return {
    criterioId: criterioId ? +criterioId : null,
    duration: duration || 0,
    scenarioId: +scenarioId,
    platoonId: platoonId ? +platoonId : null,
    instructors: instructors,
    recurrence: recurrence,
    beginningAt: _startDateTime.toISOString(),
    endAt: _endDateTime.toISOString(),
  };
};
export const getCurrentDay = (from) => {
  let d = new Date(from);

  var weekday = new Array(d.getDay());
  weekday[0] = 'Sunday';
  weekday[1] = 'Monday';
  weekday[2] = 'Tuesday';
  weekday[3] = 'Wednesday';
  weekday[4] = 'Thursday';
  weekday[5] = 'Friday';
  weekday[6] = 'Saturday';

  let n = weekday[d.getDay()];
  return `${n}, ${monthNames[d.getMonth()]} ${d.getUTCDate()}, ${d.getFullYear()}`;
};
