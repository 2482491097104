import { Avatar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ActionButtons, GridTable, TopBar } from '../../../components/common';
import RemoveModal from 'components/common/Dialogs/RemoveModal';
import { useRpcApi } from '../../../services/api';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import { columns } from '../constants';
import useStyles from '../style';
import CircularProgress from '@material-ui/core/CircularProgress';

const [deleteOrganization] = useRpcApi('organizations.deleteOrganization');

const Table = ({
  limit,
  getOrganizations,
  users: data,
  onChangeOffset,
  setValueSearch,
  setSort,
  setNameSort,
  visible,
  setVisible,
  loadingList,
}) => {
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState('');
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    setUsers(data);
  }, [data]);

  useEffect(() => {
    setValueSearch(value);
  }, [setValueSearch, value]);

  const onChangeSearch = (e) => setValue(e.target.value);

  const [open, setOpen] = useState(false);
  const [organizationId, setOrganizationId] = useState(false);
  const [selectOrganization, setSelectOrganization] = useState();
  const onChangeOpen = (id) => {
    setSelectOrganization(users.find((item) => item.id === id));
    setOrganizationId(id);
    setOpen(!open);
  };
  const onDelete = () => {
    deleteOrganization({ id: organizationId }).then(() => {
      getOrganizations().then(() => onChangeOpen());
    });
  };

  const onClickSort = (flag, name) => {
    setSort(flag);
    setNameSort(name);
  };

  const rows = users?.map((row) => [
    <>
      {<Avatar src={row.image?.url} alt="Avatar" />}
      <Typography variant="subtitle2" align={row.align}>
        {row.name}
      </Typography>
    </>,
    <Typography variant="subtitle2" align={row.align}>
      {row.address}
    </Typography>,
    <Typography variant="subtitle2" align={row.align}>
      {row.type}
    </Typography>,
    <ActionButtons
      actions={['edit', 'delete']}
      onEdit={() => history.push(`/organizations/edit-organization/${row.id}`)}
      onDelete={() => onChangeOpen(row.id)}
    />,
  ]);

  return (
    <>
      <SuccessNotification open={visible} text="Organizations" setOpen={setVisible} />

      <TopBar
        title="Organization"
        onChangeSearch={onChangeSearch}
        count={limit}
        btnTitle={!visible && 'Add New Organization'}
        onButton={() => history.push('/organizations/new-organization')}
        onChangePage={onChangeOffset}
      />

      {loadingList ? (
        <div className={classes.widthNotRecruit}>
          <CircularProgress />
        </div>
      ) : rows.length ? (
        <GridTable onSort={onClickSort} columns={columns} rows={rows} />
      ) : (
        <div className={classes.widthNotRecruit}>
          <p>
            There are no admins in the table.{' '}
            <span onClick={() => history.push('/organizations/new-organization')} className={classes.titleAdd}>
              Add New Organization!
            </span>
          </p>
        </div>
      )}

      <RemoveModal
        open={open}
        onClose={onChangeOpen}
        title="Delete this organization?"
        name={selectOrganization?.name}
        onClick={onDelete}
        subText={selectOrganization?.address}
      />
    </>
  );
};

export default Table;
