import { useTheme } from '@material-ui/styles';
import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconManufacturerAdded = (props) => {
  const theme = useTheme();
  const { color = theme.palette.icons.primary, width = 64, height = 64 } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 64 64">
      <g fill="none" fillRule="evenodd">
        <g fill={color}>
          <path
            d="M11.18 14.5c.32.366.498.835.498 1.322v6.263c0 1.105.895 2 2 2h5.163c.537 0 1.051-.216 1.427-.599l8.448-8.6c.774-.788 2.04-.8 2.828-.025.383.376.598.89.598 1.427v5.797c0 1.105.896 2 2 2l4.861-.001c-2.733.872-5.245 2.24-7.428 4h-1.428c-1.104 0-2-.895-2-1.999v-1.459c-.001-.78-.635-1.414-1.416-1.414-.38 0-.743.153-1.009.424l-3.78 3.85c-.375.383-.89.599-1.427.599H9.683c-1.105 0-2-.896-2-2v-3.39c0-.614-.498-1.112-1.112-1.112-.27 0-.531.098-.734.277L4.67 22.887c-.432.38-.679.926-.679 1.501V58c0 1.105.895 2 2 2h19.291c.687 1.422 1.516 2.761 2.469 4H4c-2.21 0-4-1.79-4-4V22.579c0-.575.247-1.122.679-1.501l7.677-6.758c.83-.73 2.093-.649 2.823.18zM46 28c9.941 0 18 8.059 18 18s-8.059 18-18 18-18-8.059-18-18 8.059-18 18-18zm5.765 12.427l-.131.112-8.259 7.723-3.009-2.814-.131-.112c-.808-.636-1.981-.557-2.696.207-.715.764-.714 1.94-.026 2.704l.121.123 4.375 4.09.132.114c.723.568 1.745.568 2.468 0l.132-.113 9.625-9 .121-.123c.688-.764.689-1.94-.026-2.704-.715-.764-1.888-.843-2.696-.207zM21.97 36c1.105 0 2 .895 2 2v1.368c-.578 1.925-.911 3.956-.963 6.057L23 46v4c0 .564.02 1.123.06 1.677-.314.204-.688.323-1.09.323h-7.985c-1.105 0-2-.895-2-2V38c0-1.105.895-2 2-2h7.985zm-2.995 4H16.98c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1h1.995c.552 0 1-.448 1-1v-6c0-.552-.448-1-1-1zM60 0c2.21 0 4 1.79 4 4v23.751c-1.238-.952-2.576-1.78-3.995-2.467V6c0-1.105-.896-2-2-2h-3.99c-1.105 0-2 .895-2 2v17.087c-.443-.038-.89-.064-1.338-.077L50 23h-1.981l.001-19c0-2.21 1.79-4 4-4H60z"
            transform="translate(-688 -200) translate(330 100) translate(157 100) translate(201)"
          />
        </g>
      </g>
    </svg>
  );
};

IconManufacturerAdded.propTypes = simpleIconPropsTypes;
