import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  const location = useLocation();

  const query = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = Object.fromEntries(searchParams.entries());

    return { query, searchParams };
  }, [location.search]);

  return { location, ...query };
};
