import { fade, makeStyles, TextField } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.input,
    borderColor: theme.palette.background.input,
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.light,
    },
    '&:not($focused) > svg': {
      color: fade(theme.palette.text.primary, 0.2),
    },
    '&$focused': {
      '& $notchedOutline': {
        borderColor: theme.palette.primary.light,
      },
    },
  },
  input: {
    '&::placeholder': {
      color: fade(theme.palette.text.primary, 0.2),
    },
  },
  marginDense: {
    borderRadius: theme.shape.borderRadiusAlt,
  },
  inputMarginDense: {
    paddingTop: 14.5,
    paddingBottom: 14.5,
  },
  inputAdornedEnd: {
    paddingRight: 10,
  },
  inputAdornedStart: {
    paddingLeft: 10,
  },
  notchedOutline: {
    borderColor: theme.palette.background.input,
  },
  focused: {},
}));

export const OutlinedInput = ({ InputProps = {}, ...rest }) => {
  const classes = useStyles();

  return <TextField variant="outlined" InputProps={{ classes, labelWidth: 0, ...InputProps }} {...rest} />;
};
