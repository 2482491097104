/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormContainer, InputBox } from 'components/common';
import SuccessModal from 'components/common/Dialogs/SuccessModal';
import { uploadFileToS3, useRpcApi } from '../../../../services/api';
import { ROLE_SUPER_ADMIN, SUCCESS_MODE, VALIDATION_EMAIL } from '../../../../constants';
import SimpleSnackbar from 'components/common/Snackbar';
import DataPicker from 'components/common/Inputs/DataTimePicker';
import { defaultValue, errors, ROLE_RECRUITER, DEFAULT_PASSWORD } from '../../constants';
import SelectAutocomplete from 'components/common/Inputs/SelectAutocomplete';
import { useLabels } from '../../../../components/contexts/AuthContext';

const [
  createUser,
  getAvatarUploadUrl,
  updateUser,
  getPlatoonList,
  getOrganizationsList,
  getPlatoonByRecruitId,
] = useRpcApi(
  'user.createUser',
  'user.getAvatarUploadUrl',
  'user.updateUser',
  'platoon.getPlatoonList',
  'organizations.getOrganizationsList',
  'platoonInRecruit.getPlatoonByRecruitId'
);

const Form = ({ currentUser, visible, setVisible, activeUser }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(errors);
  const [errorPhoto, setErrorPhoto] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [file, setFile] = useState();
  const [platoonByRecruit, setPlatoonByRecruit] = useState();
  const [platoon, setPlatoon] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [validDate, setValidDate] = useState({ errorDate: '' });
  const [Organizations, setOrganizations] = useState([]);
  const [selectOrganizations, setSelectOrganizations] = useState(0);
  const labels = useLabels();

  const id = window.location.pathname.split('/')[3];

  const goTo = () => history.push(`/recruits/recruit-details/${id}`);

  useEffect(() => {
    console.log('----------------', activeUser.roleId);
    if (activeUser.roleId === ROLE_SUPER_ADMIN) {
      getOrganizationsList().then((res) => {
        const organizationsList = [];
        // eslint-disable-next-line array-callback-return
        res.items.map((val) => {
          organizationsList.push({ id: val.id, title: val.name });
        });
        setOrganizations(organizationsList);
      });
    }
  }, [getOrganizationsList]);
  //
  // useEffect(() => {
  //   getPoliceServicesList().then((res) => {
  //     setPoliceServices(res);
  //   });
  // }, [getPoliceServicesList]);

  useEffect(() => {
    getPlatoonList({ organization: selectOrganizations }).then((res) => {
      setPlatoon(res?.items);
    });
  }, [selectOrganizations]);

  useEffect(() => {
    if (value.organization !== selectOrganizations) {
      setValue({ ...value, platoonId: null });
      setSelectOrganizations(value.organization);
    }
  }, [value]);

  useEffect(() => {
    if (id) {
      getPlatoonByRecruitId({ recruitId: id })
        .then((res) => setPlatoonByRecruit(res))
        .catch((e) => console.error(e));
    }
  }, [id]);

  useEffect(() => {
    if (currentUser) {
      setValue({
        ...currentUser,
        d: moment(currentUser?.dateOfBirth).format('DD') || null,
        m: moment(currentUser?.dateOfBirth).format('MM') || null,
        y: moment(currentUser?.dateOfBirth).format('YYYY') || null,
        platoonId: platoonByRecruit?.platoonId,
      });
      setSelectOrganizations(currentUser.organization);
    }
  }, [currentUser, platoonByRecruit]);

  const createOneMore = () => {
    setValue(defaultValue);
    setDate('');
    setFile('');
    if (currentUser) {
      history.push('/recruits/new-recruit');
    }
    setOpen(!open);
  };

  const onChange = (e) => {
    let newValye = e.target.value;
    setError(errors);

    if ((newValye && 'lastName' === e.target.name) || (newValye && 'firstName' === e.target.name)) {
      if (!/^[A-Za-z ]+$/.test(newValye)) return;
    }

    if (newValye && 'badgeNumber' === e.target.name && !/^[\d]+[-]?[\d]*?$/g.test(newValye)) {
      return;
    }

    if (e.target.name === 'd' && newValye && (newValye > 31 || newValye < 1)) {
      newValye = 31;
    }
    if (e.target.name === 'm' && newValye && (newValye > 12 || newValye < 1)) {
      newValye = 12;
    }
    if (e.target.name === 'y' && newValye && (newValye > 2999 || newValye < 1)) {
      newValye = 2020;
    }

    setValue({ ...value, [e.target.name]: newValye });
  };

  const uploadPhoto = useCallback(
    (value) => {
      if (file) {
        return getAvatarUploadUrl({ id: value.id }).then(async (value) => {
          setLoading(true);
          await uploadFileToS3(value.url.url, file, value.url.fields)
            .then(() => setLoading(false))
            .catch((e) => console.log(e));
        });
      }
    },
    [file, getAvatarUploadUrl, open]
  );

  const getValidDate = (year, month, day) => {
    if (!year && !month && !day) {
      return null;
    }
    try {
      return new Date(year, month - 1, day).toISOString();
    } catch (e) {
      console.log('error getValidDate', e);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isValide = (value) => {
    let result = true;
    let newError = error;

    for (const key in value) {
      // eslint-disable-next-line
      value.hasOwnProperty(key) && typeof value[key] === 'string' && (value[key] = value[key]?.trim());
    }
    if (!value.firstName) {
      newError = { ...newError, firstName: 'First Name is required' };
      result = false;
    }

    if (activeUser.roleId === ROLE_SUPER_ADMIN && !value.organization) {
      newError = { ...newError, organization: 'Organization is required' };
      result = false;
    }

    if (!value.lastName) {
      newError = { ...newError, lastName: 'Last  Name is required' };
      result = false;
    }

    if (!value.email) {
      newError = { ...newError, email: 'Email is required' };
      result = false;
    }

    if (value.email) {
      const valid = VALIDATION_EMAIL.test(value.email);

      if (!valid) {
        newError = { ...newError, email: 'Email must be a valid email' };
        result = false;
      }
    }

    if (value.phoneNumber) {
      let parsedPhone = value.phoneNumber.replace(/\D/g, '');
      if (parsedPhone.length !== 10) {
        newError = { ...newError, phoneNumber: 'Invalid phone format' };
        result = false;
      }
    }

    if (value.emergencyContactPhone) {
      let parsedPhone = value.emergencyContactPhone.replace(/\D/g, '');
      if (parsedPhone.length !== 10) {
        newError = { ...newError, emergencyContactPhone: 'Invalid phone format' };
        result = false;
      }
    }

    if (value.day || value.month || value.year) {
      // eslint-disable-next-line array-callback-return
      ['day', 'month', 'year'].map((key) => {
        if (!value[key]) {
          newError = { ...newError, [key]: `${key} is required` };
          result = false;
        }
      });
    }

    if (value.year < 1900) {
      newError = { ...newError, year: 'Year invalid data' };
      setErrorMessage(newError.year);
      result = false;
    }

    if (validDate?.errorDate) {
      result = false;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (!result) {
      setError(newError);
    }
    return result;
  };

  const onSubmit = useCallback(() => {
    if (!isValide(value)) {
      return;
    }
    setLoading(true);

    const { firstName, lastName, d, m, y, platoonId } = value;
    if (currentUser?.id) {
      const obj = {
        ...value,
        name: firstName,
        familyName: lastName,
        platoonId: platoonId || null,
        avatar: file ? 1 : value.avatar ? 1 : null,
        dateOfBirth: getValidDate(y, m, d),
        id: currentUser?.id,
      };

      updateUser(obj)
        .then((value) => uploadPhoto(value))
        .then(() => {
          setLoading(false);
          setOpen(!open);
          setErrorPhoto(false);
        })
        .catch((error) => setErrorMessage(error?.message))
        .finally(() => {
          goTo();
          setVisible(!visible);
        });
    } else {
      createUser({
        ...value,
        name: firstName,
        familyName: lastName,
        role: ROLE_RECRUITER,
        password: DEFAULT_PASSWORD,
        avatar: file ? 1 : null,
        dateOfBirth: getValidDate(y, m, d),
        id: currentUser?.id,
      })
        .then((value) => uploadPhoto(value))
        .then(() => setOpen(!open))
        .catch((error) => setErrorMessage(error?.message))
        .finally(() => {
          setLoading(false);
          setErrorPhoto(false);
        });
    }
  }, [value, file, currentUser, updateUser, createUser, uploadPhoto, isValide]);

  return (
    <>
      {errorMessage && <SimpleSnackbar message={errorMessage} onClose={() => setErrorMessage('')} />}
      <FormContainer
        hasImg
        titleBtn={id ? 'Save changes' : `Create ${labels.recruit}`}
        isSave={!!currentUser}
        onClick={onSubmit}
        file={file}
        setFile={setFile}
        avatar={value?.avatar}
        errorPhoto={errorPhoto}
        setErrorPhoto={setErrorPhoto}
        alt={currentUser?.firstName}
        loading={loading}
      >
        <Grid container direction="column" spacing={6}>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">Personal Information</Typography>
            </Grid>
            <Grid item xs={6}>
              <InputBox
                onInput={onChange}
                name="firstName"
                title="First Name"
                value={value?.firstName || ''}
                error={error.firstName}
                star
              />
            </Grid>
            <Grid item xs={6}>
              <InputBox
                onInput={onChange}
                name="lastName"
                title="Last Name"
                value={value?.lastName || ''}
                error={error.lastName}
                star
              />
            </Grid>
            <Grid item xs={6}>
              <DataPicker
                defaultDate={currentUser?.dateOfBirth || date}
                title="Date of birth"
                onChange={setValue}
                values={value}
                setError={setValidDate}
                error={validDate}
                maxDate={new Date()}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">Service Information</Typography>
            </Grid>
            <Grid item xs={6}>
              <InputBox onInput={onChange} name="badgeNumber" title="Badge Number" value={value?.badgeNumber || ''} />
            </Grid>
            {activeUser.roleId !== ROLE_SUPER_ADMIN ? (
              <Grid item xs={6}>
                <SelectAutocomplete
                  setValue={setValue}
                  options={platoon}
                  labelKey="name"
                  name="platoonId"
                  title={labels.platoon}
                  defaulValue={value}
                  id={value?.platoonId}
                />
              </Grid>
            ) : null}
            {activeUser.roleId === ROLE_SUPER_ADMIN ? (
              <Grid item xs={6}>
                <SelectAutocomplete
                  setValue={setValue}
                  options={Organizations}
                  name="organization"
                  title="Organization"
                  defaulValue={value}
                  id={value?.organization}
                  error={error.organization}
                  star
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">Contact Information</Typography>
            </Grid>
            <Grid item xs={12}>
              {!value?.id && (
                <InputBox
                  onInput={onChange}
                  name="email"
                  title="Email"
                  value={value?.email || ''}
                  error={error?.email}
                  star
                />
              )}
              {value?.id && (
                <span style={{ fontSize: '13px', fontWeight: 'bold', marginBottom: 30, color: '#7080a1' }}>Email</span>
              )}
              {value?.id && <br></br>}
              {value?.id && <br></br>}
              {value?.id && (
                <Typography variant="h6">
                  <span> {currentUser?.email || '-'} </span>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputBox
                onInput={onChange}
                name="phoneNumber"
                title="Phone Number"
                value={value?.phoneNumber || null}
                inputMask="(999) 999 - 9999"
                error={error?.phoneNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <InputBox
                onInput={onChange}
                name="emergencyContactName"
                title="Emergency Contact Name"
                value={value?.emergencyContactName || ''}
              />
            </Grid>
            <Grid item xs={6}>
              <InputBox
                onInput={onChange}
                name="emergencyContactPhone"
                title="Emergency Contact Phone"
                value={value?.emergencyContactPhone || null}
                inputMask="(999) 999 - 9999"
                error={error?.emergencyContactPhone}
              />
            </Grid>
            <Grid item xs={12}>
              <InputBox onInput={onChange} name="address" title="Address" value={value?.address || ''} />
            </Grid>
          </Grid>
        </Grid>
      </FormContainer>
      <SuccessModal
        onSubmit={createOneMore}
        open={open}
        onClose={() => {
          history.goBack();
        }}
        title={`${labels.recruit} successfully created`}
        name={`${value?.firstName} ${value?.lastName}`}
        mode={SUCCESS_MODE}
      />
    </>
  );
};

export default Form;
