import { IconButton, Paper, Typography } from '@material-ui/core';
import downArrow from 'components/assets/images/down-arrow.svg';
import upArrow from 'components/assets/images/up-arrow.svg';
import React from 'react';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import useStyles from '../style';

const ActionBlockDetails = ({ title, text, data }) => {
  const [open, setOpen, setClose] = useBooleanHandlers(true);
  const classes = useStyles();

  return !data ? null : (
    <Paper className={classes.rootTablePaper}>
      <div className={classes.textAreaBox}>
        <div className={classes.visibleMap}>
          {open ? (
            <Typography style={{ borderBottom: '1px solid #515e74', width: '100%' }} variant="h5">
              {title}
            </Typography>
          ) : (
            <Typography variant="h5">{title}</Typography>
          )}
          <IconButton color="primary" onClick={open ? setClose : setOpen}>
            <img src={open ? upArrow : downArrow} alt="arrow" className={classes.downArrow} />
          </IconButton>
        </div>
        {open && (
          <Typography className={classes.descText} component="div">
            {data ? <Typography>{data ? data : 'is empty'}</Typography> : text}
          </Typography>
        )}
      </div>
    </Paper>
  );
};

export default ActionBlockDetails;
