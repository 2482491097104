import { Button, Grid, IconButton, Popover, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { IconX } from '../../../../components/svg';
import { compareMonth, isToday as _isToday, weekdayNames, weekdayShortnames } from '../../utils.ts';
import Item from '../Item/Item';
import useStyles from './style';

const Cell = (props) => {
  const classes = useStyles();
  const {
    index,
    data,
    activeMonth,
    getSchedulesList,
    setScheduleData,
    setShedleInstructors,
    setIdSchedule,
    setOpen,
    shedleInstructors,
  } = props;
  const [filteredItems, setFilteredItems] = useState([]);
  const [restLength, setRestlength] = useState(0);

  const canShowWeekday = index < 7;
  const isToday = useMemo(() => _isToday(data.date), [data.date]);
  const isActiveMonth = useMemo(() => compareMonth(activeMonth, data.date), [activeMonth, data.date]);

  useEffect(() => {
    const items = data.items;
    setFilteredItems(items.slice(0, 3));
    setRestlength(items.slice(3).length);
  }, [data.items]);

  const [openPopup, setOpenPopup] = useState(null);

  const handleClick = (event) => {
    setOpenPopup(event.currentTarget);
  };

  const handleClose = () => {
    setOpenPopup(null);
  };

  const open = Boolean(openPopup);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={clsx(classes.root, !isActiveMonth && classes.cellInactiveContainer)}>
      {canShowWeekday && (
        <Typography variant="body2" color="textSecondary" style={{ marginTop: 10 }}>
          {weekdayShortnames[index]}
        </Typography>
      )}
      <div className={clsx(classes.dayText, isToday && classes.cellActiveDay)}>
        <Typography variant="body2">{data.date.getDate()}</Typography>
      </div>
      <Grid container direction="column">
        {filteredItems.map((items, index) => (
          <Item
            key={index}
            data={items}
            getSchedulesList={getSchedulesList}
            setIdSchedule={setIdSchedule}
            setScheduleData={setScheduleData}
            setShedleInstructors={setShedleInstructors}
            setOpen={setOpen}
            setOpenPopup={setOpenPopup}
            shedleInstructors={shedleInstructors}
          />
        ))}
      </Grid>
      {!!restLength && (
        <div className={classes.moreBox}>
          <Button color="primary" aria-describedby={id} onClick={handleClick}>
            <Typography color="primary">{restLength} more</Typography>
          </Button>
        </div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={openPopup}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <IconButton color="primary" size="small" className={classes.closeBtn} onClick={handleClose}>
          <IconX width={10} height={10} />
        </IconButton>
        <Grid container className={classes.viewMore} direction="column" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            {weekdayNames[index]}
          </Typography>
          <Typography variant="h4">{data.date.getDate()}</Typography>
          {data.items.map((item) => (
            <Item
              key={item.id}
              data={item}
              index={index}
              getSchedulesList={getSchedulesList}
              setScheduleData={setScheduleData}
              setShedleInstructors={setShedleInstructors}
              setOpen={setOpen}
              setOpenPopup={setOpenPopup}
              shedleInstructors={shedleInstructors}
            />
          ))}
        </Grid>
      </Popover>
    </div>
  );
};

export default Cell;
