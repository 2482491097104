import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  widthMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#0e4bad',
    height: '100vh',
    position: 'sticky',
    top: '0',
  },
  root: {
    backgroundColor: '#0e4bad',
    maxWidth: '240px',
  },
  sticky: {
    padding: '20px 20px 0 20px',
  },
  activeMenu: {
    backgroundColor: '#0e4bad',
    maxWidth: '80px',
  },
  userData: {
    display: 'flex',
    marginBottom: '25px',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    color: '#ffffff',
    overflow: 'hidden',
  },
  userName: {
    fontSize: '14px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  userEmail: {
    fontSize: '13px',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  userLogo: {
    fontSize: '17px',
    color: '#0e4bad',
    fontWeight: 'bold',
    padding: 2,
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    marginRight: '10px',
    width: '40px',
    height: '40px',

    '& > img': {
      borderRadius: 10,
    },
  },
  borderTop: {
    borderTop: '1px solid rgba(255, 255, 255, 0.16)',
    paddingTop: '20px',
  },
  widthItems: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '10px',
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
  itemName: {
    color: '#ffffff',
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  icon: {
    width: '20px',
    height: '20px',
    margin: '10px 10px 10px 10px',
  },
  logoutBorder: {
    marginTop: '15px',
    padding: '20px 0 11px 0',
    borderTop: '1px solid rgba(255, 255, 255, 0.16)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.16)',
  },
  copyright: {
    color: 'rgba(255, 255, 255, 0.5)',
    marginTop: '30px',
  },
  terms: {
    textDecoration: 'underline',
  },
  iconHide: {
    width: '12px',
    height: '12px',
    margin: '10px 20px 10px 9px',
    transform: 'rotate(0deg)',
  },
  iconRotate: {
    transform: 'rotate(180deg)',
  },
  closeMenu: {
    position: 'sticky',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: '60px',
    paddingLeft: '25px',
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#ffffff',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  active: {
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}));

export default useStyles;
