export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const columns = [
  { id: 'name', title: 'Name', align: 'left', xs: 4, isSort: true },
  { id: 'date', title: 'Date', align: 'left', xs: 2, isSort: true },
  { id: 'roles', title: 'Role', align: 'left', xs: 2 },
  { id: 'behaviours', title: 'Behaviour', align: 'left', xs: 2 },
  { id: 'grade', title: 'Grade', align: 'right', xs: 2, isSort: true },
];

export const defaultValue = {
  firstName: '',
  lastName: '',
  policeServiceId: null,
  badgeNumber: null,
  platoonId: null,
  email: '',
  phoneNumber: null,
  address: '',
  emergencyContactName: '',
  emergencyContactPhone: null,
  organization: null,
  d: null,
  m: null,
  y: null,
};

export const errors = {
  m: '',
  y: '',
  d: '',
  name: '',
  firstName: '',
  lastName: '',
  email: '',
};

export const ROLE_RECRUITER = 2;

export const DEFAULT_PASSWORD = 'A12345678*a';

export const roles = ['None', 'Assistant Team Leader', 'Contact', 'Cover', 'Point', 'Rear Guard', 'Team Leader'];

export const behaviours = ['None', 'Active', 'Assaultive', 'Bodily Harm', 'Cooperative', 'Passive'];

const getClearMilisecondsDate = (date, isUTC = false) => {
  return isUTC
    ? new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()).getTime()
    : new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
};

export const getTodayFilterDiapazon = (returnDate = false) => {
  const getTimezoneOffset = (date) => date.getTimezoneOffset() * 60000;

  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const clearStartBooundaryTime = getClearMilisecondsDate(today, true) - getTimezoneOffset(today);
  const clearEndBoundaryTime = getClearMilisecondsDate(tomorrow, true) - getTimezoneOffset(tomorrow);
  if (returnDate) {
    return [new Date(clearStartBooundaryTime), new Date(clearEndBoundaryTime)];
  } else {
    return [clearStartBooundaryTime, clearEndBoundaryTime];
  }
};
