/* eslint-disable no-use-before-define */
import React, { useMemo } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  inputTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
  star: {
    color: 'red',
    marginLeft: '3px',
  },
  root: {
    backgroundColor: '#fff',
    width: '100%',
    height: 52,
    '& .MuiFormControl-root': {
      height: '52px',
    },
    '& .MuiInputBase-root': {
      height: 52,
      border: null,
    },
  },
}));

const Link = ({ children }) => <Paper style={{ width: '100%' }}>{children}</Paper>;

const Select = ({ options, value, name, title, onChange, nameIndex, star, customClass, id, ...rest }) => {
  const classes = useStyles();

  const index = useMemo(() => options?.findIndex((x) => String(x[nameIndex]) === String(value)), [
    options,
    nameIndex,
    value,
  ]);

  return (
    <>
      {title && (
        <h4 className={classes.inputTitle}>
          {title}
          {star && <span className={classes.star}>*</span>}
        </h4>
      )}
      <Autocomplete
        id={id}
        value={(index !== -1 && options && options[index]) || 'None'}
        onChange={onChange}
        className={clsx(classes.root, customClass && customClass)}
        options={options}
        PaperComponent={Link}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option[name])}
        renderInput={(params) => <TextField {...params} style={{ margin: 0, padding: 0 }} variant="outlined" />}
        {...rest}
      />
    </>
  );
};

export default Select;
