import React, { useContext, useReducer } from 'react';

const initialValue = {
  activeRoute: '',
  defaultRoute: '',
  itemsCounts: {},
};

const MenuContex = React.createContext(initialValue);

export function useAppMenuContext() {
  return useContext(MenuContex);
}

export const MenuContexProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer((prevState, newState) => Object.assign({}, prevState, newState), initialValue);

  const value = { state, dispatch };
  return <MenuContex.Provider value={value}>{children}</MenuContex.Provider>;
};
