export const addMask = (number) => {
  if (!number) return;
  if (number.includes('(')) return number;
  number = number.replace('+', '');
  return [' (', number.slice(0, 3), ') ', number.slice(3, 6), ' - ', number.slice(6, 10)].join('');
};

export const downloadPhotos = (urls) => {
  let interval = setInterval(download, 1500, urls);

  function download(urls) {
    const url = urls.pop();
    let a = document.createElement('a');
    a.setAttribute('href', url.photoUrl);
    a.setAttribute('download', url.key);
    a.click();
    a.remove();
    if (urls.length === 0) {
      clearInterval(interval);
    }
  }
};

export const MAX_SIZE_VIDEO = 838860800;
export const MAX_SIZE_PHOTO = 10000000;
