import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconX = (props) => {
  const { width = 8, height = 8 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 8 8">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M0 0H8V8H0z"
                    transform="translate(-573 -282) translate(420 30) translate(40 206) translate(9 33) translate(104 13)"
                  />
                  <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4">
                    <path
                      d="M5 0L0 5M0 0L5 5"
                      transform="translate(-573 -282) translate(420 30) translate(40 206) translate(9 33) translate(104 13) translate(1.5 1.5)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

IconX.propTypes = simpleIconPropsTypes;
