import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRpcApi } from 'services/api';
import { useAuth } from '../../components/contexts/AuthContext';
import { AuthSidebar } from 'components/common';
import { BasicTextField } from 'components/common/Inputs/TextField/index';
import { Button } from 'components/common/Buttons';
import checkEmailIcon from 'components/assets/images/icoCheckEmail.png';
import iconSuccesss from 'components/assets/images/ico-successs.png';
import Logo from 'components/assets/images/logo.png';
import clsx from 'clsx';
import Auth from '@aws-amplify/auth';
import useStyles from './style';
import Section from '../../components/common/Section';

const [changePassword] = useRpcApi('changePassword');

const Login = () => {
  const [value, setValue] = useState({ login: '', password: '', newPassword: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();
  const { pathname } = useLocation();
  const history = useHistory();
  const s = useStyles();

  const id = pathname.split('/')[2];

  const goTo = useCallback((path) => () => history.push(path), [history]);

  const onChange = (e) => setValue({ ...value, [e.target.name]: e.target.value });

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const { login, password } = value;
      if (!login || !password) return setError('Fields cannot be empty.');
      setLoading(true);

      signIn(login, password)
        .then(() => setError(''))
        .catch((error) => {
          if (error.code === 'InvalidParameterException') {
            setError('Username or password are incorrect');
          } else if (error?.message === 'Incorrect username or password.' || 'User does not exist.') {
            setError('Username or password are incorrect.');
          } else {
            setError(error?.message || '');
          }
        })
        .finally(() => setLoading(false));
    },
    [signIn, value]
  );

  const onSubmitChange = useCallback(() => {
    const { newPassword } = value;
    if (!newPassword) return setError('Fields cannot be empty.');
    setLoading(true);

    changePassword({ id, password: newPassword })
      .then(() => {
        setError('');
        goTo('/password-changed')();
      })
      .catch((error) => setError(error?.message || ''))
      .finally(() => setLoading(false));
  }, [goTo, id, value]);

  const actionForgotPassword = () => {
    const { email } = value;
    if (!email) return setError('Fields cannot be empty.');
    setLoading(true);
    //Send confirmation code to user's email
    Auth.forgotPassword(email)
      .then(() => {
        setError('');
        goTo('/check-email')();
      })
      .catch((error) => setError(error?.message || ''))
      .finally(() => setLoading(false));
  };

  const obj = {
    '/login': () => (
      <div className={clsx(s.rightSection, s.rightSectionWidth)}>
        <div className={s.top}>
          <h2 className={s.title}>Sign In</h2>
          <form onSubmit={onSubmit} className={s.formContainer}>
            <div className={s.inputs}>
              <BasicTextField customClass={s.inputFirst} placeholder="Login" onChange={onChange} name="login" />
              <BasicTextField
                customClass={s.inputLast}
                placeholder="Password"
                type="password"
                onChange={onChange}
                name="password"
              />
            </div>
            <div className={s.textError}>{error}</div>
            <Button customClass={s.btnSubmit} type="submit" loading={loading} text="Sign me in" />
          </form>
        </div>

        <div className={s.forgotText}>
          Forgot your password?
          <span className={s.reset} onClick={goTo('/reset-password')}>
            Reset
          </span>
        </div>
      </div>
    ),
    '/reset-password': () => (
      <Section
        title="Reset Password"
        desc="Please enter the email you received the invitation to"
        placeholder="Email"
        textBtn="Send request"
        onChange={onChange}
        name="email"
        onClick={actionForgotPassword}
        style={s}
        logo={Logo}
        error={error}
      />
    ),
    [`/new-password/${id}`]: () => (
      <Section
        title="Set New Password"
        desc="For more security the password should contain numbers and uppercase letters"
        placeholder="New Password"
        textBtn="Change Password"
        type="password"
        name="newPassword"
        loading={loading}
        error={error}
        onChange={onChange}
        style={s}
        onClick={onSubmitChange}
        logo={Logo}
      />
    ),
    '/check-email': () => (
      <Section
        title="Check your email"
        desc="We have sent instructions to "
        email={value.email}
        img={checkEmailIcon}
        textBtn="Didn’t receive the email? "
        colorText=" Resend"
        style={s}
        showInput={false}
        btnClass={s.checkBtn}
        onClick={actionForgotPassword}
      />
    ),
    '/password-changed': () => (
      <Section
        title="Password changed"
        desc="Now you can use the new password to sign in"
        textBtn="Go to Sign In"
        img={iconSuccesss}
        showInput={false}
        style={s}
        btnClass={clsx(s.checkBtn, s.checkBtnChanged)}
        showSignIn={false}
        onClick={goTo('/login')}
      />
    ),
    '/check-email-success': () => (
      <Section
        title="Password set successfully"
        img={checkEmailIcon}
        textBtn="Sign In"
        style={s}
        onClick={goTo('/login')}
        showInput={false}
        showSignIn={false}
      />
    ),
    '/change-password-success': () => (
      <Section
        title="Password set successfully"
        img={checkEmailIcon}
        textBtn="Sign In"
        style={s}
        onClick={goTo('/login')}
        showInput={false}
        showSignIn={false}
      />
    ),
  };

  const content = obj[pathname] || obj['/login'];

  return (
    <section className={s.container}>
      <AuthSidebar />

      {content()}
    </section>
  );
};

export default Login;
