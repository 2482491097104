import React from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import AuthRoute from './components/common/AuthRoute';
import { Loader } from 'components/common/Loader';
import { useAuth } from 'components/contexts/AuthContext';
import AuthLayout from 'components/layouts/AuthLayout';
import AppLayout from 'components/layouts/AppLayout';
import ChangePassword from './containers/ChangePassword';
import {
  ROLE_ADMIN,
  ROLE_INSTRUCTOR,
  ROUTE_LOGIN,
  ROUTE_RESET_PASSWORD,
  ROUTE_NEW_PASSWORD,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_CHANGE_PASSWORD_SUCCESS,
  ROUTE_TERMS_CONDITIONS,
  ROUTE_NEW_CHECK_EMAIL,
  ROUTE_NEW_PASSWORD_CHANGED,
  ROUTE_RECRUITS,
  ROUTE_SCENARIOS,
  ROUTE_PLATOONS,
  ROUTE_INSTRUCTORS,
  ROUTE_CALENDAR,
  ROUTE_SETTINGS,
  ROUTE_CREATE_NEW_INSTRUCTOR,
  ROUTE_EDIT_INSTRUCTORS,
  ROUTE_EDIT_SCENARIOS,
  ROUTE_EVENT_EDIT,
  ROUTE_NEW_CHECK_EMAIL_SUCCESS_PASSWORD,
  ROUTE_EVENT_DETAILS,
  ROUTE_ADMINS,
  ROUTE_CREATE_NEW_ADMINS,
  ROUTE_EDIT_ADMINS,
  ROUTE_ORGANIZATIONS,
  ROUTE_EDIT_ORGANIZATIONS,
  ROUTE_CREATE_NEW_ORGANIZATIONS,
  ROLE_SUPER_ADMIN,
  ROUTE_MULTI_ASSESSMENT,
  ROUTE_TEMPLATES,
  ROUTE_ADD_TEMPLATE,
  ROUTE_EDIT_TEMPLATE,
  ROLE_RECRUIT,
} from './constants';
import {
  Login,
  TermAndConditions,
  Scenarios,
  Recruits,
  Platoons,
  Instructors,
  Calendar,
  Settings,
  ScheduleDetails,
  Admins,
  Organizations,
  Templates,
} from 'containers';

const App = () => {
  const { isAuthenticated, pending, user } = useAuth();

  let redirectPath = ROUTE_LOGIN.path;
  if (isAuthenticated && (user?.roleId === ROLE_INSTRUCTOR || user?.roleId === ROLE_ADMIN)) {
    redirectPath = ROUTE_CALENDAR.path;
  } else if (isAuthenticated && user?.roleId === ROLE_RECRUIT) {
    redirectPath = ROUTE_SETTINGS.path;
  } else if (isAuthenticated) {
    redirectPath = ROUTE_RECRUITS.path;
  }

  return pending ? (
    <Loader fixed open />
  ) : (
    <Router>
      <Switch>
        <AuthRoute
          path={[
            ROUTE_LOGIN.path,
            ROUTE_RESET_PASSWORD.path,
            ROUTE_NEW_PASSWORD.path,
            ROUTE_CHANGE_PASSWORD_SUCCESS.path,
            ROUTE_NEW_CHECK_EMAIL.path,
            ROUTE_NEW_CHECK_EMAIL_SUCCESS_PASSWORD.path,
            ROUTE_NEW_PASSWORD_CHANGED.path,
          ]}
          noAuth
          redirect={ROUTE_RECRUITS.path}
        >
          <AuthLayout>
            <Login />
          </AuthLayout>
        </AuthRoute>
        <AuthRoute path={ROUTE_CHANGE_PASSWORD.path} noAuth redirect={ROUTE_RECRUITS.path}>
          <AuthLayout>
            <ChangePassword />
          </AuthLayout>
        </AuthRoute>
        <AuthRoute path={ROUTE_TERMS_CONDITIONS.path} noAuth redirect={ROUTE_RECRUITS.path}>
          <AuthLayout>
            <TermAndConditions />
          </AuthLayout>
        </AuthRoute>

        {/* Authorized routes */}

        <AuthRoute
          path={ROUTE_RECRUITS.path}
          roles={[ROLE_INSTRUCTOR, ROLE_ADMIN, ROLE_SUPER_ADMIN]}
          redirect={ROUTE_LOGIN.path}
        >
          <AppLayout>
            <Recruits user={user} />
          </AppLayout>
        </AuthRoute>
        <AuthRoute
          path={[ROUTE_TEMPLATES.path, ROUTE_ADD_TEMPLATE.path, ROUTE_EDIT_TEMPLATE.path]}
          roles={[ROLE_INSTRUCTOR, ROLE_ADMIN, ROLE_SUPER_ADMIN]}
          redirect={ROUTE_LOGIN.path}
        >
          <AppLayout>
            <Templates user={user} />
          </AppLayout>
        </AuthRoute>
        <AuthRoute
          path={[ROUTE_SCENARIOS.path, ROUTE_EDIT_SCENARIOS.path]}
          redirect={ROUTE_LOGIN.path}
          roles={[ROLE_INSTRUCTOR, ROLE_ADMIN, ROLE_SUPER_ADMIN]}
        >
          <AppLayout>
            <Scenarios user={user} />
          </AppLayout>
        </AuthRoute>
        <AuthRoute path={ROUTE_PLATOONS.path} roles={[ROLE_INSTRUCTOR, ROLE_ADMIN]} redirect={ROUTE_LOGIN.path}>
          <AppLayout>
            <Platoons user={user} />
          </AppLayout>
        </AuthRoute>
        <AuthRoute
          roles={[ROLE_INSTRUCTOR, ROLE_ADMIN, ROLE_SUPER_ADMIN]}
          path={ROUTE_INSTRUCTORS.path}
          redirect={ROUTE_LOGIN.path}
        >
          <AppLayout>
            <Instructors user={user} />
          </AppLayout>
        </AuthRoute>
        <AuthRoute path={ROUTE_CALENDAR.path} roles={[ROLE_INSTRUCTOR, ROLE_ADMIN]} redirect={ROUTE_LOGIN.path}>
          <AppLayout>
            <Calendar user={user} />
          </AppLayout>
        </AuthRoute>
        <AuthRoute
          roles={[ROLE_INSTRUCTOR, ROLE_ADMIN, ROLE_SUPER_ADMIN]}
          path={[ROUTE_EVENT_DETAILS.path, ROUTE_EVENT_EDIT.path, ROUTE_MULTI_ASSESSMENT.path]}
          redirect={ROUTE_LOGIN.path}
        >
          <AppLayout>
            <ScheduleDetails />
          </AppLayout>
        </AuthRoute>
        <AuthRoute path={ROUTE_SETTINGS.path} redirect={ROUTE_LOGIN.path}>
          <AppLayout>
            <Settings user={user} />
          </AppLayout>
        </AuthRoute>
        <AuthRoute
          path={[ROUTE_CREATE_NEW_INSTRUCTOR.path, ROUTE_EDIT_INSTRUCTORS.path]}
          redirect={ROUTE_LOGIN.path}
          roles={[ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_INSTRUCTOR]}
        >
          <AppLayout>
            <Instructors user={user} />
          </AppLayout>
        </AuthRoute>

        <AuthRoute
          path={[ROUTE_ADMINS.path, ROUTE_CREATE_NEW_ADMINS.path, ROUTE_EDIT_ADMINS.path]}
          roles={[ROLE_SUPER_ADMIN, ROLE_ADMIN]}
          redirect={ROUTE_LOGIN.path}
        >
          <AppLayout>
            <Admins user={user} />
          </AppLayout>
        </AuthRoute>

        <AuthRoute
          path={[ROUTE_ORGANIZATIONS.path, ROUTE_CREATE_NEW_ORGANIZATIONS.path, ROUTE_EDIT_ORGANIZATIONS.path]}
          roles={[ROLE_SUPER_ADMIN]}
          redirect={ROUTE_LOGIN.path}
        >
          <AppLayout>
            <Organizations />
          </AppLayout>
        </AuthRoute>

        <Redirect exact path="/*" to={redirectPath} />
      </Switch>
    </Router>
  );
};

export default App;
