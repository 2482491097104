import { useState } from 'react';
import { jsonParse } from '../../../../utils/helpers';

export const useCreateCriterioStore = () => {
  const [state, dispatch] = useState({
    name: '',
    criterios: [
      {
        number: 1,
        text: '',
      },
    ],
  });

  const actions = {
    changeName: (name) => name.split(' ').join('')?.length < 140 && dispatch({ ...state, name }),
    changeEvaluation: (criterios) => dispatch({ ...state, criterios }),
    addCriteria: () => {
      dispatch((prev) => {
        const criteriaNumbers = prev.criterios.map((criterio) => criterio.number);
        const maxNumber = Math.max(...(criteriaNumbers.length ? criteriaNumbers : [0]));
        let newList = prev.criterios.slice();
        newList.push({
          number: maxNumber + 1,
          name: '',
        });
        return {
          ...prev,
          criterios: newList,
        };
      });
    },
    removeCriteria: (criteriaNumber) => {
      dispatch((prev) => {
        if (prev.criterios.length === 1) return prev;
        else {
          let newList = prev.criterios.slice();
          let criteriaIndex = prev.criterios.findIndex((criteria) => criteria.number === criteriaNumber);
          if (criteriaIndex + 1) {
            newList.splice(criteriaIndex, 1);
            return {
              ...prev,
              criterios: newList,
            };
          } else return prev;
        }
      });
    },
    writeCriteria: (criteriaNumber, criteriaValue) => {
      dispatch((prev) => {
        let criteriaIndex = prev.criterios.findIndex((criteria) => criteria.number === criteriaNumber);
        if (criteriaIndex + 1) {
          let newList = prev.criterios.slice();
          newList[criteriaIndex] = {
            number: newList[criteriaIndex].number,
            name: criteriaValue,
          };
          return {
            ...prev,
            criterios: newList,
          };
        } else return prev;
      });
    },
    setCriterio: dispatch,
  };

  return [state, actions, null];
};

export const setCorrectObject = (scenario) => {
  const newEvaluation = jsonParse(scenario.criterios).map(({ name }, index) => ({ number: index + 1, name }));
  const { name } = scenario;

  return {
    name: name || '',
    criterios: newEvaluation.length
      ? newEvaluation
      : [
          {
            number: 1,
            name: '',
          },
        ],
  };
};

export const defaulValue = {
  name: '',
  criterios: [
    {
      number: 1,
      text: '',
    },
  ],
};
