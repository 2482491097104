import { IconButton } from '@material-ui/core';
import LeftIcon from '@material-ui/icons/ArrowBack';
import RightIcon from '@material-ui/icons/ArrowForward';
import React, { useState } from 'react';
import useStyles from './style';

const ModalPhoto = ({ photoGallery, setOpen }) => {
  const [index, setIndex] = useState(photoGallery.index);
  const classes = useStyles();

  return (
    <div style={{ position: 'relative', width: 500, height: 450 }}>
      <IconButton
        style={{ backgroundColor: '#ffff', opacity: 0.7 }}
        onClick={() => {
          if (index === photoGallery.photos.length - 1) return setIndex(0);
          return setIndex(index + 1);
        }}
        className={classes.buttonNext}
      >
        <RightIcon />
      </IconButton>
      <IconButton
        style={{ backgroundColor: '#ffff', opacity: 0.7 }}
        onClick={() => {
          if (index === 0) return setIndex(photoGallery.photos.length - 1);
          return setIndex(index - 1);
        }}
        className={classes.buttonPrev}
      >
        <LeftIcon />
      </IconButton>
      <img alt="img" style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={photoGallery.photos[index]} />
    </div>
  );
};

export default ModalPhoto;
