import { Grid, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import InputBox from '../../../components/common/Inputs/InputBox/InputBox';
import UploadButton from '../../../components/common/UploadButton/UploadButton';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import { useAuth } from '../../../components/contexts/AuthContext';
import { uploadFileToS3, useRpcApi } from '../../../services/api';
import { Button } from '../../../components/common';
const [getAvatarUploadUrl, updateUser, getCurrentUser] = useRpcApi(
  'user.getAvatarUploadUrl',
  'user.updateUser',
  'user.getCurrentUser'
);

const PersonalInformation = () => {
  const { user, setContext } = useAuth();

  const [value, setValue] = useState(user);
  const [errorPhoto, setErrorPhoto] = useState(false);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState();

  const uploadPhoto = useCallback(
    async (value) => {
      if (file) {
        return Promise.resolve(
          getAvatarUploadUrl({ id: value.id })
            .then(async (value) => await uploadFileToS3(value.url.url, file, value.url.fields))
            .catch((error) => console.log(error))
        );
      }
    },
    [file]
  );

  const onUpdate = useCallback(async () => {
    setLoading(true);
    const { firstName, lastName, email } = value;
    const obj = {
      ...value,
      name: firstName,
      familyName: lastName,
      email: email || user?.email,
      avatar: file ? 1 : value.avatar ? 1 : null,
      id: user?.id,
    };
    await uploadPhoto(obj);
    updateUser(obj)
      .then(() => getCurrentUser())
      .then((user) => {
        setContext({ user });
        setOpen(true);
      })
      .finally(() => setLoading(false))
      .catch((error) => console.log(error));
  }, [user, value, file, setContext, uploadPhoto]);

  const onChange = (e) => {
    let newValye = e.target.value;
    setValue({ ...value, [e.target.name]: newValye });
  };

  return (
    <>
      <UploadButton
        file={file}
        setFile={setFile}
        avatar={value?.avatar}
        setErrorPhoto={setErrorPhoto}
        errorPhoto={errorPhoto}
      />
      <Grid container direction="column">
        <SuccessNotification open={open} text="Personal Information" setOpen={setOpen} />

        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Personal Information</Typography>
          </Grid>
          <Grid item xs={6}>
            <InputBox onInput={onChange} name="firstName" title="First Name" value={value?.firstName || ''} />
          </Grid>
          <Grid item xs={6}>
            <InputBox onInput={onChange} name="lastName" title="Last Name" value={value?.lastName || ''} />
          </Grid>
          <Grid item xs={6}>
            <InputBox onInput={onChange} name="Email" title="Email" value={value?.email || ''} disabled />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Button
              text="Save Changes"
              size="large"
              color="secondary"
              variant="contained"
              onClick={onUpdate}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalInformation;
