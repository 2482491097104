import React from 'react';
const NoUploadedBlock = ({ contant, typeOfContant, size }) => (
  <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', color: 'red' }}>
    <p style={{ marginRight: 10, fontWeight: 500 }}>
      These{` ${typeOfContant} `}are larger than {size}MB:
    </p>
    {contant &&
      contant.map((el) => (
        <p style={{ margin: '0 5px 5px 0' }} key={el}>
          {el},
        </p>
      ))}
  </div>
);

export default NoUploadedBlock;
