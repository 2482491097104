import React from 'react';
import { useRpcApi } from '../../../../services/api';

const [getPhotoByID, getVideoByID] = useRpcApi('evaluationPhoto.getPhoto', 'evaluationVideo.getVideo');
const ChangeLogContext = React.createContext({
  photosDict: {},
  videosDict: {},
  onGetFileSrc: undefined,
  pending: false,
});

export function useChangeLogContext() {
  return React.useContext(ChangeLogContext);
}

export class ChangeLogContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { photosDict: {}, videosDict: {}, pending: false };
  }

  get values() {
    return { ...this.state, onGetPhoho: this.onGetPhoho, onGetVideo: this.onGetVideo };
  }

  _loadFile = async (cb) => {
    this.setState({ pending: true });
    try {
      await cb();
    } catch (e) {
      console.log('---', e);
    } finally {
      this.setState({ pending: false });
    }
  };

  onGetPhoho = async (id) => {
    if (!id) return;
    this._loadFile(async () => {
      const fileInfo = await getPhotoByID({ id });
      this.setState({ photosDict: { ...this.state.photosDict, [id]: fileInfo && fileInfo.photoUrl } });
    });
  };

  onGetVideo = async (id) => {
    if (!id) return;
    this._loadFile(async () => {
      const fileInfo = await getVideoByID({ id });
      this.setState({ videosDict: { ...this.state.videosDict, [id]: fileInfo && fileInfo.videoUrl } });
    });
  };

  render() {
    return <ChangeLogContext.Provider value={this.values}>{this.props.children}</ChangeLogContext.Provider>;
  }
}
