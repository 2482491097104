import ApiError from './apiError';

export default class RpcApiError extends ApiError {
  get isRpc() {
    return true;
  }

  get data() {
    return this.originalError && this.originalError.data;
  }

  get fields() {
    if (!Array.isArray(this.originalError.data)) {
      return {};
    }
    const errors = {};
    for (const err of this.originalError.data) {
      if (err.context && err.context.key && err.message) {
        errors[err.context.key] = err.message;
      }
    }
    return errors;
  }
}
