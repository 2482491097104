import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import iconSuccesss from 'components/assets/images/ico-successs.png';
import React from 'react';
import { SUCCESS_MODE } from '../../../../constants/perms';
import { IconClose } from '../../../svg';
import { useStyles } from './style';

const SuccessModal = ({ open, onClose, title = '', name = '', onSubmit, mode, textBtn, offCancelBtn }) => {
  const classes = useStyles();

  const obj = {
    [SUCCESS_MODE]: () => (
      <>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            Create one more
          </Button>
        </Grid>
        <Grid item>{offCancelBtn && <Button onClick={onClose}>Close</Button>}</Grid>
      </>
    ),
    else: () => (
      <Grid item>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {textBtn ? textBtn : 'Success'}
        </Button>
      </Grid>
    ),
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.paper}>
        <IconButton color="primary" onClick={onClose} className={classes.closModallIcon}>
          <IconClose circle fontSize="small" />
        </IconButton>
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            <img className={classes.widthIconSuccess} src={iconSuccesss} alt="niceIcon" />
          </Grid>
          <Grid item>
            <Typography style={{ textAlign: 'center' }} variant="h5">
              {title}
            </Typography>
            <Typography variant="body1" color="textSecondary" align="center">
              {name}
            </Typography>
          </Grid>
          <Grid container item direction="column" spacing={2} alignItems="center">
            {obj[mode]?.() || obj.else()}
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default SuccessModal;
