import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid rgba(0, 46, 120, 0.16)',
    borderRadius: '4px',
    outline: 'none',
    '& .MuiOutlinedInput-input': {
      color: '#7080a1',
      fontSize: '15px',
      fontWeight: 500,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiIconButton-root': {
      color: '#7080a1',
    },
  },
}));

export default useStyles;
