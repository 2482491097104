import React from 'react';
import { Redirect, Route /*RouteProps*/ } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const resolveAccess = (roles, roleId, children) => {
  if (!Array.isArray(roles)) return children;
  if (roles.includes(roleId)) return children;
  return <Redirect to="/" />;
};

const AuthRoute = (props) => {
  const { noAuth = false, redirect, children, roles, ...rest } = props;
  const { isAuthenticated = false, user } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated === !noAuth ? (
          resolveAccess(roles, user && user.roleId, children)
        ) : (
          <Redirect to={{ pathname: redirect, state: { from: location } }} />
        )
      }
    />
  );
};

export default AuthRoute;
