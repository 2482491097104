import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Table from './Table';
import NewAdmin from './NewAdmin';
import { useRpcApi } from '../../services/api';
import useStyles from './style';
import debounce from '../../utils/debounce';

const LIMIT = 10;

const [getAdminList] = useRpcApi('admin.getAdminsList');

const Instructors = ({ user }) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(0);
  const [offset, setOffset] = useState(0);
  const [visible, setVisible] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [nameSotr, setNameSort] = useState('firstName');
  const [sort, setSort] = useState('asc');
  const [valueSearch, setValueSearch] = useState('');
  const { pathname } = useLocation();
  const classes = useStyles();

  const getUsers = useCallback(async () => {
    setLoadingList(true);
    const [firstValue, secondValue] = valueSearch.trim().split(' ');

    let filter = [];
    let orFilter = [];

    const Op = '$ilike';

    if (firstValue && !secondValue) {
      orFilter = Object.entries({
        firstName: {
          [Op]: `%${firstValue}%`,
        },
        lastName: {
          [Op]: `%${firstValue}%`,
        },
        email: {
          [Op]: `%${firstValue}%`,
        },
        phoneNumber: {
          [Op]: `%${firstValue}%`,
        },
      });
    }

    if (firstValue && secondValue) {
      filter = Object.entries({
        firstName: {
          [Op]: `%${firstValue}%`,
        },
        lastName: {
          [Op]: `%${secondValue}%`,
        },
      });
    }

    const response = await getAdminList({
      limit: LIMIT,
      offset: offset * LIMIT,
      order: Object.entries({ [nameSotr]: sort }),
      orFilter,
      filter,
    });
    setUsers(
      response.items.map((i) => ({
        ...i,
      }))
    );
    setLimit(response?.total);
    setLoadingList(false);
  }, [nameSotr, offset, sort, valueSearch]);

  const forceSearch = useCallback(
    debounce(() => {
      getUsers();
    }, 1000),
    [getUsers, valueSearch, offset, sort, nameSotr]
  );

  useEffect(() => {
    forceSearch();
  }, [forceSearch, valueSearch, offset, sort, nameSotr]);

  const id = window.location.pathname.split('/')[3];

  const attr = {
    onChangeOffset: (num) => setOffset(num),
    getUsers,
    limit,
    users,
    setValueSearch,
    visible,
    setVisible,
  };

  const obj = {
    '/admins': () => (
      <Table setSort={setSort} loadingList={loadingList} setNameSort={setNameSort} activeUser={user} {...attr} />
    ),
    '/admins/new-admin': () => <NewAdmin getUsers={getUsers} activeUser={user} />,
    [`/admins/edit-admin/${id}`]: () => (
      <NewAdmin getUsers={getUsers} activeUser={user} visible={visible} setVisible={setVisible} />
    ),
  };

  const content = obj[pathname] || obj['/admins'];

  return <div className={classes.wrapperWidth}>{content()}</div>;
};

export default Instructors;
