import React from 'react';
import { useHistory } from 'react-router-dom';
import TermsLogo from 'components/assets/images/termsLogo.png';
import { Button } from 'components/common/Buttons';
import useStyles from './style';
import { content } from './constants';

const Container = ({ title, desc }) => {
  const s = useStyles();

  return (
    <div>
      <h3 className={s.title}>{title}</h3>
      {desc.map((i) => (
        <>
          <h3 className={s.subTitle}>{i?.subTitle}</h3>
          {i?.paragraph && i?.paragraph.map((i) => <p className={s.desc}>{i}</p>)}
          {i?.tab &&
            i?.tab.map((i) => (
              <p className={`${s.tab} ${s.desc}`}>
                <b>{i?.bold}</b>
                {i?.text}
              </p>
            ))}
          {i?.ul && (
            <ul style={{ marginBottom: 15 }}>
              {i?.ul.map((i) => (
                <li className={`${s.li} ${s.desc}`}>{i}</li>
              ))}
            </ul>
          )}
        </>
      ))}
    </div>
  );
};

const Scenarios = () => {
  const s = useStyles();
  const history = useHistory();

  const goToLogin = () => history.push('/login');

  return (
    <>
      <header className={s.termsHeader}>
        <img onClick={goToLogin} className={s.logo} src={TermsLogo} alt="Logo" />
        <Button onClick={goToLogin} customClass={s.btn} text="Sign In" />
      </header>

      <div className={s.body}>
        <h2 className={s.desc}>Terms and Conditions</h2>
        <h2 className={s.desc}>Last updated: December 07, 2020</h2>
        <h2 className={s.desc}>Please read these terms and conditions carefully before using Our Service.</h2>
        {content.map((i, ind) => (
          <Container key={ind} {...i} />
        ))}
      </div>

      <footer className={s.footer}>
        <p>Copyright © 2019 Ten-Eight</p>
        <p>Terms and conditions</p>
      </footer>
    </>
  );
};

export default Scenarios;
