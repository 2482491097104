import React from 'react';
import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import useStyles from './style';

const Button = ({ onClick, customClass, text = '', children, loading }) => {
  const s = useStyles();

  return (
    <div className={s.wrapper}>
      <MUIButton
        type="submit"
        onClick={onClick}
        className={clsx(s.root, customClass)}
        variant="contained"
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : text || children}
      </MUIButton>
    </div>
  );
};

export default Button;
