import React from 'react';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  inputTitle: {
    fontSize: '13px',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  star: {
    color: 'red',
  },
}));

export const MultilineTextField = (props) => {
  const classes = useStyles();
  const { InputProps, errorText, helperText, error, title, star, label, placeholder, customClass, ...rest } = props;
  return (
    <div>
      <Typography className={classes.inputTitle} color="textSecondary">
        {title} {star ? <span className={classes.star}>*</span> : null}
      </Typography>
      <TextField
        className={clsx(classes.margin, classes.root, customClass)}
        variant="outlined"
        fullWidth
        multiline
        rows={6}
        placeholder={placeholder || 'Please enter'}
        error={!!errorText || !!error}
        label={errorText || label}
        InputLabelProps={!!errorText ? { shrink: true, color: 'secondary' } : { label: false }}
        {...rest}
      />
    </div>
  );
};
