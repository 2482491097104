import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { IconClose } from '../../../svg/Icons/IconClose';

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    flexDirection: 'column',
    padding: `10px 23px`,
    boxShadow: `0 0 10px 0 rgba(81, 94, 116, 0.2)`,
    zIndex: 10,
  },
  content: {
    width: 600,
    padding: 25,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
  },
  action: {
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[100],
    '& > button': {
      margin: '0 0 30px 0 !important',
    },
  },
  search: {
    margin: '10px -23px -23px',
    color: theme.palette.text.secondary,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '13px',

    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  iconButton: {
    margin: '12px',
  },
  contentChildren: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  applayBtn: {
    display: 'flex',
    alignItems: 'center',
  },
  applayBtnText: {
    color: 'blue',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginRight: '10px',
  },
}));

const DialogContainer = (props) => {
  const classes = useStyles(props);
  const {
    open,
    onAbort,
    hasBackButton,
    btnTitle,
    onClick,
    title,
    subTitle,
    headBtnTitle,
    headOnClick,
    hasSearch,
    searchPlaceholder,
    children,
    value,
    onChange,
    loading,
    onCancel,
    selectAll,
    addAllId,
    selectAllTitle,
  } = props;

  return (
    <Dialog open={open} onClose={onAbort} fullWidth={true} maxWidth="sm">
      <DialogTitle className={classes.head} disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            {hasBackButton && (
              <IconButton color="primary" onClick={onAbort}>
                <ArrowBackIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            {title && (
              <Typography variant="h4" align="center">
                {title}
              </Typography>
            )}
            {subTitle && (
              <Typography variant="subtitle2" color="textSecondary" align="center">
                {subTitle}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <div className={classes.applayBtn}>
              {selectAll && (
                <p className={classes.applayBtnText} onClick={addAllId}>
                  {selectAllTitle}
                </p>
              )}
              {headBtnTitle ? (
                <Button variant="contained" color="secondary" onClick={headOnClick}>
                  {headBtnTitle}
                </Button>
              ) : (
                <IconButton color="primary" onClick={onAbort}>
                  <IconClose circle fontSize="small" />
                </IconButton>
              )}
            </div>
          </Grid>
        </Grid>
        {hasSearch && (
          <div className={classes.search}>
            <div className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </div>
            <InputBase multiline value={value} onChange={onChange} autoFocus placeholder={searchPlaceholder} />
          </div>
        )}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.contentChildren}>{children}</div>
      </DialogContent>
      {btnTitle && (
        <DialogActions className={classes.action}>
          <Button disabled={loading} autoFocus variant="contained" color="secondary" size="large" onClick={onClick}>
            {btnTitle}
          </Button>
          {onCancel && <Button onClick={onAbort}>Cancel</Button>}
        </DialogActions>
      )}
    </Dialog>
  );
};

DialogContainer.propTypes = {
  open: PropTypes.bool,
  onAbort: PropTypes.func,
  hasBackButton: PropTypes.bool,
  btnTitle: PropTypes.string,
  onClick: PropTypes.func,
  headBtnTitle: PropTypes.string,
  headOnClick: PropTypes.func,
  haveCancel: PropTypes.bool,
  onCancel: PropTypes.bool,
  title: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  loading: PropTypes.bool,
  selectAll: PropTypes.bool,
  selectAllTitle: PropTypes.string,
  addAllId: PropTypes.func,
};

export default DialogContainer;
