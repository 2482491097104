import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconEdit = (props) => {
  const { width = 18, height = 18 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd">
        <g fill="currentColor">
          <path
            d="M11.738 1.634c.845-.845 2.216-.845 3.06 0L16.367 3.2c.845.845.845 2.216 0 3.061L6.263 16.366c-.406.406-.957.634-1.531.634h-3.01C1.323 17 1 16.677 1 16.278v-3.01c0-.574.228-1.125.634-1.531L11.738 1.634zm2.04 1.02c-.282-.281-.738-.281-1.02 0L11.402 4.01l2.588 2.588 1.356-1.356c.281-.282.281-.738 0-1.02l-1.568-1.568zm-.809 4.964l-2.587-2.587-7.727 7.726c-.136.136-.212.32-.212.51v2.29h2.289c.192 0 .375-.076.51-.212l7.727-7.727z"
            transform="translate(-1324 -250) translate(280 61) translate(0 108) translate(50 70) translate(994 11)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

IconEdit.propTypes = simpleIconPropsTypes;
