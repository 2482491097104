/* eslint-disable react-hooks/exhaustive-deps */
import 'date-fns';
import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    borderColor: '#f66',
    width: '100%',
  },
  inputTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
  star: {
    color: 'red',
    marginLeft: '3px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
}));

const DataPicker = ({ onChange, title, defaultDate, values, setError, error, star, maxDate = '2100-01-01' }) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(null);
  const [validate, setValidate] = useState('');
  const [textError, setTextError] = useState('');

  const handleDateChange = (date, value) => {
    setSelectedDate(date);
    const str = value && value?.split('/').join(' ');
    const d = str?.split(' ')[1];
    const m = str?.split(' ')[0];
    const y = str?.split(' ')[2];
    values ? onChange({ ...values, d, m, y }) : onChange({ d, m, y });
    error && setError({ ...error, errorDate: validate });
  };

  useEffect(() => {
    setSelectedDate(null);
    if (defaultDate) {
      const newDate = new Date(defaultDate);
      const d = newDate.getUTCDate();
      const m = +newDate.getMonth() + 1;
      const y = newDate.getFullYear();
      newDate.setDate(d);

      values ? onChange({ ...values, d, m, y }) : onChange({ d, m, y });
      setSelectedDate(newDate);
    }
  }, [defaultDate]);

  useEffect(() => {
    if (error && setError) {
      setError({ ...error, errorDate: validate });
    }
  }, [validate]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <>
        <h4 className={classes.inputTitle}>
          {title}
          {star && <span className={classes.star}>*</span>}
        </h4>
        <KeyboardDatePicker
          helperText={false}
          onError={(e) => {
            setValidate(e && true);
            setTextError(e);
          }}
          maxDate={maxDate}
          maxDateMessage="Date should not be after current date"
          placeholder="MM/DD/YYYY"
          className={classes.root}
          inputVariant="outlined"
          margin="normal"
          id="date-picker-dialog"
          format="MM/dd/yyyy"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        {textError && <div className={classes.error}>*{textError}</div>}
      </>
    </MuiPickersUtilsProvider>
  );
};

export default DataPicker;
