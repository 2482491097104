import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapperWidth: {
    flex: 1,
    overflow: 'auto',
  },
  widthNotRecruit: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '60px',
  },
  titleAdd: {
    color: 'blue',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
