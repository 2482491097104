import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PlatoonRecruits from './PlatoonRecruits';
import PlatoonsTable from './PlatoonsTable';
import useStyles from './style';

const Platoons = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperWidth}>
      <Switch>
        <Route exact path="/platoon">
          <PlatoonsTable />
        </Route>
        <Route exact path="/platoon/recruits/:id">
          <PlatoonRecruits />
        </Route>

        <Redirect exact path="/*" to="/platoon" />
      </Switch>
    </div>
  );
};

export default Platoons;
