import React, { useRef, useCallback, useEffect } from 'react';
import { MAPBOX_TOKEN_GEOCODER, MAPBOX_TOKEN } from './apiMap';
import MapGL, { Marker } from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import useStyles from './style';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { InputBox } from '../Inputs';
import marker from 'components/assets/images/marker.png';
import { useLabels } from '../../contexts/AuthContext';

const MapForm = ({ viewport, setViewport, createScenarioStore, createScenarioActions }) => {
  const geocoderContainerRef = useRef();
  const mapRef = useRef();
  const classes = useStyles();
  const labels = useLabels();

  useEffect(() => {
    if (createScenarioStore?.address) {
      document.querySelector('.mapboxgl-ctrl-geocoder--input').value = createScenarioStore?.address;
    }
  }, [createScenarioStore.address]);

  const handleViewportChange = useCallback(
    (newViewport) => {
      setViewport(newViewport);
    },
    [setViewport]
  );

  const onDragEnd = (lngLat) => setViewport({ longitude: viewport.lng, latitude: viewport.lat });

  return (
    <div className={classes.locationWrapper}>
      <div className={classes.pisitionMap}>
        <h2 className={classes.formTitle}>Location</h2>
        <InputBox
          onInput={(e) => createScenarioActions.changeLocationName(e.target.value)}
          placeholder="Please enter"
          title={`Location Name (visible for ${labels.recruits})`}
          value={createScenarioStore?.locationName}
        />
        <h4 className={classes.inputTitle}>Address (visible for {labels.recruits})</h4>
        <div className={classes.searchGeocoder} ref={geocoderContainerRef} id="geocoderInput" />
      </div>
      <div className={classes.map}>
        <MapGL
          mapStyle="mapbox://styles/mapbox/streets-v11"
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          minZoom={16}
        >
          <Marker
            longitude={+createScenarioStore?.longitude}
            latitude={+createScenarioStore?.latitude}
            onDragEnd={onDragEnd}
            draggable
          >
            <img src={marker} alt="mapBox" style={{ width: '65px', height: '70px' }} />
          </Marker>
          <Geocoder
            mapRef={mapRef}
            containerRef={geocoderContainerRef}
            onViewportChange={handleViewportChange}
            mapboxApiAccessToken={MAPBOX_TOKEN_GEOCODER}
          />
        </MapGL>
      </div>
    </div>
  );
};

export default MapForm;
