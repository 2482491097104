import React from 'react';
import ChangeLog from './ChangeLog';
import { ChangeLogContextProvider } from './context';

export default function ChangeLogWrapper(props) {
  return (
    <ChangeLogContextProvider>
      <ChangeLog {...props} />
    </ChangeLogContextProvider>
  );
}
