import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const useStyles = makeStyles((theme) => {
  return {
    box: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey['A400']}`,
      borderLeft: 0,
      '& > div': {
        padding: 5,
        borderLeft: `1px solid ${theme.palette.grey['A400']}`,
      },
      '& button': {
        padding: '11px 15px 13px',
        width: '100%',
      },
    },
    activeListItem: {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    inputTitle: {
      fontSize: 13,
      fontWeight: 'bold',
      color: theme.palette.text.secondary,
      marginBottom: 10,
    },
    star: {
      color: 'red',
    },
  };
});

const Segments = (props) => {
  const classes = useStyles(props);
  const { title = 'Recurrency', star, value, onValueChange, listItems = [], selectReccurence } = props;

  const size = useMemo(() => 12 / listItems.length, [listItems.length]);

  return (
    <>
      <h4 className={classes.inputTitle}>
        {title}
        {star && <span className={classes.star}>*</span>}
      </h4>
      <Grid container className={classes.box}>
        {listItems.map((item) => (
          <Grid key={item} item xs={size}>
            <Button
              className={clsx(classes.btn, value === item && classes.activeListItem)}
              color="primary"
              onClick={() => {
                selectReccurence(item);
                onValueChange(item === 'No' ? null : item);
              }}
            >
              {item}
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

Segments.propTypes = {
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  setAddDate: PropTypes.func,
};

export default Segments;
