import { useTheme } from '@material-ui/styles';
import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconAlertError = (props) => {
  const theme = useTheme();
  const { color = theme.palette.icons.primary, width = 32, height = 32 } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <path
            d="M41.796 20c.53 0 1.039.21 1.414.586l8.204 8.204c.375.375.586.884.586 1.414v11.592c0 .53-.21 1.039-.586 1.414l-8.204 8.204c-.375.375-.884.586-1.414.586H30.204c-.53 0-1.039-.21-1.414-.586l-8.204-8.204c-.375-.375-.586-.884-.586-1.414V30.204c0-.53.21-1.039.586-1.414l8.497-8.497c.187-.188.442-.293.707-.293zm0 2H30.204L22 30.204v11.592L30.204 50h11.592L50 41.796V30.204L41.796 22zM37 41v2h-2v-2h2zm-1-12c.513 0 .936.386.993.883L37 30v8c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L35 38v-8c0-.552.448-1 1-1z"
            transform="translate(-540 -50) translate(520 30)"
          />
        </g>
      </g>
    </svg>
  );
};

IconAlertError.propTypes = simpleIconPropsTypes;
