import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: 10,
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey['A100']}`,
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
    },
    '& > div': {
      borderLeft: `1px solid ${theme.palette.grey['A100']}`,
      '&:first-child': {
        border: 0,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 0,
      border: 0,
    },
  },
  inputTitle: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
}));

const InputDate = (props) => {
  const classes = useStyles();
  const { valueDay = null, valueMonth = null, valueYear = null, onChange } = props;
  return (
    <>
      <span className={classes.inputTitle}>Date of birth</span>
      <Grid container className={classes.root}>
        <Grid item xs={4}>
          <TextField
            onChange={onChange}
            name="day"
            placeholder="DD"
            value={valueDay}
            variant="outlined"
            type="number"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            onChange={onChange}
            name="month"
            placeholder="MM"
            value={valueMonth}
            variant="outlined"
            type="number"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            onChange={onChange}
            name="year"
            placeholder="YYYY"
            value={valueYear}
            variant="outlined"
            type="number"
          />
        </Grid>
      </Grid>
    </>
  );
};

InputDate.propTypes = {
  valueDay: PropTypes.number,
  valueMonth: PropTypes.number,
  valueYear: PropTypes.number,
  onChange: PropTypes.func,
};

export default InputDate;
