import React from 'react';
import useStyles from '../style';
import { CheckBox } from 'components/common';

const VideoComponentDb = ({ idToDeleteVideo, addIdToDeleteVideoDb, handleOpenVideoBox, url, id }) => {
  const classes = useStyles();
  return (
    <>
      <CheckBox
        style={{
          backgroundColor: '#ffff',
        }}
        className={classes.checkBox}
        checked={idToDeleteVideo.includes(id)}
        onClick={() => addIdToDeleteVideoDb(id)}
      />
      <video
        key={id}
        src={url}
        title="video"
        style={{ width: 100, height: 100, cursor: 'pointer', border: '1px solid #ffff' }}
        onClick={() => handleOpenVideoBox(url)}
      />
    </>
  );
};

export default VideoComponentDb;
