import { FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    borderRadius: 3,
    backgroundColor: '#ffffff',
    '& .MuiOutlinedInput-input': {
      padding: '17px 0 17px 20px',
      color: '#000',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.grey['A400']}`,
    },
    '& .MuiInputBase-formControl': {
      border: 0,
      backgroundColor: '#fff',
      margin: 0,
      padding: 0,
    },
    '& .MuiFormHelperText-contained': {
      color: 'red',
    },
    '& .Mui-disabled': {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.text.secondary,
    },
  },
  inputTitle: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
  star: {
    color: 'red',
  },
}));

const InputBox = (props) => {
  const classes = useStyles();
  const {
    title = '',
    star = false,
    placeholder = '',
    variant = '',
    onInput = () => {},
    inputMask = '',
    value = '',
    name = '',
    type = '',
    error = '',
    ...res
  } = props;
  return (
    <FormControl fullWidth>
      {title && (
        <span className={classes.inputTitle}>
          {title} {star ? <span className={classes.star}>*</span> : null}
        </span>
      )}

      {inputMask ? (
        <InputMask
          fullWidth
          mask={inputMask}
          maskChar=" "
          className={classes.root}
          onChange={onInput}
          name={name}
          value={value || ''}
          helperText={error && `* ${error}`}
          placeholder={placeholder || 'Please enter'}
          variant={variant || 'outlined'}
          type={type}
          {...res}
        >
          <TextField />
        </InputMask>
      ) : (
        <TextField
          fullWidth
          className={classes.root}
          onChange={onInput}
          name={name}
          value={value || ''}
          helperText={error && `* ${error}`}
          placeholder={placeholder || 'Please enter'}
          variant={variant || 'outlined'}
          type={type}
          {...res}
        />
      )}
    </FormControl>
  );
};

InputBox.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  onInput: PropTypes.func,
  inputMask: PropTypes.string,
  value: PropTypes.string,
  star: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
};

export default InputBox;
