/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { MultiAutocomplete, Segments } from 'components/common';
import { DurationList } from '../../static.ts';
import DataPicker from 'components/common/Inputs/DataTimePicker';
import { InputTime } from '../../../../components/common';
import Select from 'components/common/Inputs/Select';
import useStyles from '../../style';
import { useLabels } from '../../../../components/contexts/AuthContext';

const ReccurrenceList = ['No', 'Daily', 'Weekly'];

const DialogChildren = ({
  store,
  actions,
  categoryData,
  platoonsData,
  instructorsData,
  scenariosData,
  scheduleData,
  shedleInstructors,
  error,
  setError,
}) => {
  const [activeReccurence, selectReccurence] = useState(ReccurrenceList[0]);
  const classes = useStyles();
  const [visible, setVisible] = useState();
  const labels = useLabels();

  const getIdsInstructor = () => {
    const ids = [];
    // eslint-disable-next-line array-callback-return
    instructorsData.map((i) => {
      const inst = shedleInstructors.find((j) => j?.instructor?.id === i?.id);
      if (inst) {
        ids.push(inst?.instructor);
      }
    });

    return ids;
  };

  const handlerObj = () => setVisible(!!Object.keys(scheduleData).length);

  useEffect(() => {
    handlerObj();
  }, [scheduleData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Select
          star
          title="Scenario"
          name="name"
          id="scenario"
          onChange={(e, indx) => actions.selectScenario(indx?.id)}
          value={store?.scenarioId}
          options={scenariosData}
          nameIndex="id"
        />
        {error?.scenario && <p className={classes.error}>*Scenario must be selected</p>}
      </Grid>
      <Grid item xs={6}>
        <Select
          title={labels.platoon}
          name="name"
          id="platoon"
          onChange={(e, indx) => actions.selectPlatoon(indx?.id)}
          value={store?.platoonId}
          options={platoonsData}
          nameIndex="id"
        />
      </Grid>
      <Grid item xs={12}>
        <MultiAutocomplete
          name="scenarioId"
          title={labels.instructors}
          labelKey="firstName"
          lastName="lastName"
          options={instructorsData}
          onChange={(e) => actions.selectInstructors(e)}
          defaultValue={getIdsInstructor()}
        />
      </Grid>

      <Grid container item xs={6} direction="column">
        <DataPicker
          star
          defaultDate={store?.beginningAt}
          title={store?.recurrence ? 'Start date' : 'Date'}
          onChange={(e) => actions.changeStartDate(e)}
          setError={setError}
          error={error}
        />
        {error?.date && <p className={classes.error}>*Date must be filled</p>}
      </Grid>

      <Grid container item xs={6} direction="column">
        <InputTime star onChange={(e) => actions.changeStartTime(e)} setError={setError} error={error} />
        {error?.time && <p className={classes.error}>*Time must be filled</p>}
      </Grid>

      {store?.recurrence && true ? (
        <Grid container item xs={12}>
          <DataPicker
            star
            defaultDate={store?.endAt}
            title="End date"
            onChange={(e) => actions.changeEndDate(e)}
            setError={setError}
            error={error}
          />
          {error?.endDate && <p className={classes.error}>*Date must be filled</p>}
        </Grid>
      ) : null}

      <Grid item xs={6}>
        <Select
          title="Duration"
          name="label"
          id={'duration'}
          onChange={(e, indx) => actions.changeDuration(indx?.value)}
          value={store?.duration}
          options={DurationList}
          timeValue
          nameIndex="value"
        />
      </Grid>
      {
        /* Temporary removed */
        //   <Grid item xs={6}>
        //   <Select
        //     title="Criteria"
        //     name="name"
        //     onChange={(e, indx) => actions.selectCategories(indx?.id)}
        //     value={store?.criterioId}
        //     options={categoryData}
        //     nameIndex="id"
        //   />
        // </Grid>
      }
      {!visible && (
        <Grid item xs={12}>
          <Segments
            selectReccurence={selectReccurence}
            value={store?.recurrence || activeReccurence || null}
            onValueChange={actions.changeRecurrence}
            listItems={ReccurrenceList}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DialogChildren;
