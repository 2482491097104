import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: '0 !important',
    padding: '0 !important',
    width: '100%',
    maxWidth: '100% !important',
    backgroundColor: '#F3F4F7',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    color: '#00c853',
    backgroundColor: '#ffffff',
    border: '1px solid #00c853',
    '&:hover': {
      backgroundColor: '#00c853',
      color: '#ffffff',
    },
  },
  table: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleCategory: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  rowTable: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px solid #e3e4e8',
    marginBottom: '10px',
    padding: '5px 0 5px 10px',
  },
  childCategory: {
    width: '100%',
    marginBottom: '5px',
    marginTop: '5px',
  },
  iconTable: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emptyText: {
    color: 'red',
  },
}));

export default useStyles;
