import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { IconClose } from '../../svg/Icons/IconClose';
import { IconEdit } from '../../svg/Icons/IconEdit';
import { IconTrash } from '../../svg/Icons/IconTrash';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

export const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
}));

const styleIcon = { color: '#7080a1', width: '18px', height: '18px' };

const ActionButtons = (props) => {
  const classes = useStyles(props);
  const { actions, onEdit, onDelete, onRemove, visibleIcon, visible } = props;

  return (
    <div className={classes.root}>
      {actions.includes('visible') && (
        <IconButton color="primary" aria-label="visible" onClick={visibleIcon}>
          {visible ? <VisibilityOutlinedIcon style={styleIcon} /> : <VisibilityOffOutlinedIcon style={styleIcon} />}
        </IconButton>
      )}
      {actions.includes('edit') && (
        <IconButton color="primary" aria-label="edit" onClick={onEdit}>
          <IconEdit />
        </IconButton>
      )}
      {actions.includes('delete') && (
        <IconButton color="primary" aria-label="delete" onClick={onDelete}>
          <IconTrash />
        </IconButton>
      )}
      {actions.includes('remove') && (
        <IconButton color="primary" aria-label="remove" onClick={onRemove}>
          <IconClose />
        </IconButton>
      )}
    </div>
  );
};

ActionButtons.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.oneOf(['edit', 'delete', 'remove', 'visible'])).isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onDetails: PropTypes.func,
  onReload: PropTypes.func,
  visibleIcon: PropTypes.func,
  onRemove: PropTypes.func,
  visible: PropTypes.bool,
};

export default ActionButtons;
