import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
} from '@material-ui/core';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useRpcApi } from 'services/api';
import { IconEdit, IconTrash } from '../../../../components/svg';
import { getCurrentDay } from '../../utils.ts';
import { deleteEvent } from '../constants';
import CloseIcon from '@material-ui/icons/Close';
import TimeIcon from '@material-ui/icons/AccessTimeOutlined';
import DescIcon from '@material-ui/icons/FormatAlignLeft';
import useStyles from './style';
import moment from 'moment';

const Item = (props) => {
  const { data, getSchedulesList, setShedleInstructors, shedleInstructors } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverElement, setPopoverElement] = useState(null);
  const [deleteSchedule, getInstructorsInSchedule, deleteScheduleChain] = useRpcApi(...deleteEvent);
  const [newId, setNewId] = useState('');
  const [openSummary, setOpenSummary] = useState('');
  const [scheduleId, setScheduleId] = useState(data?.id);
  const open = Boolean(anchorEl);
  const visible = Boolean(popoverElement);
  const id = open ? 'simple-popover' : undefined;
  const history = useHistory();
  const [value, setValue] = useState('Event');

  const handleChange = (event) => setValue(event.target.value);

  const getScheduleInstructors = useCallback(
    (id) => {
      return getInstructorsInSchedule({ scheduleId: id })
        .then((res) => setShedleInstructors(res))
        .catch(console.error);
    },
    [getInstructorsInSchedule, setShedleInstructors]
  );

  useEffect(() => {
    if (newId) {
      getScheduleInstructors(newId);
    }
  }, [getScheduleInstructors, newId]);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSummary(false);
    setNewId('');
  };

  const handleClosePopover = () => setPopoverElement(null);

  const handleOpen = (event) => setPopoverElement(event.currentTarget);

  const getEndTimeDuration = () => {
    let date = Date.parse(data?.beginningAt);
    return moment(new Date(date + +data?.duration)).format('H:mm');
  };

  const romoveHandler = () => {
    if (value === 'Event') {
      deleteSchedule({ id: scheduleId })
        .then(() => {
          handleClose();
          getSchedulesList();
          handleClosePopover();
        })
        .catch((e) => console.error(e));
    } else {
      deleteScheduleChain({ parentId: scheduleId })
        .then(() => {
          handleClose();
          getSchedulesList();
          handleClosePopover();
        })
        .catch((e) => console.error(e));
    }
  };

  const type = useMemo(() => {
    if (data && data.id) {
      const occurrenceDate = new Date(data.beginningAt);
      let offset = occurrenceDate.getTimezoneOffset() / 60;
      let occStartTime = occurrenceDate.getTime() - offset * -1 * 3600000;
      let occEndTime = occStartTime + data.duration;
      let currTime = new Date().getTime();
      if (currTime < occStartTime) return 'future';
      else if (currTime > occEndTime) return 'past';
      else return 'current';
    } else return null;
  }, [data]);

  return (
    <>
      <Grid container item alignItems="center">
        <Button
          color="primary"
          className={classes.btn}
          onClick={(e) => {
            handleClick(e);
            setNewId(data?.id);
          }}
        >
          {data.beginningAt && <Typography className={classes.itemText}>{data?.name}</Typography>}
        </Button>
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {!visible && (
          <IconButton color="primary" className={classes.closeBtn} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
        {visible ? (
          <div className={classes.removeModal}>
            <Typography variant="h4" color="primary">
              Remove recurring event
            </Typography>
            <FormControl className={classes.radioGroup} component="fieldset">
              <RadioGroup aria-label="event" name="event1" value={value} onChange={handleChange}>
                <FormControlLabel
                  onClick={() => setScheduleId(data?.id)}
                  value="Event"
                  control={<Radio className={classes.radioBtn} color="primary" />}
                  label="This event"
                />
                <FormControlLabel
                  onClick={() => setScheduleId(data?.parentId)}
                  value="EventAll"
                  control={<Radio className={classes.radioBtn} color="primary" />}
                  label="This and following events"
                />
              </RadioGroup>
            </FormControl>
            <div className={classes.btnContainer}>
              <Button className={classes.cancelBtn} onClick={handleClosePopover}>
                Cancel
              </Button>
              <Button onClick={romoveHandler} color="primary">
                OK
              </Button>
            </div>
          </div>
        ) : (
          <Grid container className={classes.viewMore} direction="column">
            <Typography
              onClick={() => history.push(`/event-details/${data?.id}`)}
              variant="h4"
              color="primary"
              style={{ cursor: 'pointer' }}
            >
              {data?.name}
            </Typography>
            <Typography className={classes.dateWidth} variant="body1">
              <TimeIcon style={{ color: '#7080a1', marginRight: '10px' }} />
              {getCurrentDay(data?.beginningAt)}
            </Typography>
            <Typography className={classes.timeWidth} variant="body2" color="textSecondary">
              {moment(new Date(data?.beginningAt)).format('H:mm')}
              {data?.duration !== 0 &&
                ` to ${getEndTimeDuration()} (${+data?.duration / 3600000} ${
                  data?.duration <= 3600000 ? 'Hour' : 'Hours'
                })`}
            </Typography>
            {data?.summary && <Divider style={{ margin: '20px 0 0 0' }} />}
            <div className={classes.summaryText}>
              {data?.summary && (
                <>
                  <DescIcon
                    onClick={() => setOpenSummary(!openSummary)}
                    style={{ marginRight: '10px', color: '#7080a1' }}
                  />
                  <p className={classes.text} style={openSummary ? { height: 'auto' } : null}>
                    {data?.summary.split()}
                  </p>
                </>
              )}
            </div>
            <Divider style={{ margin: '0 0 0 0' }} />
            <Grid container spacing={1}>
              <Grid container item wrap="nowrap" alignItems="center">
                <Button onClick={() => history.push(`/platoon/recruits/${data?.platoonData?.id}`)}>
                  <Avatar className={classes.avatarText} src={data?.platoonData?.avatar}>
                    {data?.platoonData?.name?.charAt(0)}
                  </Avatar>
                  <Typography variant="body2">{data?.platoonData?.name}</Typography>
                </Button>
              </Grid>
            </Grid>
            <Divider style={{ margin: '0 0 0 0' }} />
            {shedleInstructors?.map((i, ind) => (
              <Grid key={ind} container spacing={1}>
                <Grid container item wrap="nowrap" alignItems="center">
                  <div className={classes.instructorBlock}>
                    <Avatar className={classes.avatarText} src={i?.instructor?.avatar} alt="avatar">
                      {i?.instructor?.firstName?.charAt(0) + i?.instructor?.lastName?.charAt(0)}
                    </Avatar>
                    <Typography variant="body2">{`${i?.instructor?.firstName} ${i?.instructor?.lastName}`}</Typography>
                  </div>
                </Grid>
              </Grid>
            ))}
            <Divider style={{ margin: '0 0 10px 0' }} />
            {type === 'future' || type === 'current' ? (
              <Grid container item spacing={1}>
                <Grid item>
                  <Button
                    onClick={() => history.push(`/event-edit/${data?.id}`)}
                    startIcon={<IconEdit />}
                    color="primary"
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleOpen} startIcon={<IconTrash />} color="primary">
                    Remove
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        )}
      </Popover>
    </>
  );
};

export default Item;
