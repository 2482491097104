import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import { BasicTextField } from '../../../components/common/Inputs';
import { useRpcApi } from '../../../services/api';
import useStyles from './style';

const [createTemplate] = useRpcApi('templates.saveTemplate');

export const ConfirmContainer = ({ customFields, setOpenConfirm }) => {
  const [title, setTitle] = useState('');
  const classes = useStyles();
  const handleAddName = (event) => {
    const { value } = event.target;
    setTitle(value);
  };

  const handleSaveTemplate = async (event) => {
    event.preventDefault();
    const cleaningCustomFields = customFields.map((el) => ({
      ...el,
      value: '',
    }));

    const result = await createTemplate({
      title,
      customFields: cleaningCustomFields,
    });
    if (result) {
      setOpenConfirm(false);
    }
  };
  return (
    <form onSubmit={handleSaveTemplate} className={classes.formContainer}>
      <Typography variant="h5" style={{ textAlign: 'center', paddingBottom: 10 }}>
        Enter Template name
      </Typography>
      <BasicTextField onChange={handleAddName} value={title} />
      <Button variant="contained" color="secondary" type="submit" style={{ margin: '10px auto 0', width: 100 }}>
        Save Template
      </Button>
    </form>
  );
};
