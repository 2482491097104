import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contained: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&$error': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    '&$success': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
    '&$warning': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.main,
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
      },
    },
  },
  outlined: {
    '&$error': {
      color: theme.palette.error.main,
      borderColor: fade(theme.palette.error.main, 0.4),
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.08),
      },
    },
    '&$success': {
      color: theme.palette.success.main,
      borderColor: fade(theme.palette.success.main, 0.4),
      '&:hover': {
        backgroundColor: fade(theme.palette.success.main, 0.08),
      },
    },
    '&$warning': {
      color: theme.palette.warning.main,
      borderColor: fade(theme.palette.warning.main, 0.4),
      '&:hover': {
        backgroundColor: fade(theme.palette.warning.main, 0.08),
      },
    },
  },
  text: {
    '&$error': {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.08),
      },
    },
    '&$success': {
      color: theme.palette.success.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.success.main, 0.08),
      },
    },
    '&$warning': {
      color: theme.palette.warning.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.warning.main, 0.08),
      },
    },
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    visibility: 'visible',
  },

  disabledStyles: {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  pendingStyles: {
    '& > $label': {
      visibility: 'hidden',
    },
  },

  label: {},
  sizeSmall: {},
  sizeLarge: {},
  error: {},
  success: {},
  warning: {},
}));

export default useStyles;
