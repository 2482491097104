import { Grid, Typography } from '@material-ui/core';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import InputBox from 'components/common/Inputs/InputBox/InputBox';
import React, { useState } from 'react';
import { Button, SimpleSnackbar } from '../../../components/common';
import Auth from '../../../services/helpers/amplify';

const ChangePassword = () => {
  const [value, setValue] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [openSuccess, setOpenSuccess] = useState();

  const onChange = (e) => {
    let newValye = e.target.value;
    setValue({ ...value, [e.target.name]: newValye });
  };

  const isValide = () => {
    let result = true;

    if (value?.newPassword !== value?.confirmPassword) {
      setError('The confirm password is not correct.');
      result = false;
    } else if (!value?.newPassword || !value?.confirmPassword) {
      setError('Password cannot be empty.');
      result = false;
    } else if (value?.newPassword === value?.oldPassword) {
      setError('The new password must be different from the old.');
      result = false;
    }

    return result;
  };

  const UpdatePassword = async () => {
    setLoading(true);
    if (!isValide()) {
      setLoading(false);
      return;
    }

    Auth.changePassword(Auth.user, value?.oldPassword, value?.newPassword)
      .then(() => {
        setOpenSuccess(true);
        setLoading(false);
        setOpenSuccess(true);
      })
      .catch((error) => {
        if (error.code === 'InvalidPasswordException' || error.code === 'InvalidParameterException') {
          setError(
            'Please enter a valid password that is at least 8 characters with one uppercase, one lowercase, one numeric and one special symbol.'
          );
        } else if (error.code === 'NotAuthorizedException') {
          setError('The old password is not correct.');
        } else {
          setError(error?.message || '');
        }
        setLoading(false);
      });
  };

  return (
    <Grid container direction="column">
      {error && <SimpleSnackbar message={error} onClose={() => setError('')} />}
      <SuccessNotification open={openSuccess} text="Password" setOpen={setOpenSuccess} />

      <Grid container item spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Change Password</Typography>
        </Grid>
        <Grid item xs={6}>
          <InputBox onInput={onChange} name="oldPassword" value={value?.oldPassword || ''} title="Old Password" />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <InputBox onInput={onChange} name="newPassword" value={value?.newPassword || ''} title="New Password" />
        </Grid>
        <Grid item xs={6}>
          <InputBox
            onInput={onChange}
            name="confirmPassword"
            value={value?.confirmPassword || ''}
            title="Confirm new password"
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={UpdatePassword}
            loading={loading}
            size="large"
            color="secondary"
            variant="contained"
            text="Update Password"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
