import { Avatar, Badge, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  badge: {
    marginRight: 30,
    float: 'left',
    backgroundColor: '#ffffff',
    '& > div': {
      width: 120,
      height: 120,
      margin: 0,
    },
  },
  subAvatar: {
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: '#3482ff',
    fontSize: '17px',
  },
  avatarText: {
    backgroundColor: '#3482ff',
    fontSize: '30px',
  },
}));

const BigAvatar = (props) => {
  const classes = useStyles();
  const { src = '', subSrc = '', alt = '', subAlt = '', user, platoon } = props;

  return (
    <>
      <Badge
        className={classes.badge}
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <Avatar className={classes.subAvatar} alt={subAlt} src={subSrc}>
            {platoon?.name?.charAt(0)}
          </Avatar>
        }
      >
        <Avatar className={classes.avatarText} alt={alt} src={src}>
          {user?.firstName?.charAt(0) + user?.lastName?.charAt(0)}
        </Avatar>
      </Badge>
    </>
  );
};

BigAvatar.propTypes = {
  src: PropTypes.string,
  subSrc: PropTypes.string,
  user: PropTypes.object,
  platoon: PropTypes.object,
};

export default BigAvatar;
