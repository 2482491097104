export const changePlace = (arr, keyName, direction) => {
  if (direction === 1) {
    let element = arr.find((el) => el.name === keyName);
    let index = arr.findIndex((el) => el.name === keyName);
    if (index === arr.length - 1) {
      return;
    }
    arr.splice(index + 2, 0, element);
    arr.splice(index, 1);

    return arr;
  }
  if (direction === 2) {
    let element = arr.find((el) => el.name === keyName);
    let index = arr.findIndex((el) => el.name === keyName);
    if (index === 0) {
      return;
    }
    arr.splice(index - 1, 0, element);
    arr.splice(index + 1, 1);

    return arr;
  }
};
