import AppEventEmitter from '../AppEventEmitter';
import ApiError from './apiError';
import RpcApiError from './rpcApiError';
import Auth from '../../services/helpers/amplify';

export default class ApiClient {
  constructor(http) {
    this.http = http;

    http.initErrorResponseInterceptor(async (error) => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 419) {
        await Auth.signOut();
        this.setToken('');
        window.location.href = '/';
        return;
      }
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: await this.refreshTokens(),
          };

          return http.repeatRequest(originalRequest);
        } catch (e) {
          localStorage.clear();
          AppEventEmitter.instance.emit('unauthorized');
        }
      } else {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          AppEventEmitter.instance.emit('unauthorized');
        }
        throw new ApiError(error, originalRequest.url);
      }
    });
  }

  setToken(token) {
    this.http.setAuthorizationToken(token);
  }

  async refreshTokens() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();
    const session = await new Promise((resolve, reject) =>
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err) reject(err);
        resolve(session);
      })
    );
    const { accessToken } = session;
    this.setToken(accessToken.jwtToken);
    return accessToken.jwtToken;
  }

  getHealthCheck() {
    return this.http.get('/');
  }

  createDocument(formData) {
    return this.http.post('/document', formData);
  }

  updateDocument(formData) {
    return this.http.put('/document', formData);
  }

  uploadFile(originFile, method, params) {
    const formData = new FormData();
    formData.set('method', method);
    formData.set('params', JSON.stringify(params));
    if (Array.isArray(originFile)) originFile.forEach((file) => formData.append('file', file));
    else formData.set('file', originFile);

    return this.http.post('/upload', formData);
  }

  async jsonRpc(data) {
    try {
      let result = (await this.http.post('/', data)) || {};
      if (result && result.error && result.error.code < 0) {
        return Promise.reject(new RpcApiError(result.error));
      }
      return Array.isArray(result) ? result : result && result.result;
    } catch (e) {
      return Promise.reject(new RpcApiError(e));
    }
  }
}
