import React from 'react';
import useStyles from './style';

const AuthLayout = ({ children }) => {
  const s = useStyles();

  return <div className={s.wrapperLayout}>{children}</div>;
};

export default AuthLayout;
