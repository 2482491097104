import { useCallback, useReducer } from 'react';

const reducer = (state, action) => Object.assign({}, state, action);

export default function useFormFields(defaultValues, init) {
  const [values, dispatch] = useReducer(reducer, defaultValues, init);

  const onChange = useCallback((event) => {
    const { value, name } = event.target || {};
    return dispatch({ [name]: value });
  }, []);

  return { values, onChange };
}
