import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cir: {
    width: 6,
    height: 6,
    marginRight: 6,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.dark}`,
    backgroundColor: '#fff',
  },
  itemText: {
    fontSize: '0.9rem',
    color: theme.palette.primary.dark,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:last-child': {
      textOverflow: 'ellipsis',
    },
  },
  closeBtn: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  btn: {
    width: '100%',
    padding: '0 5px',
    justifyContent: 'flex-start',
  },
  viewMore: {
    minWidth: 360,
    padding: '20px 40px',
  },
  dateWidth: {
    display: 'flex',
    alignContent: 'center',
    marginTop: '30px',
  },
  timeWidth: {
    marginLeft: '35px',
  },
  text: {
    display: 'block',
    width: '250px',
    height: '69px',
    padding: '5px',
    outline: 'none;',
    overflow: 'hidden',
  },
  btntest: {
    outline: 'none',
    '&:focus': {
      '.text': {
        height: 'auto',
      },
    },
  },
  summaryText: {
    width: '250px',
    display: 'flex',
    alignItems: 'center',
  },
  removeModal: {
    width: '300px',
    height: '220px',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 15px',
    textAlign: 'center',
    position: 'relative',
  },
  radioGroup: {
    marginLeft: '5px',
    marginTop: '15px',
  },
  btnContainer: {
    position: 'absolute',
    right: '5px',
    bottom: '10px',
  },
  cancelBtn: {
    color: '#5f6368',
  },
  radioBtn: {
    width: '30px',
    height: '30px',
  },
  instructorBlock: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0 10px 10px',
  },
  avatarText: {
    backgroundColor: '#3482ff',
    fontSize: '17px',
  },
}));

export default useStyles;
