export const ACCESS_VIEW = 50;
export const ACCESS_EDIT = 70;

export const VALIDATION_EMAIL = /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;

// MODAL

export const SUCCESS_MODE = 'SUCCESS_MODE';
export const UPDATE_MODE = 'UPDATE_MODE';

// DATABASE
export const PERM_CONTACTS = 'contacts';
export const PERM_DB_BASE = 'databaseBase';
export const PERM_DB_TEMPLATES = 'databaseTemplates';

// Assignments
export const PERM_ASSIGNMENTS = 'assignments';

// Project Management
export const PERM_STRUCTURE = 'structure';
export const PERM_FINANCIALS = 'financials';
export const PERM_SUBMITALL = 'submittal';
export const PERM_CHANGE_ORDERS = 'changeOrders';
export const PERM_RFI = 'rfi';
export const PERM_SCHEDULE_PERMITS_INSPECTIONS = 'schedulePermitsInspections';
export const PERM_DOCUMENTS = 'documents';
export const PERM_JOBSITE = 'jobsiteSafety';

// Post Construction
export const PERM_SERVICE_WARRANTY = 'serviceWarranty';
export const PERM_CLOSE_OUT_DOCS = 'closeOutDocs';

// Payroll
export const PERM_PAYROLL = 'payroll';

// Administration
export const PERM_USER_MANAGEMENT = 'userManagement';
export const PERM_QUICKBOOKS = 'quickBooks';

// Uses only in front
export const PERMISSIONS = [
  {
    key: 'database',
    i18nkey: 'Database',
    perms: [
      {
        key: PERM_CONTACTS,
        i18nkey: 'Contacts',
      },
      {
        key: PERM_DB_BASE,
        i18nkey: 'Base Level DB’s (materials, fixtures, labor, etc.)',
      },
      {
        key: PERM_DB_TEMPLATES,
        i18nkey: 'Templates (types for assemblies, projects, etc.)',
      },
    ],
  },
  {
    key: PERM_ASSIGNMENTS,
    i18nkey: 'Assignments',
  },
  {
    key: 'project_management',
    i18nkey: 'Project Management',
    perms: [
      {
        key: PERM_STRUCTURE,
        i18nkey: 'Structure (assemblies, utis, etc)',
      },
      {
        key: PERM_FINANCIALS,
        i18nkey: 'Financials',
      },
      {
        key: PERM_SUBMITALL,
        i18nkey: 'Submittal',
      },
      {
        key: PERM_CHANGE_ORDERS,
        i18nkey: 'Change Orders',
      },
      {
        key: PERM_RFI,
        i18nkey: 'RFI',
      },
      {
        key: PERM_SCHEDULE_PERMITS_INSPECTIONS,
        i18nkey: 'Schedule, Permits, Inspections',
      },
      {
        key: PERM_DOCUMENTS,
        i18nkey: 'Documents',
      },
      {
        key: PERM_JOBSITE,
        i18nkey: 'Jobsite Safety',
      },
    ],
  },
  {
    key: 'post_construction',
    i18nkey: 'Post Construction',
    perms: [
      { key: PERM_SERVICE_WARRANTY, i18nkey: 'Service and Warranty' },
      { key: PERM_CLOSE_OUT_DOCS, i18nkey: 'Close Out Docs' },
    ],
  },
  {
    key: PERM_PAYROLL,
    i18nkey: 'Payroll',
  },
  {
    key: 'administration',
    i18nkey: 'Administration',
    perms: [
      {
        key: PERM_USER_MANAGEMENT,
        i18nkey: 'User Management',
      },
      {
        key: PERM_QUICKBOOKS,
        i18nkey: 'QuickBooks',
      },
    ],
  },
];
