import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    zIndex: theme.zIndex.modal,
    display: 'none',
    '&$open': {
      display: 'block',
    },
  },
  open: {},
  container: {
    margin: '0 auto',
    paddingBottom: 50,
    maxWidth: (props) => props.maxWidth || 680,
  },
}));

export const ModalDialog = ({ children, maxWidth, open, TopHeaderComponent }) => {
  const styles = useStyles({ maxWidth });
  return (
    <div className={clsx(styles.root, { [styles.open]: open })}>
      <div className={styles.container}>
        {TopHeaderComponent}
        {children}
      </div>
    </div>
  );
};
