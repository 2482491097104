import React, { useContext } from 'react';
import { ROLE_ADMIN, ROLE_INSTRUCTOR, ROLE_SUPER_ADMIN, ROLE_RECRUIT } from '../../../constants';
import api, { useRpcApi } from '../../../services/api';
import AppEventEmitter from '../../../services/AppEventEmitter';
import Auth from '../../../services/helpers/amplify';
import { DEFAULT_LABELS } from './constants';

export const authContextInitialValue = {
  userToken: '',
  user: {},
  labels: DEFAULT_LABELS,
  isAuthenticated: false,
  pending: true,
  setContext: () => {},
  signIn: () => {},
  signOut: () => {},
  getUserInfo: () => {},
  updateLabels: () => {},
};

const AuthContext = React.createContext(authContextInitialValue);
const [getCurrentUser] = useRpcApi('user.getCurrentUser');
const canAccess = (roleId) => [ROLE_INSTRUCTOR, ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_RECRUIT].includes(roleId);

export function useAuth() {
  return useContext(AuthContext);
}
export const useLabels = () => useAuth().labels;

export class AuthContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...authContextInitialValue,
    };
  }

  get labels() {
    const user = this.state.user;
    return (user && user.orgSettings && user.orgSettings.labels) || DEFAULT_LABELS;
  }

  get values() {
    return {
      ...this.state,
      labels: this.labels,
      setContext: this.setContext,
      signIn: this.signIn,
      signOut: this.signOut,
      getUserInfo: this.getUserInfo,
      updateLabels: this.updateLabels,
    };
  }

  componentDidMount() {
    AppEventEmitter.instance.on('unauthorized', this.setUnauthorizedListener);
    Auth.currentAuthenticatedUser()
      .then((res) => {
        const { accessToken } = res.signInUserSession;
        api.setToken(accessToken.jwtToken);
        return getCurrentUser();
      })
      .then(this.resolveAccess)
      .catch(() => {
        this.setState({ isAuthenticated: false, pending: false });
      });
  }

  componentWillUnmount() {
    AppEventEmitter.instance.off('unauthorized', this.setUnauthorizedListener);
  }

  setContext = (data) => {
    this.setState({ ...this.state, ...data });
  };

  updateLabels = (labels) => {
    const user = this.state.user;
    const orgSettings = Object.assign({}, user.orgSettings, { labels });
    this.setState({ ...this.state, user: { ...user, orgSettings } });
  };

  resolveAccess = (user) => {
    if (user && canAccess(user.roleId)) {
      this.setState({ user, isAuthenticated: true, pending: false });
    } else {
      this.signOut();
      throw new Error('Forbidden');
    }
  };

  setUnauthorizedListener = () => {
    this.setState({ isAuthenticated: false, user: {} });
    api.setToken('');
  };

  signIn = async (login, password) => {
    return Auth.signIn(login.toLowerCase(), password)
      .then((res) => {
        const {
          signInUserSession: { accessToken },
        } = res;
        api.setToken(accessToken.jwtToken);
        return getCurrentUser();
      })
      .then(this.resolveAccess)
      .catch((e) => {
        this.signOut();
        throw e;
      });
  };

  getUserInfo = () => {
    return this.state.user;
  };

  signOut = () => {
    Auth.signOut()
      .then(() => {
        api.setToken(null);
        this.setState({ isAuthenticated: false });
      })
      .catch(console.error);
  };

  render() {
    return <AuthContext.Provider value={this.values}>{this.props.children}</AuthContext.Provider>;
  }
}
