import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import useStyles from '../style';
import { AssessmentChoose } from './AssessmentChoose';
import { AssessmentSelect } from './AssessmentSelect';

const AssessmentPanel = ({ selectedRecruits, selectAll, goToMultiAssessment, dataSchedule, onChangeScore }) => {
  const classes = useStyles();
  return (
    <div className={classes.modalPannel}>
      <div className={classes.buttonWrapper}>
        <button type="button" className={classes.assessmentButtonSecondary} onClick={() => selectAll()}>
          <ClearIcon className={classes.failIconModalPanel} />
        </button>
        <span>{selectedRecruits.length} Selected</span>
      </div>
      {dataSchedule.scoringType === 'four-numeric-stage' ? (
        <AssessmentSelect selectedRecruits={selectedRecruits} onChangeScore={onChangeScore} />
      ) : (
        <div>
          <AssessmentChoose selectedRecruits={selectedRecruits} onChangeScore={onChangeScore} actionButtons />
        </div>
      )}

      <Button
        variant="outlined"
        color="primary"
        style={{ marginLeft: 30, height: 50 }}
        onClick={() => goToMultiAssessment()}
      >
        Go to Assessment
      </Button>
    </div>
  );
};

export default AssessmentPanel;
