import { monthNames as shortMonthName } from '../../../utils/dateTimeFormat.ts';
import { canGetPrevMonth, getCurrentMonth, getNextMonth, getPrevMonth, monthNames } from '../utils.ts';

export interface UseMonthHook {
  (dateFrom: Date): {
    canGoBack: boolean;
    monthName: string;
    shortMonthName: string;
    year: number;
    prevMonth: Date;
    nextMonth: Date;
    current: Date;
  };
}
export const useMonth: UseMonthHook = (dateFrom) => ({
  canGoBack: canGetPrevMonth(dateFrom),
  prevMonth: getPrevMonth(dateFrom),
  nextMonth: getNextMonth(dateFrom),
  monthName: monthNames[dateFrom.getMonth()],
  shortMonthName: shortMonthName[dateFrom.getMonth()].toUpperCase(),
  current: getCurrentMonth(),
  year: dateFrom.getFullYear(),
});
