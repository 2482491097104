import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton } from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';
import React from 'react';

export default function SimpleDialog({ open, onClose, children, title = '', ...rest }) {
  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center" spacing={3}>
          <Grid item>{title}</Grid>
          <Grid item>
            <IconButton color="primary" onClick={onClose} edge="end" size="small">
              <IconClose />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
