import React from 'react';
import { TopBar } from '../../../components/common';
import useStyles from './style';
import { useHistory } from 'react-router-dom';
import { GridTable, ActionButtons } from 'components/common';
import { CircularProgress, Typography } from '@material-ui/core';
import moment from 'moment';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import { useRpcApi } from '../../../services/api';
const columns = [
  { id: 'title', title: 'Template name', filterField: 'title', align: 'left', xs: 4, isSort: true },
  { id: 'createdAt', title: 'Date', filterField: 'createdAt', align: 'center', xs: 4, isSort: true },
  { id: 'Actions', title: 'Actions', align: 'right', xs: 4 },
];
const [deleteTemplate] = useRpcApi('templates.deleteTemplate');

const TemplatesList = ({
  templatesData,
  limit,
  onChangeOffset,
  value,
  getData,
  loadingList,
  setSort,
  setNameSort,
  setValue,
  visible,
  setVisible,
  textPlatoon,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const onDelete = (id) => {
    deleteTemplate({ id })
      .then(() => getData())
      .catch((e) => console.error(e));
  };
  const onChangeSearch = (e) => setValue(e.target.value);
  const onClickSort = (flag, name) => {
    setSort(flag);
    setNameSort(name);
  };
  const getRows = templatesData?.map((row) => [
    <Typography
      style={{ width: 152 }}
      onClick={() => history.push(`/templates/details/${row?.id}`)}
      className={classes.typography}
      variant="body2"
      key={row?.id}
    >
      {row.title}
    </Typography>,

    <Typography style={{ width: 228 }} variant="body2" key={row?.id}>
      {moment(row.createdAt).format('YYYY-MM-DD')}
    </Typography>,
    <ActionButtons
      style={{ width: 152 }}
      actions={['edit', 'delete']}
      onEdit={() => history.push(`/templates/details/${row?.id}`)}
      onDelete={() => onDelete(row.id)}
      key={row?.id + ' Buttons'}
    />,
  ]);
  return (
    <div className={classes.container}>
      <TopBar
        onChangeSearch={onChangeSearch}
        hasSearch
        hasButton
        count={limit}
        onChangePage={onChangeOffset}
        maxWidth={900}
        title="Templates"
        value={value}
        hasBackButton
        btnTitle="Add New template"
        onButton={() => history.push('/templates/new-template')}
      />
      <SuccessNotification open={visible} textPlatoon={textPlatoon} setOpen={setVisible} />
      {loadingList ? (
        <div className={classes.widthNotRecruit}>
          <CircularProgress />
        </div>
      ) : getRows.length ? (
        <GridTable onSort={onClickSort} columns={columns} rows={getRows} sortTab />
      ) : (
        <div className={classes.widthNotRecruit}>
          <p>
            There are no templates in the table.{' '}
            <span onClick={() => history.push('/templates/new-template')} className={classes.titleAdd}>
              Add new template!
            </span>
          </p>
        </div>
      )}
    </div>
  );
};
export default TemplatesList;
