import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      // color: theme.palette.common.white,
    },
    absolute: {
      position: 'absolute',
    },
  })
);

export const Loader = ({ open = false, fixed = false, className, ...rest }) => {
  const styles = useStyles();

  return (
    <Backdrop className={clsx(styles.root, className, { [styles.absolute]: !fixed })} open={open} {...rest}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
