import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#f3f4f7',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
}));

export default useStyles;
