import { useState } from 'react';

export const useCustomScenarioStore = () => {
  const [state, dispatch] = useState({
    name: '',
    shortName: '',
    assessment: '',
    locationName: '',
    address: '',
    latitude: '',
    organization: null,
    longitude: '',
    search: '',
    customFields: [],
    scoringType: 'four-numeric-stage',
  });

  const actions = {
    addFieldsAfteJuggling: (fields) => {
      dispatch({
        ...state,
        customFields: fields,
      });
    },
    addFieldsFromDB: (fields) => dispatch({ ...state, customFields: fields }),
    addField: (fieldName) => {
      if (state.customFields.find((el) => el.name === fieldName)) return;
      dispatch({
        ...state,
        customFields: [...state.customFields, { name: fieldName, value: '' }],
      });
    },
    completeCustomTextFields: (value, name, index) => {
      const arr = state.customFields;
      arr[index].value = value;
      dispatch({ ...state, customFields: arr });
    },
    deleteField: (name) => {
      let filteredFields = state.customFields.filter((el) => el.name !== name);

      dispatch({ ...state, customFields: filteredFields });
    },
    changeName: (name) => name.split(' ').join('')?.length < 120 && dispatch({ ...state, name }),
    changeShortName: (shortName) => shortName.split(' ').join('')?.length < 120 && dispatch({ ...state, shortName }),
    changeLocationName: (locationName) => dispatch({ ...state, locationName }),
    changeAddress: (address) => dispatch({ ...state, address }),
    changeCoords: (coords) => dispatch({ ...state, latitude: coords?.lat, longitude: coords?.lng }),
    changeScoringType: (scoringType) => dispatch({ ...state, scoringType }),
    changeSearch: (search) => dispatch({ ...state, search }),
    setTemplate: dispatch,
    setScenario: dispatch,
  };

  return [state, actions, null];
};
