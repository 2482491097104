import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import Evaluation from '../../../../components/common/Evaluation/Evaluation';
import MediaView from './MediaView';

const fieldsMap = {
  comment: { label: 'Updated the comment' },
  evaluation: { label: 'Updated the evaluation' },
  photo: { label: 'Photo' },
  video: { label: 'Video' },
};

export default function ChangeLogRow({ field = '', fieldData = {}, classes }) {
  const fieldInfo = fieldsMap[field] || {};
  const isEvaluation = field === 'evaluation';
  const isPhoto = field === 'photo';
  const isVideo = field === 'video';

  return (
    <Grid item xs={12} className={classes.chLogRow}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={4}>
          <Typography color="textSecondary">{fieldInfo.label || 'Field'}</Typography>
        </Grid>
        <Grid item xs={4}>
          {isEvaluation ? (
            <Evaluation evaluation={fieldData.old} />
          ) : (isPhoto || isVideo) && Array.isArray(fieldData.old) ? (
            fieldData.old.map((_v, ind) => <Typography key={ind}>Removed {isPhoto ? 'image' : 'video'}</Typography>)
          ) : (
            <Typography>{fieldData.old}</Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          {isEvaluation ? (
            <Evaluation evaluation={fieldData.new} />
          ) : (isPhoto || isVideo) && Array.isArray(fieldData.new) ? (
            fieldData.new.map((id, ind) => <MediaView fileId={id} type={isPhoto ? 'image' : 'video'} key={ind} />)
          ) : (
            <Typography>{fieldData.new}</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
