import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '40px auto',
    position: 'relative',
  },
  assessmentButtonFail: {
    backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    width: 20,
    height: 20,
    marginLeft: 10,
    borderRadius: '50%',
    marginRight: 5,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
      backgroundColor: 'red',
    },
  },
  failIcon: {
    color: '#fff',
    width: 15,
    height: 15,
    borderRadius: 25,
  },
}));

export default useStyles;
