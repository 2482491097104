import React, { useEffect, useState } from 'react';
import { TopBar } from 'components/common';
import { useRpcApi } from '../../../services/api';
import FormInstructor from './Form';
import { useParams } from 'react-router-dom';
import { useLabels } from '../../../components/contexts/AuthContext';

const [getUser] = useRpcApi('getUser');

const NewInstructor = ({ getUsers, visible, setVisible, activeUser }) => {
  const [currentUser, setCurrentUser] = useState();
  const { id } = useParams();
  const labels = useLabels();

  useEffect(() => {
    if (id)
      getUser({ id })
        .then((res) => setCurrentUser(res))
        .catch((e) => console.error(e));
  }, [id]);

  return (
    <>
      <TopBar
        title={id ? `Edit ${labels.instructor}` : `Create New ${labels.instructor}`}
        hasBackButton
        backText="Cancel"
        maxWidth={944}
      />

      <FormInstructor
        getUsers={getUsers}
        currentUser={currentUser}
        visible={visible}
        setVisible={setVisible}
        activeUser={activeUser}
      />
    </>
  );
};

export default NewInstructor;
