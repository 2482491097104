import { Button, CircularProgress, Typography } from '@material-ui/core';
import { GridTable, TopBar, Evaluation } from 'components/common';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sortByName } from 'utils/helpers';
import { getEventStatus } from '../../../utils/helpers';
import { useRpcApi } from '../../../services/api';
import { behaviours, columns, getTodayFilterDiapazon, monthNames, roles } from '../constants';
import useStyles from '../RecruitDetails/style';

const [getScheduleListByRecruitId, getScheduleListInRecruitId] = useRpcApi(
  'schedule.getScheduleListByRecruitId',
  'scheduleInRecruit.getScheduleListInRecruitId'
);

const CompletedScenarios = ({ currentUser }) => {
  const history = useHistory();
  const [finishScenariosList, setFinishScenariosList] = useState([]);
  const [finishScenarios, setFinishScenarios] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);
  const [scheduleRoleBehavior, setScheduleRoleBehavior] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const userId = currentUser && currentUser.id ? currentUser.id : null;

  const filterFinishScenarios = (list) => list.filter((item) => getEventStatus(item) === 'COMPLETED');

  const onClickSort = (isReverse, name) => {
    let newData = [...finishScenarios];
    let flag = isReverse === 'asc' ? true : false;

    if (name === 'name') {
      newData = newData.sort((a, b) => sortByName(a?.name, b?.name, flag));
    } else if (name === 'date') {
      newData.sort((a, b) => sortByName(a?.beginningAt, b?.beginningAt, flag));
    } else if (name === 'grade') {
      newData.sort((a, b) => sortByName(String(a.evaluation?.evaluation), String(b.evaluation?.evaluation), flag));
    }

    setFinishScenarios(newData);
  };

  const getCurrentDay = (from) => {
    let d = new Date(from);
    return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  };

  const getScheduleList = useCallback(
    async (diapazon, attr) => {
      const [eventList, evaluationList] = await getScheduleListByRecruitId({
        recruitId: userId,
        withEvaluation: true,
        order: Object.entries({ beginningAt: 'asc' }),
        filter: Object.entries({
          beginningAt: { [attr]: diapazon },
          parentId: { $ne: null },
        }),
      }).catch((e) => console.error(e));
      const list = eventList.map((event, index) => ({
        ...event,
        evaluationList: evaluationList[index],
      }));
      return list;
    },
    [userId]
  );

  const getScheduleRoleBehaviorList = useCallback(() => {
    getScheduleListInRecruitId({ recruitId: userId })
      .then((res) => setScheduleRoleBehavior(res))
      .catch(console.error);
  }, [userId]);

  useEffect(() => {
    getScheduleList()
      .then((res) => setScheduleList(res))
      .catch((e) => console.error(e));
    getScheduleList(getTodayFilterDiapazon()[1], '$lt')
      .then((res) => setFinishScenariosList(filterFinishScenarios(res)))
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, [getScheduleList]);

  useEffect(() => {
    if (finishScenariosList?.length) {
      setFinishScenarios(finishScenariosList);

      const newDataRecruits = finishScenariosList.map((i) => {
        const roleBehaviour = scheduleRoleBehavior?.find(
          (j) => j?.scheduleId === i?.id && i?.recruitId === j?.recruitId
        );
        return { ...i, roleBehaviour };
      });

      return setFinishScenarios(newDataRecruits.reverse());
    }
  }, [finishScenariosList, scheduleRoleBehavior]);

  useEffect(() => {
    if (scheduleList?.[0]?.platoonId) {
      getScheduleRoleBehaviorList();
    }
  }, [getScheduleRoleBehaviorList, scheduleList]);

  const rows = finishScenarios?.map((row) => [
    <Button onClick={() => history.push(`/scenarios/details/${row?.scenarioId}`)}>{row?.name}</Button>,
    <Typography variant="body2">{getCurrentDay(row?.beginningAt)}</Typography>,
    <Typography variant="body2">
      {+row?.roleBehaviour?.roles ? roles[+row?.roleBehaviour?.roles] : '------'}
    </Typography>,
    <Typography variant="body2">
      {+row?.roleBehaviour?.behaviours ? behaviours[+row?.roleBehaviour?.behaviours] : '------'}
    </Typography>,
    <Typography variant="body2">
      <Evaluation evaluation={row?.evaluationList[0]?.evaluation} />
    </Typography>,
  ]);
  return (
    <>
      <TopBar
        title="Completed Scenarios"
        subtitle={`${currentUser?.firstName} ${currentUser?.lastName}`}
        hasBackButton
        maxWidth={744}
      />
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : rows?.length ? (
        <GridTable columns={columns} rows={rows} onSort={onClickSort} />
      ) : (
        <div className={classes.loadingContainer}>
          <div className={classes.emptyScenarios}>Completed Scenarios are empty!</div>
        </div>
      )}
    </>
  );
};

export default CompletedScenarios;
