import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    backgroundColor: '#f3f4f7',
    height: '100%',
  },
  titleDetails: {
    fontSize: '26px',
    fontWeight: 'normal',
    marginTop: '30px',
  },
  textAreaBox: {
    width: '100%',
  },
  tablePlutton: {
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.14)',
    backgroundColor: '#ffffff',
    padding: '15px 30px',
    margin: '30px 0 0 0',
  },
  textTitle: {
    marginBottom: '10px',
  },
  sectionBlock: {
    marginBottom: '15px',
  },
  descText: {
    fontSize: '15px',
    color: '#333333',
    whiteSpace: 'break-spaces',
    fontFamily: 'sans-serif',
  },
  map: {
    marginTop: '25px',
    position: 'relative',
    marginBottom: '5px',
  },
  downArrow: {
    width: '15px',
    height: '15px',
    color: '#000000',
    cursor: 'pointer',
  },
  visibleMap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  criteriosBlock: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  criteriaTitle: {
    fontSize: '15px',
    color: '#7080a1',
    marginRight: '170px',
  },
  criteria: {
    fontSize: '15px',
    color: '#515e74',
  },
  widthHeader: {
    width: '800px',
    backgroundColor: '#ffffff',
    margin: '0 auto',
  },
  wrapperHeader: {
    width: '100%',
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
  },
  textCriteriaBox: {
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.14)',
    backgroundColor: '#ffffff',
    padding: '15px 30px 15px 30px',
    marginTop: '30px',
  },
  btnCroup: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'absolute',
    right: 20,
    bottom: 25,
  },
  btnPlus: {
    width: '36px',
    height: '50px',
    backgroundColor: '#ffffff',
    color: '#0e4bad',
    fontSize: '25px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  btnMinus: {
    marginTop: '10px',
    width: '36px',
    height: '50px',
    backgroundColor: '#ffffff',
    color: '#0e4bad',
    fontSize: '25px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  oval: {
    color: '#0e4bad',
  },
  ovalBtn: {
    position: 'absolute',
    left: 20,
    bottom: 25,
    width: '50px',
    height: '50px',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  container: {
    margin: '40px auto',
    '& > div:not(.ninl)': {
      marginBottom: 20,
    },
  },
  containerTable: {
    width: '50%',
    borderRight: '1px solid #dce2ea',
    paddingRight: 30,
    paddingLeft: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '70px',
  },
  containerTableRight: {
    paddingLeft: 30,
    paddingRight: 30,
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '70px',
  },
  titleIcon: {
    color: '#7080a1',
    marginLeft: '10px',
  },
  boxTable: {
    alignItems: 'center',
    display: 'flex',
  },
  rowInstructor: {
    padding: '15px 0',
    '&:not(:last-child)': {
      borderBottom: '1px solid #dce2ea',
    },
  },
  passText: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#515e74',
  },
  failText: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#515e74',
  },
  blockTableLeft: {
    borderBottom: '1px solid #dce2ea',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0',
  },
  blockTableRight: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0',
  },
  rootBtn: {
    borderRadius: '25px',
    backgroundColor: 'rgba(105, 216, 115, 0.2)',
    color: '#2fbe3c',
    width: '115px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customClass: {
    backgroundColor: 'rgba(112, 128, 161, 0.17)',
    color: '#515e74',
    borderRadius: '25px',
  },
  rootTablePaper: {
    padding: '10px 30px',
  },
  platoonBox: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    width: '120px',
    textAlign: 'left',
  },
  checkboxInner: {
    width: '30px',
    textAlign: 'left',
    color: 'black',
  },
  checkbox: {
    width: '30px',
    height: '30px',
    marginRight: 10,
    color: 'black',
  },
  quantityRecruit: {
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    wordBreak: 'break-word',
    textAlign: 'left',
  },
  assessmentText: {
    width: '100px',
    textAlign: 'right',
  },
  recruitButtonHeader: {
    width: 90,
  },
  recruitAvatar: {
    margin: '0 10px 0 0',
    width: '50px',
    height: '50px',
    backgroundColor: '#3482ff',
    fontSize: '17px',
  },
  avatarBtn: {
    padding: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  instructorBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  instructorAvatar: {
    margin: '0 10px 0 0',
    width: '50px',
    height: '50px',
    backgroundColor: '#3482ff',
    fontSize: '17px',
  },
  tableIcon: {
    color: '#7080a1',
  },
  timeIcon: {
    color: '#7080a1',
  },
  assessmentBox: {
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiGrid-container': {
      justifyContent: 'center',
    },
  },
  root: {
    '& .MuiInputBase-input': {
      fontSize: '14px',
      padding: 0,
      margin: 0,
    },
  },

  platoonAvatar: {
    width: '45px',
    height: '45px',
    backgroundColor: '#3482ff',
    fontSize: '17px',
    margin: 0,
    '&.ml': {
      marginLeft: 5,
    },
  },

  chLogCardBlock: {
    padding: '20px 30px',
    '&.head': {
      padding: '10px 30px',
    },
  },
  chLogRow: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  upContainer: {
    position: 'relative',
    width: '100%',
  },
  modalPannel: {
    position: 'fixed',
    width: 'calc(100% - 240px)',
    height: 65,
    top: 0,
    left: 240,
    zIndex: '1',
    paddingRight: 40,
    paddingLeft: 40,
    backgroundColor: '#ffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 'inherit',
    boxShadow: '0 0 1px 1px rgba(0,0,0,0.2)',
  },
  buttonRefresh: {
    width: 40,
    height: 40,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 150,
    alignItems: 'center',
    marginRight: 50,
  },
  assessmentButton: {
    fontWeight: '600',
  },

  assessmentButtonSecondary: {
    backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 30,
    border: 'none',
    padding: 5,
    borderRadius: 25,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  assessmentButtonFail: {
    backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none',
    padding: 10,
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  fail: {
    backgroundColor: '#ff7373',
  },
  failIconModalPanel: {
    color: '#fff',
    width: 15,
    height: 15,
    borderRadius: 25,
  },
  failIcon: {
    color: '#fff',
    width: 30,
    height: 30,
    borderRadius: 25,
  },
  passIcon: {
    backgroundColor: 'green',
    color: '#ffff',
    width: 30,
    height: 30,
    borderRadius: 25,
  },
  spanText: {
    marginRight: 10,
    color: '#ffff',
  },
}));

export default useStyles;
