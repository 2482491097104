import colors from '../common/colors';

const palette = {
  type: 'light',
  common: {
    black: colors.black,
    white: colors.white,
  },
  // primary: {
  //   ...colors.primary,
  //   inputLabel: '#4B4A4D',
  //   inputLabelFilled: '#7c7b7f',
  //   inputText: '#cac9cf',
  // },
  primary: colors.primary,
  secondary: colors.secondary,
  error: colors.error,
  warning: colors.warning,
  info: colors.info,
  success: colors.success,
  text: colors.dark.text,
  background: colors.dark.background,
  grey: colors.dark.grey,
  divider: colors.dark.divider,
  dividerSecondary: colors.dark.dividerSecondary,
  icons: colors.dark.icons,
  action: colors.dark.action,
  ...colors.dark.custom,
  // table: colors.dark.table,
  // outlinedBtn: colors.dark.outlinedBtn,
  // emailHint: colors.dark.text.success,
};

export default palette;
