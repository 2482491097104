import { makeStyles } from '@material-ui/core/styles';

const mainColor = 'rgba(0, 0, 0, 0.87)';

const useStyles = makeStyles((theme) => ({
  termsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px 30px 0 30px',
  },
  logo: {
    height: '30px',
    cursor: 'pointer',
  },
  btn: {
    borderRadius: '3px',
    background: 'none',
    color: '#00c853',
    border: 'solid 1px rgba(0, 200, 83, 0.4)',
    boxShadow: 'none',
    fontWeight: '600',
    padding: '16px 30px',
    fontSize: '15px',
    '&:hover': {
      color: 'white',
      background: '#00c853',
    },
  },
  body: {
    width: '740px',
    margin: '0 auto',
  },
  title: {
    marginTop: 20,
    marginBottom: 20,
    paddingBottom: 5,
    fontSize: 30,
    fontWeight: '600',
    color: '#1F497D',
    borderBottom: '1px solid #4F81BD',
  },
  subTitle: {
    color: '#4F81BD',
    fontSize: 16,
    fontWeight: 'bold',
  },
  desc: {
    marginBottom: 15,
    fontSize: '15px',
    color: mainColor,
    lineHeight: '24px',
    fontWeight: '600',
  },
  tab: {
    marginLeft: 50,
    marginBottom: 0,
    '& b': {
      fontWeight: '700',
    },
  },
  li: {
    marginBottom: 0,
    marginLeft: 20,
    listStyle: 'disc',
  },
  footer: {
    color: '#73738b',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    padding: '24px 0px 30px 0px',
    borderTop: '1px solid rgba(115, 115, 139, 0.2)',
    margin: '70px 30px 0 30px',
  },
}));

export default useStyles;
