const DATE_SHORT_OPTIONS = { month: 'short', day: 'numeric', year: 'numeric' };
const DATETIME_SHORT_OPTIONS = { ...DATE_SHORT_OPTIONS, hour: 'numeric', minute: 'numeric' };

/* INTL */
export function intlDateFormat(date, locale = 'en-US', options = DATE_SHORT_OPTIONS) {
  try {
    return new Date(date).toLocaleDateString(locale, options);
  } catch (e) {
    return 'Invalid Date';
  }
}

export const intlDateWithTimeFormat = (date) => intlDateFormat(date, 'en-US', DATETIME_SHORT_OPTIONS);

export const formatPrettyDateTime = (date) => {
  const today = new Date();
  const objDate = new Date(date);

  if (_dayMatch(objDate, today)) {
    return 'Today';
  } else {
    today.setDate(today.getDate() - 1);
    return _dayMatch(objDate, today) ? 'Yesterday' : intlDateWithTimeFormat(date);
  }
};

const _dayMatch = (d, t) =>
  d.getDate() === t.getDate() && d.getMonth() === t.getMonth() && d.getFullYear() === t.getFullYear();
