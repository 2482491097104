import React, { useEffect, useState, useCallback } from 'react';
import { useRpcApi } from 'services/api';
import { TopBar } from 'components/common';
import DialogContainer from 'components/common/Dialogs/DialogContainer';
import Cell from './components/Cell/Cell';
import CustomHead from './components/CustomHead/CustomHead';
import DialogChildren from './components/DialogChildren/DialogChildren';
import { useEventCreationStore } from './hooks/useEventCreationStore.js';
import { apis } from './components/constants';
import useStyles from './style';
import { getCurrentMonth, getRelevantSheduleList, getFilterDiapazon, getCorrectObject } from './utils.ts';
import SuccessModal from 'components/common/Dialogs/SuccessModal';
import SimpleSnackbar from 'components/common/Snackbar';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';

const SUCCESS_MODE = 'SUCCESS_MODE';

const [
  getPlatoonList,
  getInstructorsList,
  getScenarioList,
  createSchedule,
  getScheduleList,
  updateSchedule,
  getCriteriaList,
] = useRpcApi(...apis);

const Calendar = () => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [store, actions] = useEventCreationStore({});
  const [platoonsData, setPlatoonsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [instructorsData, setInstructorsData] = useState([]);
  const [scenariosData, setScenariosData] = useState([]);
  const [schedulesData, setSchedulesData] = useState([]);
  const [activeMonth, changeMonth] = useState(new Date(getCurrentMonth()));
  const [cellsData, setCellsData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [scheduleData, setScheduleData] = useState({});
  const [shedleInstructors, setShedleInstructors] = useState([]);
  const [idSchedule, setIdSchedule] = useState('');
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState({
    scenario: false,
    time: false,
    date: false,
    endDate: false,
    errorTime: '',
    errorDate: '',
  });

  const getCategoriesList = useCallback(() => {
    return getCriteriaList({
      order: Object.entries({ name: 'asc' }),
    })
      .then((res) => setCategoryData(res?.items))
      .catch(console.error);
  }, []);

  const getPlatoonsList = useCallback(() => {
    return getPlatoonList({
      order: Object.entries({ name: 'asc' }),
    })
      .then((res) => setPlatoonsData(res?.items))
      .catch(console.error);
  }, []);

  const getInsructorsList = useCallback(() => {
    return getInstructorsList()
      .then((res) =>
        setInstructorsData(res?.items.sort((a, b) => (a.fullName?.toLowerCase() > b.fullName?.toLowerCase() ? 1 : -1)))
      )
      .catch(console.error);
  }, []);

  const getScenariosList = useCallback(() => {
    return getScenarioList({
      order: Object.entries({ name: 'asc' }),
    })
      .then((res) => setScenariosData(res?.items.filter((e) => e.visibleScenario === true)))
      .catch(console.error);
  }, []);

  const getSchedulesList = useCallback(() => {
    return getScheduleList({
      filter: Object.entries({
        beginningAt: { $between: getFilterDiapazon(activeMonth) },
        parentId: { $ne: null },
      }),
      order: Object.entries({ beginningAt: 'asc' }),
    })
      .then((res) => setSchedulesData(res))
      .catch(console.error);
  }, [activeMonth]);

  useEffect(() => {
    getSchedulesList();
  }, [getSchedulesList]);

  useEffect(() => {
    setCellsData(getRelevantSheduleList(schedulesData, activeMonth));
    getPlatoonsList();
    getInsructorsList();
    getScenariosList();
    getCategoriesList();
  }, [activeMonth, getPlatoonsList, getInsructorsList, getScenariosList, schedulesData, getCategoriesList]);

  const onChange = () => {
    setScheduleData({});
    setIdSchedule('');

    // getSchedulesList();
    setError({
      scenario: false,
      date: false,
      endDate: false,
      time: false,
    });
    setShedleInstructors([]);
    actions.setEvent({
      scenarioId: '',
      platoonId: '',
      recurrence: null,
      activeReccurence: null,
      startTime: { h: '', m: '', format: '' },
      startDate: { d: '', m: '', y: '' },
      endDate: { d: '', m: '', y: '' },
      instructors: [],
      duration: 0,
      criterioId: '',
    });
    setOpen((prev) => !prev);
  };

  const onSubmit = () => {
    if (!idSchedule) {
      if (
        !store?.scenarioId ||
        !store?.startDate?.d ||
        (!store?.endDate?.d && store.recurrence) ||
        !store?.startTime?.h ||
        !store?.startTime?.m ||
        error?.errorTime ||
        error?.errorDate
      ) {
        setError({
          ...error,
          scenario: !store?.scenarioId && true,
          date: !store?.startDate?.d && true,
          endDate: !store?.endDate?.d && store.recurrence,
          time: !store?.startTime?.h || !store?.startTime?.m,
        });
      } else {
        setError({
          scenario: false,
          endDate: false,
          date: false,
          time: false,
        });
        let correctObject = getCorrectObject(store);
        createSchedule(correctObject)
          .then(() => {
            setOpenSuccess(true);
            setOpen(false);
          })
          .catch((e) => setErrorMessage(e?.message))
          .finally(() => {
            actions.setEvent({ ...store });
            setScheduleData({});
          });
      }
    } else {
      let correctObject = getCorrectObject(store);
      updateSchedule({ id: idSchedule, ...correctObject })
        .then(() => setVisible(true), setOpen(false))
        .catch((e) => setErrorMessage(e?.message))
        .finally(() => getSchedulesList());
    }
  };

  const createMore = () => {
    setOpenSuccess(false);
    onChange();
  };

  return (
    <>
      {errorMessage && <SimpleSnackbar message={errorMessage} onClose={() => setErrorMessage('')} />}
      <div className={classes.wrapperWidth}>
        <SuccessModal
          onSubmit={createMore}
          textBtn="Create one more"
          open={openSuccess}
          title={'Schedule successfully created'}
          mode={SUCCESS_MODE}
          onClose={() => {
            setOpenSuccess(false);
            setOpen(false);
            getSchedulesList();
          }}
        />
        <SuccessNotification open={visible} setOpen={setVisible} text="Schedule Scenario" />
        <TopBar
          title="Calendar"
          hasButton
          btnTitle={!visible && 'Schedule Scenario'}
          onButton={onChange}
          startSlot={<CustomHead activeMonth={activeMonth} onMonthChange={changeMonth} />}
        />

        <div className={classes.container}>
          {cellsData.map((e, index) => (
            <Cell
              key={index}
              data={e}
              index={index}
              activeMonth={activeMonth}
              getSchedulesList={getSchedulesList}
              setScheduleData={setScheduleData}
              setShedleInstructors={setShedleInstructors}
              setIdSchedule={setIdSchedule}
              setOpen={setOpen}
              shedleInstructors={shedleInstructors}
            />
          ))}
        </div>
      </div>

      <DialogContainer
        open={open}
        onAbort={onChange}
        title={idSchedule ? 'Edit Scheduled Scenario' : 'New Scheduled Scenario'}
        btnTitle={idSchedule ? 'Save' : 'Create Schedule'}
        onClick={onSubmit}
        haveCancel
        createMore={createMore}
      >
        <DialogChildren
          setError={setError}
          error={error}
          store={store}
          actions={actions}
          platoonsData={platoonsData}
          instructorsData={instructorsData}
          scenariosData={scenariosData}
          scheduleData={scheduleData}
          shedleInstructors={shedleInstructors}
          categoryData={categoryData}
        />
      </DialogContainer>
    </>
  );
};

export default Calendar;
