import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  '5py': { paddingTop: theme.spacing(5), paddingBottom: theme.spacing(5) },
  '4py': { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4) },
  '3py': { paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3) },
  '2py': { paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) },
  '1py': { paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) },

  '5px': { paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5) },
  '4px': { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) },
  '3px': { paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) },
  '2px': { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) },
  '1px': { paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) },
}));

const SpacingContainer = React.forwardRef(({ className, component, py = 0, px = 0, ...rest }, ref) => {
  const Component = component ? component : 'div';
  const styles = useStyles();

  return <Component ref={ref} className={clsx(className, styles[`${py}py`], styles[`${px}px`])} {...rest} />;
});

export default SpacingContainer;
