import React, { useEffect, useState } from 'react';
import useStyles from './style';
import Select from '../../../components/common/Inputs/Select';
import { Button, Container, Grid, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { FormContainer, InputBox } from '../../../components/common';
import { MultilineTextField } from '../../../components/common/Inputs';
import MapForm from '../../../components/common/MapBox';
import ButtonsGroup from '../../../components/common/Buttons/ButtonsGroup';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { changePlace } from '../../../utils/changeFieldsPlace';
import { useCustomScenarioStore } from '../../../hooks/useStoreCustomScenarioBuilder';
import { useRpcApi } from '../../../services/api';
import SelectAutocomplete from '../../../components/common/Inputs/SelectAutocomplete';
import { ROLE_SUPER_ADMIN } from '../../../constants';
import { setCorrectObject } from '../../../utils/helpers';
import SuccessModal from '../../../components/common/Dialogs/SuccessModal';
import { useHistory } from 'react-router';
import {
  defaultValue,
  defaultViewport,
  ReccurrenceList,
  SUCCESS_MODE,
  templateFields as templateFieldsFactory,
} from '../../../utils/constants';
import { useLabels } from '../../../components/contexts/AuthContext';

const [getTemplates, getOrganizationsList, createScenario] = useRpcApi(
  'templates.getTemplatesList',
  'organizations.getOrganizationsList',
  'scenario.createScenario'
);

const FromTemplate = ({ categoryData, activeUser }) => {
  const classes = useStyles();
  const labels = useLabels();
  const templateFields = templateFieldsFactory(labels);

  const history = useHistory();
  const [address, setAddress] = useState('');
  const [viewport, setViewport] = useState(defaultViewport);
  const [choosenField, setChoosenField] = useState(null);
  const [usedFileds, setUsedFields] = useState([]);
  const [activeReccurence, selectReccurence] = useState(ReccurrenceList[0]);
  const [createScenarioStore, createScenarioActions] = useCustomScenarioStore(null);
  const [activeCategory, setActiveCategory] = useState();
  const [templates, setTemplates] = useState([{ id: 0, name: 'None' }]);
  const [choosenNameTemplate, setChoosenNameTemplate] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  const [customFields, setCustomFields] = useState(templateFields);
  const [Organizations, setOrganizations] = useState([]);
  const [error, setError] = useState(null);
  const [errorOrganization, setErrorOrganization] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorScenarioISExist, setErrorScenarioISExist] = useState(null);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (activeUser.roleId === ROLE_SUPER_ADMIN) {
      getOrganizationsList().then((res) => {
        const organizationsList = [];
        res.items.forEach((val) => {
          organizationsList.push({ id: val.id, title: val.name });
        });
        setOrganizations(organizationsList);
      });
    }
  }, [activeUser.roleId]);

  const isValide = () => {
    let result = true;
    // let newError = error;

    if (activeUser.roleId === ROLE_SUPER_ADMIN && !createScenarioStore.organization) {
      setErrorOrganization('Organization is required');
      result = false;
    }

    if (!createScenarioStore.name) {
      setError('Name is required');
      result = false;
    }

    return result;
  };

  const onSubmit = async () => {
    if (!isValide()) {
      return;
    }
    setLoading(true);
    try {
      const obj = {
        ...setCorrectObject({ ...createScenarioStore }),
        ...(activeCategory ? { criterioId: activeCategory } : {}),
      };
      if (!obj?.name?.trim()?.length) {
        setError('Field "Scenario Name" is required');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
        return;
      }
      const inputAddress = document.querySelector('.mapboxgl-ctrl-geocoder--input')?.value;

      const coords = {
        latitude: viewport?.latitude?.toString() || '',
        longitude: viewport.longitude?.toString() || '',
        address: inputAddress,
      };
      createScenario({ ...obj, ...coords })
        .then(() => {
          setLoading(false);
          setOpen(!open);
        })
        .catch((e) => {
          if ('scenario_exists' === e.message) {
            setErrorScenarioISExist('A Scenario with this name exists');
          }
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async () => {
    const result = await getTemplates({});
    if (result.total <= 0) {
      return null;
    }
    setTemplateList(result.items);
    const options = result.items.map((el) => ({ id: el.id, name: el.title }));
    setTemplates(options);
  };
  useEffect(() => {
    getData();
  }, []);

  const handleAddFieldValue = (event, index) => {
    const { value, name } = event.target;
    createScenarioActions.completeCustomTextFields(value, name, index);
  };

  const handleAddField = () => {
    if (choosenField && choosenField.name) {
      createScenarioActions.addField(choosenField.name);
      setCustomFields((prev) => [...prev.filter((el) => el.name !== choosenField.name)]);
      setUsedFields((prev) => [...prev, choosenField]);
      setChoosenField(null);
      return;
    }
    return;
  };

  const changePlaceFields = (nameField, direction) => {
    let resultArray = changePlace(createScenarioStore.customFields, nameField, direction);
    if (!resultArray) {
      return null;
    }
    createScenarioActions.addFieldsAfteJuggling(resultArray);
  };
  const handleDeleteField = (name) => {
    const deletedField = usedFileds.find((el) => el.name === name);
    if (deletedField) {
      setCustomFields((prev) => [...prev, deletedField]);
    }
    createScenarioActions.deleteField(name);
  };
  const handleSelectTemplate = () => {
    const template = templateList.find((el) => el.id === choosenNameTemplate.id);
    if (!template) {
      return null;
    }
    const usedFields = [];
    for (let i = 0; i < templateFields.length; i++) {
      if (template.customFields.find((el) => el.name === templateFields[i].name)) {
        usedFields.push(templateFields[i]);
      }
    }
    setUsedFields(usedFields);
    setCustomFields(templateFields.filter((el) => !usedFields.includes(el)));
    createScenarioActions.addFieldsFromDB(template.customFields);
  };
  const createOneMore = () => {
    setOpen(false);
    setViewport(defaultViewport);
    createScenarioActions.setScenario({ ...defaultValue });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Container className={classes.container}>
      <FormContainer
        titleBtn={'Save scenario'}
        onClick={onSubmit}
        maxWidth={744}
        style={{ position: 'relative' }}
        loading={loading}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ paddingBottom: 15 }}>
              Select template
            </Typography>
            <Grid container item direction="row" spacing={3}>
              <Grid item xs={9}>
                <Select
                  name="name"
                  options={templates}
                  value={choosenNameTemplate}
                  onChange={(_, template) => setChoosenNameTemplate(template)}
                  disableClearable
                />
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" color="secondary" size="medium" onClick={handleSelectTemplate}>
                  Enter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {createScenarioStore.customFields.length > 0 && (
          <Grid container direction="column" spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h5">Scenario Information</Typography>
            </Grid>
            <Grid container item direction="row" spacing={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Grid item xs={6}>
                <InputBox
                  name="name"
                  onInput={(e) => createScenarioActions.changeName(e.target.value)}
                  title={`Scenario Name (visible for ${labels.recruits})`}
                  value={createScenarioStore.name}
                  error={error || errorScenarioISExist}
                  star
                />
              </Grid>
              <Grid item xs={6}>
                <InputBox
                  name="shortCode"
                  onInput={(e) => createScenarioActions.changeShortName(e.target.value)}
                  title={`Short Code (visible for ${labels.recruits})`}
                  value={createScenarioStore.shortName}
                />
              </Grid>
            </Grid>
            {createScenarioStore.customFields.length > 0 &&
              createScenarioStore.customFields.map((el, index) => (
                <Grid container direction="column" item xs={12} key={el.name}>
                  <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <Typography variant="h5">{el.name}</Typography>
                    <IconButton className={classes.assessmentButtonFail} onClick={() => handleDeleteField(el.name)}>
                      <ClearIcon className={classes.failIcon} />
                    </IconButton>
                    <IconButton onClick={() => changePlaceFields(el.name, 2)}>
                      <ArrowUp />
                    </IconButton>
                    <IconButton onClick={() => changePlaceFields(el.name, 1)}>
                      <ArrowDown />
                    </IconButton>
                  </div>
                  <MultilineTextField
                    name={el.name}
                    onChange={(event) => handleAddFieldValue(event, index)}
                    value={el.value}
                    style={{ width: '100%' }}
                  />
                </Grid>
              ))}
            <Grid item xs={12}>
              <Typography variant="h5" style={{ paddingBottom: 15 }}>
                Select a field
              </Typography>
              <Grid container item direction="row" spacing={3}>
                <Grid item xs={9}>
                  <Select
                    name="name"
                    options={customFields}
                    value={choosenField}
                    onChange={(_, field) => {
                      setChoosenField(field);
                    }}
                    disableClearable
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button variant="contained" color="secondary" size="medium" onClick={handleAddField}>
                    Add field
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">Evaluation</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <ButtonsGroup
                    star
                    title="Scoring type"
                    value={createScenarioStore?.scoringType || activeReccurence || null}
                    selectReccurence={selectReccurence}
                    onValueChange={createScenarioActions.changeScoringType}
                    listItems={ReccurrenceList}
                  />
                </Grid>
              </Grid>
            </Grid>
            {activeUser.roleId === ROLE_SUPER_ADMIN ? (
              <Grid item xs={12}>
                <SelectAutocomplete
                  onChange={(e, id) => createScenarioActions.changeOrganization(id?.id)}
                  options={Organizations}
                  name="organization"
                  star
                  title="Organization"
                  defaulValue={createScenarioStore}
                  id={createScenarioStore?.organization}
                  error={errorOrganization}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Select
                title="Criteria"
                name="name"
                onChange={(e, indx) => setActiveCategory(indx?.id)}
                value={activeCategory}
                options={categoryData}
                nameIndex="id"
              />
            </Grid>
            <Grid item xs={12}>
              <MapForm
                viewport={viewport}
                setViewport={setViewport}
                address={address}
                setAddress={setAddress}
                createScenarioStore={createScenarioStore}
                createScenarioActions={createScenarioActions}
              />
            </Grid>
          </Grid>
        )}
      </FormContainer>
      <SuccessModal
        onSubmit={createOneMore}
        textBtn="success"
        open={open}
        onClose={() => history.push('/scenarios')}
        title={'Scenario successfully created'}
        name={createScenarioStore?.name}
        mode={SUCCESS_MODE}
      />
    </Container>
  );
};

export default FromTemplate;
