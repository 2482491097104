import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import icoHomeWork from '../../../../../components/assets/images/home_work.png';
import icoCalendar from '../../../../../components/assets/images/ico-calendar.png';
import icoHide from '../../../../../components/assets/images/ico-hide.png';
import icoInstructors from '../../../../../components/assets/images/ico-instructors.png';
import icoLogout from '../../../../../components/assets/images/ico-logout.png';
import icoPlatoon from '../../../../../components/assets/images/ico-platoon.png';
import icoRecruits from '../../../../../components/assets/images/ico-recruits.png';
import icoScenarios from '../../../../../components/assets/images/ico-scenarios.png';
import icoSettings from '../../../../../components/assets/images/ico-settings.png';
import { ROLE_ADMIN, ROLE_INSTRUCTOR, ROLE_SUPER_ADMIN } from '../../../../../constants';
import { useAuth } from '../../../../contexts/AuthContext';
import useStyles from './styles';

const nawItems = [
  {
    item: 'recruits',
    img: icoRecruits,
    roles: [ROLE_INSTRUCTOR, ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  {
    item: 'scenarios',
    img: icoScenarios,
    roles: [ROLE_INSTRUCTOR, ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  {
    item: 'platoon',
    img: icoPlatoon,
    roles: [ROLE_INSTRUCTOR, ROLE_ADMIN],
  },
  {
    item: 'instructors',
    img: icoInstructors,
    roles: [ROLE_INSTRUCTOR, ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  {
    item: 'calendar',
    img: icoCalendar,
    roles: [ROLE_INSTRUCTOR, ROLE_ADMIN],
  },
  {
    item: 'admins',
    img: icoRecruits,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  },
  {
    item: 'organizations',
    img: icoHomeWork,
    roles: [ROLE_SUPER_ADMIN],
  },
];

const DashboardItem = ({ user, item, img, disableClass, roles, styles, pathname, labels }) => {
  if (roles && roles.length > 0 && !roles.includes(user.roleId)) {
    return null;
  }

  return (
    <Link to={`/${item}`} className={clsx(styles.widthItems, `/${item}` === pathname && styles.active)}>
      <img className={styles.icon} src={img} alt={item} />
      {!disableClass && <span className={styles.itemName}>{labels[item] || item}</span>}
    </Link>
  );
};

const Menu = () => {
  const { signOut, user, labels } = useAuth();
  const [disableClass, setDisableClass] = useState(false);
  const history = useHistory();
  const styles = useStyles();
  const { pathname } = useLocation();

  const onChangeClass = () => setDisableClass(!disableClass);
  return (
    <div className={clsx(styles.widthMenu, disableClass ? styles.activeMenu : styles.root)}>
      <div className={styles.sticky}>
        <div className={styles.userData} onClick={() => history.push('/settings')}>
          <Avatar className={styles.userLogo} src={user?.avatar}>
            {user.firstName?.substr(0, 1)}
            {user.lastName?.substr(0, 1)}
          </Avatar>
          {!disableClass && (
            <p className={styles.userInfo}>
              <span className={styles.userName}>{`${user?.firstName || ''} ${user?.lastName || ''}`}</span>
              <span className={styles.userEmail}>{user?.email || ''}</span>
            </p>
          )}
        </div>
        <div className={nawItems.filter((i) => i.roles.includes(user.roleId)).length > 0 ? styles.borderTop : {}}>
          {nawItems.map((i, ind) => (
            <DashboardItem
              user={user}
              disableClass={disableClass}
              key={ind}
              roles={i.roles ? i.roles : []}
              styles={styles}
              labels={labels}
              pathname={pathname}
              {...i}
            />
          ))}
          <div className={styles.logoutBorder}>
            <div onClick={() => history.push('/settings')} className={styles.widthItems}>
              <img className={styles.icon} src={icoSettings} alt="Settings" />
              {!disableClass && <span className={styles.itemName}>Settings</span>}
            </div>
            <div onClick={signOut} className={styles.widthItems}>
              <img className={styles.icon} src={icoLogout} alt="Logout" />
              {!disableClass && <span className={styles.itemName}>Logout</span>}
            </div>
          </div>
        </div>
      </div>
      <div onClick={onChangeClass} className={styles.closeMenu}>
        <img className={clsx(styles.iconHide, disableClass && styles.iconRotate)} src={icoHide} alt="Hide" />
        {!disableClass && <span className={styles.itemName}>Hide</span>}
      </div>
    </div>
  );
};

export default Menu;
