import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useRpcApi } from '../../services/api';
import CompletedScenarios from './CompletedScenarios';
import NewRecruit from './NewRecruit';
import RecruitDetails from './RecruitDetails';
import RecruitsTable from './RecruitsTable';
import useStyles from './style';

const [getUser] = useRpcApi('getUser');

const Recruits = ({ user }) => {
  const { pathname } = useLocation();
  const [currentUser, setCurrentUser] = useState();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const id = pathname.split('/')[3];

  const getCurentRecruit = useCallback(() => {
    getUser({ id })
      .then((res) => setCurrentUser(res))
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (id) getCurentRecruit();
    else setCurrentUser('');
  }, [getCurentRecruit, id]);

  return (
    <div className={classes.wrapperWidth}>
      <Switch>
        <Route exact path="/recruits">
          <RecruitsTable activeUser={user} />
        </Route>
        <Route exact path="/recruits/new-recruit">
          <NewRecruit activeUser={user} />
        </Route>
        <Route exact path="/recruits/edit-recruit/:id">
          <NewRecruit activeUser={user} visible={visible} setVisible={setVisible} />
        </Route>
        <Route exact path="/recruits/recruit-details/:id">
          <RecruitDetails
            getCurentRecruit={getCurentRecruit}
            visible={visible}
            setVisible={setVisible}
            currentUser={currentUser}
            loading={loading}
          />
        </Route>
        <Route exact path="/recruits/completed-scenarios/:id">
          <CompletedScenarios currentUser={currentUser} />
        </Route>

        <Redirect exact path="/*" to="/recruits" />
      </Switch>
    </div>
  );
};

export default Recruits;
