import { Avatar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ActionButtons, GridTable, TopBar } from 'components/common';
import RemoveModal from 'components/common/Dialogs/RemoveModal';
import { useRpcApi } from '../../../services/api';
import SuccessNotification from 'components/common/Dialogs/SuccessNotification/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import { columns } from '../constants';
import useStyles from '../style';
import { useLabels } from '../../../components/contexts/AuthContext';

const [deleteUser] = useRpcApi('deleteUser');

const Table = ({
  limit,
  getUsers,
  users: data,
  onChangeOffset,
  setValueSearch,
  setSort,
  setNameSort,
  visible,
  setVisible,
  loading,
  activeUser,
}) => {
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState('');
  const history = useHistory();
  const classes = useStyles();
  const labels = useLabels();

  useEffect(() => {
    setUsers(data);
  }, [data]);

  useEffect(() => {
    setValueSearch(value);
  }, [setValueSearch, value]);

  const onChangeSearch = (e) => setValue(e.target.value);

  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(false);
  const [selectUser, setSelectUser] = useState();
  const onChangeOpen = (id) => {
    setSelectUser(users.find((item) => item.id === id));
    setUserId(id);
    setOpen(!open);
  };
  const onDelete = () => {
    deleteUser({ id: userId }).then(() => {
      getUsers().then(() => onChangeOpen());
    });
  };

  const addMask = (number) => {
    if (!number) return;
    if (number.includes('(')) return number;
    number = number.replace('+', '');
    return [' (', number.slice(0, 3), ') ', number.slice(3, 6), ' - ', number.slice(6, 10)].join('');
  };

  const onClickSort = (flag, name) => {
    setSort(flag);
    setNameSort(name);
  };

  const rows = users?.map((row) => [
    <>
      <Avatar className={classes.avatarText} src={row?.avatar} alt="Avatar">
        {row?.firstName?.charAt(0) + row?.lastName?.charAt(0)}
      </Avatar>
      <Typography variant="subtitle2" align={row.align}>
        {`${row?.firstName} ${row?.lastName}`}
      </Typography>
    </>,
    <Typography variant="subtitle2" align={row.align}>
      {row.organizationInfo?.name}
    </Typography>,
    <Typography variant="subtitle2" align={row.align} style={{ wordBreak: 'break-all' }}>
      {row?.email}
    </Typography>,
    <Typography variant="subtitle2" align={row.align}>
      {addMask(row?.phoneNumber)}
    </Typography>,
    <ActionButtons
      actions={['edit', 'delete']}
      onEdit={() => history.push(`/instructors/edit-instructor/${row.id}`)}
      onDelete={() => onChangeOpen(row.id)}
    />,
  ]);

  return (
    <>
      <SuccessNotification open={visible} text={labels.instructor} setOpen={setVisible} />

      <TopBar
        title={labels.instructors}
        onChangeSearch={onChangeSearch}
        count={limit}
        btnTitle={!visible && `Add New ${labels.instructor}`}
        onButton={() => history.push('/instructors/new-instructor')}
        onChangePage={onChangeOffset}
      />
      {rows.length ? (
        <GridTable onSort={onClickSort} columns={columns} rows={rows} role={activeUser.roleId} />
      ) : (
        <div className={classes.widthNotRecruit}>
          {!loading ? (
            <p>
              There are no {labels.instructors} in the table.{' '}
              <span onClick={() => history.push('/instructors/new-instructor')} className={classes.titleAdd}>
                Add new {labels.instructor}!
              </span>
            </p>
          ) : (
            <CircularProgress />
          )}
        </div>
      )}

      <RemoveModal
        open={open}
        onClose={onChangeOpen}
        title={`Delete this ${labels.instructor}?`}
        name={`${selectUser?.firstName} ${selectUser?.lastName}`}
        onClick={onDelete}
        subText={selectUser?.email}
      />
    </>
  );
};

export default Table;
