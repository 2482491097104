import { ROLE_ADMIN, ROLE_INSTRUCTOR, ROLE_SUPER_ADMIN } from '../../constants';

export const defaultValue = {
  firstName: '',
  lastName: '',
  super: false,
  email: '',
  badgeNumber: '',
  phoneNumber: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  address: '',
  avatar: '',
  organization: null,
  d: '',
  m: '',
  y: '',
};

export const errors = {
  month: '',
  year: '',
  day: '',
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  organization: null,
};

export const columns = [
  { id: 'firstName', title: 'Full Name', align: 'left', xs: 3, isSort: true },
  { id: 'organization', title: 'Organization', align: 'left', xs: 3, sortRole: [ROLE_SUPER_ADMIN] },
  { id: 'email', title: 'E-mail', align: 'left', xs: 2, sortRole: [ROLE_INSTRUCTOR, ROLE_ADMIN] },
  { id: 'phoneNumber', title: 'Phone Number', align: 'left', xs: 2 },
  { id: '', title: '', align: 'center', xs: 1 },
  { id: 'actions', title: 'Actions', align: 'right', xs: 1 },
];

export const DEFAULT_PASSWORD = 'A12345678*a';
