import React, { useEffect, useState } from 'react';
import { TopBar } from 'components/common';
import { useRpcApi } from '../../../services/api';
import Form from './Form';
import useStyles from './style';
import { useLabels } from '../../../components/contexts/AuthContext';

const [getUser] = useRpcApi('getUser');

const NewRecruit = ({ visible, setVisible, activeUser }) => {
  const [currentUser, setCurrentUser] = useState();
  const classes = useStyles();
  const labels = useLabels();

  const id = window.location.pathname.split('/')[3];

  useEffect(() => {
    if (id)
      getUser({ id })
        .then((res) => setCurrentUser(res))
        .catch((e) => console.error(e));
  }, [id]);

  return (
    <section className={classes.wrapper}>
      <TopBar
        title={id ? `Edit ${labels.recruit}` : `Create New ${labels.recruit}`}
        hasBackButton
        backText="Cancel"
        maxWidth={944}
      />

      <div className={classes.informationSection}>
        <Form visible={visible} setVisible={setVisible} currentUser={currentUser} activeUser={activeUser} />
      </div>
    </section>
  );
};

export default NewRecruit;
