import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';
import CustomButton from '../../../components/common/Buttons/Button';
import SpacingContainer from '../../../components/common/Containers/SpacingContainer';
import SimpleDialog from '../../../components/common/Dialogs/SimpleDialog';
import DatePicker from '../../../components/common/Inputs/DatePicker';
import { generateRecruitsReport, isValidReportParams } from './actions.ts';

export default function GenerateReportDialog({ open, recruitId, onClose }) {
  const today = new Date();
  const [pending, setPending] = React.useState(false);
  const [dateFrom, setDateFrom] = React.useState(today);
  const [dateTo, setDateTo] = React.useState(today);
  const [error, setError] = React.useState('');

  const onChangeDateFrom = React.useCallback((dateFrom) => setDateFrom(moment(dateFrom).startOf('day').toDate()), []);
  const onChangeDateTo = React.useCallback((dateTo) => setDateTo(moment(dateTo).endOf('day').toDate()), []);

  const onGenerateReport = () => {
    if (!isValidReportParams({ recruitId, dateFrom, dateTo })) {
      return setError('Wrong dates');
    }

    setError('');
    setPending(true);
    generateRecruitsReport({ recruitId, dateFrom, dateTo })
      .then(() => {
        setPending(false);
        onClose();
      })
      .catch((error) => {
        setError(error.message);
        setPending(false);
      });
  };

  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      title={<Typography variant="h4">Generate Report</Typography>}
      maxWidth="sm"
    >
      <SpacingContainer px={3} py={3}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={6}>
            <DatePicker label="From" onChange={onChangeDateFrom} value={dateFrom} maxDate={today} />
          </Grid>
          <Grid item xs={6}>
            <DatePicker label="To" onChange={onChangeDateTo} value={dateTo} maxDate={today} />
          </Grid>
          {error && (
            <Grid item xs={12} component={Typography} variant="subtitle1" color="error">
              {error}
            </Grid>
          )}
          <Grid item xs={12}>
            <CustomButton onClick={onGenerateReport} loading={pending}>
              Generate
            </CustomButton>
          </Grid>
        </Grid>
      </SpacingContainer>
    </SimpleDialog>
  );
}
