import { FilledInput, FormControl, IconButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

const SearchBar = (props) => {
  const { placeholder, onChangeSearch, ...rest } = props;
  return (
    <FormControl variant="outlined">
      <FilledInput
        style={{ width: 200 }}
        placeholder={placeholder || 'Search…'}
        onChange={onChangeSearch}
        endAdornment={
          <InputAdornment position="end">
            <IconButton size="small" edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        {...rest}
      />
    </FormControl>
  );
};

export default SearchBar;
