import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRpcApi } from '../../services/api';
import debounce from '../../utils/debounce';
import NewInstructor from './NewInstructor';
import useStyles from './style';
import Table from './Table';

const LIMIT = 10;

const [getInstructorsList] = useRpcApi('getInstructorsList');

const Instructors = ({ user }) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(0);
  const [offset, setOffset] = useState(0);
  const [visible, setVisible] = useState(false);
  const [nameSotr, setNameSort] = useState('createdAt');
  const [sort, setSort] = useState('desc');
  const [loading, setLoading] = useState(true);
  const [valueSearch, setValueSearch] = useState('');
  const classes = useStyles();

  const getUsers = useCallback(async () => {
    setLoading(true);
    const [firstValue, secondValue] = valueSearch.trim().split(' ');

    let filter = [];
    let orFilter = [];

    const Op = '$ilike';

    if (firstValue && !secondValue) {
      orFilter = Object.entries({
        firstName: {
          [Op]: `%${firstValue}%`,
        },
        lastName: {
          [Op]: `%${firstValue}%`,
        },
        email: {
          [Op]: `%${firstValue}%`,
        },
        phoneNumber: {
          [Op]: `%${firstValue}%`,
        },
      });
    }
    if (firstValue && secondValue) {
      filter = Object.entries({
        firstName: {
          [Op]: `%${firstValue}%`,
        },
        lastName: {
          [Op]: `%${secondValue}%`,
        },
      });
    }

    const response = await getInstructorsList({
      limit: LIMIT,
      offset: offset * LIMIT,
      order: Object.entries({ [nameSotr]: sort }),
      orFilter,
      filter,
    });
    setUsers(
      response.items.map((i) => ({
        ...i,
      }))
    );
    setLoading(false);
    setLimit(response?.total);
  }, [nameSotr, offset, sort, valueSearch]);

  const forceSearch = useCallback(
    debounce(() => {
      getUsers();
    }, 1000),
    [getUsers, valueSearch, offset, sort, nameSotr]
  );

  useEffect(() => {
    forceSearch();
  }, [forceSearch, valueSearch, offset, sort, nameSotr]);

  const attr = {
    onChangeOffset: (num) => setOffset(num),
    getUsers,
    limit,
    users,
    setValueSearch,
    visible,
    setVisible,
    loading,
  };

  return (
    <div className={classes.wrapperWidth}>
      <Switch>
        <Route exact path="/instructors">
          <Table setSort={setSort} setNameSort={setNameSort} activeUser={user} {...attr} />
        </Route>
        <Route exact path="/instructors/new-instructor">
          <NewInstructor getUsers={getUsers} activeUser={user} />
        </Route>
        <Route exact path="/instructors/edit-instructor/:id">
          <NewInstructor getUsers={getUsers} visible={visible} setVisible={setVisible} activeUser={user} />
        </Route>

        <Redirect exact path="/*" to="/instructors" />
      </Switch>
    </div>
  );
};

export default Instructors;
