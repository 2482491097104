import { makeStyles } from '@material-ui/core/styles';

const displayCenter = {
  display: 'flex',
  alignItems: 'center',
};

export const useStyles = makeStyles((theme) => ({
  paper: {
    ...displayCenter,
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    width: 400,
    height: 330,
    borderRadius: '20px',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 40px 0 rgba(0, 0, 0, 0.2)',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    '&:focus': {
      outline: 'none',
    },
  },
  widthModall: {
    ...displayCenter,
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  widthIconSuccess: {
    width: '40px',
    height: '36px',
  },
  modalTitle: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#000000',
    marginTop: '20px',
  },
  modalTitleUser: {
    fontSize: '15px',
    color: '#7080a1',
    textAlign: 'center',
    width: '80%',
  },
  modalTitleClose: {
    fontSize: '14px',
    color: '#515e74',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.4',
    },
  },
  // modallButoon: {
  //   width: '144px',
  //   height: '40px',
  //   fontSize: '14px',
  //   fontWeight: '600',
  //   padding: '0',
  //   margin: '25px 0',
  //   borderRadius: '4px',
  //   backgroundColor: '#00c853',
  //   marginTop: '30px',
  //   color: '#ffffff',
  // },
  closModallIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: '20px 20px 0 0',
  },
}));
