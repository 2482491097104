import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconClose = (props) => {
  const { circle = false, width = 18, height = 18 } = props;

  return circle ? (
    <SvgIcon viewBox="0 0 22 22" width={width} height={height}>
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor">
        <g strokeWidth="2">
          <g>
            <g transform="translate(-878 -277) translate(520 257) translate(359 21)">
              <circle cx="10" cy="10" r="10" strokeOpacity=".399" />
              <path d="M13 7L7 13M7 7L13 13" />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  ) : (
    <SvgIcon viewBox="0 0 18 18" width={width} height={height}>
      <defs>
        <path id="rdhnibw9da" d="M0 0H18V18H0z" />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="currentColor">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-1372 -234) translate(240) translate(20 122) translate(18 99) translate(1094 13)">
                  <mask id="ivtqdpil3b" fill="#fff">
                    <use xlinkHref="#rdhnibw9da" />
                  </mask>
                  <g strokeLinecap="round" strokeLinejoin="round" mask="url(#ivtqdpil3b)">
                    <g transform="translate(1.5 1.5)">
                      <rect width="15" height="15" strokeWidth="1.2" rx="2" />
                      <g>
                        <path strokeWidth="1.2" d="M.5.5L6.5 6.5M6.5.5L.5 6.5" transform="translate(4 4)" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

IconClose.propTypes = simpleIconPropsTypes;
