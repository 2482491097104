import { Avatar, Button, Grid, IconButton, Typography, Box } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import { IconClose, IconTrash } from '../../../svg';
import { useStyles } from './style';
import defaultAvatar from '../../../assets/images/defaultAvatar.png';

const RemoveModal = ({
  open,
  onClose,
  onClick,
  header = '',
  title = '',
  name = '',
  subText = '',
  noAvatar,
  isRemove = false,
  avatar = { defaultAvatar },
  email = '',
  urlAvatar,
  textBtn = 'Delete',
  offCancelBtn,
}) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Grid container direction="column" justify="center" alignItems="center" className={classes.paper}>
        <IconButton color="primary" onClick={onClose} className={classes.closModallIcon}>
          <IconClose circle width={20} height={20} />
        </IconButton>
        <div className={classes.widthIconSuccess}>
          {isRemove ? <IconClose width={40} height={40} /> : <IconTrash width={40} height={40} />}
        </div>
        {header !== '' && (
          <Box pb={2}>
            <Typography noWrap="true" variant="h4">
              {header}
            </Typography>
          </Box>
        )}
        <Typography variant="h5">{title}</Typography>
        <div className={classes.informationInstructor}>
          {!noAvatar && avatar && <Avatar src={urlAvatar} />}
          <Grid container direction="column">
            <Typography variant="subtitle2">{name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {subText}
            </Typography>
          </Grid>
        </div>
        <Button variant="contained" size="large" className={classes.modallButoon} onClick={onClick}>
          {textBtn}
        </Button>
        {offCancelBtn && <Button onClick={onClose}>Cancel</Button>}
      </Grid>
    </Modal>
  );
};

export default RemoveModal;
