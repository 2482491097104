/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@material-ui/core';
import { FormContainer, InputBox } from 'components/common';
import SuccessModal from 'components/common/Dialogs/SuccessModal';
import SimpleSnackbar from 'components/common/Snackbar';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from '../../../../components/common/Inputs/Select';
import { SUCCESS_MODE } from '../../../../constants';
import api, { useRpcApi } from '../../../../services/api';
import { defaultValue, errors } from '../../constants';

const typeOrg = [{ type: 'Service' }, { type: 'College' }];
const [createOrganization, updateOrganization] = useRpcApi(
  'organizations.createOrganization',
  'organizations.updateOrganization'
);
const uploadFile = (file, params) => api.uploadFile(file, 'organizations.uploadOrganizationImage', params);

const FormOrganization = ({ getOrganizations, currentOrganization, id }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [errorPhoto, setErrorPhoto] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(errors);
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const onChangeOpen = () => setOpen((prev) => !prev);

  useEffect(() => {
    if (currentOrganization) {
      setValue(currentOrganization);
    }
  }, [currentOrganization]);

  const goTolist = () => {
    history.push('/organizations');
    getOrganizations();
  };

  const onChange = (e) => {
    let newValye = e.target.value;
    setError(errors);
    setValue({ ...value, [e.target.name]: newValye });
  };

  const createOneMore = () => {
    setValue(defaultValue);
    if (currentOrganization) {
      history.push('/organizations/new-organization');
    }
    onChangeOpen();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isValide = (value) => {
    let result = true;
    let newError = error;

    for (const key in value) {
      if (value.hasOwnProperty(key) && typeof value[key] === 'string') {
        value[key] = value[key].trim();
      }
    }
    if (!value.name) {
      newError = { ...newError, name: 'Name is required' };
      result = false;
    }

    if (!value.address) {
      newError = { ...newError, address: 'Address is required' };
      result = false;
    }

    if (!value.phone) {
      newError = { ...newError, phone: 'Phone is required' };
      result = false;
    }

    if (!result) {
      setError(newError);
    }
    return result;
  };

  const onSubmit = useCallback(() => {
    if (!isValide(value)) return;

    const errorHandler = (error) => {
      if (error.message === 'value too long for type character varying(255)') {
        setErrorMessage('Value exceeds max length 255 characters');
      }
    };

    setLoading(true);

    const apiCallPromise = id ? updateOrganization({ ...value, id }) : createOrganization(value);
    apiCallPromise
      .then((value) => (file ? uploadFile(file, { id: value.id }) : Promise.resolve()))
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
        setOpen(!open);
      });
  }, [value, updateOrganization, id, createOrganization, isValide, file]);

  return (
    <FormContainer
      titleBtn={id ? 'Save changes' : 'Create Organization'}
      onClick={onSubmit}
      loading={loading}
      hasImg
      file={file}
      setFile={setFile}
      avatar={value.image?.url}
      errorPhoto={errorPhoto}
      setErrorPhoto={setErrorPhoto}
    >
      <Grid container direction="column" spacing={6}>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Organization Information</Typography>
          </Grid>

          <Grid item xs={6}>
            {errorMessage && <SimpleSnackbar message={errorMessage} onClose={() => setErrorMessage('')} />}
            <InputBox onInput={onChange} name="name" title="Name" value={value.name || ''} error={error.name} star />
          </Grid>
          <Grid item xs={6}>
            <InputBox
              onInput={onChange}
              name="address"
              title="Address"
              value={value.address || ''}
              error={error.address}
              star
            />
          </Grid>
          <Grid item xs={6}>
            <InputBox
              onInput={onChange}
              name="phone"
              title="Phone"
              value={value.phone || ''}
              error={error.phone}
              star
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              title="Type"
              name="type"
              nameIndex={'type'}
              onChange={(e, val) => setValue({ ...value, ...val })}
              value={value.type}
              getOptionLabel={(option) => option.type}
              options={typeOrg}
            />
          </Grid>
        </Grid>
      </Grid>

      <SuccessModal
        open={open}
        onSubmit={createOneMore}
        onClose={goTolist}
        title={id ? 'Organization successfully updated' : 'Organization successfully created'}
        name={`${value.name}`}
        mode={SUCCESS_MODE}
      />
    </FormContainer>
  );
};

export default FormOrganization;
