export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getTimeInFormatAMPM = (date: Date): string => {
  let hours = date.getHours();
  let minutes: string = date.getMinutes() + '';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = +minutes < 10 ? String(minutes).padStart(2, '0') : minutes;
  return `${hours}:${minutes} ${hours >= 12 ? 'pm' : 'am'}`;
};
