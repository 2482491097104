import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    overflow: 'auto',
  },
}));

export default useStyles;
